import React from "react";
import { Grid, Typography, Checkbox, IconButton } from "@material-ui/core";
import { withRouter } from "react-router-dom";
// Components
import { InputBase, Autocomplete } from "../";
// Icons
import { TrashIcon } from "../svg";
// Styles
import qdmstyles from "./styles.json";

function Statuslist(props) {
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {}, [state]);

  const {
    data,
    index,
    drugStatusOptions,
    entityNameOptions,
    setFun = () => {},
    stateName,
    statevalue,
    onDeleteClick = () => {},
  } = props;

  return (
    <React.Fragment>
      <Grid
        key={"0"}
        id={"yYTdx"}
        container={true}
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        lg={"12"}
        md={"12"}
        sm={"12"}
        xl={"12"}
        xs={"12"}
        spacing={"1"}
        style={qdmstyles.NVcG}
      >
        <Grid
          key={"0"}
          id={"yYTdx"}
          container={""}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={1}
          md={1}
          sm={1}
          xl={1}
          xs={1}
          item={true}
        >
          <Typography
            key={"0"}
            id={"FG5WL"}
            align={"inherit"}
            color={"initial"}
            display={"initial"}
            variant={"body1"}
            children={data?.entityType?.label}
            style={qdmstyles.KBgPH}
          />
        </Grid>
        <Grid
          key={"1"}
          id={"yYTdx"}
          container={""}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={"2"}
          md={"2"}
          sm={"2"}
          xl={"2"}
          xs={"2"}
          item={true}
        >
          <Typography
            key={"0"}
            id={"hgfNC"}
            align={"inherit"}
            color={"initial"}
            display={"initial"}
            variant={"body1"}
            children={
              entityNameOptions?.filter(
                (x) =>
                  x?.value === statevalue?.entityCode ||
                  x?.value === statevalue?.entityCode?._id
              )?.[0]?.name
            }
            style={qdmstyles.fZKC}
          />
        </Grid>
        <Grid
          key={"2"}
          id={"yYTdx"}
          container={""}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={"2"}
          md={"2"}
          sm={"2"}
          xl={"2"}
          xs={"2"}
          item={true}
        >
          <Autocomplete
            id={"s66D7"}
            style={qdmstyles?.sD}
            getOptionLabel={(option) => option?.["label"]}
            options={drugStatusOptions ?? []}
            placeholder="Status"
            value={statevalue?.status}
            onLoad={() => setFun(stateName, "status", statevalue?.status)}
            onChange={(event, newValue) =>
              setFun(stateName, "status", newValue)
            }
          />
        </Grid>
        <Grid
          key={"3"}
          id={"yYTdx"}
          container={""}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={1}
          md={1}
          sm={1}
          xl={1}
          xs={1}
          item={true}
        >
          <Checkbox
            key={"0"}
            id={"KvhLv"}
            color={"default"}
            size={"small"}
            style={qdmstyles.uEXR}
            checked={statevalue?.formulary}
            onChange={(event) =>
              setFun(stateName, "formulary", event.target.checked)
            }
          />
        </Grid>
        <Grid
          key={"4"}
          id={"yYTdx"}
          container={""}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={"2"}
          md={"2"}
          sm={"2"}
          xl={1}
          xs={1}
          item={true}
        >
          <InputBase
            key={"0"}
            id={"ysf9u"}
            label={""}
            placeholder={"select"}
            type={"date"}
            value={statevalue?.effFr}
            onChange={(event) => {
              setFun(stateName, "effFr", event.target.value);
            }}
          />
        </Grid>
        <Grid
          key={"5"}
          id={"yYTdx"}
          container={""}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={"2"}
          md={"2"}
          sm={"2"}
          xl={"2"}
          xs={"2"}
          item={true}
        >
          <InputBase
            key={"0"}
            id={"EpEab"}
            label={""}
            placeholder={"select"}
            type={"date"}
            value={statevalue?.effTo}
            onChange={(event) => {
              setFun(stateName, "effTo", event.target.value);
            }}
          />
        </Grid>
        <Grid
          key={"6"}
          id={"yYTdx"}
          container={""}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={"2"}
          md={"2"}
          sm={"2"}
          xl={"2"}
          xs={"2"}
          item={true}
          style={qdmstyles.nZQc}
        >
          <InputBase
            key={"0"}
            id={"SX3Jx"}
            label={"Type here"}
            placeholder={"Type here"}
            type={"text"}
            style={{ flex: 1 }}
            inputProps={{
              maxlength: 100,
            }}
            value={statevalue?.reason}
            onChange={(event) => {
              setFun(stateName, "reason", event.target.value);
            }}
          />
          <IconButton onClick={onDeleteClick}>
            <TrashIcon />
          </IconButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withRouter(Statuslist);
