/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2020-11-27
 * @modify date 2021-02-03
 * @desc Collection of all the routes used in the application.
 */

export const routes = {
  //Auth routes
  signIn: "/signin",
  availabilitymanagement: "/availabilitymanagement",
  practitionermaster: "/practitionermaster",
  repository: "/repository",
  administration: "/administration",
  // addpractitioner: "/add-practitioner",
  // timetable: "/timetable",
  createTimeTable: "/createTimeTable",
  createappointment: "/createappointment",
  addpractitioner: "/addpractitioner",
  addlocationMater: "/addlocationmaster",
  consult: "/consultMaster",
  organization:"/organization",
  parameterSetup:"/parameterSetup",
  organizationConfig:"/organizationConfig",
  addhealthcareservice: "/addhealthcareservice",
  addspeciality: "/addspeciality",
  addperson:"/addperson",
  frequency: "/frequency",
  addFrequency: "/addnewfrequency_org",
  editFrequency: "/editnewfrequency_org",
  DrugMasters: "/drug",
  addNewDrug: "/addnewdrug_org",
  editNewDrug: "/editnewdrug_org",
  alertRestriction: "/alert_restriction"
};

export default routes;
