import React from "react";
import { Div } from "qdm-component-library";
import "../../practitionermaster/addPractotioner/style.css";
import ShowList from "../addPractotioner/showList";
import { withStyles } from "@material-ui/core";
import { withAllContexts } from "../../../HOCs";
import moment from "moment";

const styles = (theme) => ({
  muiPopovers: {
    boxShadow: "0px 10px 75px #0000003D",
    borderRadius: 8,
  },
});

class Submitrole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addAddress: null,
      addCollapse: true,
      editadd: {},
      editindex: null,
    };
  }

  OpenAddress = async (e) => {
    this.setState({
      addAddress: e?.currentTarget ?? null,
    });
  };
  handleClear = () => {
    this.setState({
      editadd: {},
      editindex: null,
    });
  };

  adCollapse(e) {
    this.setState({
      addCollapse: !this.state.addCollapse,
    });
  }

  onedit = async (v, i, e) => {
    if (this.props?.onedit) {
      this.props.onedit(v, i, e);
    }
    this.setState({
      editadd: { ...v },
      editindex: i,
    });
    this.OpenAddress(e);
  };

  ondelete = (val, index) => {
    if (this.props?.ondelete) {
      this.props.ondelete(val, index)
    }
    // let patientData = this.props[this.props.saveto];
    // patientData.training.splice(i, 1);
    // patientData[this.props.setTo]({
    //   ...patientData,
    // });
  };

  render() {
    let { addCollapse } = this.state;
    const { practitionerbyrole } = this.props[this.props.saveto];
    const { parent_id } = this.props;
    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root">
        <Div
          id={`${parent_id}-parent-sub-div`}
          className="pa-showList">
          {practitionerbyrole?.map((val, i) => {
            return (
              <div
                id={`${parent_id}-${i}-ShowList-div`}
                style={{ marginBottom: 12 }}>
                <ShowList
                  parent_id={'submitrole' + i}
                  // index={i + 1}
                  // title={'Selected Role Name'}
                  // description={'13 Mar, 2021' + " " + '19 Jan, 2022' + " " + 'Cardiology' + " " + 'Emergency Unit 1' + " " + 'Contact ( Mobile Phone | 91 9840426348 )'}
                  // ondelete={() => this.ondelete(val, i)}
                  // onedit={(e) => this.onedit(val, i, e)}
                  index={i + 1}
                  title={val?.select_entity?.label ?? ""}
                  description={
                    (val?.select_role?.label ?? "") +
                    (val.speciality?.label ? ", " + val.speciality?.label : "") +
                    // (val.select_entity?.label ? ", " + val.select_entity?.label : "") +
                    (val.entity_name?.label ? ", " + val.entity_name?.label : "") +
                    (val.select_location?.label ? ", " + val.select_location?.label : "") +
                    (val.start_date ? ", " + moment(val.start_date).format("Do' MMM YYYY") : "") +
                    (val.end_date ? ", " + moment(val.end_date).format("Do' MMM YYYY") : "")
                  }
                  ondelete={() => this.ondelete(val, i)}
                  onedit={(e) => this.onedit(val, i, e)}
                  // onswitch={true}
                  checked={val.status ?? false}
                />
              </div>
            );
          })}
        </Div>
        {/* </Paper> */}
      </Div>
    );
  }
}

export default withStyles(styles)(withAllContexts(Submitrole));
