import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import AppBar from "@material-ui/core/AppBar";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
// import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import DenseTable from "./availabilitymanagement/holidaymasterstable";
import TimeTable from "./availabilitymanagement/timetable";
import ResourcebyType from "./availabilitymanagement/resourceByAppointmentTypes";
import AppointmentType from "./availabilitymanagement/appointmentTypes";
import "./font.css";
import { CommonTabList } from "../components/common";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,

    "& .MuiTab-textColorPrimary": {
      "&.Mui-selected": {
        color: "#0071F2",
      },
    },
    "&.MuiPaper-elevation4": {
      shadow: "none",
    },
    "& .MuiTab-root": {
      [theme.breakpoints.up("xs")]: {
        minWidth: "inherit !important",
      },
    },
    "& .MuiAppBar-root": {
      width: "80%",
      height: "56px",
    },
  },
  MuiTab: {
    root: {
      minWidth: 0,
      "@media (min-width: 0px)": {
        minWidth: 0,
      },
    },
  },
  box: {
    padding: "24px",
    paddingBottom: "43px",
    backgroundColor: "#F6F6F6",
    // height: "100vh",
    height: "100%",
    paddingTop: "14px",
  },
}));
function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;
  useEffect(() => {
    console.log("availabilityData", props.availabilityData);
  });

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.box}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     "aria-controls": `scrollable-auto-tabpanel-${index}`,
//   };
// }

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <Grid container spacing={0}>
      <Grid item md={12}>
        <div className={classes.root}>
          {/* <div style={{ display: "flex" }}>
            <div
              style={{
                paddingTop: "14px",
                paddingLeft: "25px",
                marginTop: "10px",
                fontFamily: "poppinsemibold",
                fontSize: "14px",
                width: "198px !important",
              }}
            >
              Availablility Management
            </div>
            <AppBar
              position="static"
              color="default"
              style={{ boxShadow: "none", zIndex: "auto" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className={classes.root}
              >
                <Tab
                  style={{
                    fontSize: "12px",
                    textTransform: "unset",
                    fontFamily: "poppinsemibold",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginTop: "10px",
                    marginLeft: "20px",
                  }}
                  className={classes.color}
                  label="Holiday"
                  {...a11yProps(1)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    textTransform: "unset",
                    fontFamily: "poppinsemibold",
                    paddingLeft: "0px",
                    marginTop: "10px",
                    paddingRight: "0px",
                    marginLeft: "20px",
                  }}
                  label="Time table"
                  {...a11yProps(2)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    textTransform: "unset",
                    fontFamily: "poppinsemibold",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginTop: "10px",
                    marginLeft: "20px",
                  }}
                  label="Resource by appointment types"
                  {...a11yProps(3)}
                />
                <Tab
                  style={{
                    fontSize: "12px",
                    textTransform: "unset",
                    fontFamily: "poppinsemibold",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginTop: "10px",
                    marginLeft: "20px",
                  }}
                  label="Appointment types"
                  {...a11yProps(4)}
                />
              </Tabs>
            </AppBar>
          </div> */}
          {/* <TabPanel value={value} index={0} style={{ padding: "0px" }}>
            <div style={{ borderRadius: "10px", width: "99%", padding: "0px" }}>
              <DenseTable />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div style={{ borderRadius: "10px", width: "99%", padding: "0px" }}>
              <TimeTable />
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div style={{ borderRadius: "10px", width: "99%", padding: "0px" }}>
              <ResourcebyType />
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div style={{ borderRadius: "10px", width: "99%", padding: "0px" }}>
              <AppointmentType />
            </div>
          </TabPanel> */}

          {/* New Code.......................... */}

          <CommonTabList
            title="Availability Management"
            list={[
              {
                name: "Practitioner",
                component: <DenseTable />,
              },
              {
                name: "Practitioner Role",
                component: (
                  <TimeTable type={"PRACTROLE"} header={"Practitioner Role"} />
                ),
              },
              {
                name: "Practitioner Type",
                component: (
                  <ResourcebyType
                    type={"PRACTTYPE"}
                    header={"Practitioner Type"}
                  />
                ),
              },
              {
                name: "Qualification",
                component: (
                  <AppointmentType
                    type={"QUALIFICATION"}
                    header={"Qualification"}
                  />
                ),
              },
            ]}
          />
        </div>
      </Grid>
    </Grid>
  );
}
