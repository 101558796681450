import React from "react";
import { withAllContexts, withHoverDrawer } from "../../../../HOCs";
import { DrugAddEditComponent } from "clmm-masters";
import { useLocation, withRouter } from "react-router";
import { routes } from "../../../../router/routes";
import { AlertProps } from "../../../../utils";

function DrugsAddEditComponent(props) {
  const location = useLocation();

  const successMessage = (isEdit) => {
    if(isEdit){
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Updated Successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Added Successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  }

  return (
    <DrugAddEditComponent
      data={{ ...location?.state }}
      isView={location?.state?.isView}
      onBackClick={() => {
        props.history.push(routes.DrugMasters);
      }}
      onCancelClick={() => {
        props.history.push(routes.DrugMasters);
      }}
      onSaveSuccess={(data) => {
        debugger;
        props.history.push({
          pathname: routes.alertRestriction,
          state: { ...data },
        });
        successMessage(location?.state?._key);
      }}
    />
  );
}

export default withAllContexts(
  withRouter(withHoverDrawer(DrugsAddEditComponent))
);
