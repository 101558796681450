import React from "react";
import {
  Div,
  Paper,
  Col,
  Row,
  Select,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
// import Chip from '@material-ui/core/Chip';
// import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import { actions } from "primary_care_admin_binder";
// import { connect } from "react-redux";
import { SelectBox } from "../../../components/common/smartForm/component";
import { Typography } from "@material-ui/core";

class EntityCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  // componentDidMount = async () => {
  //   await this.props?.LOCATION_MASTERS_ENTITYTYPE();
  //   await this.props?.LOCATION_MASTERS_ENTITYNAME();
  // };

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
      // width: "514px",
      // height: "40px",
    },
  };

  render() {
    //let { communication } = this.props.patientData;
    const { labelStyle, borderStyle, errorBorderStyle } = this.styles;
    const { parent_id, error } = this.props;
    return (
      <Div id={`${parent_id}-parent-div`} className="pi-root">
        <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
          <Row id={`${parent_id}-parent-row`} className="pi-actions-root">
            <Col
              id={`${parent_id}-parent-col`}
              md={3}
              lg={3}
              sm={6}
              xs={12}
              inLineStyles={{ padding: 5 }}
            >
              <Select
                id={`${parent_id}-parent-col`}
                showArrow
                label="Entity Type"
                placeholder="Entity Type"
                labelStyle={labelStyle}
                //inLineStyles={borderStyle}
                inLineStyles={
                  error?.entityType ? errorBorderStyle : borderStyle
                }
                hoverColor="#0071F2"
                options={this.props.entityTypeData}
                onChangeValue={(value) =>
                  this.props.onChangeState("entityType", value)
                }
                isRequired={"true"}
                value={this.props?.entityType?.value ?? ""}
              />
            </Col>
            <Col
              id={`${parent_id}-Entity-Name-col`}
              md={6}
              lg={6}
              sm={12}
              xs={12}
              inLineStyles={{ padding: 5 }}
            >
              {/* <Select
                showArrow
                label="Entity Name"
                placeholder="Entity Name"
                labelStyle={labelStyle}
                inLineStyles={borderStyle}
                hoverColor="#0071F2"
                options={this.props.entityNameData}
                onChangeValue={(value) =>
                  this.props.onChangeState("entityName", value)
                }
                value={this.props?.entityName?.value ?? ""}
                isRequired={"true"}
              /> */}
              <Typography
                id={`${parent_id}-Entity-Name-typography`}
                style={{
                  fontSize: "12px",
                  paddingBottom: 4,
                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Entity Name{" "}
                <span
                  id={`${parent_id}-Entity-Name-star-span`}
                  style={{ color: "red" }}
                >
                  *
                </span>
              </Typography>
              <SelectBox
                parent_id={"entitycard"}
                list={this.props.entityNameData}
                placeholder={"Entity Name"}
                value={this.props?.entityName ?? ""}
                onchange={(e, value) =>
                  this.props.onChangeState("entityName", value)
                }
                error={error?.entityName}
              />
            </Col>
          </Row>
        </Paper>
      </Div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   entityNameData: state?.locationMasterSlice?.location_masters_entityname,
//   entityTypeData: state?.locationMasterSlice?.location_masters_entitytype,
// });

// export default connect(mapStateToProps, actions)(withAllContexts(EntityCard));

export default withAllContexts(EntityCard);
