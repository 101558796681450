import React from "react";
import { VerticalList, } from "../../../../components";
import { MeasureCode } from "./measureCode";
import { Vitals } from "./vitalsMasters";
import { VitalsCriteria } from "./VitalsApplicableCriteria";
import { connect } from "react-redux";
import { actions } from 'primary_care_admin_binder';
class VitalsMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openIndex: null,
      currentTab:'',
      search:'',
      perPage: 10,
      page: 0,
    };
    this.handleAddBtn = this.handleAddBtn.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeState = this.changeState.bind(this);
  }
  handleAddBtn = (index) => {
    let { open } = this.state;
    this.setState({
      open: !open,
      openIndex: index,
    });
  };
  changeState = (name, value) => {
    this.setState({ [name]: value });
  };
  changeTab = () => {
    this.setState({ open: false });
  };

  currentTab = (val) => {
    this.setState({
      currentTab:val
  })
    return val
  };

  getPlaceHolder = () => {
    if(this.state?.currentTab === "measureCode" || this.state?.currentTab === "vitalsMaster" ){
      return "search code & description"
    }
    if(this.state?.currentTab === "vitalsApplicable"){
      return "search code & group"
    }
  }

  handleSearch = async(value = "") => {
   
   if(this.state?.currentTab === "measureCode"){

    if (value?.length > 0) {
      debugger
      await this.props.GET_VITALS_MEASURE_CODE({
        search:value.trim(),
        page: this.state.page,
        perPage: this.state.perPage
      });
      this.setState({
        ...this.state,
        search: value.trim(),
      });
    } else if (!value) {
     await this.props.GET_VITALS_MEASURE_CODE({
      page: this.state.page,
      perPage: this.state.perPage
     });
    }
    this.setState({
      ...this.state,
      search: ""
    });

  };

   if(this.state?.currentTab === "vitalsMaster"){

    if (value?.length > 0) {
      debugger
      await this.props.READ_VITAL_MASTER({
        search:value.trim(),
        page: this.state.page,
        perPage: this.state.perPage
      });
      this.setState({
        ...this.state,
        search: value.trim()
      });
    } else if (!value) {
     await this.props.READ_VITAL_MASTER({
      page: this.state.page,
      perPage: this.state.perPage
     });
    }
    this.setState({
      ...this.state,
      search: ""
    });

  }

   if(this.state?.currentTab === "vitalsApplicable"){

    if (value?.length > 0) {
      debugger
      await this.props.READ_VITAL_APPLICABLE({
        search:value.trim(),
        page: this.state.page,
        perPage: this.state.perPage
      });
      this.setState({
        ...this.state,
        search: value.trim()
      });
    } else if (!value) {
     await this.props.READ_VITAL_APPLICABLE({
      page: this.state.page,
      perPage: this.state.perPage
     });
    }
    this.setState({
      ...this.state,
      search: ""
    });

  }

  }


  render() {
    const { open, openIndex } = this.state;
    return (
      <React.Fragment>
        <VerticalList
          parent_id={"consult_master_vitals"}
          isLeftList={true}
          addFunc={this.handleAddBtn}
          changeTab={this.changeTab}
          // searchCmpProps starts here
          id={"consult_master_vitals"}
          placeholder={this.getPlaceHolder()}
          // loading={this.props?.searchLoading}
          onSearchChange={this?.handleSearch}
          hideSearchBar={false}
          hideFilter={true}
          // onFilterClicked={this.props?.onFilterClicked}
          // isFilterApplied={this.props?.isFilterApplied}
          TabList={[
            {
              name: "Measure code",
              component: (
                <MeasureCode
                  parent_id={"consult_master_MeasureCode"}
                  changeState={this.changeState}
                  currentTab={this?.currentTab}
                  open={open}
                  openIndex={openIndex}
                />
              ),
            },
            {
              name: "Vitals Master",
              component: (
                <Vitals
                  parent_id={"consult_master_Vitals"}
                  closeFrom={this.handleAddBtn}
                  open={open}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
            },
            {
              name: "Vitals Applicable Criterias",
              component: (
                <VitalsCriteria
                  parent_id={"consult_master_Vitals"}
                  closeFrom={this.handleAddBtn}
                  open={open}
                  openIndex={openIndex}
                  currentTab={this?.currentTab}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  
});

export default connect(mapStateToProps, actions)(VitalsMaster)
