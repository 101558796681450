/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  AppBar,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Switch,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Divider,
  IconButton,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import { DrawerProps, getUtcTime, utcTOLocal } from "../../../utils";
import { withAllContexts } from "../../../HOCs";
import styles from "./styles";
import CreateNewUser from "./createNewUser";
import Config from "../../../../src/config";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";

import CustomInputDatePicker from "../../../components/common/datePicker/inputDatePicker";

import {
  readDocument,
  getUserId,
  deleteUserToKeyClock,
  deleteDocument,
  upsertDocument,
  addRolesForUser_KeyClock,
  getPractioner,
} from "../../../function/commonapi";
import DeleteComponent from "./deleteComp";
import EditIcon from "../../../assets/icons - Edit.svg";
import DeleteIcon from "../../../assets/icons8-trash.svg";
import { withStyles } from "@material-ui/core/styles";
import searchicon from "../../../assets/icons - Search.svg";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  // Div,
  // Paper,
  // Select,
  TextInput,
  H6,
  Col,
  Row,
  Text,
  // Divider,
  // Upload,
  // Image,
} from "qdm-component-library";
import { SelectWithLabel } from "../../../components";
import "./userStyles.css";
import { DateTimePicker } from "../../../components/common/smartForm/component";
import orgCode from "../../../assets/org_code.svg";
import orgDesignation from "../../../assets/org_designation.svg";
import orgParent from "../../../assets/org_parent.svg";
import orgPersonName from "../../../assets/org_person_name.svg";
import orgAddress from "../../../assets/org_address.svg";
import moment from "moment";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#0071F2",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 12,
    height: 12,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(Switch);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const customStyles = {
  labelStyle: {
    color: "#6F6F6F",
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: "#E0E0E0",
  },
  errorBorderStyle: {
    borderRadius: 8,
    border: "1px solid red",
  },
};

function Users(props) {
  const classes = styles();
  const { labelStyle, borderStyle, errorBorderStyle } = customStyles;

  const [value, setValue] = React.useState(0);
  const [state, setState] = useState({
    statusActive: [],
    active: null,
    secondarySection: "Roles",
    UserPage: {},
    roleName: null,
  });
  const [userData, setUserData] = useState([]);
  const [userData1, setUserData1] = useState([]);
  const [loader, setLoader] = useState(true);
  const [rolesData, setRolesData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteEmail, setDeleteEmail] = useState("");
  const [roleName, setRoleName] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [selectedItem, setItem] = useState([]);
  const [selectIds, setSelectIds] = useState({
    PersonID: 1,
    PractitionerRoleID: "",
  });
  const [PersonID, setPersonID] = useState("");
  const [PractitionerRoleID, setPractitionerRoleID] = useState("");
  const [editMode, setEditMode] = useState(false);

  // Handle Detail Permission Edit
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  console.log("loader", loader);

  const handleClickOpen = (item) => {
    setDeleteId(item?.Users?.id);
    setDeleteEmail(item?.Users?.email);
    setOpen(true);
  };

  const handleClose = async (status) => {
    const { alert } = props;
    let { setSnack } = alert;
    if (status) {
      let sendPageData = {
        entity: Config.userEntity,
        metadataId: Config.metadataid,
        id: [deleteId],
        keyvalue: ["id"],
      };
      //DELETE FUNCTION for keyclock
      await deleteUserToKeyClock(deleteEmail).then(async (res) => {
        //DELETE FUNCTION for arango
        await deleteDocument(sendPageData)
          .then((res) => {
            if (res?.data?.data) {
              setSnack({
                ...alert,
                horizontal: "right",
                msg: "Document deleted successfully",
                open: true,
                severity: "success",
                vertical: "top",
              });
            }
          })
          .catch((error) => {
            alert("Document not deleted.");
          });
      });

      setOpen(false);
      getUserData();
      return;
    }
    setOpen(false);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // useEffect(() => {
  //   getUserData();
  //   getRolesData();
  //   getPractionerData(1);
  // }, []);

  const getUserData = async () => {
    try {
      let readDocParams = {
        entity: Config.personEntity,
        // isfilter: true,
        // filterName: "origin",
        // filterValue: "ORGANIZATION",
      };
      let getReadDocument = await readDocument(readDocParams);
      // setTimeout(() => {
      setUserData(getReadDocument?.data?.result ?? []);
      setUserData1(getReadDocument?.data?.result ?? []);
      setState({
        ...state,
        active: getReadDocument?.data?.result[0]?.name[0]?.text,
      });
      setPersonID(getReadDocument?.data?.result[0]?.Id);
      // }, 1000);
    } catch (error) {
      setUserData([]);
      setUserData1([]);
    }
    setLoader(false);
  };

  const getRolesData = async () => {
    try {
      let readDocParams = {
        entity: Config.codabaleConceptMaster,
      };
      let getReadDocument = await readDocument(readDocParams);
      // setTimeout(() => {
      setRolesData(getReadDocument?.data?.result ?? []);
      // }, 1000);
    } catch (error) {
      setUserData([]);
    }
  };

  const handleSaveRole = async () => {
    const { alert } = props;
    let { setSnack } = alert;
    if (roleName && userName) {
      let list = {
        username: userName,
        email: userName,
        realmRoles: [],
        role_mapping: {
          roles: roleName,
        },
      };
      // list.push(newobj)
      let tokenData = localStorage.getItem("payload");
      //
      let parsedToken = JSON.parse(tokenData);

      let sendUserData = {
        entity: Config.userEntity,
        metadataId: Config.metadataid,
        isedit: true,
        id: userId,
        list,
        keyvalue: "id",
      };

      //ASSIGN ROLES TO USER for keyclock
      let keyclockvalue = await addRolesForUser_KeyClock(userName, roleName);
      if (keyclockvalue) {
        //ASSIGN ROLES TO USER for arango
        let arangores = await upsertDocument(sendUserData);
        if (arangores) {
          // if (res?.data?.Code === 201) {
          setSnack({
            ...alert,
            horizontal: "right",
            msg: "Roles mapped successfully",
            open: true,
            severity: "success",
            vertical: "top",
          });
          // }
          await getUserData();
        }
      }
    } else {
      handleAlerts("Please Select User and Role!", false);
    }
  };

  const handleSelectedCell = (event, index, item) => {
    setState({
      ...state,
      active: item?.name[0]?.text || item?.telecom[0]?.value,
      secondarySection: item?.Users?.role_mapping?.roles,
    });
    console.log(item, "item");
    setUserName(item?.Users?.username);
    setUserId(item?.Users?.id);
    setRoleName(item?.Users?.role_mapping?.roles);
    setPersonID(item?.Id);
    getPractionerData(item?.Id);
    setItem(item);
  };

  // useEffect(()=>{
  //   getPractionerData();
  // },[selectIds.PersonID])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setState({ ...state, secondarySection: event.target.outerText });
  };

  const handleStatusCheck = (e, index, item) => {
    let { statusActive } = state;
    if (statusActive?.indexOf(item?.Users?.username) > -1) {
      statusActive.splice(statusActive?.indexOf(item?.Users?.username), 1) ??
        false;
    } else {
      statusActive = statusActive ? statusActive : [];
      statusActive.push(item?.Users?.username);
    }
    setState({
      ...state,
      statusActive,
    });
  };

  // API CALL TO GET PRACTIONER
  // let  [prevPractioner,setPrevPractioner] = useState('');
  const getPractionerData = async (_personID) => {
    try {
      let readDocParams = {
        entity: Config.practitionerEntity,
        isfilter: true,
        filterName: "PersonID",
        filterValue: _personID,
      };
      let getReadDocument = await readDocument(readDocParams);
      // setTimeout(() => {
      // // setRolesData(getReadDocument?.data?.result ?? []);
      // console.log("result",getReadDocument?.data?.result)
      if (
        getReadDocument?.data?.result?.length > 0 &&
        getReadDocument?.data?.result[0]?.Practitioner?.practitioner_role
          ?.length > 0
      ) {
        let params_prac = {
          entity: Config.practitionerRoleEntity,
          isfilter: true,
          filterName: "_id",
          filterValue:
            getReadDocument?.data?.result[0]?.Practitioner
              ?.practitioner_role[0],
        };
        let getPractRole = await readDocument(params_prac);

        setPractitionerRoleID(
          getPractRole?.data?.result[0]?.PractitionerRole?.role
        );
        // setSelectIds({...selectIds,PractitionerRoleID: getReadDocument?.data?.result[0]?.Practitioner?.id})
      } else {
        setPractitionerRoleID("");

        // setSelectIds({...selectIds,PractitionerRoleID: ''})
      }
      // }, 1000);
    } catch (error) {
      // setUserData([]);
    }
  };

  // API CALL FOR PRACTITIONER Update
  const handlePractionerUpdate = async (event, id) => {
    let list = {
      PersonID: PersonID,
      // PractitionerRoleID : event  ?  id : 0,
      _id: "",
      identifier: [],
      active: true,
      telecom: [],
      address: [],
      photo: [],
      id: 0,
      External: true,
      ReportingEntityType: "",
      ReportingEntityName: "",
      PrimarySpeciality: true,
      qualification: [],
      training_details: [],
      job_experience: [],
      publication_details: [],
      biography: [],
      achievements_awards_details: [],
      practitioner_id: new Date().getTime().toString().substr(0, 10),
      gender: "",
      birthDate: 0,
      name: [
        {
          _id: "",
          use: "",
          text: "",
          family: "",
          given: "",
          prefix: "",
          suffix: "",
          period: [
            {
              _id: "",
              start: 0,
              end: 0,
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      marital_status: "",
      status: true,
      nationality: "",
      practitioner_type: "",
      position: "",
      communication: [],
      employee_status: "",
      practitioner_role: [
        {
          _id: "",
          identifier: [],
          active: true,
          period: [],
          role: id,
          telecom: [],
          availableTime: [],
          notAvailable: [],
          availabilityExceptions: "",
          id: 0,
          HealthCareServiceID: "",
          OrgID: "",
          locationID: "",
          EntityType: "",
          EntityName: "",
          biography: "",
          experience: [],
          primary_specialty: true,
          files: [],
          SpecialtyID: "",
        },
      ],
    };

    let sendPractionerUpdate = {
      entity: Config.practitionerEntity,
      metadataId: Config.metadataid,
      list,
      // keyvalue:'PersonID',
      // id:PersonID,
      // isedit:true
    };
    if (event) {
      let sendPageData = {
        entity: Config.practitionerEntity,
        metadataId: Config.metadataid,
        id: [PersonID],
        keyvalue: ["PersonID"],
        logic: "and",
      };
      let addPractitionerdelete = await deleteDocument(sendPageData);
      let addPractitionerUpdate = await upsertDocument(sendPractionerUpdate)
        .then((res) => {
          if (res?.data?.Code === 201) {
            alert("Updated successfully");
            getPractionerData(PersonID);
          } else {
            alert(res?.data?.validation_error);
            getPractionerData(PersonID);
          }
        })
        .catch((error) => {
          alert("catch failed");
        });
    } else {
      // let sendPageData = {
      //   entity: Config.practitionerEntity,
      //   metadataId: Config.metadataid,
      //   id: [PractitionerRoleID, PersonID],
      //   keyvalue: ["PractitionerRoleID","PersonID"],
      //   logic:"and"
      // };
      let sendPageData = {
        entity: Config.practitionerEntity,
        metadataId: Config.metadataid,
        id: [PersonID],
        keyvalue: ["PersonID"],
        logic: "and",
      };
      let addPractitionerUpdate = await deleteDocument(sendPageData)
        .then((res) => {
          if (res?.data?.Code === 201) {
            getPractionerData(PersonID);

            alert("Delete successfully");
          } else {
            alert("Delete failed");
            getPractionerData(PersonID);
          }
        })
        .catch((error) => {
          alert("catch failed");
        });
    }
  };

  //API CALL FOR CHANGE USER STATUS
  const handleUserStatus = async (event, item) => {
    let list = {
      active: !item?.active,
      _key: item._key,
    };
    try {
      let delete_data = await dispatch(actions.PERSON_UPSERT(list));
      // setOpen(false);
      if (
        delete_data?.payload?.error ||
        delete_data?.payload?.data?.Code !== 201
      ) {
        handleAlerts("Person status changed unsuccessfully!", false);
        return;
      }
      setInitialize();
      handleAlerts("Person status changed successfully!", true);
    } catch (error) {
      // setOpen(false);
      handleAlerts("Person status changed unsuccessfully!", false);
    }
  };

  const handleCheck = (item, index, event) => {
    setRoleName(item?.MasterRoles?.rolename);
    // setSelectIds({...selectIds,PractitionerRoleID: item?.PractitionerRole?.id})
    setPractitionerRoleID(item?._id);
    handlePractionerUpdate(event, item?._id);
    // let { UserPage } = state;
    // if (
    //   UserPage?.[state.active]?.[state.secondarySection]?.indexOf(
    //     item?.MasterRoles?.rolename
    //   ) > -1
    // ) {
    //   UserPage?.[state.active][state.secondarySection].splice(
    //     UserPage?.[state.active]?.[state.secondarySection]?.indexOf(
    //       item?.MasterRoles?.rolename
    //     ),
    //     1
    //   ) ?? false;
    // } else {
    //   UserPage[state.active] = UserPage[state.active]
    //     ? UserPage[state.active]
    //     : {};
    //   UserPage[state.active][state.secondarySection] = UserPage[state.active][
    //     state.secondarySection
    //   ]
    //     ? UserPage[state.active][state.secondarySection]
    //     : [];

    //   UserPage[state.active][state.secondarySection] =
    //     item?.MasterRoles?.rolename;
    // }
    // setState({
    //   ...state,
    //   UserPage,
    // });
  };

  const handleAlerts = (message, status) => {
    const { alert } = props;
    let { setSnack } = alert;
    setSnack({
      ...alert,
      horizontal: "right",
      msg: message,
      open: true,
      autoHideDuration: 6000,
      severity: status ? "success" : "error",
      vertical: "top",
    });
  };

  const editUsers = async (e, item) => {
    //try{
    const { drawer } = props;

    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      // isLarge:true,
      component: (
        <CreateNewUser
          editName={item?.name[0].text || item?.telecom[0]?.value}
          userParams={item}
          //userId={getUserid?.data[0]?.id}
          getUserData={getUserData}
          handleAlerts={handleAlerts}
          incomingUserdata={userData}
        />
      ),
    });
    // }
    // catch(error){
    //   console.log(error,'============edit user userid error');
    // }
  };

  const deleteUsers = async (e, item) => {
    // let tokenData = localStorage.getItem("payload");
    // let parsedToken = JSON.parse(tokenData);
    // let getdeleteUserid = await getUserId(parsedToken.access_token,item?.Users?.username);
    // let deleteUserData = {
    //   entity:Config.userEntity,
    //   metadataId:Config.metadataid,
    //   isdelete:true,
    //   id:item?.Users?.id
    // }
    // let addUserUpsert = await upsertDocument(deleteUserData);
    // getUserData();
    // console.log("deleteUserData",deleteUserData);
  };

  const drawerOpening = () => {
    const { drawer } = props;
    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      // isLarge:true,
      component: (
        <CreateNewUser
          getUserData={getUserData}
          handleAlerts={handleAlerts}
          incomingUserdata={userData}
        />
      ),
    });
  };

  //SEARCH FOR USERS
  const searchProcessChange = (event) => {
    let val = event.target.value.toLowerCase();
    const filterDate = clonepersonList.filter((item) => {
      return (
        item?.name[0]?.text?.toLowerCase().includes(val) ||
        item?.telecom[0]?.value?.toLowerCase().includes(val)
      );
    });
    setPersonList(filterDate);
  };

  //integration by manikandan

  const dispatch = useDispatch();

  const clonepersonList = useSelector(
    (state) => state?.personSlice?.person_read?.data
  );
  const roleList = useSelector((state) => state?.rolesSlice?.role_read?.data);
  const allMasterList = useSelector(
    (state) => state?.practitionerMasterSlice?.practitioner_masters?.data
  );
  const userInfo = useSelector((state) => state?.userSlice?.user_read);

  const [personList, setPersonList] = useState(clonepersonList);
  useEffect(() => {
    setPersonList(clonepersonList);
  }, [clonepersonList]);

  React.useLayoutEffect(() => {
    // dispatch(actions.ROLE_READ());

    dispatch(actions.PRACTITIONER_MASTERS());
  }, []);
  useEffect(() => {
    setInitialize();
  }, []);
  const [currentData, setCurrentData] = useState(null);
  const [userDetails, setUserDetails] = useState({
    PersonID: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    user_id: "",
    health_care: false,
    effective_from: null,
    effective_to: null,
    speciality: "",
    reporting_entity_type: "",
    reporting_entity_name: "",
    work_location: "",
    selected_roles: [],
    select_user: {},
    error: {
      first_name: false,
      middle_name: false,
      last_name: false,
      user_id: false,
      reporting_entity_type: false,
      reporting_entity_name: false,
      selected_roles: false,
      speciality: false,
    },
    errorMsg: {
      speciality: "please enter the field",
      first_name: "please enter the field",
      middle_name: "please enter the field",
      last_name: "please enter the field",
      user_id: "please enter the field",
      reporting_entity_type: "please select the field",
      reporting_entity_name: "please select the field",
      selected_roles: "please enter the field",
    },
  });
  const setInitialize = async () => {
    try {
      const { alert } = props;
      let { setSnack } = alert;
      let person_list = await dispatch(actions.PERSON_READ({ withoutLimit: true }));
      let role_list = await dispatch(actions.ROLE_READ());

      if (person_list?.payload?.error) {
        setLoader(false);
        setSnack({
          ...alert,
          horizontal: "right",
          msg: "Something went wrong !",
          open: true,
          severity: "error",
          vertical: "top",
        });
        return;
      }
      setLoader(false);
      setUpdateUser(person_list?.payload?.data[0]);
      setCurrentData(person_list?.payload?.data[0]);
    } catch (error) { }
  };

  const setUpdateUser = async (_user) => {
    let readPractiesnerId = await dispatch(actions.USER_READ({ id: _user?._id, PersonID: _user?.Id }));
    console.log(readPractiesnerId, '--------------------------------')
    if (readPractiesnerId?.payload?.error) {
      return;
    }
    
    if (readPractiesnerId?.payload?.data?.length > 0) {
      let speciality_ = "";
      let _data = readPractiesnerId?.payload?.data[0];
      speciality_ = _data?.Practitioner?.[0]?.SpecialtyID
      let splitRole = _data?.Practitioner?.[0]?.role?.length > 0 ? _data?.Practitioner?.[0]?.role : []
      // _data?.Practitioner?.role?.length > 0
      //   ? _data?.Practitioner?.role?.map((_) => {
      //     speciality_ = _.SpecialtyID;
      //     return {
      //       ..._,
      //     };
      //   })
      //   : [];

      console.clear()
      console.log(_data?.Practitioner)
    console.log(allMasterList)
     
      setUserDetails({
        ...userDetails,
        PersonID: _user?.Id ?? "",
        _id: _user?._id,
        _key: _user?._key,
        select_user: _user ?? {},
        first_name: _data?.name?.[0]?.text ?? "",
        middle_name: _data?.name?.[0]?.given ?? "",
        last_name: _data?.name?.[0]?.family ?? "",
        user_id: _data?.Practitioner?.[0]?.userId,
        health_care: _data?.PrimarySpeciality,
        speciality:
          allMasterList?.speciality?.filter((_) => _._key === speciality_?.split('/')?.[1])[0] ??
          "",
        // effective_from: _data?.name[0]?.period[0]?.start  ??  null,
        // effective_to: _data?.name[0]?.period[0]?.end  ?? null,
        effective_from: _data?.identifier?.[0]?.period[0]?.createddate ?? null,
        effective_to: _data?.identifier?.[0]?.period[0]?.end ?? null,
        reporting_entity_type: allMasterList?.entity?.filter(
          (_) => _.value === _data?.reporting_entity_type?._id
        )[0],
        reporting_entity_name: allMasterList?.entity_name?.filter(
          (_) => _._id === _data?.reporting_entity_name?._id
        )[0],
        work_location: "",
        selected_roles: splitRole,
        selected_practiesner: _data,
        // select_user:{},
        error: {
          first_name: false,
          speciality: false,
          middle_name: false,
          last_name: false,
          user_id: false,
          reporting_entity_type: false,
          reporting_entity_name: false,
          selected_roles: false,
        },
        errorMsg: {
          first_name: "please enter the field",
          middle_name: "please enter the field",
          last_name: "please enter the field",
          user_id: "please enter the field",

          reporting_entity_type: "please select the field",
          reporting_entity_name: "please select the field",
          selected_roles: "please enter the field",
        },
      });
    } else {
      setUserDetails({
        ...userDetails,
        PersonID: _user?.Id ?? "",
        select_user: _user ?? {},
        first_name: "",
        middle_name: "",
        last_name: "",
        user_id: "",
        health_care: false,
        speciality: "",
        effective_from: null,
        effective_to: null,
        reporting_entity_type: "",
        reporting_entity_name: "",
        work_location: "",
        selected_roles: [],
        selected_practiesner: {},
        // select_user:{},
        error: {
          first_name: false,
          middle_name: false,
          last_name: false,
          speciality: false,
          user_id: false,
          reporting_entity_type: false,
          reporting_entity_name: false,
          selected_roles: false,
        },
        errorMsg: {
          speciality: "please enter the field",

          first_name: "please enter the field",
          middle_name: "please enter the field",
          last_name: "please enter the field",
          user_id: "please enter the field",
          reporting_entity_type: "please select the field",
          reporting_entity_name: "please select the field",
          selected_roles: "please enter the field",
        },
      });
    }
  };

  const submit = async (userDetails) => {
    const { alert } = props;
    let { setSnack } = alert;
    if (!isValidEffectiveDate(userDetails, false)) {
      return setSnack({
        ...alert,
        horizontal: "right",
        msg: "Effective dates should not be before minimal date",
        open: true,
        severity: "error",
        vertical: "top",
      });
    }
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "submiting",
    });
    setUserDetails(userDetails);
    let error = userDetails.error;
    let keys = Object.keys(error);

    keys.map((_) => {
      if (typeof userDetails[_] === "object" && userDetails[_]?.length <= 0) {
        error[_] = true;
      } else if (
        typeof userDetails[_] === "string" &&
        userDetails[_]?.length <= 0
      ) {
        error[_] = true;
      } else {
        error[_] = false;
      }
    });
    let errorValue = Object.values(error);
    if (errorValue?.some((_) => _ === true)) {
      setUserDetails({
        ...userDetails,
        error,
      });
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
        message: "",
      });

      return;
    }
    let practiesnerRole =
      userDetails?.selected_roles?.length > 0
        ? userDetails?.selected_roles?.map((_) => {
          return {
            _id: "",
            identifier: [],
            active: true,
            period: [
              {
                _id: "",
                start: null,
                end: null,
                id: 0,
              },
            ],
            role: _._id,
            telecom: [],
            availableTime: [],
            notAvailable: [],
            availabilityExceptions: "",
            id: 0,
            HealthCareServiceID: "",
            OrgID: userDetails?.reporting_entity_name?._id ?? "",
            locationID: "",
            EntityType: userDetails?.reporting_entity_type?._id ?? "",
            EntityName: userDetails?.reporting_entity_name?._id ?? "",
            biography: "",
            experience: [],
            primary_specialty: userDetails?.health_care,
            files: [],
            SpecialtyID: userDetails?.speciality?._id ?? "",
          };
        })
        : [];
    let practiesnerJson = {
      _id: "string",
      identifier: [
        {
          _id: "",
          user: "",
          system: "",
          value: "",
          period: [
            {
              _id: "",
              start: userDetails.effective_from
                ? getUtcTime(moment(userDetails.effective_from))
                : null,
              end: userDetails.effective_to
                ? getUtcTime(moment(userDetails.effective_to))
                : null,
              id: 0,
            },
          ],
          assigner: "",
          Type: "",
          id: 0,
          files: [],
        },
      ],
      active: true,
      telecom: [],
      address: [],
      photo: [],
      id: 0,
      PersonID: userDetails?.PersonID,
      External: true,
      ReportingEntityType: userDetails?.reporting_entity_type?._id ?? "",
      ReportingEntityName: userDetails?.reporting_entity_name?._id ?? "",
      PrimarySpeciality: userDetails?.health_care,
      qualification: [],
      training_details: [],
      job_experience: [],
      publication_details: [],
      biography: [],
      achievements_awards_details: [],
      practitioner_id: userDetails?.user_id.toLocaleUpperCase(),
      gender: "",
      birthDate: null,
      name: [
        {
          _id: "",
          use: "",
          text: userDetails?.first_name,
          family: userDetails?.last_name,
          given: userDetails?.middle_name,
          prefix: "",
          suffix: "",
          period: [
            {
              _id: "",
              start: null,
              end: null,
              // "start": userDetails.effective_from ? moment(userDetails.effective_from).unix() : null,
              // "end": userDetails.effective_to ? moment(userDetails.effective_to).unix() : null,
              id: 0,
            },
          ],
          id: 0,
        },
      ],
      marital_status: "",
      status: true,
      nationality: "",
      practitioner_type: "",
      position: "",
      communication: [],
      employee_status: "",
      practitioner_role: practiesnerRole,
    };
    let upsertPractiesner = await dispatch(
      actions.USER_UPSERT(practiesnerJson)
    );

    

    if (
      upsertPractiesner?.payload?.error ||
      upsertPractiesner?.payload?.data?.Code !== 201
    ) {
      setSnack({
        ...alert,
        horizontal: "right",
        msg: "Something went wrong !",
        open: true,
        severity: "error",
        vertical: "top",
      });
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
        message: "",
      });
      return;
    }
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    setSnack({
      ...alert,
      horizontal: "right",
      msg: "created successfully !",
      open: true,
      severity: "success",
      vertical: "top",
    });
    setEditMode(false);
    setUpdateUser(currentData);
  };

  const isValidEffectiveDate = (userDetails, isEdit = false) => {
    const effectiveFrom =
      userInfo?.data[0]?.identifier[0]?.period[0]?.start ?? null;
    const effectiveTo =
      userInfo?.data[0]?.identifier[0]?.period[0]?.end ?? null;
    const checkFromDate = isEdit
      ? userDetails?.effective_from !== effectiveFrom
      : userDetails?.effective_from;
    const checkToDate = isEdit
      ? userDetails?.effective_to !== effectiveTo
      : userDetails?.effective_to;
    const validateEffectiveFrom = checkFromDate
      ? moment.unix(userDetails?.effective_from) >= moment().startOf('day')
      : true;
    const validateEffectiveTo = checkToDate
      ? userDetails?.effective_from
        ? moment.unix(userDetails?.effective_to).endOf('day') >=
        moment.unix(userDetails?.effective_from)
        : moment.unix(userDetails?.effective_to) >= moment().startOf('day')
      : true;
    return validateEffectiveFrom && validateEffectiveTo;
  };

  const update = async (userDetails) => {
    const { alert } = props;
    let { setSnack } = alert;
    if (!isValidEffectiveDate(userDetails, true)) {
      return setSnack({
        ...alert,
        horizontal: "right",
        msg: "Effective dates should not be before minimal date",
        open: true,
        severity: "error",
        vertical: "top",
      });
    }
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "updating",
    });
    setUserDetails(userDetails);
    let error = userDetails.error;
    let keys = Object.keys(error);

    keys.map((_) => {
      if (typeof userDetails[_] === "object" && userDetails[_]?.length <= 0) {
        error[_] = true;
      } else if (
        !userDetails[_] ||
        (typeof userDetails[_] === "string" && userDetails[_]?.length <= 0)
      ) {
        error[_] = true;
      } else {
        error[_] = false;
      }
    });
    let errorValue = Object.values(error);
    if (errorValue?.some((_) => _ === true)) {
      setUserDetails({
        ...userDetails,
        error,
      });
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
        message: "",
      });
      return;
    }
    let practiesnerRole =
      userDetails?.selected_roles?.length > 0
        ? userDetails?.selected_roles?.map((_) => {
          return {
            identifier: [],
            active: true,
            period: [
              {
                _id: "",
                start: null,
                end: null,
                id: 0,
              },
            ],
            role: _._id,
            telecom: [],
            availableTime: [],
            notAvailable: [],
            availabilityExceptions: "",
            id: 0,
            HealthCareServiceID: "",
            OrgID: userDetails?.reporting_entity_name?._id ?? "",
            locationID: "",
            EntityType: userDetails?.reporting_entity_type?._id ?? "",
            EntityName: userDetails?.reporting_entity_name?._id ?? "",
            biography: "",
            experience: [],
            primary_specialty: userDetails?.health_care,
            files: [],
            SpecialtyID: userDetails?.speciality?._id ?? "",
          };
        })
        : [];

    console.clear()
    console.log(userDetails)
    let practiesnerJson = {
      _key: userDetails?.selected_practiesner?._key ?? "",
      identifier: [
        {
          _id: userDetails?.select_user?.identifier?.[0]?._id,
          _key: userDetails?.select_user?.identifier?.[0]?._key,
          period: [
            {
              _id: "",
              start: userDetails.effective_from
                ? userDetails.effective_from
                : null,
              end: userDetails.effective_to ? userDetails.effective_to : null,
              id: 0,
            },
          ],
        },
      ],
      active: true,
      telecom: [],
      address: [],
      photo: [],
      id: 0,
      PersonID: userDetails?.PersonID,
      External: true,
      ReportingEntityType: userDetails?.reporting_entity_type?._id ?? "",
      ReportingEntityName: userDetails?.reporting_entity_name?._id ?? "",
      PrimarySpeciality: userDetails?.health_care,
      qualification: [],
      training_details: [],
      job_experience: [],
      publication_details: [],
      biography: [],
      achievements_awards_details: [],
      practitioner_id: userDetails?.user_id.toLocaleUpperCase(),
      gender: "",
      birthDate: 0,
      name: [
        {
          _id: userDetails?.select_user?.name?.[0]?._id,
          _key: userDetails?.select_user?.name?.[0]?._key,
          use: "",
          text: userDetails?.first_name,
          family: userDetails?.last_name,
          given: userDetails?.middle_name,
          prefix: "",
          suffix: "",
          period: [
            // {
            //   "_id": "",
            //   "start": userDetails.effective_from ? moment(userDetails.effective_from).unix() : null,
            //   "end": userDetails.effective_to ? moment(userDetails.effective_to).unix() : null,
            //   "id": 0
            // }
          ],
          id: 0,
        },
      ],
      marital_status: "",
      status: true,
      nationality: "",
      practitioner_type: "",
      position: "",
      communication: [],
      employee_status: "",
      practitioner_role: practiesnerRole,
    };
    let upsertPractiesner = await dispatch(
      actions.USER_UPSERT(practiesnerJson)
    );
    let personJson = {
      _key: userDetails?._key,
      _id: userDetails?._id,
      "OrgID": [userDetails?.reporting_entity_name?._id], //entity name
      "orgType": userDetails?.reporting_entity_type?.value //entitytype
    }
    let upsertPerson = await dispatch(
      actions.PERSON_UPSERT(personJson)
    )
    if (
      upsertPractiesner?.payload?.error ||
      upsertPractiesner?.payload?.data?.Code !== 201 || upsertPerson?.payload?.error
    ) {
      setSnack({
        ...alert,
        horizontal: "right",
        msg: "Something went wrong !",
        open: true,
        severity: "error",
        vertical: "top",
      });
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
        message: "",
      });
      return;
    }
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    setSnack({
      ...alert,
      horizontal: "right",
      msg: "Updated successfully !",
      open: true,
      severity: "success",
      vertical: "top",
    });
    setEditMode(false);
  };
  return (
    <div className={classes.contentBox}>
      <Grid container direction="row" spacing={2}>
        {/* <Paper className={classes.root}> */}
        <Grid item xs={4}>
          <div
            // className={classes.firstSec}
            style={{
              backgroundColor: "#fff",
              border: "1px solid #DCDCDC",
              // padding: "20px",
              borderRadius: "10px",
            }}
          >
            <div>
              {/* ------------------------------Title---------------------- */}
              <div style={{ display: "flex", margin: "16px 20px 8px" }}>
                <Typography
                  // className={classes.numbersEdit}
                  style={{
                    marginTop: "4px",
                    fontFamily: "poppinsemibold",
                    // padding: "10px 20px 6px"
                  }}
                >
                  {loader ? "Users" : personList.length + " Users"}
                </Typography>
                <div style={{ flexGrow: 1 }}></div>

                {/* <Button
                  className={classes.btn}
                  onClick={drawerOpening}
                  contained
                >
                  + Add
                </Button> */}
              </div>

              {/* -----------------------------Search---------------------------- */}
              <div>
                <TextField
                  id="outlined-search"
                  placeholder="Search field"
                  size="small"
                  style={{
                    // width: "231px",
                    //  height: "32px",
                    padding: "8px 20px",
                  }}
                  type="search"
                  variant="outlined"
                  fullWidth
                  onChange={searchProcessChange}
                  InputProps={{
                    style: {
                      fontFamily: "poppin",
                      fontSize: "12px",
                      background: "#f6f6f6",
                      // marginRight: "10px",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={searchicon} alt="Icon" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { fontSize: 12 },
                  }}
                />
              </div>

              <Divider />

              <div
                className={classes.descriptionTab}
                style={{ marginLeft: 20, marginRight: 20 }}
              >
                <Typography
                  className={classes.innerText}
                  style={{ color: "#0000008a" }}
                >
                  Person Name
                </Typography>
                <div style={{ flexGrow: 1 }}></div>
                <Typography
                  className={classes.innerText}
                  style={{ color: "#0000008a" }}
                >
                  Action
                </Typography>
              </div>

              <div
                className={classes.mainArea}
                style={{
                  height: `calc(100vh - 320px)`,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              >
                {loader ? (
                  <div style={{ textAlign: "center", paddingTop: "90px" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  personList.map((item, index) => (
                    <div style={{ padding: "8px 0px" }}>
                      <div
                        className={
                          userDetails?.select_user?.Id === item?.Id
                            ? classes.selectedCell
                            : classes.rolesDisplay
                        }
                        onClick={(e) => {
                          setCurrentData(item);
                          setUpdateUser(item);
                        }}
                        selected={
                          userDetails?.select_user?.Id === item?.Id
                            ? true
                            : false
                        }
                      >
                        <Typography className={classes.innerText}>
                          {item?.name[0]?.text === ""
                            ? item?.telecom[0]?.value
                            : item?.name[0]?.text}
                        </Typography>
                        <div style={{ flexGrow: 1 }}></div>

                        <Tooltip
                          title={item?.active === true ? "Active" : "Inactive"}
                          arrow
                        >
                          <IOSSwitch
                            // checked={
                            //   state.statusActive?.indexOf(item?.Users?.username) > -1
                            //     ? true
                            //     : false
                            // }
                            // onChange={(e) => handleStatusCheck(e, index, item)}
                            checked={item?.active === true ? true : false}
                            onChange={(e) => handleUserStatus(e, item)}
                          />
                        </Tooltip>

                        {/* <Tooltip title="Edit" arrow>
                          <img
                            src={EditIcon}
                            onClick={(e) => editUsers(e, item)}
                            width="12px"
                            style={{ margin: "0px 16px" }}
                          />
                        </Tooltip> */}
                        {/* <img
                      src={DeleteIcon}
                      width="12px"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickOpen(item)}
                    /> */}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={8}>
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              // padding: "10px 20px",
              border: "1px solid #DCDCDC",
            }}
          >
            {editMode ? (
              <Typography
                className={classes.numbersEdit}
                style={{ marginTop: 0, marginRight: 0 }}
              >
                Edit User
              </Typography>
            ) : (
              <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                <Typography
                  className={classes.numbersEdit}
                  style={{ marginTop: 0, marginRight: 0 }}
                >
                  User Details
                </Typography>
                <IconButton
                  size="small"
                  onClick={toggleEditMode}
                  style={{ height: 24, width: 24 }}
                >
                  <CreateOutlinedIcon fontSize="small" />
                </IconButton>
              </div>
            )}

            <Divider />

            {/* User Info Content */}
            {editMode ? (
              <UserEditMode
                userDetails={userDetails ?? {}}
                labelStyle={labelStyle}
                borderStyle={borderStyle}
                allMasterList={allMasterList}
                roleList={roleList}
                classes={classes}
                toggleEditMode={toggleEditMode}
                update={update}
                submit={submit}
                editMode={editMode}
              />
            ) : (
              <div style={{ padding: 14 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    className={classes.displaySectionTitle}
                    variant="body2"
                  >
                    USER INFO
                  </Typography>
                  <Divider
                    style={{
                      flex: 1,
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.12)",
                      backgroundColor: "unset",
                    }}
                  />
                </div>

                <Row className="user-display-row1">
                  <Col className="no-padding-left" style={{ padding: "0" }}>
                    <div className={classes.boxFirstDiv}>
                      <div className={classes.detailBox}>
                        <img src={orgCode} className={classes.iconStyle} />
                        <Typography variant="body2" color="textSecondary">
                          Person ID
                        </Typography>
                      </div>
                      <Typography className={classes.detailData}>
                        {userDetails.PersonID ?? `--`}
                      </Typography>
                    </div>
                  </Col>
                  <Col className="no-padding-left" style={{ padding: "0" }}>
                    <div className={classes.boxFirstDiv}>
                      <div className={classes.detailBox}>
                        <img
                          src={orgDesignation}
                          className={classes.iconStyle}
                        />
                        <Typography variant="body2" color="textSecondary">
                          First Name
                        </Typography>
                      </div>
                      <Typography className={classes.detailData}>
                        {userDetails?.first_name ?? "---"}
                      </Typography>
                    </div>
                  </Col>
                  <Col className="no-padding-left" style={{ padding: "0" }}>
                    <div className={classes.boxFirstDiv}>
                      <div className={classes.detailBox}>
                        <img
                          src={orgDesignation}
                          className={classes.iconStyle}
                        />
                        <Typography variant="body2" color="textSecondary">
                          Middle Name
                        </Typography>
                      </div>
                      <Typography className={classes.detailData}>
                        {userDetails?.middle_name ?? "---"}
                      </Typography>
                    </div>
                  </Col>
                  <Col className="no-padding-left" style={{ padding: "0" }}>
                    <div className={classes.boxFirstDiv}>
                      <div className={classes.detailBox}>
                        <img
                          src={orgDesignation}
                          className={classes.iconStyle}
                        />
                        <Typography variant="body2" color="textSecondary">
                          Last Name
                        </Typography>
                      </div>
                      <Typography className={classes.detailData}>
                        {userDetails?.last_name ?? "---"}
                      </Typography>
                    </div>
                  </Col>
                  <Col className="no-padding-left" style={{ padding: "0" }}>
                    <div className={classes.boxFirstDiv}>
                      <div className={classes.detailBox}>
                        <img src={orgCode} className={classes.iconStyle} />
                        <Typography variant="body2" color="textSecondary">
                          Effective From
                        </Typography>
                      </div>
                      <Typography className={classes.detailData}>
                        {userDetails?.effective_from
                          ? utcTOLocal(userDetails.effective_from, "MM/DD/YYYY")
                          : "---"}
                      </Typography>
                    </div>
                  </Col>
                  <Col className="no-padding-left" style={{ padding: "0" }}>
                    <div className={classes.boxFirstDiv}>
                      <div className={classes.detailBox}>
                        <img src={orgCode} className={classes.iconStyle} />
                        <Typography variant="body2" color="textSecondary">
                          Effective To
                        </Typography>
                      </div>
                      <Typography className={classes.detailData}>
                        {userDetails?.effective_to
                          ? utcTOLocal(userDetails.effective_to, "DD/MM/YYYY")
                          : "---"}
                      </Typography>
                    </div>
                  </Col>
                </Row>

                <Row className="user-display-row2">
                  <Col className="no-padding-left" style={{ padding: "0" }}>
                    <div className={classes.boxFirstDiv}>
                      <div className={classes.detailBox}>
                        <img src={orgCode} className={classes.iconStyle} />
                        <Typography variant="body2" color="textSecondary">
                          User Id
                        </Typography>
                      </div>
                      <Typography className={classes.detailData}>
                        {console.log(userDetails)}
                        {userDetails?.selected_practiesner?.Practitioner[0]?.userId ?? "----"}
                      </Typography>
                    </div>
                  </Col>
                  <Col className="no-padding-left" style={{ padding: "0" }}>
                    <div className={classes.boxFirstDiv}>
                      <div className={classes.detailBox}>
                        <img src={orgCode} className={classes.iconStyle} />
                        <Typography variant="body2" color="textSecondary">
                          User Type
                        </Typography>
                      </div>
                      <Typography className={classes.detailData}>
                        {userDetails?.health_care
                          ? "Health  Care"
                          : "Non health care"}
                      </Typography>
                    </div>
                  </Col>
                </Row>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    className={classes.displaySectionTitle}
                    variant="body2"
                  >
                    ORGANIZATION DETAILS
                  </Typography>
                  <Divider
                    style={{
                      flex: 1,
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.12)",
                      backgroundColor: "unset",
                    }}
                  />
                </div>

                <Row className="user-display-row3">
                  <Col className="no-padding-left" style={{ padding: "0" }}>
                    <div className={classes.boxFirstDiv}>
                      <div className={classes.detailBox}>
                        <img src={orgParent} className={classes.iconStyle} />
                        <Typography variant="body2" color="textSecondary">
                          Entity Type
                        </Typography>
                      </div>
                      <Typography className={classes.detailData}>
                        {/* {console.clear()} */}
                        {console.log(userDetails)}
                        {userDetails?.selected_practiesner?.orgType?.display ? userDetails?.selected_practiesner?.orgType?.display : userDetails?.reporting_entity_type?.label ? userDetails?.reporting_entity_type?.label : "---"}
                      </Typography>
                    </div>
                  </Col>
                  <Col className="no-padding-left" style={{ padding: "0" }}>
                    <div className={classes.boxFirstDiv}>
                      <div className={classes.detailBox}>
                        <img
                          src={orgPersonName}
                          className={classes.iconStyle}
                        />
                        <Typography variant="body2" color="textSecondary">
                          Entity Name
                        </Typography>
                      </div>
                      <Typography className={classes.detailData}>
                        {userDetails?.select_user?.OrgID ? userDetails?.select_user?.OrgID[0]?.name : userDetails?.reporting_entity_name?.label ? userDetails?.reporting_entity_name?.label : "----"}
                      </Typography>
                    </div>
                  </Col>
                  {/* <Col className="no-padding-left" style={{ padding: "0" }}>
                <div className={classes.boxFirstDiv}>
                  <div className={classes.detailBox}>
                    <img src={orgAddress} className={classes.iconStyle} />
                    <Typography variant="body2" color="textSecondary">
                      Work Location
                    </Typography>
                  </div>
                  <Typography className={classes.detailData}>
                    {userDetails?.work_location ?? '---'}
                  </Typography>
                </div>
              </Col> */}
                </Row>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    className={classes.displaySectionTitle}
                    variant="body2"
                  >
                    ROLE DETAILS
                  </Typography>
                  <Divider
                    style={{
                      flex: 1,
                      borderBottom: "1px dashed rgba(0, 0, 0, 0.12)",
                      backgroundColor: "unset",
                    }}
                  />
                </div>
                {/* orgDesignation */}

                <Row className="user-display-row4">
                  <Col className="no-padding-left" style={{ padding: "0" }}>
                    <div className={classes.boxFirstDiv}>
                      <div className={classes.detailBox}>
                        <img
                          src={orgDesignation}
                          className={classes.iconStyle}
                        />
                        <div style={{ display: "flex", gap: 8 }}>
                          {userDetails?.selected_practiesner?.Practitioner[0]?.practitioner_role?.map((x, i) => (
                            <Chip
                              label={x?.display}
                              classes={{ root: classes.rolesChipRoot }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* <div className={classes.addressbox}>
									<div className={classes.detailBox}>
										<img src={orgAddress} className={classes.iconStyle} />
										Address
									</div>
									<div className={classes.detailData}>
										{props?.treeData?.data?.address === ""
											? "-"
											: props?.treeData?.data.address}
									</div>
								</div> */}
              </div>
            )}
          </div>
        </Grid>

        {/* <Grid item xs={6}>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid #DCDCDC",
              borderRadius: "10px",
            }}
          > */}
        {/* <AppBar
              position="static"
              style={{
                backgroundColor: "#fff",
                boxShadow: "none",
                color: "#000",
                width: "100%",
                borderRadius: "10px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                className={classes.tabs}
              >
                <Tab
                  className={classes.tabEdit}
                  label="Roles"
                  {...a11yProps(0)}
                />
              </Tabs>
            </AppBar> */}

        {/* ------------------------------ Roles --------------------------------------- */}

        {/* <TabPanel value={value} index={0}>
              <div style={{ padding: "10px 20px" }}>
                <div
                  className={
                    userData.length > 0
                      ? classes.Section2
                      : classes.Section2NoMain
                  }
                  style={{ height: `calc(100vh - 213px)` }}
                >
                  {rolesData.map((item, index) => (
                   item?.coding[0]?.display && <div style={{ padding: "8px 0px" }}>
                      <div className={classes.lowerDisplay}>
                        <Checkbox
                          checked={
                            PractitionerRoleID === item?._id
                              ? true
                              : false
                          }
                          onChange={(e,check) => handleCheck(item, index,check)}
                          inputProps={{ "aria-label": "primary checkbox" }}
                          size="small"
                          style={{
                            padding: "0px 10px 0px 0px",
                            color: "#0071F2",
                          }}
                        />
                        <Typography className={classes.innerText}>
                          {item?.coding[0]?.display}
                        </Typography>
                      </div>
                    </div>
                  ))}

                  <Grid container>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={3}>
                      <Button
                        className={classes.btn}
                        contained
                        style={{ marginTop: "15px", marginLeft: "30px" }}
                        onClick={handleSaveRole}
                      >
                        Save Roles
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </TabPanel> */}
        {/* </div>
        </Grid> */}
        {/* </Paper> */}
      </Grid>
      {/* -------------------------------- delete ----------------------------------  */}
      <DeleteComponent open={open} deleteClose={handleClose} />
    </div>
  );
}

function UserEditMode({
  userDetails,
  labelStyle,
  borderStyle,
  allMasterList,
  classes,
  roleList,
  toggleEditMode,
  update,
  submit,
  editMode,
}) {
  const [state, setState] = React.useState({ ...userDetails });
  const dispatch = useDispatch();
  const personMasterList = useSelector((state) => state?.personSlice);
  useEffect(() => {
    setState(userDetails);
  }, [userDetails]);

  const handleState = async (name, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let error = state.error;
    let errorMsg = state.errorMsg;
    if (typeof error[name] === "boolean") {
      if (typeof value === "object" && value?.length <= 0) {
        error[name] = true;
      } else if (typeof value === "string" && value?.length <= 0) {
        error[name] = true;

        errorMsg[name] = "please enter the field";
      } else {
        error[name] = false;
      }
    }
    // if(name === 'effective_from' || name === 'effective_to'){
    //   const isValid = name === 'effective_to' ? state?.effective_from ? 
    //     moment(value) >= moment(state?.effective_from) : moment(value) >= moment() 
    //     : moment(value) >= moment();
    //   error[name] = !isValid;
    // }
    if (name === 'effective_from') {
      if (state?.effective_to) {
        const isValid = moment(state?.effective_to) >= moment(value);
        error[name] = !isValid;
      }
    }
    if (name === 'effective_to') {
      if (state?.effective_from) {
        const isValid = moment(value) >= moment(state?.effective_from);
        error[name] = !isValid;
      } else {
        error[name] = true;
      }
    }
    if (name === "reporting_entity_type") {
      let entityNameData = await dispatch(actions.ENTITYNAMEMASTER(value?.label?.toLocaleLowerCase()));
      setState({
        ...state,
        reporting_entity_name: {},
        speciality: {}
      })
    } else if (name === "reporting_entity_name") {
      let specialityData = await dispatch(actions.SPECIALITYBYENTITYNAME(value?.id));
      setState({
        ...state,
        speciality: {}
      })
    }

    setState({
      ...state,
      [name]: value,
      error,
      errorMsg,
    });
  };
  return (
    <div style={{ padding: 14 }}>
      {/* Row 1 */}
      <Row className="user-edit-row1">
        <Col className="no-padding-left" style={{ padding: "0" }}>
          <TextInput
            // isRequired={true}
            // error={
            //   val?.error && val?.error["first_name"] ? true : false
            // }
            value={state.PersonID}
            label="Person ID"
            labelStyle={labelStyle}
            // style={borderStyle}
            placeholder="Person ID"
            disabled={true}
            hoverColor="#0071F2"
            style={false ? errorBorderStyle : borderStyle}
          />
        </Col>
        <Col className="no-padding-left" style={{ padding: "0" }}>
          <TextInput
            onChange={(e) => handleState("first_name", e.target.value, 20)}
            value={state.first_name}
            error={state.error.first_name ? true : false}
            //helperText={state.error.first_name ? state.errorMsg.first_name : ''}
            isRequired={true}
            // error={
            //   val?.error && val?.error["first_name"] ? true : false
            // }
            label="First Name"
            labelStyle={labelStyle}
            // style={borderStyle}
            placeholder="first name"
            hoverColor="#0071F2"
          //style={false ? errorBorderStyle : borderStyle}
          />
        </Col>
        <Col className="no-padding-left" style={{ padding: "0" }}>
          <TextInput
            onChange={(e) => handleState("middle_name", e.target.value, 20)}
            value={state.middle_name}
            error={state.error.middle_name ? true : false}
            //helperText={state.error.middle_name ? state.errorMsg.middle_name : ''}
            isRequired={true}
            // error={
            //   val?.error && val?.error["first_name"] ? true : false
            // }
            label="Middle Name"
            labelStyle={labelStyle}
            placeholder="middle name"
            hoverColor="#0071F2"
          //style={false ? errorBorderStyle : borderStyle}
          />
        </Col>
        <Col className="no-padding-left" style={{ padding: "0" }}>
          <TextInput
            onChange={(e) => handleState("last_name", e.target.value, 20)}
            value={state.last_name}
            error={state.error.last_name ? true : false}
            // helperText={state.error.last_name ? state.errorMsg.last_name : ''}
            isRequired={true}
            // error={
            //   val?.error && val?.error["first_name"] ? true : false
            // }
            label="Last Name"
            labelStyle={labelStyle}
            // style={borderStyle}
            placeholder="last name"
            // onKeyPress={function (e) {
            //   var key = e.which;
            //   if (
            //     (key >= 65 && key <= 90) ||
            //     (key >= 95 && key <= 122)
            //   ) {
            //     return;
            //   } else {
            //     e.preventDefault();
            //   }
            // }}
            hoverColor="#0071F2"
          //style={false ? errorBorderStyle : borderStyle}
          />
        </Col>
      </Row>

      {/* Row 2 */}
      <Row className="user-edit-row2">
        <Col className="no-padding-left" style={{ padding: "0" }}>
          <TextInput
            onChange={(e) => handleState("user_id", e.target.value, 20)}
            value={state.user_id}
            error={state.error.user_id ? true : false}
            //helperText={state.error.user_id ? state.errorMsg.user_id : ''}
            isRequired={true}
            // error={
            //   val?.error && val?.error["first_name"] ? true : false
            // }
            // disabled={true}
            label="User ID"
            labelStyle={labelStyle}
            // style={borderStyle}
            placeholder="User ID"
            // onKeyPress={function (e) {
            //   var key = e.which;
            //   if (
            //     (key >= 65 && key <= 90) ||
            //     (key >= 95 && key <= 122)
            //   ) {
            //     return;
            //   } else {
            //     e.preventDefault();
            //   }
            // }}
            hoverColor="#0071F2"
          //style={false ? errorBorderStyle : borderStyle}
          />
        </Col>
        <Col
          md={2}
          lg={2}
          sm={6}
          xs={12}
          className="no-padding-left"
          inLineStyles={{ color: "#101010" }}
        >
          <H6 className="default-size pc_regular no-wrap">Health Care User</H6>
          {/* <Typography>
                    Health Care User
                    </Typography> */}
          <FormControlLabel
            control={
              <Checkbox
                color={"primary"}
                onChange={(e) => handleState("health_care", e.target.checked)}
                checked={state.health_care}
                name="health_care_user"
              />
            }
          />
        </Col>
        <Col className="no-padding-left" style={{ padding: "0" }}>
          <Typography
            style={{
              fontSize: "12px",
              Fontfamily: "pc_regular",
              color: "#6F6F6F",
              paddingBottom: 8,
            }}
            variant="body1"
          >
            Effective From
          </Typography>
          {/* <DateTimePicker
            required={true}
            label={""}
            placeholder={"dd/mm/yyyy"}
            dateFormat={"dd/MM/yyyy"}
            onChange={(value) => {
              handleState("effective_from", getUtcTime(value));
            }}
            value={
              state.effective_from
                ? new Date(utcTOLocal(state.effective_from, "MM/DD/YYYY"))
                : null
            }
            // error={details?.error?.["dob"] ?? false}
            // maxDate={new Date()}
            // minDate={details?.dob   ? moment(vdetails.dob).diff(moment(new Date()), 'days') >= 0 ? new Date() : moment(details.dob) : new Date()}
            minDate={new Date()}
          /> */}
          <CustomInputDatePicker
            // disabled={data?.disabled}
            placeholderText="dd / mm / yyyy"
            // minDate={state?.states?.[data?.minDateState] ?? null}
            selectedDate={
              state.effective_from
                ? new Date(utcTOLocal(state.effective_from, "MM/DD/YYYY"))
                : null
            }
            handleChange={(value) =>
              handleState("effective_from", getUtcTime(value))
            }
            inputField={
              <TextField
                fullWidth
                variant="outlined"
                value={
                  state.effective_from
                    ? moment(
                      new Date(utcTOLocal(state.effective_from, "MM/DD/YYYY"))
                    ).format("DD-MM-YYYY")
                    : null
                }
                inputProps={{
                  style: {
                    padding: "10.5px 14px",
                    fontFamily: "poppin",
                    fontSize: "12px",
                  },
                }}
              />
            }
          />
        </Col>
        <Col className="no-padding-left" style={{ padding: "0" }}>
          <Typography
            style={{
              fontSize: "12px",
              Fontfamily: "pc_regular",
              color: "#6F6F6F",
              paddingBottom: 8,
            }}
            variant="body1"
          >
            Effective To
          </Typography>
          {/* <DateTimePicker
            required={true}
            label={""}
            placeholder={"dd/mm/yyyy"}
            dateFormat={"dd/MM/yyyy"}
            onChange={(value) => {
              handleState("effective_to", getUtcTime(value));
            }}
            value={
              state.effective_to
                ? utcTOLocal(state.effective_to, "MM/DD/YYYY")
                : null
            }
            // value={details.dob ? details.dob : null}
            // onChange={(value) =>
            //   this.setDetails("dob", moment(value)?.format("YYYY-MM-DD"), "age")
            // }
            // error={details?.error?.["dob"] ?? false}
            // maxDate={new Date()}
            minDate={
              state.effective_from
                ? new Date(utcTOLocal(state.effective_from, "MM/DD/YYYY"))
                : new Date()
            }
          /> */}
          <CustomInputDatePicker
            // disabled={data?.disabled}
            placeholderText="dd / mm / yyyy"
            minDate={state.effective_from
              ? new Date(utcTOLocal(state.effective_from, "MM/DD/YYYY"))
              : null}
            selectedDate={
              state.effective_to
                ? new Date(utcTOLocal(state.effective_to, "MM/DD/YYYY"))
                : null
            }
            handleChange={(value) => handleState("effective_to", getUtcTime(value))}
            inputField={
              <TextField
                fullWidth
                variant="outlined"
                value={
                  state.effective_to
                    ? moment(
                      new Date(utcTOLocal(state.effective_to, "MM/DD/YYYY"))
                    ).format("DD-MM-YYYY")
                    : null
                }
                inputProps={{
                  style: {
                    padding: "10.5px 14px",
                    fontFamily: "poppin",
                    fontSize: "12px",
                  },
                }}
              />
            }
          />
        </Col>
      </Row>

      <Row className="user-edit-row3">
        <Col className="no-padding-left" style={{ padding: "0" }}>
          <SelectWithLabel
            options={allMasterList?.entity}
            label={"Reporting Entity Type"}
            required={true}
            disableClearable
            onChange={(e) => handleState("reporting_entity_type", e)}
            value={state.reporting_entity_type}
            error={state.error.reporting_entity_type ? true : false}
            // helperText={state.error.reporting_entity_type ? state.errorMsg.reporting_entity_type : ''}
            // value={}
            // onChange={(value) => this.setData("use", value, i)}
            placeholder={"Reporting entity type"}
          // onChange={(data)=>props?.onChangeState('class',data)}
          // error={val?.error?.["use"] ?? false}
          />
        </Col>
        <Col className="no-padding-left" style={{ padding: "0" }}>
          {/* {console.clear()} */}
          {console.log(state)}
          <SelectWithLabel
            //options={allMasterList?.entity_name}
            options={personMasterList?.entity_name_master?.data ?? []}
            label={"Reporting Entity Name"}
            required={true}
            disableClearable
            onChange={(e) => handleState("reporting_entity_name", e)}
            value={state.reporting_entity_name}
            error={state.error.reporting_entity_name ? true : false}
            //helperText={state.error.reporting_entity_name ? state.errorMsg.reporting_entity_name : ''}
            // value={}
            // onChange={(value) => this.setData("use", value, i)}
            placeholder={"Reporting entity name"}
          // onChange={(data)=>props?.onChangeState('class',data)}
          // error={val?.error?.["use"] ?? false}
          />
        </Col>
        <Col className="no-padding-left" style={{ padding: "0" }}>
          <SelectWithLabel
            //options={allMasterList?.speciality}
            options={personMasterList?.speciality_by_entity_name?.data ?? []}
            label={"Specialty"}
            required={true}
            disableClearable
            onChange={(e) => handleState("speciality", e)}
            value={state.speciality}
            error={state.error.speciality ? true : false}
            //helperText={state.error.speciality ? state.errorMsg.speciality : ''}
            // value={}
            // onChange={(value) => this.setData("use", value, i)}
            placeholder={"specialty"}
          // onChange={(data)=>props?.onChangeState('class',data)}
          // error={val?.error?.["use"] ?? false}
          />
        </Col>
        {/* <Col className="no-padding-left" style={{ padding: "0" }}>
                    <SelectWithLabel
                      options={allMasterList?.location}
                      label={"work location"}
                      required={true}
                      disableClearable
                      onChange={(e)=>handleState('work_location',e)}
                      value={state.work_location}
                      error={state.error.work_location ? true :false}
                      helperText={state.error.work_location ? state.errorMsg.work_location :''}
                      // value={}
                      // onChange={(value) => this.setData("use", value, i)}
                      placeholder={"work location"}
                      // onChange={(data)=>props?.onChangeState('class',data)}
                      // error={val?.error?.["use"] ?? false}
                    />
                  </Col> */}
        {/* <Col className="no-padding-left" style={{ padding: "0" }}>
          <TextInput
            // helperText={
            //   val?.error && val?.error["first_name"]
            //     ? "Is required"
            //     : ""
            // }
            // value={val.first_name ? val.first_name : ""}
            // onChange={(e) =>
            //   this.setData("first_name", e.target.value, i, 20)
            // }
            isRequired={false}
            // error={
            //   val?.error && val?.error["first_name"] ? true : false
            // }
            onChange={(e) => handleState('work_location', e.target.value)}
            value={state.work_location}
            label="Work Location"
            labelStyle={labelStyle}
            // style={borderStyle}
            placeholder="work location"
            // onKeyPress={function (e) {
            //   var key = e.which;
            //   if (
            //     (key >= 65 && key <= 90) ||
            //     (key >= 95 && key <= 122)
            //   ) {
            //     return;
            //   } else {
            //     e.preventDefault();
            //   }
            // }}
            hoverColor="#0071F2"
            style={false ? errorBorderStyle : borderStyle}
          />
        </Col> */}
      </Row>

      <div style={{ padding: "6px 0px" }}>
        <Typography
          className={classes.numbersEdit}
          variant="body2"
          style={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 10 }}
        >
          Select Roles
        </Typography>
        <Typography
          id={`permission-list-typography`}
          style={{
            color: "#6F6F6F",
            fontSize: "12px",
            marginBottom: "6px",
            fontFamily: "pc_regular",
          }}
        >
          Select Roles
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <Autocomplete
          multiple
          fullWidth
          size="small"
          getOptionLabel={(option) => option?.coding?.[0]?.display ?? ""}
          onChange={(e, v) => handleState("selected_roles", v)}
          value={state.selected_roles}
          options={roleList}
          // onChange={(e, v) =>
          // }
          renderInput={(params) => (
            <TextField
              {...params}
              label={false}
              variant="outlined"
              placeholder="Type Here"
              size="small"
              error={state.error.selected_roles ? true : false}
            //helperText={state.error.selected_roles ? state.errorMsg.selected_roles : ''}
            />
          )}
          classes={{
            tag: classes.autocompleteTag,
          }}
        // renderTags={(values) =>
        //   values.map((value) => (
        //     <Chip
        //       label={value?.label ?? value}
        //       classes={{ root: classes.rolesChipRoot }}
        //       on
        //       style={{marginRight: 8}}
        //     />
        //   ))
        // }
        />
      </div>

      {/* <div style={{ padding: "6px 0px" }}>
                  <Typography
                    className={classes.numbersEdit}
                    variant="body2"
                    style={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 10 }}
                  >
                    Password
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      height: 32,
                      borderRadius: 8,
                      textTransform: "unset",
                    }}
                  >
                    Reset Password
                  </Button>
                </div> */}

      <div
        style={{
          margin: "8px 14px 8px 0px",
          display: "flex",
          justifyContent: "flex-end",
          gap: 12,
        }}
      >
        <Button
          variant="outlined"
          onClick={toggleEditMode}
          style={{ height: 32, borderRadius: 8 }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color={"primary"}
          style={{ height: 32, borderRadius: 8 }}
          onClick={() => (editMode ? update(state) : submit(state))}
        >
          {editMode ? "update" : "create"}
        </Button>
      </div>
    </div>
  );
}

export default withAllContexts(Users);
