import React from "react";
import { Card, CircularProgress } from "@material-ui/core";
import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { GenerateForm, CommonTable } from "../../components/common";
import { SearchWithFilter } from "../../components";
//import { AlertProps } from "../../utils";
import { withAllContexts } from "../../HOCs";

function LevelofCareTable(props) {
  const dispatch = useDispatch();
  const { parent_id } = props;
  //const value_ = [{ value: "1", label: "1" }]
  const storeData_ = useSelector((state) => state?.organizationConfigSlice);
  const storeMasterData = useSelector((state) => state?.organizationConfigMasterSlice);
  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
    careTypeData: []
  });

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(actions.LEVEL_OF_CARE_READ({ page: (currentPage - 1) * PerPage, perPage: PerPage }));

  };

  const handleDrawerOpen = async () => {
    setState({
      ...state,
      open: true,
    });
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      edit_data: null,
    });
  };

  React.useLayoutEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Loading...",
    });
    await dispatch(actions.LEVEL_OF_CARE_READ({page: page, perPage: perPage}));
    let data_ = await dispatch(actions.CARE_TYPE_MASTERS());
    
    console.log(storeMasterData?.care_type_master?.data)
    setState({ ...state, open: false, careTypeData: data_?.payload?.data });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  }

  const saveLevelOfCare = async (values) => {
    let data = {
      levelofcareCode: values.levelofcareCode,
      description: values.description,
      careType: values.careType.value,
      status: true,
      id: 0,
      _key: null
    };
    if (state?.edit_data) {
      data["_key"] = state?.edit_data._key;
      data["id"] = state?.edit_data.id;
      data["status"] = state?.edit_data.status;
    }
    
    let save_res = await dispatch(actions.LEVEL_OF_CARE_INSERT(data));
    

    if (state?.edit_data) {
      if (save_res?.payload?.data?.Code === 201) {
        handleDrawerClose();
        props.alert.setSnack("update");
      } else {
        props.alert.setSnack("error");
      }
    } else {
      if (save_res?.payload?.data?.Code === 201) {
        handleDrawerClose();
        props.alert.setSnack("success");
      } else {
        props.alert.setSnack("error");  
      }
    }
    await fetchInfo();

  };

  const handleEdit = async (e, values) => {
    
    let data__ = await dispatch(actions.LEVEL_OF_CARE_SINGLE_READ({ _key: values?.key }));
    
    // let careType_ = [];
    // if(state.careTypeData.length >0){
    // careType_ = state.careTypeData.filter(
    //   (val, i) => val.value === data.careTypeId
    // );
    // }
    let data = data__?.payload?.data;
    let data_ = {
      _key: values?.key,
      _id: data?._id,
      id: data?.id,
      levelofcareCode: data?.levelofcareCode,
      description: data?.description,
      careType: data?.careType?._id,
      careTypeId: data?.careType?.description,
      status: data?.active,
      dropdown: { value: data?.careType?._id, label: data?.careType?.description } ?? {},
    };
    setState({
      ...state,
      edit_data: data_,
      open: true
    });

  }

  const statusChange = async (e, values, index) => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Status updating...",
    });
    let data = {
      levelofcareCode: values.levelofcareCode,
      description: values.description,
      careType: values.careType,
      type: props.type,
      status: e.target.checked,
      _id: values._id,
      id: values.id,
      key: values.key
    };
    setState({
      ...state,
      edit_data: data,
    });
    let data_ = await dispatch(actions.LEVEL_OF_CARE_STATUS_CHANGE(data));
    
    if (data_?.payload?.data?.Code === 201) {
      props.alert.setSnack("update");
    } else {
      props.alert.setSnack("error");
    }
    await dispatch(actions.LEVEL_OF_CARE_READ({page: page, perPage: perPage}));
    setState({
      ...state,
      edit_data: null,
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  };

  const onSearchChange = (value = "") => {
    if (value?.length > 1) {
      dispatch(actions.LEVEL_OF_CARE_READ({
        search: value.trim(),
        page: page,
        perPage: perPage
      }));
    } else if (!value) {
      dispatch(actions.LEVEL_OF_CARE_READ({
        page: page,
        perPage: perPage
      }));
    }
  };

  return (
    <Card
      id={`${parent_id}-parent-card`}
      style={{ borderRadius: "12px" }}>
      <div
        id={`${parent_id}-parent-div`}
        style={{ width: "100%" }}>
        <Card
          id={`${parent_id}-parent-sub-Card`}
          style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-sub-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-header-div`}
              style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                id={`${parent_id}-header-sub-div`}
                style={{ marginLeft: "18px" }}>
                <p
                  id={`${parent_id}-header-title-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              <div
                id={`${parent_id}-Add-New-button-div`}
                style={{ marginTop: "16px", display:'flex', marginRight:"20px" }}>
                  <SearchWithFilter
                  id="symptomyandspeciality"
                  placeholder="Search code and Description!"
                  //  loading={data?.length > 0 ? loading : false}
                  onSearchChange={onSearchChange}
                  hideSearchBar={false}
                  hideFilter={true}
                  //  onFilterClicked={onFilterClicked}
                  //  isFilterApplied={props?.isFilterApplied}
                />
                <button
                  id={`${parent_id}-Add-New-button`}
                  style={{
                    backgroundColor: "#0071F2",
                    color: "white",
                    border: "none",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                    fontFamily: "poppin",
                    fontSize: "12px"
                    }}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </button>
                <GenerateForm
                  parent_id={'GenerateForm'}
                  nocancel
                  loading={storeData_?.level_of_care_insert?.loading ? storeData_?.level_of_care_insert?.loading : false}
                  open={state.open}
                  save={saveLevelOfCare}
                  handlecancel={handleDrawerClose}
                  header={
                    state.edit_data
                      ? `Edit ${props.header}`
                      : `Add ${props.header}`
                  }
                  json={[
                    {
                      componet_type: "text",
                      label: "Code",
                      state_name: "levelofcareCode",
                      value: state?.edit_data?.levelofcareCode ?? "",
                      required: true,
                      isUpperCase: true,
                      textLength: 20
                    },
                    {
                      componet_type: "text",
                      label: "Description",
                      state_name: "description",
                      value: state?.edit_data?.description ?? "",
                      required: true,
                      textLength: 60
                    },
                    {
                      componet_type: "select",
                      options: storeMasterData?.care_type_master?.data ?? [],
                      label: "Care Type",
                      state_name: "careType",
                      value: state?.edit_data?.dropdown ?? {},
                      required: true
                    }
                  ]}
                />
              </div>
            </div>
            {/* table */}
            <div
              id={`${parent_id}-parent-table-div`}
              style={{ padding: 20, height: "71vh", overflow: "auto" }}>
              {storeData_?.care_type_list?.loading && state.edit_data === null ? (
                <div
                  id={`${parent_id}-CircularProgress-div`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "66vh",
                  }}
                >
                  <CircularProgress
                    id={`${parent_id}-CircularProgress`}
                  />
                </div>
              ) : (
                <CommonTable
                  parent_id={'CommonTable'}
                  Header={[
                    "S.No",
                    "Code",
                    "Description",
                    "Care Type",
                    "Status",
                    "Action",
                  ]}
                  dataList={storeData_?.level_of_care_list?.data ?? []}
                  rightAlign={["Status", "Action"]}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "levelofcareCode" },
                    { type: ["TEXT"], name: "description" },
                    { type: ["TEXT"], name: "careType" },
                    { type: ["CHECKBOX"], name: "status", align: "right" },
                    { type: ["EDIT"], name: "", align: "right" },
                  ]}
                  handleCheckBox={statusChange}
                  handleEdit={handleEdit}
                  handlepageChange={(currentPage, PerPage) =>
                    handlepageChange(currentPage, PerPage)
                  }
                  TableCount={storeData_?.level_of_care_list?.data?.[0]?.total_count}
                  incrementCount={page}
                  showPagination={true}
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                />

              )}

            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
}

export default withAllContexts(LevelofCareTable);
