import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
export const CheckBoxComponent = (props) => {
    const { parent_id } = props;
    return (
        <FormControlLabel
            id={`${parent_id}-FormControlLabel`}
            control={
                <Checkbox
                    id={`${parent_id}-${props?.value}-Checkbox`}
                    checked={props?.value}
                    onChange={props?.onChange}
                    name="checkedB"
                    color="primary"
                />
            }
            label={props?.label}
            labelPlacement={props?.labelPlacement}
        />
    )
}

CheckBoxComponent.propsTypes = {
    label: PropTypes.string,
    labelPlacement: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
}
CheckBoxComponent.defaultProps = {
    label: '',
    labelPlacement: "end",
    value: false,
    onChange: null,
}