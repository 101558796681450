import React from 'react';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        top: 64,
        left: 80,
        zIndex: "10",
        width: "calc(100% - 80px)",
        height: "calc(100vh - 64px)",
        background: "#f7f6f6",
        overflow: "auto",
        paddingBottom: "50px"
    }
}));

function OverlayCompt(props) {

    const classes = useStyles();
    const { children, open, parent_id } = props

    return <React.Fragment>
        {open && <div
            id={`${parent_id}-overlay-div`} className={classes.root}>
            {children}
        </div>}
    </React.Fragment>

}

export default OverlayCompt;