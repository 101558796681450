/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/11/2020
 * @modify 12/11/2020
 * @desc Exporting all the components from /src/components
 */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Typography, InputAdornment } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = (theme) => ({
  selectBox: {
    width: "100%",
    height: "35px",

    "& .Mui-disabled": {
      backgroundColor: "#F0F0F0",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "7px",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiFormLabel-root.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 9px) scale(1) !important",
    },
    "& .MuiAutocomplete-inputRoot": {
      height: "39px",
      "& input": {
        padding: "0.5px 4px !important",
      },
    },
    "& .MuiAutocomplete-tag": {
      margin: "0px",
      height: "20px",
      borderRadius: "5px",
      "& .MuiChip-deleteIcon": {
        width: "17px",
      },
    },
  },
  drawerSubHead: {
    fontFamily: "poppin",
    color: "#6F6F6F",
    fontSize: "12px",
    paddingBottom: "8px",
  },
  MultiselectBox: {
    width: "100%",
    // height: "35px",
    "& .MuiAutocomplete-inputRoot": {
      "& input": {
        padding: "0.5px 4px !important",
      },
    },
    "& .MuiAutocomplete-tag": {
      margin: "0px",
      height: "20px",
      borderRadius: "5px",
      marginLeft: "5px",
      "& .MuiChip-deleteIcon": {
        width: "17px",
      },
    },
  },
});

class SelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let {
      serachIcon,
      Optionlabel = "label",
      placeholder,
      disabled,
      classes,
      onchange,
      classname,
      list,
      value,
      title,
      top_title,
      multi,
      disableClearable,
      requireStart,
      error,
      search,
      onInputChange,
      parent_id
    } = this.props;
    return (
      <React.Fragment>
        {top_title && (
          <Typography
            id={`${parent_id}-${top_title.replaceAll(" ", "-")}_typography`}
            variant="caption" className={classes.drawerSubHead}>
            {top_title}
            {requireStart && (
              <span
                style={{
                  color: "red",
                  marginLeft: 5,
                }}
              >
                *
              </span>
            )}
          </Typography>
        )}
        {serachIcon ? (
          <Autocomplete
            id={`${parent_id}_${top_title.replaceAll(" ", "-")}_typography`}
            multiple={multi}
            options={list}
            className={`${classes.selectBox} ${value ? "Mui-focused" : ""} ${classname ? classname : ""
              }`}
            onChange={onchange}
            getOptionLabel={(option) => option[Optionlabel]}
            style={{ width: "100%", height: "35px", marginTop: "4px" }}
            value={value}
            popupIcon={
              <InputAdornment position="end">
                <SearchIcon
                  id={`${parent_id}-SearchIcon`}
                  style={{ color: "#6F6F6F" }} />
              </InputAdornment>
            }
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                id={`${parent_id}_title_textField`}
                style={{ height: "35px" }}
                placeholder={placeholder}
                {...params}
                error={error}
                label={top_title ? "" : title}
                variant="outlined"
              />
            )}
          />
        ) : (
          <Autocomplete
            id={`${parent_id}_autocomplete`}
            multiple={multi}
            disableClearable={disableClearable}
            options={list}
            className={`${multi ? classes.MultiselectBox : classes.selectBox} ${value ? "Mui-focused" : ""
              } ${classname ? classname : ""}`}
            onChange={onchange}
            getOptionLabel={(option) => option[Optionlabel]}
            style={{
              width: "100%",
              height: multi ? "" : "35px",
              marginTop: "4px",
            }}
            value={value}
            onInputChange={search ? onInputChange : null}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                id={`${parent_id}_title_textField`}
                style={{ height: multi ? "" : "35px" }}
                placeholder={placeholder}
                // value={textValue}
                {...params}
                error={error}
                label={top_title ? "" : title}
                variant="outlined"
              />
            )}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(SelectBox);
