const casbinjs = require("casbin.js");

const json = {
    "ruleBuilder": {
        "id": "718a9537-2fa9-4a8c-a365-4c95bc8b5bc7",
        "permission": {
            "read": true,
            "write": false,
            "update": false,
            "delete": false
        },
    },
    "consultMaster": {
        "id": "718a9537-2fa9-4a8c-a365-4c95bc8b5bc7",
        "permission": {
            "read": true,
            "write": false,
            "update": false,
            "delete": false
        },
        "component": {
            "ageRangeMaster": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "vitalsMaster": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "OrderMaster": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "DrugMaster": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            }
        }
    },
    "practitionerMaster": {
        "id": "718a9537-2fa9-4a8c-a365-4c95bc8b5bc7",
        "permission": {
            "read": true,
            "write": false,
            "update": false,
            "delete": false
        },
        "component": {
            "practitioner": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "practitionerRole": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "practitionerType": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "qualification": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            }
        }
    },
    "userManagement": {
        "id": "718a9537-2fa9-4a8c-a365-4c95bc8b5bc7",
        "permission": {
            "read": true,
            "write": false,
            "update": false,
            "delete": false
        },
        "component": {
            "repository": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "permissionList": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "roles": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "person": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "users": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            }
        }
    },
    "availabilityManagement": {
        "id": "718a9537-2fa9-4a8c-a365-4c95bc8b5bc7",
        "permission": {
            "read": true,
            "write": false,
            "update": false,
            "delete": false
        },
        "component": {
            "holiday": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "timetable": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "resourcebyAppointmentTypes": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "appointmentTypes": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "scheduleManagement": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            }
        }
    },
    "administrations": {
        "id": "718a9537-2fa9-4a8c-a365-4c95bc8b5bc7",
        "permission": {
            "read": true,
            "write": false,
            "update": false,
            "delete": false
        },
        "component": {
            "patientDemograpics": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "generalMaster": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "geographicHierarchy": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "geographicMaster": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "location": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "numberSeries": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "pendingRescheduleList": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            }
        }
    },
    "organizationSetup": {
        "id": "718a9537-2fa9-4a8c-a365-4c95bc8b5bc7",
        "permission": {
            "read": true,
            "write": false,
            "update": false,
            "delete": false
        },
    },
    "organizations": {
        "id": "718a9537-2fa9-4a8c-a365-4c95bc8b5bc7",
        "permission": {
            "read": true,
            "write": false,
            "update": false,
            "delete": false
        },
    },
    "OrganizationConfig": {
        "id": "718a9537-2fa9-4a8c-a365-4c95bc8b5bc7",
        "permission": {
            "read": true,
            "write": false,
            "update": false,
            "delete": false
        },
        "component": {
            "careType": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "levelofCare": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            },
            "healthCareService": {
                "id": "6c113535-322d-4082-bbad-dfa0cea40502",
                "permission": {
                    "read": false,
                    "write": true,
                    "update": false,
                    "delete": false
                },
                "actionable": "Yes"
            }
        }
    },
    "admindashboard": {
        "id": "718a9537-2fa9-4a8c-a365-4c95bc8b5bc7",
        "permission": {
            "read": true,
            "write": false,
            "update": false,
            "delete": false
        },
    },
}

export const permissionJson = (funName = []) => {
    // localStorage.setItem("permissionData", btoa(JSON.stringify(json)));
    let pj = localStorage.getItem("permissionData");
    pj = JSON.parse(atob(pj))
    let returnJson = {
        read: [],
        write: []
    }
    funName.map(sname => {
        let data = pj[sname];
        if (!data) {
            return false
        }
        Object.keys(data.component).map(val => {
            if (data.component[val].permission.read) {
                returnJson.read.push(val)
            }
            if (data.component[val].permission.write || data.component[val].permission.update || data.component[val].permission.delete) {
                returnJson.write.push(val)
            }
            let compt = data.component[val].component
            if (compt) {
                Object.keys(compt).map(val => {
                    if (compt[val].permission.read) {
                        returnJson.read.push(val)
                    }
                    if (compt[val].permission.write || compt[val].permission.update || compt[val].permission.delete) {
                        returnJson.write.push(val)
                    }
                })
            }
        })

    })

    return returnJson
}

export const getPermissionRoutes = () => {
    let pj = localStorage.getItem("permissionData");
    pj = JSON.parse(atob(pj))
    
    let arr = [];
    Object.keys(pj).map(val => {
        let data = pj[val].permission;
        if (data.read || data.write || data.delete || data.update) {
            arr.push(val)
        }
    })
    return arr;
}

export const checkWithCasbin = async (funName = []) => {
    let permission = permissionJson(funName)
    const authorizer = new casbinjs.Authorizer("manual", permission);
    authorizer.setPermission(permission);
    let data = await Promise.all(funName.map(async (sname) => {
        return await new Promise(async (resolve, reject) => {
            let list = casbinList[sname];
            let readList = await Promise.all(list.read.map(async (val) => {
                return await new Promise(async (resolve, reject) => {
                    let isread = await authorizer.can("read", val);
                    resolve({ [val]: isread })
                })
            }))
            let readData = []
            readList.map(val => {
                let key = Object.keys(val)[0]
                if (val[key]) {
                    readData.push(key)
                }
            })

            let writeList = await Promise.all(list.write.map(async (val) => {
                return await new Promise(async (resolve, reject) => {
                    let isread = await authorizer.can("write", val);
                    resolve({ [val]: isread })
                })
            }))
            let writeData = []
            writeList.map(val => {
                let key = Object.keys(val)[0]
                if (val[key]) {
                    writeData.push(key)
                }
            })
            resolve({
                read: readData,
                write: writeData
            })
        })
    }))
    let read = [], write = [];
    data.map(val => {
        read = [...read, ...val.read]
        write = [...write, ...val.write]
    })
    return {
        read: read,
        write: write
    }
}

const casbinList = {
    consultMaster: {
        read: ["ageRangeMaster", "vitalsMaster", "OrderMaster", "DrugMaster", "orderSet", "allergenMaster", "diagnosisMaster","flexAttribute", "flexAttributesApplicableCriteria",],
        write: ["ageRangeMaster", "vitalsMaster", "OrderMaster", "DrugMaster", "orderSet", "allergenMaster", "diagnosisMaster","flexAttribute", "flexAttributesApplicableCriteria",]
    },
    practitionerMaster: {
        read: ["practitioner", "practitionerRole", "practitionerType", "qualification", "position"],
        write: ["practitioner", "practitionerRole", "practitionerType", "qualification", "position"]
    },
    userManagement: {
        read: ["repository", "permissionList", "roles", "person", "users"],
        write: ["repository", "permissionList", "roles", "person", "users"]
    },
    availabilityManagement: {
        read: ["holiday", "timetable", "resourcebyAppointmentTypes", "appointmentTypes", "scheduleManagement"],
        write: ["holiday", "timetable", "resourcebyAppointmentTypes", "appointmentTypes", "scheduleManagement"]
    },
    administrations: {
        // read: ["patientDemograpics", "generalMaster", "geographicHierarchy", "geographicMaster", "location", "numberSeries", "pendingRescheduleList", "visitTypeGM", "symptomsAndSpecialty"],
        // write: ["patientDemograpics", "generalMaster", "geographicHierarchy", "geographicMaster", "location", "numberSeries", "pendingRescheduleList", "visitTypeGM", "symptomsAndSpecialty"]
        read: ["generalMaster", "geographicMaster", "location", "pendingRescheduleList", "visitTypeGM", "symptomsAndSpecialty", "patienttype", "locationroletype", "encountertype"],
        write: ["generalMaster", "geographicMaster", "location", "pendingRescheduleList", "visitTypeGM", "symptomsAndSpecialty", "patienttype", "locationroletype", "encountertype"]
    },
    OrganizationConfig: {
        read: ["careType", "levelofCare", "healthCareService"],
        write: ["careType", "levelofCare", "healthCareService"]
    },
    parameterSetup: {
        read: ["admin", "patient", "appointment", "others"],
        write: ["admin", "patient", "appointment", "others"]  
    }
}
