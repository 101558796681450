import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import EditIcon from "@material-ui/icons/CreateOutlined";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles((theme) => ({
  req_red: {
    color: "red",
  },
  dot: {
    height: "7px",
    width: "7px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: "5px",
    marginRight: "7px",
    marginLeft: "7px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
  },
  line: {
    borderLeft: "3px solid #e1e1e1",
    marginRight: "8px",
    marginLeft: "8px",
  },
}));

export default function GeneralAppointmentCard(props) {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Alertstate, setAlertState] = React.useState(false);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    AppInstructionCriteria: "",
    AppInstructions: "",
    Appcode: "",
  });
  const resourceAppointment = useSelector(
    (state) => state?.resourceByAppointmentTypesSlice
  );

  React.useLayoutEffect(() => {
    const getdata = async () => {
      await dispatch(actions.RES_SPECIALINSTRUCTION());
      await dispatch(actions.RES_CRITERIA_INSTRUVTION());
    };
    getdata();

    if (props?.editData?.length > 0) {
      setState({
        ...state,
        Appcode: props?.editData[0]?.Appcode,
        AppInstructionCriteria: props?.editData[0]?.AppInstructionCriteria,
        AppInstructions: props?.editData[0]?.AppInstructions,
      });
    }
  }, [props?.editData]);

  React.useEffect(() => {
    if (props?.editData?.length > 0) {
      document.getElementById("special_appointment_card_Id").click();
    }
  }, [props?.editData]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setState({
      ...state,
      Appcode: {},
      AppInstructions: "",
      AppInstructionCriteria: "",
      _id: {},
    });
    setAnchorEl(null);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertState(false);
  };

  const savegenerateFun = () => {
    if (state?.AppInstructionCriteria && state?.AppInstructions) {
      props.onChangeAll("", state, "ApptInstructions");
      setState({ ...state, AppInstructionCriteria: "", description: "" });
      handleClose();
    } else {
      setAlertState(true);
    }
  };

  const generateFun = (e, types, val) => {
    if (types === "Appcode") {
      setState({
        ...state,
        Appcode: { title: val?.title, value: val?._id },
        AppInstructions: val?.value,
        _id: val?._id,
      });
    } else if (types === "dis") {
      setState({
        ...state,
        AppInstructions: e?.target?.value,
      });
    } else {
      setState({ ...state, [types]: val });
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <Snackbar
        open={Alertstate}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleCloseAlert}
      >
        <Alert severity="error">Fill all Mandatory Fields</Alert>
      </Snackbar>
      <Card
        style={{
          padding: "18px",
          width: "95%",
          marginLeft: "16px",
          marginTop: "20px",
          borderRadius: "10px",
        }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <p style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
                SPECIAL APPOINTMENT INSTRUCTIONS
              </p>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  className={classes.newRes}
                  color="primary"
                  onClick={handleClick}
                  id={"special_appointment_card_Id"}
                >
                  + Add New
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Card
          style={{
            marginTop: "5px",
            borderRadius: "5px",
          }}
        >
          {props?.data?.map((val, i) => {
            return (
              <Grid
                container
                style={{
                  border: "1px solid #E0E0E0",
                  padding: 12,
                  marginBottom: 8,
                  backgroundColor: "#F9F9F9",
                  position: "relative",
                }}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={1}
                      style={{
                        backgroundColor: "#E0E0E0",
                        position: "absolute",
                        left: 0,
                        padding: "6px 12px",
                        borderTopRightRadius: 9,
                        borderBottomRightRadius: 9,
                      }}
                    >
                      {i + 1}
                    </Grid>
                    <Grid item xs={12} style={{ marginLeft: "4%" }}>
                      <div>Special Instrctions</div>
                      <div>
                        {val?.Appcode
                          ? val?.Appcode?.title
                          : val?.Appcode
                          ? val?.Appcode
                          : ""}{" "}
                        <div className={classes.dot} />
                        {val?.AppInstructions}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <IconButton
                      aria-label="EditIcon"
                      className={classes.margin}
                      size="small"
                    >
                      <EditIcon
                        onClick={() => props.editFun("ApptInstructions", i)}
                        style={{ color: "#0071F2", fontSize: 18 }}
                      />
                    </IconButton>
                    <IconButton
                      aria-label="DeleteIcon"
                      className={classes.margin}
                      size="small"
                      onClick={() => props.deleteFun("ApptInstructions", i)}
                    >
                      <DeleteIcon style={{ color: "#FF4D4A", fontSize: 18 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Card>
      </Card>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div style={{ padding: 20 }}>
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: 26 }}>
              <p
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 4,
                  marginTop: 0,
                }}
              >
                Instruction Code <span className={classes.req_red}>*</span>
              </p>
              <Autocomplete
                id="combo-box-demo"
                options={
                  resourceAppointment?.res_special_instruction?.data
                    ? resourceAppointment?.res_special_instruction?.data
                    : null
                }
                getOptionLabel={(option) => option.title}
                style={{ width: 220, marginTop: "1%" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                onChange={(e, newval) => generateFun(e, "Appcode", newval)}
                value={state.Appcode}
              />
            </Grid>
            <Grid item xs={6}>
              <p
                style={{
                  color: "#6F6F6F",
                  fontSize: 14,
                  marginBottom: 4,
                  marginTop: 0,
                }}
              >
                Criteria <span className={classes.req_red}>*</span>
              </p>
              <Autocomplete
                id="combo-box-demo"
                options={
                  resourceAppointment?.res_criteria_instruction?.data
                    ? resourceAppointment?.res_criteria_instruction?.data
                    : null
                }
                getOptionLabel={(option) => option.title}
                style={{ width: 220, marginTop: "1%" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                onChange={(e, newval) =>
                  generateFun(e, "AppInstructionCriteria", newval)
                }
                value={state.AppInstructionCriteria}
              />
            </Grid>
          </Grid>

          <div>
            <p style={{ color: "#6F6F6F", fontSize: 14, marginBottom: 4 }}>
              Description
            </p>
          </div>
          <div>
            <textarea
              style={{
                width: "100%",
                height: "148px",
                fontSize: 16,
                resize: "none",
                padding: 14,
              }}
              value={state.AppInstructions}
              onChange={(e, val) => generateFun(e, "dis", val)}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              float: "right",
              paddingBottom: "30px",
            }}
          >
            <Button
              variant="contained"
              variant="outlined"
              size="medium"
              style={{ marginRight: "14px" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={savegenerateFun}
            >
              Save
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}
