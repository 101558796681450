import React from "react";
import {
  Div,
  Paper,
  H6,
  Col,
  Row,
  Select,
  TextInput,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
// import Chip from '@material-ui/core/Chip';
// import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import { actions } from "primary_care_admin_binder";
// import { connect } from "react-redux";
// import Editor from "./editor";

class ClinicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedOptions: [],
    };
  }

  // componentDidMount = async () => {
  //   await this.props?.LOCATION_MASTER_SPECIALITY();
  // };
  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
      marginRight: 10,
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
    },
  };

  setDetails = (name, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let { patientData } = this.props;
    patientData.communication[name] = value;
    patientData.setPatientData({
      ...patientData,
    });
  };

  setDetailslanguage = (name, value) => {
    let { patientData } = this.props;
    if (patientData.communication?.clanguage?.length > 0) {
      const seen = new Set();
      const filteredArr = value.filter((el) => {
        const duplicate = seen.has(el.value);
        seen.add(el.value);
        return !duplicate;
      });

      patientData.communication[name] = filteredArr;
      patientData.setPatientData({
        ...patientData,
      });
      return;
    }

    patientData.communication[name] = value;
    patientData.setPatientData({
      ...patientData,
    });
  };
  FlterOptions = (type) => {
    const { allMasters } = this.props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
        label: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };

  render() {
    //let { communication } = this.props.patientData;
    const { labelStyle, borderStyle} =
      this.styles;
    const { parent_id } = this.props;
    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root" style={{ marginTop: "20px" }}>
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-title-div`}
          >
            <H6
              id={`${parent_id}-title-h6`}
              className="pi-title">CLINIC INFO</H6>
          </Div>
          <Row
            id={`${parent_id}-Speciality-row`}
            className="pi-actions-root">
            <Col
              id={`${parent_id}-Speciality-col`}
              md={3} lg={3} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
              <Select
                id={`${parent_id}-Speciality-select`}
                showArrow
                label="Specialty"
                placeholder="Specialty"
                labelStyle={labelStyle}
                inLineStyles={borderStyle}
                hoverColor="#0071F2"
                options={this.props.specialityData.data}
                onChangeValue={(value) =>
                  this.props.onChangeState("speciality", value)
                }
                value={this.props.speciality?.value ?? ""}
                isRequired={"true"}
              />
            </Col>
          </Row>
          <Row>
            <Col
              id={`${parent_id}-Clinic-Details-col`}
              md={12} lg={12} sm={12} xs={12} inLineStyles={{ padding: 5 }}>
              <TextInput
                id={`${parent_id}-Clinic-Details-textInput`}
                placeholder="Clinic Details"
                onChange={(e) =>
                  this.props.onTextChangeState(
                    "clinicDetails",
                    e.target.value,
                    300
                  )
                }
                maxLength={300}
                value={this.props.clinicDetails ?? ""}
                label="Clinic Details"
                labelStyle={labelStyle}
                style={borderStyle}
                hoverColor="#0071F2"
              />
            </Col>
          </Row>
          {/* <Row className="pi-actions-root">
            <Col md={12} lg={12} sm={12} xs={12} inLineStyles={{ padding: 5 }}>
              <Editor />
            </Col>
          </Row> */}
        </Paper>
      </Div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   specialityData: state?.locationMasterSlice?.location_masters_speciality,
// });

// export default connect(mapStateToProps, actions)(withAllContexts(ClinicInfo));
export default withAllContexts(ClinicInfo);
