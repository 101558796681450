import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  Checkbox,
} from "@material-ui/core";
import { InputComp, SelectBox } from "../../../../../../components/common/smartForm/component";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    borderRadius: 8,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
    "& .Mui-disabled": {
      background: "#f0f0f0",
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {},
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  textfieldGrid: {
    marginTop: "4px",
  },
  titleGrid: {
    padding: "14px !important",
  },
}));

function DefaultQuantity(props) {
  const classes = useStyles();
  const storeData = useSelector((state) => state?.orderCatalogSlice);
  const {
    DefaultQuantity,
    AllowQtyCheck,
    Qtyno,
    maxno,
    Maxorder,
    handleFormState,
    parent_id,
    error
  } = props;
  return (
    <React.Fragment>
      <Paper id={`${parent_id}_parent_paper`} className={classes.root}>
        <Grid
          id={`${parent_id}_parent_container`}
          container
          className={classes.form}
          spacing={2}
        >
          <Grid id={`${parent_id}_parent_grid`} item xs={12}>
            <Typography
              id={`${parent_id}_QUANTITY_typography`}
              className={classes.title}
              variant="h6"
            >
              QUANTITY
            </Typography>
          </Grid>

          <Grid id={`${parent_id}_Default_Qty_grid`} item xs={5}>
            <Typography
              id={`${parent_id}_Default_Qty_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Default Qty <span style={{ color: "red" }}>*</span>
            </Typography>
            <Grid id={`${parent_id}_Default_Qty_grid`} container spacing={2}>
              <Grid
                id={`${parent_id}_Default_Qty_div`}
                className={classes.textfieldGrid}
                item
                xs={4}
              >
                {/* <TextField
                  id={`${parent_id}_Default_Qty_textField`}
                  value={Qtyno}
                  onChange={(e) => handleFormState("Qtyno", e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      maxlength: 2,
                    },
                  }}
                  inputProps={{ maxLength: 2 }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                /> */}
                <InputComp
                  id={`${parent_id}_Default_Qty_textField`}
                  value={Qtyno}
                  onChange={(e) => handleFormState("Qtyno", e.target.value)}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      maxlength: 2,
                    },
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  error={error?.Qtyno}
                />
              </Grid>
              <Grid id={`${parent_id}_Default_Qty_grid`} item xs={8}>
                <SelectBox
                  parent_id={`${parent_id}_default_quantity`}
                  list={storeData?.order_catalog_masters?.data?.quantity ?? []}
                  placeholder={"Select"}
                  value={DefaultQuantity}
                  onchange={(e, value) =>
                    handleFormState("DefaultQuantity", value)
                  }
                  error={error?.DefaultQuantity}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid id={`${parent_id}_AllowQtyCheck_grid`} item xs={2}>
            <Typography
              id={`${parent_id}_AllowQtyCheck_typography`}
              className={classes.formtitle}
              style={{paddingBottom:"0px",lineHeight:"14px"}}
              variant="body1"
            >
              Allow Qty Change
            </Typography>
            <Checkbox
              id={`${parent_id}_AllowQtyCheck_Checkbox`}
              color="primary"
              checked={AllowQtyCheck}
              onChange={(e) =>
                handleFormState("AllowQtyCheck", e.target.checked)
              }
              inputProps={{ aria_label: "primary checkbox" }}
            />
          </Grid>
          <Grid id={`${parent_id}_MaxOrder_grid`} item xs={5}>
            <Typography
              id={`${parent_id}_MaxOrder_typography`}
              // style={{ marginLeft: "10px" }}
              className={classes.formtitle}
              variant="body1"
            >
              Max Order Qty
            </Typography>
            <Grid id={`${parent_id}_maxno_container`} container spacing={2}>
              <Grid
                id={`${parent_id}_maxno_grid`}
                className={classes.textfieldGrid}
                item
                xs={4}
              >
                {/* <TextField
                  id={`${parent_id}_maxno_textField`}
                  value={maxno}
                  onChange={(e) => handleFormState("maxno", e.target.value)}
                  fullWidth
                  type="number"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                /> */}
                <InputComp
                  id={`${parent_id}_maxno_textField`}
                  value={maxno}
                  onChange={(e) => handleFormState("maxno", e.target.value)}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      maxlength: 2,
                    },
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </Grid>
              <Grid id={`${parent_id}_Maxorder_select_grid`} item xs={8}>
                <SelectBox
                  parent_id={`${parent_id}_Maxorder`}
                  list={storeData?.order_catalog_masters?.data?.quantity ?? []}
                  placeholder={"Select"}
                  value={Maxorder}
                  onchange={(e, value) => handleFormState("Maxorder", value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default DefaultQuantity;
