import React from "react";
import { CommonTable, GenerateForm } from "../../../../../components";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AlertProps } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";

class OrderType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditFrom: false,
      // saveloading: false,
      edit_data: null,
      perPage : 10,
      page: 0
    };
  }
  componentDidMount() {
    this.props?.currentTab("orderType");
    const getdata = async () => {
      await this.props.ORDERTYPEREAD({page: this.state.page, perPage: this.state.perPage});
      await this.props.ORDERCATEGORYMASTER();
    };
    getdata();
  }

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    })
    await this.props.ORDERTYPEREAD({ page: (currentPage - 1) * PerPage, perPage: PerPage });

  };

  handleCloseForm = () => {
    const { changeState } = this.props;
    changeState("open", false);
    this.setState({
      openEditFrom: false,
      edit_data: null,
    });
  };

  handleEdit = (e, values, index) => {
    const { changeState } = this.props;
    changeState("open", true);
    changeState("openIndex", 3);
    let ordercategory = this.props.ordertype.order_category_master.data.filter(
      (val, i) => val.value._id === values.ordercategory_id
    );
    let data = {
      _key: values?._key,
      _id: values?._id,
      id: values?.id,
      ordertype: values?.ordertype,
      shortdesc: values?.short,
      longdesc: values?.long,
      ordercategory: ordercategory[0]?.value?.ordercategory,
      registrationReq: values?.registrationReq,
      status: values?.status,
      dropdown: ordercategory[0],
    };
    this.setState({
      ...this.state,
      edit_data: data,
    });
  };

  statusChange = async (e, values, index) => {
    let data = {
      code: values.code,
      description: values.short,
      status: e.target.checked,
      _key: values._key,
      id: values.id,
    };
    await this.props.UPDATEORDERTABLESTATE({
      data,
    });
    await this.props.ORDERTYPEREAD({page: this.state.page, perPage: this.state.perPage});
    this.handleCloseForm();
    this.props.alert.setSnack("update");
  };

  saveOrderType = async (values) => {
    if (this.state.edit_data) {
      let data = {
        _key: this.state.edit_data?._key,
        _id: this.state.edit_data?._id,
        id: this.state.edit_data?.id,
        ordertype: values?.order_Type,
        shortdesc: values?.short_Description,
        longdesc: values?.long_Description,
        ordercategory: values?.Order_category?.value?._id,
        registrationReq: values?.required,
        status: this.state.edit_data?.status,
      };
      const upsertEditOrderTable = await this.props.UPDATESETORDERTABLE({
        data,
      });
      console.log("upsertEditOrderTable", upsertEditOrderTable);
      if (
        upsertEditOrderTable?.payload?.data?.error === "Validation Not Passed"
      ) {
        this.props.alert.setSnack("unique");
      } else {
        await this.props.ORDERTYPEREAD({page: this.state.page, perPage: this.state.perPage});
        this.props.alert.setSnack("update");
        this.setState({
          openEditFrom: false,
          edit_data: null,
        });
        this.handleCloseForm();
      }
    } else {
      let data = {
        ordertype: values?.order_Type,
        shortdesc: values?.short_Description,
        longdesc: values?.long_Description,
        ordercategory: values?.Order_category?.value?._id,
        registrationReq: values?.required,
        status: true,
      };
      const upsertOrderTable = await this.props.INSERTORDERTABLE({ data });
      if (upsertOrderTable?.payload?.data?.error === "Validation Not Passed") {
        this.props.alert.setSnack("unique");
      } else {
        await this.props.ORDERTYPEREAD({page: this.state.page, perPage: this.state.perPage});
        this.props.alert.setSnack("success");
        this.setState({
          openEditFrom: false,
          edit_data: null,
        });
        this.handleCloseForm();
      }
      // console.log("upsertOrderTable",upsertOrderTable);
    }
  };

  render() {
    const { open, openIndex, parent_id } = this.props;
    console.log("show state", this.state);
    return (
      <React.Fragment>
        <CommonTable
          style={{ height: "calc(100vh - 245px)" }}
          parent_id={"ordertype"}
          // handleEdit={this.handleEdit}
          Header={[
            "S.No",
            "Order Type Code",
            "Short Description",
            "Long Description",
            "Status",
            "Action",
          ]}
          dataList={
            this.props?.ordertype?.order_type_read.data
              ? this.props.ordertype.order_type_read.data
              : []
          }
          tableData={[
            { type: ["INCRIMENT"], name: "" },
            { type: ["TEXT"], name: "ordertype" },
            { type: ["TEXT"], name: "short" },
            { type: ["TEXT"], name: "long" },
            { type: ["CHECKBOX"], name: "status" },
            { type: ["EDIT"], name: "" },
          ]}
          handleCheckBox={this.statusChange}
          handleEdit={this.handleEdit}
          handlepageChange={(currentPage, PerPage) =>
            this.handlepageChange(currentPage, PerPage)
          }
          TableCount={ this.props?.ordertype?.order_type_read.data?.[0]?.total_count}
          incrementCount={this.state.page}
          showPagination={true}
          rowsPerPageOptions={[
            { label: "10 Rows", value: 10 },
            { label: "50 Rows", value: 50 },
            { label: "100 Rows", value: 100 },
          ]}
        />
        <GenerateForm
          parent_id={"ordertype"}
          open={open && openIndex === 3}
          handlecancel={() => this.handleCloseForm()}
          header={
            this.state?.edit_data?._id?.length > 0
              ? "Edit Order Type"
              : "Add New Order Type"
          }
          nocancel
          save={this.saveOrderType}
          json={[
            {
              componet_type: "select",
              options: this.props.ordertype.order_category_master.data
                ? this.props.ordertype.order_category_master.data
                : [],
              label: "Order Category",
              state_name: "Order_category",
              value: this.state?.edit_data?.dropdown ?? "",
              required: true,
            },
            {
              componet_type: "text",
              label: "Order type Code",
              state_name: "order_Type",
              disabled: this.state?.edit_data ? true : false,
              isUpperCase: true,
              value: this.state?.edit_data?.ordertype ?? "",
              required: true,
              textLength: 10,
            },
            {
              componet_type: "text",
              label: "Short Description",
              state_name: "short_Description",
              value: this.state?.edit_data?.shortdesc ?? "",
              required: true,
              textLength: 30,
            },
            {
              componet_type: "text",
              label: "Long Description",
              state_name: "long_Description",
              value: this.state?.edit_data?.longdesc ?? "",
              required: true,
              textLength: 60,
            },
            {
              componet_type: "checkbox",
              label: "Registration Required",
              state_name: "required",
              value: this.state?.edit_data?.registrationReq ?? false,
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ordertype: state?.ordertypeSlice,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAllContexts(OrderType)));
// export default OrderType
