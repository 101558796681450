import React from "react";
import { OverlayCompt, CommonTable } from "../../../../../components";
import VitalMastersForm from "./form";
import { withAllContexts } from "../../../../../HOCs";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";
//import { AlertProps } from "../../../../../utils";

class Vitals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: null,
      perPage : 10,
      page: 0
    };
    this.handleForm = this.handleForm.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    })
    await this.props.READ_VITAL_MASTER({ page: (currentPage - 1) * PerPage, perPage: PerPage });

  };

  async componentDidMount() {
    await this.props.READ_VITAL_MASTER({page: this.state.page, perPage: this.state.perPage});
    this.props.currentTab("vitalsMaster");
  }

  handleCheckBox = async (e, data, index) => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Updating...",
    });
    await this.props.UPDATE_VITAL_MASTER_STATUS({
      editId: data._id,
      status: e.target.checked,
    });
    await this.props.READ_VITAL_MASTER({page: this.state.page, perPage: this.state.perPage});
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
     this.props.alert.setSnack("update");
  };

  handleForm = () => {
    let { closeFrom } = this.props;
    closeFrom();
    this.setState({
      editData: null,
    });
  };
  handleEdit = (e, data, index) => {
    ;
    let { closeFrom } = this.props;
    this.setState({
      editData: data,
    });
    closeFrom();
  };
  handleClose = async () => {
    let { closeFrom } = this.props;
    await this.props.READ_VITAL_MASTER({page: this.state.page, perPage: this.state.perPage});
    closeFrom();
    this.setState({
      editData: null,
    });
  };

  render() {
    const { open, 
      //parent_id
     } = this.props;
    const { editData } = this.state;
    return (
      <React.Fragment>
        <CommonTable
          parent_id={"vitlas_master"}
          handleEdit={this.handleEdit}
          handleCheckBox={this.handleCheckBox}
          Header={[
            "S.No",
            "Code",
            "Short Description",
            "Long Description",
            "Status",
            "Action",
          ]}
          dataList={this.props.vitalsRead.data ?? []}
          tableData={[
            { type: ["INCRIMENT"], name: "" },
            { type: ["TEXT"], name: "code" },
            { type: ["TEXT"], name: "short" },
            { type: ["TEXT"], name: "long" },
            { type: ["CHECKBOX"], name: "status" },
            { type: ["EDIT"], name: "" },
          ]}
          handlepageChange={(currentPage, PerPage) =>
            this.handlepageChange(currentPage, PerPage)
          }
          TableCount={this.props.vitalsRead.data?.[0]?.TotalCount}
          incrementCount={this.state.page}
          showPagination={true}
          rowsPerPageOptions={[
            { label: "10 Rows", value: 10 },
            { label: "50 Rows", value: 50 },
            { label: "100 Rows", value: 100 },
          ]}
        />
        <OverlayCompt
          parent_id={"vitlas_master"}
          open={open}
          children={
            <React.Fragment>
              {open && (
                <VitalMastersForm
                  parent_id={"vitlas_master_form"}
                  editData={editData}
                  closeForm={this.handleClose}
                />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  vitalsRead: state.vitalsMasterSectionSlice.vital_master_read,
});

export default connect(mapStateToProps, actions)(withAllContexts(Vitals));
