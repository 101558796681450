import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  makeStyles,
  Typography,
  IconButton,
  TextField,
  Grid,
  Radio,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import deleteicon from "../../../../../assets/icons8-trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { InputComp } from "../../../../../components/common/smartForm/component";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontSize: 16,
    color: "#101010",
    fontFamily: "pc_semibold",
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 10,
  },
  formParent: {
    paddingRight: 10,
  },
  paper: {
    width: "750px",
  },
  subText: {
    fontSize: 14,
    color: "#101010",
    fontFamily: "pc_regular",
    fontWeight: 600,
  },
  lowerFormTitle: {
    fontSize: "12px",
    paddingBottom: 5,
  },
}));

const optionsAttr = [
  { label: "List" },
  { label: "Boolean" },
  { label: "Text" },
  { label: "Number" },
];

const numberAttr = [{ label: "max" }, { label: "min" }, { label: "HRS" }];

const FlexAdd = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dropDowns, setDropDowns] = useState({
    attrDropDown: {},
    numUOMDropDowm: {},
  });
  const getFlexData = async () => {
    const attrTypeDropDown = await dispatch(actions.ATTRIBUTE_TYPE_DROPDOWN());
    const numUOMDrop = await dispatch(actions.UOM_NUM_DROPDOWN());
    setDropDowns({
      ...dropDowns,
      attrDropDown: attrTypeDropDown?.payload?.data,
      numUOMDropDowm: numUOMDrop?.payload?.data,
    });
  };

  useEffect(() => {
    getFlexData();
  }, []);

  const showHTML = (item) => {
    if (item?.label === "List") {
      return (
        <>
          <div
            style={{
              padding: "0px 20px 10px",
              borderBottom: "1px solid #e6e6e6",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography className={classes.subText}>
              Attribute Valid Values
            </Typography>
            <Button
              style={{
                color: "#0071F2",
                textTransform: "none",
                fontSize: "14px",
                fontFamily: "pc_regular",
                padding: "0px",
              }}
              onClick={props.handleValidAdd}
            >
              + Add new
            </Button>
          </div>
          <div style={{ width: "650px", padding: "20px" }}>
            <Grid container>
              <Grid item xs={2} className={classes.formParent}>
                <Typography className={classes.lowerFormTitle} variant="body1">
                  Seq. No <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.formParent}>
                <Typography className={classes.lowerFormTitle} variant="body1">
                  Valid Code 
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.lowerFormTitle}>
                  Valid Display Value 
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.lowerFormTitle}>
                  Default 
                </Typography>
              </Grid>
            </Grid>
            {props.state.forText.map((val, i) => (
              <Grid
                container
                style={{ alignItems: "center", paddingBottom: "8px" }}
              >
                <Grid item xs={2} className={classes.formParent}>
                  <TextField
                    value={val.seqNo ?? ""}
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                    style={{ background: "#F0F0F0" }}
                    disabled
                  />
                </Grid>
                <Grid item xs={4} className={classes.formParent}>
                  <TextField
                    value={val?.validCode ?? ""}
                    onChange={(e) =>
                      props.handleForType(
                        "validCode",
                        e.target.value.toLocaleUpperCase().replaceAll(" ", ""),
                        i
                      )
                    }
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 10 }}
                    disabled={props?.editKey ? true : false}
                  />
                </Grid>
                <Grid item xs={4} className={classes.formParent}>
                  <TextField
                    value={val?.displayValidCode ?? ""}
                    onChange={(e) =>
                      props.handleForType("displayValidCode", e.target.value, i)
                    }
                    fullWidth
                    type="text"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 30 }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Radio
                    checked={val?.active === true}
                    onChange={(e) =>
                      props.handleForType("active", e.target.value, i)
                    }
                    style={{ padding: "0px" }}
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "A" }}
                    size="small"
                  />
                </Grid>
                <div
                  onClick={() => props.handleValidDelete(i)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={deleteicon}
                    width="11px"
                    style={{ margin: "0px 16px 0px 0px" }}
                  />
                </div>
              </Grid>
            ))}
          </div>
        </>
      );
    } else if (item?.label === "Numeric") {
      return (
        <div style={{ width: "700px", padding: "0 20px" }}>
          <Grid container>
            <Grid item xs={2} className={classes.formParent}>
              <Typography className={classes.formtitle} variant="body1">
                Attribute Size <span style={{ color: "red" }}>*</span>
              </Typography>
              {/* <TextField
                value={props.state.attributeSize}
                onChange={(e) =>
                  props.handleFormState("attributeSize", parseInt(e.target.value))
                }
                fullWidth
                type="number"
                variant="outlined"
                size="small"
                inputProps={
                  {maxLength: 4}
                }
                disabled={props?.editKey ? true : false}
              /> */}
              <InputComp
                value={props.state.attributeSize}
                onChange={(e) =>
                  props.handleFormState(
                    "attributeSize",
                    parseInt(e.target.value)
                  )
                }
                InputProps={{
                  inputProps: {
                    min: 0,
                    maxlength: 4,
                  },
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                disabled={props?.editKey ? true : false}
                // error={error?.Qtyno}
              />
            </Grid>
            <Grid item xs={2} className={classes.formParent}>
              <Typography className={classes.formtitle} variant="body1">
                Number Min
              </Typography>
              <InputComp
                value={props.state.numberMin}
                onChange={(e) =>
                  props.handleFormState("numberMin", parseInt(e.target.value))
                }
                InputProps={{
                  inputProps: {
                    min: 0,
                    maxlength: 4,
                  },
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                // error={error?.Qtyno}
              />
            </Grid>
            <Grid item xs={2} className={classes.formParent}>
              <Typography className={classes.formtitle} variant="body1">
                Number Max
              </Typography>
              <InputComp
                value={props.state.numberMax}
                onChange={(e) =>
                  props.handleFormState("numberMax", parseInt(e.target.value))
                }
                InputProps={{
                  inputProps: {
                    min: 0,
                    maxlength: 4,
                  },
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                // error={error?.Qtyno}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.formtitle}>Number UOM</Typography>
              <Autocomplete
                getOptionLabel={(option) => option.label}
                style={{ width: "auto" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField
                    // id={`${parent_id}-Pincode-textField`}
                    {...params}
                    variant="outlined"
                  />
                )}
                options={dropDowns?.numUOMDropDowm ?? []}
                onChange={(e, val) => props?.handleFormState("numberUOM", val)}
                value={props?.state?.numberUOM ?? {}}
              />
            </Grid>
          </Grid>
        </div>
      );
    } else if (item?.label === "Text Box") {
      return (
        <div style={{ padding: "0px 20px" }}>
          <Grid container>
            <Grid item xs={2} className={classes.formParent}>
              <Typography className={classes.formtitle} variant="body1">
                Attribute Size <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                value={props.state.attributeSize}
                onChange={(e) =>
                  props.handleFormState(
                    "attributeSize",
                    parseInt(e.target.value)
                  )
                }
                fullWidth
                type="number"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 4 }}
                disabled={props?.editKey ? true : false}
              />
            </Grid>
          </Grid>
        </div>
      );
    } else if (item?.label === "Boolean") {
      return (
        <div style={{ width: "650px", padding: "20px" }}>
          <Grid container>
            <Grid item xs={2} className={classes.formParent}>
              <Typography className={classes.lowerFormTitle} variant="body1">
                Seq. No <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.formParent}>
              <Typography className={classes.lowerFormTitle} variant="body1">
                Valid Code <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.lowerFormTitle}>
                Valid Display Value <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.lowerFormTitle}>
                Default <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
          </Grid>
          {props.state.boolean.map((val, i) => (
            <Grid
              container
              style={{ alignItems: "center", paddingBottom: "8px" }}
            >
              <Grid item xs={2} className={classes.formParent}>
                <TextField
                  value={val.seqNo ?? ""}
                  disabled
                  fullWidth
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{ background: "#F0F0F0" }}
                />
              </Grid>
              <Grid item xs={4} className={classes.formParent}>
                <TextField
                  value={val?.validCode ?? ""}
                  disabled
                  fullWidth
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{ background: "#F0F0F0" }}
                />
              </Grid>
              <Grid item xs={4} className={classes.formParent}>
                <TextField
                  value={val?.displayValidCode ?? ""}
                  disabled
                  fullWidth
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{ background: "#F0F0F0" }}
                />
              </Grid>
              <Grid item xs={1}>
                <Radio
                  checked={val?.active === true}
                  onChange={(e) =>
                    props.handleForBoolean("active", e.target.value, i)
                  }
                  style={{ padding: "0px" }}
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                  size="small"
                />
              </Grid>
            </Grid>
          ))}
        </div>
      );
    }
  };
  return (
    <div>
      <Dialog
        open={props.open}
        // onClose={handleClose}
        classes={{ paper: classes.paper }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #e6e6e6",
            padding: "20px 20px 10px",
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.titleText}>
            {props?.editKey ? "Edit Flex Attribute" : "Add New Flex Attribute"}
          </Typography>
          <IconButton
            onClick={props.handleCloseAddNew}
            style={{ padding: "0px" }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ width: "650px", padding: "15px 20px" }}>
          <Grid container>
            <Grid item xs={4} className={classes.formParent}>
              <Typography className={classes.formtitle} variant="body1">
                Attribute Code <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                value={props.state.attributeCode}
                onChange={(e) =>
                  props.handleFormState(
                    "attributeCode",
                    e.target.value.toLocaleUpperCase().replaceAll(" ", "")
                  )
                }
                fullWidth
                type="text"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 10 }}
                disabled={props?.editKey ? true : false}
              />
            </Grid>
            <Grid item xs={4} className={classes.formParent}>
              <Typography className={classes.formtitle} variant="body1">
                Attribute Label <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                value={props.state.attributeLable}
                onChange={(e) =>
                  props.handleFormState("attributeLable", e.target.value)
                }
                fullWidth
                type="text"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.formtitle}>
                Attribute Type <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                getOptionLabel={(option) => option.label}
                style={{ width: "auto" }}
                size={"small"}
                renderInput={(params) => (
                  <TextField
                    // id={`${parent_id}-Pincode-textField`}
                    {...params}
                    variant="outlined"
                    disabled={props.editKey ? true : false}
                  />
                )}
                options={props?.editKey ? [] : dropDowns.attrDropDown ?? []}
                onChange={
                  props?.editKey
                    ? () => null
                    : (e, val) => props?.handleFormState("attributeType", val)
                }
                value={props?.state?.attributeType ?? {}}
              />
            </Grid>
          </Grid>
        </div>

        {showHTML(props?.state?.attributeType)}
        {/* <DialogActions>
          <Button onClick={props.handleCloseAddNew} color="primary">
            Disagree
          </Button>
          <Button onClick={props.handleCloseAddNew} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions> */}
        <Grid style={{ padding: "20px" }}>
          <Button
            onClick={() => props.saveForm()}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => props.handleCloseAddNew()}
            style={{ float: "right", marginRight: "20px" }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
      </Dialog>
    </div>
  );
};

export default FlexAdd;
