import React from "react";
import PropTypes from "prop-types";
import { alpha, makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ApartmentIcon from "@material-ui/icons/Apartment";
import BusinessIcon from "@material-ui/icons/Business";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../HOCs";

const useTreeItemStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.text.secondary,
		"&:hover > $content": {
			color: "var(--tree-view-color)",
			backgroundColor: "#fff",
		},
		"&:focus > $content, &$selected > $content": {
			color: "var(--tree-view-color)",
			backgroundColor: `var(--tree-view-bg-color)`,
		},
		"&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
		{
			color: "var(--tree-view-color)",
		},
		"&$selected > $content $label": {
			color: "var(--tree-view-color)",
			backgroundColor: `var(--tree-view-bg-color)`,
		},
	},
	content: {
		color: theme.palette.text.secondary,
		borderTopRightRadius: theme.spacing(2),
		borderBottomRightRadius: theme.spacing(2),
		paddingRight: theme.spacing(1),
		fontWeight: theme.typography.fontWeightMedium,
		"$expanded > &": {
			fontWeight: theme.typography.fontWeightRegular,
		},
	},
	group: {
		marginLeft: 7,
		paddingLeft: 18,
		borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
	},
	label: {
		fontWeight: "inherit",
		color: "inherit",
	},
	labelRoot: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0.5, 0),
	},
	labelIcon: {
		marginRight: theme.spacing(1),
	},
	labelText: {
		fontWeight: "inherit",
		flexGrow: 1,
		textAlign: "left",
		fontSize: 13,
	},
	selected: {
		color: "var(--tree-view-color)",
	},
}));

const StyledTreeItem = (props) => {
	const classes = useTreeItemStyles();
	const {
		labelText,
		labelIcon: LabelIcon,
		labelInfo,
		color,
		bgColor,
		...other
	} = props;
	return (
		<TreeItem
			id={props.id}
			label={
				<div id={props.id} className={classes.labelRoot}>
					<LabelIcon
						id={props.id}
						color="inherit"
						className={classes.labelIcon}
					/>
					<Typography
						id={props.id}
						variant="body2"
						className={classes.labelText}
					>
						{labelText}
					</Typography>
					<Typography id={props.id} variant="caption" color="inherit">
						{labelInfo}
					</Typography>
				</div>
			}
			style={{
				"--tree-view-color": color,
				"--tree-view-bg-color": bgColor,
			}}
			classes={{
				root: classes.root,
				content: classes.content,
				expanded: classes.expanded,
				selected: classes.selected,
				group: classes.group,
				label: classes.label,
			}}
			{...other}
		/>
	);
};

StyledTreeItem.propTypes = {
	bgColor: PropTypes.string,
	color: PropTypes.string,
	labelIcon: PropTypes.elementType.isRequired,
	labelInfo: PropTypes.string,
	labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
	root: {
		height: 264,
		flexGrow: 1,
		maxWidth: 400,
	},
	checkbox: {
		"& .MuiButtonBase-root": {
			"& .MuiSvgIcon-root": {
				width: 15,
				height: 15,
			},
		},
		"& span": {
			fontSize: 14,
		},
	},
	addbtn: {
		fontSize: 13,
		color: "#6F6F6F",
	},
	main: {
		position: "relative",
	},
	left_arrow: {
		padding: 6,
		backgroundColor: "white",
		position: "absolute",
		right: "-26px",
		"& svg": {
			fontSize: 16,
			paddingLeft: 4,
		},
		boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
	},
});

const TreeViewStuct = (props) => {
	const classes = useStyles();
	const { parent_id } = props
	const [state, setState] = React.useState(false);
	const [expanded, setExpanded] = React.useState([]);
	const [allkeys, setallKeys] = React.useState([]);

	React.useLayoutEffect(() => {
		const getdata = async () => {
			await dispatch(actions.ORGANIZATION_TREE_READ({isexternal: props?.isexternal}));
		};
		getdata();
	}, []);

	const dispatch = useDispatch();
	const organizationSlice = useSelector((state) => state.organizationSlice);
	const getTreeItemsFromData = (treeItems) => {
		return treeItems.map((treeItemData) => {
			let children = undefined;
			if (treeItemData.name.constructor.name === "String") {
				if (treeItemData.children && treeItemData.children.length > 0) {
					children = getTreeItemsFromData(treeItemData.children);
				}
				allkeys.push(treeItemData._key);
				return (
					<StyledTreeItem
						id={`${parent_id}-StyledTreeItem`}
						children={children}
						nodeId={treeItemData._key}
						labelText={treeItemData.name}
						id={treeItemData._key}
						labelIcon={
							treeItemData.icon === "enterprice"
								? BusinessIcon
								: treeItemData.icon === "organization"
									? ApartmentIcon
									: treeItemData.icon === "facility"
										? LocationCityIcon
										: BusinessIcon
						}
						color="#1a73e8"
						bgColor="#e8f0fe"
					/>
				);
			}
		});
	};

	const handleToggle = (event, nodeIds) => {
		let element = event?.target?.parentElement?.parentElement?.parentElement
		let ele = (element?.getAttribute("id")) || (element?.parentElement?.getAttribute("id") ?? null)
		if (ele) {
			if (expanded.indexOf(ele) > -1) {
				expanded.splice(expanded.indexOf(ele), 1);
				setExpanded(expanded);
			} else {
				expanded.push(nodeIds[0]);
				setExpanded(expanded);
			}
		} else {
		}
	};
	const handleExpandClick = (event) => {
		setState(event.target.checked);
		event.target.checked ? setExpanded(allkeys) : setExpanded([]);
	};
	const handleSelect = (event, nodeId) => {
		props.getKeyfuntion(nodeId[0], props?.isexternal);
	};
	return (
		<div
			id={`${parent_id}-expand-div`}
			className={classes.main} style={{backgroundColor: "#fff"}} >
			<Grid
				id={`${parent_id}-expand-grid`}
				container spacing={3} style={{ paddingBottom: 8 }}>
				<Grid
					id={`${parent_id}-expand-sub-grid`}
					item xs={6} style={{ textAlign: "left" }}>
					<FormControlLabel
						id={`${parent_id}-expand-FormControlLabel`}
						style={{ fontSize: 8 }}
						className={classes.checkbox}
						control={
							<Checkbox
								id={`${parent_id}-expand-Checkbox`}
								checked={state}
								onChange={handleExpandClick}
								name="checked"
								color="primary"
								style={{ fontSize: 8 }}
							/>
						}
						label="Expand all"
					/>
				</Grid>
				<Grid
					id={`${parent_id}-ADD-NEW-button-grid`}
					item xs={6} style={{ textAlign: "right" }}>
					<Button
						id={`${parent_id}-ADD-NEW-button`}
						className={classes.addbtn}
						onClick={() => props.onClickformClick("", props?.isexternal)}
					>
						+ ADD NEW
					</Button>
				</Grid>
			</Grid>
			{/* <IconButton
				id={`${parent_id}-left-arrow-iconbutton`}
				aria-label="left arrow"
				className={classes.left_arrow}
				onClick={props.onClickTreebtn()}
			>
				<ArrowBackIosIcon
					id={`${parent_id}-left-arrowbackIosicon`}
				/>
			</IconButton> */}
			<div id={`${parent_id}-TreeView-div`} style={{ height: "calc(100vh - 180px)", overflowX: "auto" }}>
				<TreeView
					id={`${parent_id}-TreeView`}
					expanded={expanded}
					className={classes.root}
					defaultCollapseIcon={<ArrowDropDownIcon />}
					defaultExpandIcon={<ArrowRightIcon />}
					defaultEndIcon={<div style={{ width: 24 }} />}
					onNodeToggle={handleToggle}
					onNodeSelect={handleSelect}
					multiSelect
				>
					{getTreeItemsFromData(organizationSlice?.organizationTreeList?.data)}
				</TreeView>
			</div>
			<div
				id={`${parent_id}-div`}
				style={{ paddingBottom: "3%" }} />
		</div>
	);
};
export default TreeViewStuct;
