import {
  Div,
  H6,
  TextInput,
  Divider,
  Select,
  Row,
  Col,
  Image,
} from "qdm-component-library";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  
  entity: {
    width: "70% ",
    marginTop: "10px",
    color: "#6F6F6F",
    marginLeft: "8px",
    fontSize: "10px",
    fontFamily: "poppin",
  },
  entity1: {
    width: "35% ",
    marginTop: "10px",
    color: "#6F6F6F",
    marginLeft: "8px",
    fontSize: "10px",
    fontFamily: "poppin",
  },
}));
const optiondata = [{ value:"Facility",label:"Facility" }];

export default function Dropdown() {
  const classes = useStyles();
  return (
    <div style={{ display: "flex" }}>
      <Div
        style={{
          display: "flex",
          border: "1px solid #E0E0E0",
          height: "32px",
          width: "162px",
          borderRadius: "5px",
          backgroundColor: "white",
        }}
      >
        <h5 className={classes.entity}>Entity Type :</h5>{" "}
        <Select
          className={classes.selectbox}
          options={optiondata}
          label={false}
          variant="none"
          onChangeValue={(e) => this.setDetails("entity_type", e)}
          showArrow
          placeholder="select..."
          labelStyle={classes.labelStyle}
          inLineStyles={{
            borderColor: "#fff",
            border: "none",
            height: "30px",
          }}
          hoverColor="#0071F2"
        />
      </Div>

      <Div
        style={{
          display: "flex",
          border: "1px solid#E0E0E0",
          height: "32px",
          width: "282px",
          marginLeft: "20px",
          borderRadius: "5px",
          backgroundColor: "white",
        }}
      >
        <h5 className={classes.entity1}>Entity Name :</h5>
        <Select
          options={optiondata}
          label={false}
          variant="none"
          onChangeValue={(e) => this.setDetails("entity_Name", e)}
          showArrow
          placeholder="select..."
          labelStyle={classes.labelStyle}
          inLineStyles={{
            borderColor: "#fff",
            border: "none",
            height: "30px",
          }}
          hoverColor="#0071F2"
        />
      </Div>
    </div>
  );
}
