import React from "react";
import { CommonTable, OverlayCompt } from "../../../../../components";
import PerformingLocationForm from "./form";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../../../../HOCs";
//import { AlertProps } from "../../../../../utils"


class PerformingLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: null,
      tableDatas: [],
      openEditFrom: false,
      perPage : 10,
      page: 0,
      dataForSearch:[]
    };
    // this.saveForm = this.saveForm.bind(this)
    this.handleEdit = this.handleEdit.bind(this);
    // this.handleCheckBox = this.handleCheckBox.bind(this)
  }

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    })
    const data = await this.props.READ_PERFORMINGLOCATION({ page: (currentPage - 1) * PerPage, perPage: PerPage });
    var arr = [];
    const tableData = data?.payload?.data?.map(v => {
      return arr.push({
        ...v,
        facility_name: v?.ordering_org?.name ?? "",
        source_location: v?.ordering_source_code?.longdesc ?? '',
        service_applicabile: v?.service_applicability?.display ?? '',
        status: v?.status,
      })
    })
    this.setState({
      ...this.state,
      tableDatas: arr
    })

  };

  async componentDidMount() {
    this.props?.currentTab("Performinglocation");
    const data = await this.props.READ_PERFORMINGLOCATION({page: this.state.page, perPage: this.state.perPage})
    var arr = [];
    const tableData = data?.payload?.data?.map(v => {
      return arr.push({
        ...v,
        facility_name: v?.ordering_org?.name ?? "",
        source_location: v?.ordering_source_code?.longdesc ?? '',
        service_applicabile: v?.service_applicability?.display ?? '',
        status: v?.status,
      })
    })
    this.setState({
      ...this.state,
      tableDatas: arr,
      dataForSearch: data
    })
  }

    // state update on search
    async componentDidUpdate() {
      if (this.props?.read_performing_location?.data !== this.state?.dataForSearch) {


        const datas = this.props?.read_performing_location?.data
        var arr1 = [];
        const tableDatas = datas?.payload?.data?.map(v => {
          return arr1.push({
            ...v,
            facility_name: v?.ordering_org?.name ?? "",
            source_location: v?.ordering_source_code?.longdesc ?? '',
            service_applicabile: v?.service_applicability?.display ?? '',
            status: v?.status,
          })
        })
        // this.setState({
          // ...this.state,
          // tableDatas: arr1,
          // dataForSearch: datas
        // })
      }
    };

  handleForm = async () => {
    let state = this.state;
    let { closeFrom } = this.props;
    const data = await this.props.READ_PERFORMINGLOCATION({page: this.state.page, perPage: this.state.perPage})
    var arr = [];
    const tableData = await data?.payload?.data?.map(v => {
      return arr.push({
        ...v,
        facility_name: v?.ordering_org?.name ?? "",
        source_location: v?.ordering_source_code?.longdesc ?? '',
        service_applicabile: v?.service_applicability?.display ?? '',
        status: v?.status,
      })
    })
    this.setState({
      ...state,
      openEditFrom: false,
      tableDatas: arr,
      editData: null
    })
    await closeFrom();
  };

  handleEdit = (e, data, index) => {
    let { closeFrom } = this.props;
    this.setState({
      editData: data,
      openEditFrom: true,
    });
    closeFrom();
  };

  handleCloseForm = () => {
    const { changeState } = this.props;
    changeState("open", false);
    this.setState({
      openEditFrom: false,
      edit_data: null,
    });
  };


  handleCheckBox = async (e, values, index) => {
    await this.props.STATUS_PERFORMINGLOCATION({ list: e.target.checked, _key: values?._key });
    this.props.alert.setSnack("update")
    await this.props.READ_PERFORMINGLOCATION({page: this.state.page, perPage: this.state.perPage})
    this.componentDidMount()
  }

  render() {
    const { open } = this.props;
    const { editData, 
      //openIndex,
       tableDatas,
       // parent_id
       } = this.state;
    return (
      <React.Fragment>
        <CommonTable
          style={{ height: "calc(100vh - 245px)" }}
          parent_id={"performinglocation"}
          handleEdit={this.handleEdit}
          handleCheckBox={this.handleCheckBox}
          Header={[
            "S.No",
            "Facility Name",
            "Source Location",
            "Service Applicability",
            "Status",
            "Action",
          ]}
          dataList={tableDatas || []}
          tableData={[
            { type: ["INCRIMENT"], name: "" },
            { type: ["TEXT"], name: "facility_name" },
            { type: ["TEXT"], name: "source_location" },
            { type: ["TEXT"], name: "service_applicabile" },
            { type: ["CHECKBOX"], name: "status" },
            { type: ["EDIT"], name: "" },
          ]}
          handlepageChange={(currentPage, PerPage) =>
            this.handlepageChange(currentPage, PerPage)
          }
          TableCount={ tableDatas?.[0]?.TotalCount}
          incrementCount={this.state.page}
          showPagination={true}
          rowsPerPageOptions={[
            { label: "10 Rows", value: 10 },
            { label: "50 Rows", value: 50 },
            { label: "100 Rows", value: 100 },
          ]}
        />
        <OverlayCompt
          parent_id={"performinglocation"}
          open={open}
          children={
            <React.Fragment>
              {open && (
                <PerformingLocationForm
                  parent_id={"performinglocation"}
                  editData={editData}
                  closeForm={this.handleForm}
                />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  read_performing_location: state?.performLocationSlice?.read_performing_location,
});

export default connect(mapStateToProps, actions)(withAllContexts(PerformingLocation));
