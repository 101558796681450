import React from "react";
import { Card, CircularProgress } from "@material-ui/core";
import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { CommonTable } from "../../components/common";
import { SearchWithFilter } from "../../components";
import { useHistory } from "react-router";
import { routes } from "../../router/routes";
//import { AlertProps } from "../../utils";
import { withAllContexts } from "../../HOCs";

function HealthCareServiceTable(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { parent_id } = props;
  // const listData = [
  //   {
  //     serviceCode: "HL032",
  //     description: "Allied Health",
  //     serviceCategory: "Counselling",
  //     serviceType: "Personal Care"
  //   },
  //   {
  //     serviceCode: "HL034",
  //     description: "Clinical Neuropsychologist",
  //     serviceCategory: "Counselling",
  //     serviceType: "Personal Care"
  //   },
  //   {
  //     serviceCode: "HL035",
  //     description: "Smallville Hospital",
  //     serviceCategory: "Counselling",
  //     serviceType: "Personal Care"
  //   }
  // ]
  const listData = useSelector(
    (state) => state?.organizationConfigActions?.health_care_service_read?.data
  );
  const storeData_ = useSelector((state) => state?.organizationConfigSlice);
  const loading = useSelector(
    (state) => state?.organizationConfigActions?.health_care_service_read?.loading
  );

  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
  });

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(actions.HEALTH_CARE_SERVICE_READ({ page: (currentPage - 1) * PerPage, perPage: PerPage }));

  };

  const handleDrawerOpen = () => {
    history.push({
      pathname: routes.addhealthcareservice
    });
  };

  React.useLayoutEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Loading...",
    });
    await dispatch(actions.HEALTH_CARE_SERVICE_READ({page: page, perPage: perPage}));
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  }

  const handleEdit = (e, values, index) => {
    history.push({
      pathname: routes.addhealthcareservice,
      state: {
        _key: values._key,
      },
    });
  };

  const statusChange = async (e, values, index) => {
    
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Status updating...",
    });
    let data = {
      status: e.target.checked,
      _key: values._key
    };
    if (values?._key) {
      let data_ = await dispatch(actions.HEALTH_CARE_SERVICE_STATUS_CHANGE(data));
      
      if (data_?.payload?.data?.Code === 201) {
        props.alert.setSnack("update");
      } else {
        props.alert.setSnack("error");
      }
      await dispatch(actions.HEALTH_CARE_SERVICE_READ({page: page, perPage: perPage}));
    } else {
      props.alert.setSnack("error");
    }
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  };

  const onSearchChange = (value = "") => {
    if (value?.length > 1) {
      dispatch(actions.HEALTH_CARE_SERVICE_READ({
        search: value.trim(),
        page: page,
        perPage: perPage
      }));
    } else if (!value) {
      dispatch(actions.HEALTH_CARE_SERVICE_READ({
        page: page,
        perPage: perPage
      }));
    }
  };

  return (
    <Card
      id={`${parent_id}-parent-card`}
      style={{ borderRadius: "12px" }}>
      <div
        id={`${parent_id}-parent-div`}
        style={{ width: "100%" }}>
        <Card
          id={`${parent_id}-parent-sub-card`}
          style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-sub-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-header-parent-div`}
              style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                id={`${parent_id}-header-div`}
                style={{ marginLeft: "18px" }}>
                <p
                  id={`${parent_id}-header-title-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              <div
                id={`${parent_id}-Add-New-button-div`}
                style={{ marginTop: "16px", display:'flex', marginRight:"20px" }}>
                  <SearchWithFilter
                  id="symptomyandspeciality"
                  placeholder="Search code and Description!"
                  //  loading={data?.length > 0 ? loading : false}
                  onSearchChange={onSearchChange}
                  hideSearchBar={false}
                  hideFilter={true}
                  //  onFilterClicked={onFilterClicked}
                  //  isFilterApplied={props?.isFilterApplied}
                />
                <button
                  id={`${parent_id}-Add-New-button`}
                  style={{
                    backgroundColor: "#0071F2",
                    color: "white",
                    border: "none",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                    fontFamily: "poppin",
                    fontSize: "12px"
                  }}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </button>
              </div>
            </div>
            {/* table */}
            <div
              id={`${parent_id}-CommonTable-parent-div`}
              style={{ padding: 20, height: "71vh", overflow: "auto" }}>
              {loading && state.edit_data === null ? (
                <div
                  id={`${parent_id}-CircularProgress-div`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "66vh",
                  }}
                >
                  <CircularProgress
                    id={`${parent_id}-CircularProgress`}
                  />
                </div>
              ) : (
                <CommonTable
                  parent_id={'CommonTable'}
                  Header={[
                    "S.No",
                    "Sevice Code",
                    "Description",
                    "Service Category",
                    "Service Type",
                    "Status",
                    "Action",
                  ]}
                  dataList={storeData_?.health_care_service_read?.data}
                  rightAlign={["Status", "Action"]}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "healthcareservicecode" },
                    { type: ["TEXT"], name: "name" },
                    { type: ["TEXT"], name: "category" },
                    { type: ["TEXT"], name: "Type" },
                    { type: ["CHECKBOX"], name: "status", align: "right" },
                    { type: ["EDIT"], name: "", align: "right" },
                  ]}
                  handleCheckBox={statusChange}
                  handleEdit={handleEdit}
                  handlepageChange={(currentPage, PerPage) =>
                    handlepageChange(currentPage, PerPage)
                  }
                  TableCount={storeData_?.health_care_service_read?.data?.[0]?.total_count}
                  incrementCount={page}
                  showPagination={true}
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                />
              )}
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
}

export default withAllContexts(HealthCareServiceTable);
