import React from "react";
import {
    Paper, Typography, makeStyles, Grid, TextField, InputAdornment,
    Checkbox, FormControlLabel, FormGroup, Divider,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { SelectBox } from "../../../components";
import { enumerateDaysBetweenDates, btAllMonths, weekOfMonth, weekOfMonthbasedOnDay } from "../../../utils";
import { DateTimePicker } from "../../common/smartForm/component";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomInputDatePicker from "../../common/datePicker/inputDatePicker";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        margin: 15
    },
    title: {
        fontFamily: "poppinsemibold",
        fontSize: "12px",
    },
    form: {
        paddingTop: 15
    },
    formtitle: {
        fontSize: "12px",
        paddingBottom: 10
    },
    formParent: {
        paddingRight: 10
    },
    perioSelection: {
        background: "#F7F7F7",
        padding: 20,
        borderRadius: "8px"
    },
    grayfont: {
        fontSize: "12px",
        color: "gray",
        width: "38px"
    },
    marginleft10: {
        marginLeft: "10px",
        fontSize: "12px",
        fontFamily: "poppin",
    },
    monthcheckbox: {
        "& .MuiTypography-body1": {
            fontSize: "12px !important",
            fontFamily: "poppin !important",
        },
    },
    stitleData: {
        display: "grid",
        gridTemplateColumns: "123px 1fr"
    },
    atitleData: {
        display: "grid",
        gridTemplateColumns: "133px 1fr"
    },
    divider: {
        marginTop: "9px",
        borderBottom: "2px dotted black",
        background: "none"
    }
}));

const LoadPeriod = (props) => {
    const classes = useStyles();
    const { name, options, color, stateData = {}, setState = () => null } = props;
    return (
        <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
                <p className={classes.grayfont}>{name}<span style={{ color: "red", paddingLeft: 5 }}>*</span></p>
                <div
                    style={{
                        height: "25px",
                        width: "3px",
                        backgroundColor: color,
                        marginLeft: "20px",
                        marginTop: "8px",
                    }}
                ></div>
            </div>
            <div className={classes.monthcheckbox}>
                <FormGroup row>
                    {options.map((val) => {
                        return (
                            <FormControlLabel
                                className={classes.marginleft10}
                                control={
                                    <Checkbox
                                        onChange={e => setState(val.value, e.target.checked)}
                                        checked={stateData?.[val.value] ?? false}
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        name={val.label}
                                        disabled={val.disabled}
                                        color="primary"
                                    />
                                }
                                label={val.label}
                            />
                        );
                    })}
                </FormGroup>
            </div>
        </div>
    )
}

function TTSchedulePeriod(props) {
    const state = useSelector(state => state.timetableMasterSlice)
    const classes = useStyles();
    const { parent_id, ttOverRide } = props;
    const [period, setPeriod] = React.useState({
        month: month,
        Week: Week,
        Days: Days,
        isfirst: false
    })
    const [isfirst, setisfirst] = React.useState(true)
    const [, forceUpdate] = React.useReducer(x => x + 1, 0);
    const { stateData = {}, setState = () => null, editData = null } = props;
    const disableArray = (array) => {
        let arr = array.map(val => {
            val.disabled = false
            return val
        })
        return arr;
    }
    React.useEffect(() => {
        if (stateData.start_date && stateData.end_date) {
            let dayCount = moment(stateData.end_date).diff(moment(stateData.start_date), 'days');
            if (dayCount < 7) { // days
                let days = enumerateDaysBetweenDates(stateData.start_date, stateData.end_date)
                period.Days = period.Days.map(val => {
                    val.disabled = days.indexOf(val.name) === -1 ? true : false
                    return val
                })
            } else {
                period.Days = disableArray(period.Days)
            }
            let stmonths = moment(stateData.start_date).format("MM")
            let etmonths = moment(stateData.end_date).format("MM")
            let stday = moment(stateData.start_date).format("DD")
            let etday = moment(stateData.end_date).format("DD")
            let stweak = weekOfMonthbasedOnDay(stday)
            let etweak = weekOfMonthbasedOnDay(etday)
            let dayCountonMonth = moment(stateData.start_date).daysInMonth();

            if (dayCount < 29 || parseInt(stmonths) === 2 && dayCountonMonth === 28 && etweak < 5) { // weeks
                // let stweak = weekOfMonth(moment(stateData.start_date))
                // let etweak = weekOfMonth(moment(stateData.end_date))
                let weak5disable = false;
                if (parseInt(stmonths) === 2 && dayCountonMonth === 28 && etweak < 5) {
                    weak5disable = true;
                }
                period.Week = period.Week.map(val => {
                    if (weak5disable && val.name === 5) {
                        val.disabled = true
                    } else {
                        if (parseInt(stmonths) === parseInt(etmonths)) {
                            val.disabled = (val.name <= etweak && val.name >= stweak) ? false : true
                        } else {
                            val.disabled = (val.name <= etweak || val.name >= stweak) ? false : true
                        }
                    }
                    return val
                })
            } else {
                period.Week = disableArray(period.Week)
            }
            if (dayCount < 340) { // months
                let months = btAllMonths(moment(moment(stateData.start_date).format("YYYY-MM-DD")), moment(moment(stateData.end_date).format("YYYY-MM-DD")))
                period.month = period.month.map(val => {
                    val.disabled = months.indexOf(val.name) === -1 ? true : false
                    return val
                })
            } else {
                period.month = disableArray(period.month)
            }
            let periodJson = {};
            if (!isfirst) {
                Week.map(val => {
                    periodJson[val.value] = false;
                })
                Days.map(val => {
                    periodJson[val.value] = false;
                })
                month.map(val => {
                    periodJson[val.value] = false;
                })
            }
            setState({
                ...stateData, ...periodJson, "availablity_management": dayCount + 1,
                schedule_mode: state?.scheduleMode_list?.data?.filter(val => val.label === "Automatic")[0]
            })
            setPeriod(period)
            forceUpdate()
        }
    }, [stateData.start_date, stateData.end_date])
    const updateState = async (name, value) => {
        setisfirst(false)
        setState({ ...stateData, [name]: value })
    }
    return (
        <React.Fragment>
            <Paper
                id={`${parent_id}-parent-paper`}
                className={classes.root}>
                <Grid
                    id={`${parent_id}-SCHEDULED-PERIOD-grid`}
                    className={classes.stitleData} >
                    <Typography
                        id={`${parent_id}-SCHEDULED-PERIOD-title-typography`}
                        className={classes.title} variant="h6">SCHEDULED PERIOD</Typography>
                    <Divider
                        id={`${parent_id}-SCHEDULED-PERIOD-divider`}
                        className={classes.divider} />
                </Grid>
                <Grid
                    id={`${parent_id}-date-container-grid`}
                    container className={classes.form}>
                    <Grid
                        id={`${parent_id}-date-Start-Date-grid`}
                        md="3" lg="3" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-date-Start-Date-title`}
                            className={classes.formtitle} variant="body1">Start Date <span
                                id={`${parent_id}-date-Start-Date-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        {/* <DateTimePicker
                            parent_id={'schedule-datetimepicker'}
                            minDate={editData ? null : new Date()}
                            // disableToolbar={editData ? true : false }
                            disabled={(editData && !ttOverRide) ? true : false}
                            label={""}
                            dateFormat={'dd-MM-yyyy'}
                            value={stateData.start_date}
                            onChange={(value) => updateState("start_date", value)}
                        // error={state?.error?.[data?.state_name] ?? false}
                        /> */}
                        <CustomInputDatePicker
                            disabled={editData && !ttOverRide ? true : false}
                            placeholderText="dd / mm / yyyy"
                            minDate={editData ? null : new Date()}
                            selectedDate={
                                stateData.start_date ? new Date(stateData.start_date) : null
                            }
                            handleChange={(value) => updateState("start_date", value)}
                            inputField={
                                <TextField
                                    id={"schedule-datetimepicker"}
                                    //  label = {data.label}
                                    fullWidth
                                    variant="outlined"
                                    required={true}
                                    disabled={editData && !ttOverRide ? true : false}
                                    type={"text"}
                                    value={
                                        stateData.start_date
                                            ? moment(new Date(stateData.start_date)).format(
                                                "DD-MM-YYYY"
                                            )
                                            : null
                                    }
                                    inputProps={{
                                        style: {
                                            padding: "10.5px 14px",
                                            fontFamily: "poppin",
                                            fontSize: "12px",
                                            backgroundColor:
                                                editData && !ttOverRide ? "#E0E0E0" : "#fff",
                                        },
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-date-end-Date-grid`}
                        md="3" lg="3" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-date-end-Date-title`}
                            className={classes.formtitle} variant="body1">End Date</Typography>
                        {/* <DateTimePicker
                            parent_id={'schedule-datetimepicker'}
                            minDate={stateData.start_date ? stateData.start_date : new Date()}
                            // disableToolbar={ false }
                            dateFormat={'dd-MM-yyyy'}
                            value={stateData.end_date}
                            onChange={(value) => updateState("end_date", value)}
                        // error={state?.error?.[data?.state_name] ?? false}
                        /> */}
                        <CustomInputDatePicker
                            // disabled = {(editData && !ttOverRide) ? true : false}
                            placeholderText="dd / mm / yyyy"
                            minDate={
                                stateData.start_date
                                    ? new Date(stateData.start_date)
                                    : new Date()
                            }
                            selectedDate={
                                stateData.end_date ? new Date(stateData.end_date) : null
                            }
                            handleChange={(value) => updateState("end_date", value)}
                            inputField={
                                <TextField
                                    id={"schedule-datetimepicker"}
                                    //  label = {data.label}
                                    fullWidth
                                    variant="outlined"
                                    // required={true}
                                    // disabled={(editData && !ttOverRide) ? true : false}
                                    type={"text"}
                                    value={
                                        stateData.end_date
                                            ? moment(new Date(stateData.end_date)).format(
                                                "DD-MM-YYYY"
                                            )
                                            : null
                                    }
                                    inputProps={{
                                        style: {
                                            padding: "10.5px 14px",
                                            fontFamily: "poppin",
                                            fontSize: "12px",
                                            borderRadius: 8,
                                            borderColor: "#e0e0e0",
                                        },
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-date-Generation-Mode-grid`}
                        md="3" lg="3" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-date-Generation-Mode-typography`}
                            className={classes.formtitle} variant="body1">Schedule Generation Mode <span
                                id={`${parent_id}-date-Generation-Mode-star-span`}
                                style={{ color: "red" }}>*</span></Typography>
                        <SelectBox
                            parent_id={'schedule-period'}
                            disabled={true}
                            list={state.scheduleMode_list.data}
                            // value={state?.scheduleMode_list?.data?.filter(val => val.value === "Automatic")[0] ? state?.scheduleMode_list?.data?.filter(val => val.value === "Automatic")[0] : null}
                            value={stateData?.schedule_mode ?? null}
                            onchange={(e, value) => updateState("schedule_mode", value)}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}-date-Availability-Management-grid`}
                        md="3" lg="3" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}-date-Availability-Management-typography`}
                            className={classes.formtitle} variant="body1">Schedule Availability Management</Typography>
                        <TextField
                            id={`${parent_id}-date-Availability-Management-textField`}
                            onChange={e => updateState("availablity_management", e.target.value)}
                            fullWidth variant="outlined" size="small" value={stateData?.availablity_management}
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment
                                    id={`${parent_id}-date-Days-inputadornment`}
                                    position="start">Days</InputAdornment>
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    id={`${parent_id}-APPOINTMENT-grid`}
                    className={classes.atitleData} style={{ paddingTop: 25 }}>
                    <Typography
                        id={`${parent_id}-APPOINTMENT-title-typography`}
                        className={classes.title} variant="h6" >APPOINTMENT PERIOD</Typography>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid
                    id={`${parent_id}-APPOINTMENT-LoadPeriod-grid`}
                    container className={classes.form}>
                    <Grid
                        id={`${parent_id}-LoadPeriod-grid`}
                        className={classes.perioSelection}>
                        <LoadPeriod
                            id={`${parent_id}-month-LoadPeriod`}
                            stateData={stateData} setState={updateState} color="#0C78F2" name="Month" options={period.month} />
                        <LoadPeriod
                            id={`${parent_id}-Week-LoadPeriod`}
                            stateData={stateData} setState={updateState} color="#F5900C" name="Week" options={period.Week} />
                        <LoadPeriod
                            id={`${parent_id}-Days-LoadPeriod`}
                            stateData={stateData} setState={updateState} color="#F5CE0C" name="Days" options={period.Days} />
                    </Grid>
                </Grid>
            </Paper>

        </React.Fragment>
    )
}

export default TTSchedulePeriod;


const month = [
    { value: "Month1", label: "Jan", disabled: false, name: "Jan", },
    { value: "Month2", label: "Feb", disabled: false, name: "Feb", },
    { value: "Month3", label: "Mar", disabled: false, name: "Mar", },
    { value: "Month4", label: "Apr", disabled: false, name: "Apr", },
    { value: "Month5", label: "May", disabled: false, name: "May", },
    { value: "Month6", label: "Jun", disabled: false, name: "Jun", },
    { value: "Month7", label: "Jul", disabled: false, name: "Jul", },
    { value: "Month8", label: "Aug", disabled: false, name: "Aug", },
    { value: "Month9", label: "Sep", disabled: false, name: "Sep", },
    { value: "Month10", label: "Oct", disabled: false, name: "Oct", },
    { value: "Month11", label: "Nov", disabled: false, name: "Nov", },
    { value: "Month12", label: "Dec", disabled: false, name: "Dec", },
];
const Week = [
    { value: "Week1", label: "Week 1", disabled: false, name: 1, },
    { value: "Week2", label: "Week 2", disabled: false, name: 2, },
    { value: "Week3", label: "Week 3", disabled: false, name: 3, },
    { value: "Week4", label: "Week 4", disabled: false, name: 4, },
    { value: "Week5", label: "Week 5", disabled: false, name: 5, },
];
const Days = [
    { value: "Day1", label: "Sun", disabled: false, name: "Sunday", },
    { value: "Day2", label: "Mon", disabled: false, name: "Monday", },
    { value: "Day3", label: "Tue", disabled: false, name: "Tuesday", },
    { value: "Day4", label: "Wed", disabled: false, name: "Wednesday", },
    { value: "Day5", label: "Thu", disabled: false, name: "Thursday", },
    { value: "Day6", label: "Fri", disabled: false, name: "Friday", },
    { value: "Day7", label: "Sat", disabled: false, name: "Saturday", },
];