import React, { useContext, useEffect, useState } from "react";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Typography, Button, Grid, Drawer, Avatar } from "@material-ui/core";
import { generateComponent } from "../../../components/common/smartForm/formsList";
import SingleList from "./SingleList";
//import { GenerateForm } from "../../../components";
import { AlertContext, BackdropContext } from "../../../contexts";
//import { AlertProps, checkError } from "../../../utils";
import state_ from "../../../assets/GeoMaster/state.svg";
import district from "../../../assets/GeoMaster/district.svg";
import city from "../../../assets/GeoMaster/city.svg";
import styles from "../../../components/common/smartForm/styles";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../../HOCs";
import { validationForm, valitationFunc } from "../../../utils";

const useStyles = makeStyles((theme) => ({
	btn: {
		backgroundColor: "#0071F2",
		color: "#fff",
		padding: "6px 16px",
		fontFamily: "poppin",
		fontSize: "12px",
		textTransform: "none",
		borderRadius: "10px",
		width: "100px",
		"&:hover": {
			backgroundColor: "#0e5ab2",
		},
	},
	numbersEdit: {
		marginTop: "4px",
		fontFamily: "poppinsemibold",
	},
	locationType: {
		background: "#F7F5F5",
		paddingBottom: "10px",
		padding: "16px",
		fontSize: "16px",
		color: "black",
		fontWeight: "bold"
	},
	root: {
		width: "100%",
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
}));

const Div = ({ children, icon }) => (
	<div
		style={{
			textAlign: "center",
			paddingTop: "50%",
			color: "#6F6F6F",
		}}
	>
		{icon && (
			<>
				<img id={`table-EditIcon-img`} src={icon} alt="Icon" width="24px" />
				<br />
			</>
		)}
		{children}
	</div>
);

const GeographicMasters = (props) => {
	const classes_ = styles();
	const { parent_id } = props;
	const { setBackDrop, open: loading } = useContext(BackdropContext);
	const { setSnack } = useContext(AlertContext);
	const classes = useStyles();
	//const [open, setOpen] = useState(false);

	const storeData = useSelector((state) => state?.geoGraphicSlice);
	let geoMasterMaster_ = storeData?.geoMasterMaster.data ?? [];
	let geoMasterParentCode_ = storeData?.geoMasterParentCode.data ?? [];
	//

	// const [editData, setEditData] = useState({
	// 	_id: "",
	// 	_key: "",
	// 	id: "",
	// 	name: "",
	// 	code: "",
	// 	type: "",
	// 	geolocationISD: "",
	// 	parentType: "",
	// 	parentCode: "",
	// 	status: true,
	// });
	const [selectedInfo, setSelectedInfo] = useState({
		country: null,
		state: null,
		district: null,
		city: null,
		pincode: null
	});
	const [state, setState] = React.useState({
		open: false,
		saveloading: false,
		edit_data: false,
		editIndex: null,
		loading: false,
		_key_id: null,
		list: [
			{
				componet_type: "select",
				options: geoMasterMaster_ ?? [],
				label: "Type",
				state_name: "type",
				value: "",
				required: true
			},
			{
				componet_type: "text",
				label: "Geolocation ISD",
				state_name: "geolocationISD",
				value: "",
				required: true,
				hidden: false
			},
			{
				componet_type: "text",
				label: "Code",
				state_name: "code",
				value: "",
				required: true
			},
			{
				componet_type: "text",
				label: "Name",
				state_name: "name",
				value: "",
				required: true
			},
			{
				componet_type: "select",
				label: "Parent Type",
				options: geoMasterMaster_ ?? [],
				state_name: "parentType",
				value: "",
				required: false
			},
			{
				componet_type: "select",
				label: "Parent Code",
				options: geoMasterParentCode_ ?? [],
				state_name: "parentCode",
				value: "",
				required: false
			}
		],
		states: {
			type: "",
			geolocationISD: "",
			code: "",
			name: "",
			parentType: "",
			parentCode: "",
			_id: "",
			id: "",
			_key: "",
			status: true
		},
		error: {}
	});
	const [countryData, setCountryData] = useState([]);
	const [stateData, setStateData] = useState([]);
	const [districtDate, setDistrictData] = useState([]);
	const [cityData, setCityData] = useState([]);
	const [pincodeData, setPincodeData] = useState([]);

	const dispatch = useDispatch();
	const geoGraphicSlice = useSelector((state) => state.geoGraphicSlice);
	let geoCountryRead_ = geoGraphicSlice?.geoCountryRead ?? [];
	let geoStateRead_ = geoGraphicSlice?.geoStateRead ?? [];
	let geoDistrictRead_ = geoGraphicSlice?.geoDistrictRead ?? [];
	let geoCityRead_ = geoGraphicSlice?.geoCityRead ?? [];
	let geoPincodeRead_ = geoGraphicSlice?.geoPincodeRead ?? [];
	// console.log(JSON.stringify(geoGraphicSlice) + " geoGraphicSlice");
	// const sleep = () => new Promise((r) => setTimeout(r, 1000));

	const fetchData = async (type, val, __) => {
debugger
		// make api call
		//STATE DATA SERVICE
		if (type === "initial") {
			const newInfo = {
				country: null,
				state: null,
				district: null,
				city: null,
				pincode: null
			};
			let dataCounty = await dispatch(actions.GEOGRAPHIC_COUNTRY_READ());
			debugger;
			if (Array.isArray(dataCounty?.payload?.data)) {
				setCountryData(dataCounty?.payload?.data);
				newInfo.country = dataCounty?.payload?.data?.[0]?.code ?? null;
				//STATE SERVICE CALL
				if(dataCounty?.payload?.data?.[0]?.code){
					debugger;
					let dataState = await dispatch(actions.GEOGRAPHIC_STATE_READ({ id: dataCounty?.payload?.data?.[0]?.code }));
					if (Array.isArray(dataState?.payload?.data)) {
						setStateData(dataState?.payload?.data)
						newInfo.state = dataState?.payload?.data?.[0]?.code ?? null;
						//DISTRICT SERVICE CALL
						if(dataState?.payload?.data?.[0]?.code){
							let dataDistrict = await dispatch(actions.GEOGRAPHIC_DISTRICT_READ({ id: dataState?.payload?.data?.[0]?.code }));
							debugger;
							if(dataDistrict?.payload?.data?.[0]?.code){
								if (Array.isArray(dataDistrict?.payload?.data)) {
									setDistrictData(dataDistrict?.payload?.data)
									newInfo.district = dataDistrict?.payload?.data?.[0]?.code ?? null;
									//CITY CERVICE CALL
									let dataCity = await dispatch(actions.GEOGRAPHIC_CITY_READ({ id: dataDistrict?.payload?.data?.[0]?.code }));
									debugger
									if(dataCity?.payload?.data?.[0]?.code){
										if (Array.isArray(dataCity?.payload?.data)) {
											setCityData(dataCity?.payload?.data)
											newInfo.city = dataCity?.payload?.data?.[0]?.code ?? null;
										} else {
											setSnack("error");
										}
										//PINCODE SERVICE CALL
										let dataPincode = await dispatch(actions.GEOGRAPHIC_PINCODE_READ({ id: dataCity?.payload?.data?.[0]?.code }));
										debugger;
										if(dataPincode?.payload?.data?.[0]?.code){
											if (Array.isArray(dataPincode?.payload?.data)) {
												setPincodeData(dataPincode?.payload?.data)
												newInfo.pincode = dataPincode?.payload?.data?.[0]?.code ?? null;
											} else {
												setSnack("error");
											}
										}
								    } 
							    } else {
									setSnack("error");
								}
							}
					    }
					} else {
						setSnack("error");
					}
				}
			} else {
				setSnack("error");
			}
			await setSelectedInfo(newInfo);
		}
		//STATE DATA SERVICE
		if (type === "country") {
			if (__.country) {
				let dataState = await dispatch(actions.GEOGRAPHIC_STATE_READ({ id: __.country }));
				//
				// check err
				if (Array.isArray(dataState?.payload?.data)) {
					setStateData(dataState?.payload?.data)
					setBackDrop({
						open: false,
						message: "",
					});
				} else {
					setSnack("error");
				}
			}
		}
		//DISTRICT DATA SERVICE
		if (type === "state") {
			if (__.state) {
				let dataDistrict = await dispatch(actions.GEOGRAPHIC_DISTRICT_READ({ id: __.state }));
				//
				// check err
				if (Array.isArray(dataDistrict?.payload?.data)) {
					setDistrictData(dataDistrict?.payload?.data)
					setBackDrop({
						open: false,
						message: "",
					});
				} else {
					setSnack("error");
				}
			}
		}
		//CITY DATA SERVICE
		if (type === "district") {
			if (__.district) {
				let dataCity = await dispatch(actions.GEOGRAPHIC_CITY_READ({ id: __.district }));
				//
				// check err
				if (Array.isArray(dataCity?.payload?.data)) {
					setCityData(dataCity?.payload?.data)
					setBackDrop({
						open: false,
						message: "",
					});
				} else {
					setSnack("error");
				}
			}
		}
		//PIN CODE DATA SERVICE
		if (type === "city") {
			if (__.city) {
				let dataPincode = await dispatch(actions.GEOGRAPHIC_PINCODE_READ({ id: __.city }));
				//
				// check err
				if (Array.isArray(dataPincode?.payload?.data)) {
					setPincodeData(dataPincode?.payload?.data)
					let d = pincodeData;

					setBackDrop({
						open: false,
						message: "",
					});
				} else {
					setSnack("error");
				}
			}
		}

		setBackDrop({
			open: false,
			message: "",
		});
	};

	const fetchDataStatus = async () => {
		//
		// make api call
		//STATE DATA SERVICE
		let dataCounty = await dispatch(actions.GEOGRAPHIC_COUNTRY_READ());
		//
		// check err
		if (Array.isArray(dataCounty?.payload?.data)) {
			setCountryData(dataCounty?.payload?.data)
			setBackDrop({
				open: false,
				message: "",
			});
		} else {
			setSnack("error");
		}

		//STATE DATA SERVICE
		if (selectedInfo?.country) {
			let dataState = await dispatch(actions.GEOGRAPHIC_STATE_READ({ id: selectedInfo?.country }));
			//
			// check err
			if (Array.isArray(dataState?.payload?.data)) {
				setStateData(dataState?.payload?.data)
				setBackDrop({
					open: false,
					message: "",
				});
			} else {
				setSnack("error");
			}
		}
		//DISTRICT DATA SERVICE
		if (selectedInfo?.state) {
			let dataDistrict = await dispatch(actions.GEOGRAPHIC_DISTRICT_READ({ id: selectedInfo?.state }));
			//
			// check err
			if (Array.isArray(dataDistrict?.payload?.data)) {
				setDistrictData(dataDistrict?.payload?.data)
				setBackDrop({
					open: false,
					message: "",
				});
			} else {
				setSnack("error");
			}
		}
		//CITY DATA SERVICE
		if (selectedInfo?.district) {
			let dataCity = await dispatch(actions.GEOGRAPHIC_CITY_READ({ id: selectedInfo?.district }));
			//
			// check err
			if (Array.isArray(dataCity?.payload?.data)) {
				setCityData(dataCity?.payload?.data)
				setBackDrop({
					open: false,
					message: "",
				});
			} else {
				setSnack("error");
			}
		}
		//PIN CODE DATA SERVICE
		if (selectedInfo?.city) {
			let dataPincode = await dispatch(actions.GEOGRAPHIC_PINCODE_READ({ id: selectedInfo?.city }));
			//
			// check err
			if (Array.isArray(dataPincode?.payload?.data)) {
				setPincodeData(dataPincode?.payload?.data)
				let d = pincodeData;
				//
				setBackDrop({
					open: false,
					message: "",
				});
			} else {
				setSnack("error");
			}
		}
		setBackDrop({
			open: false,
			message: "",
		});
	};

	const handleDrawerClose = () => {
		setState({
			...state,
			open: false,
			loading: false,
			editIndex: null,
			_key_id: null,
			states: {
				type: "",
				geolocationISD: "",
				code: "",
				name: "",
				parentType: "",
				parentCode: "",
				_id: "",
				status: true
			},
		});
	};

	const handleChange = async (type, val) => {
		const state_ = JSON.parse(JSON.stringify(state));
		if (type === "type") {
			state_.states.geolocationISD = "";
			state_.states.parentType = "";
			state_.states.parentCode = "";
			if (val?.label === "Country") {
				state_.list[1].hidden = false;
				state_.list[1].required = true;
				state_.list[4].required = false;
				state_.list[5].required = false;
			} else {
				state_.list[1].hidden = true;
				state_.list[1].required = false;
				state_.list[4].required = true;
				state_.list[5].required = true;
			}

			//getParentCodeByParentType(type, val?.value);
		}
		if (type === "parentType") {
			state_.states.parentCode = "";
			//getParentCodeByParentType(type, val?.value);
			const data = await dispatch(actions.GEOGRAPHIC_PARENT_CODE({ id: val?.value }));
		}
		if (type === "code") {
			if(val.length <= 20){
				val = (val || "").toUpperCase().replace(/\s/g, "");
			} else {
				val = state_.states[type]
			}
		}
		state_.states[type] = val ?? "";
		setState({ ...state_ })
	};

	const getParentCodeByParentType = async (type, val) => {
		if (type === "parentType") {
			const data = await dispatch(actions.GEOGRAPHIC_PARENT_CODE({ id: val }));

		}
	}

	const saveGeographicMaster = async () => {
		setState({ ...state, loading: true })
		let { error, errorState } = validationForm(state.list, state.states);
		if (
			!error &&
			(state.states.type.label === "Country"
				? state.states.geolocationISD
				: !state.states.geolocationISD)
		) {
			let data = state.states;
			let codeExit = await dispatch(
				actions.GEOGRAPIC_EXITS_DATA({ levelType: state.states.type.value, geoLevelCode: state.states.code })
			);
			let exitData = codeExit?.payload?.data?.result ?? null;
			if (data?._id) {
				if (exitData && (exitData?.[0]?._id !== data?._id)) {
					props.alert.setSnack("unique");
					return false
				}
			}
			if (!data?._id) {
				if (exitData && exitData?.length > 0) {
					props.alert.setSnack("unique");
					return false
				}	
			}

			let payload = await dispatch(
				actions.GEOGRAPIC_INSERT({ dataList: data })
			);

			if (payload?.payload?.data?.Code === 201) {
				if (state._key_id) {
					props.alert.setSnack("update");
				} else {
					props.alert.setSnack("success");
				}
				handleDrawerClose();
				await fetchDataStatus();
				setState({
					...state, open: false, states: {
					type: "",
					geolocationISD: "",
					code: "",
					name: "",
					parentType: "",
					parentCode: "",
					_id: "",
					id: "",
					_key: "",
					status: true
					},
					error: {}
			    })
			} else if (payload?.error === true) {
				props.alert.setSnack("error");
				return false;
			} else {
				let type =
					payload?.payload?.data?.validation_error?.[0]?.Errormsg?.includes(
						"unique"
					)
						? "unique"
						: "error";
				props.alert.setSnack(type);
				return false;
			}
		} else {
			props.alert.setSnack("mandatory");
			setState({
				...state,
				error: errorState
			})
		}
	}

	useEffect(() => {
		setBackDrop({
			open: true,
			message: "Fetching...",
		});
		fetchData("initial");
		getMasterData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getMasterData = async () => {
		const data_ = await dispatch(actions.GEOGRAPHIC_MASTER_MASTER());
		const data__ = await dispatch(actions.GEOGRAPHIC_PARENT_CODE());

	}

	const handleOpenForm = async (val) => {

		if (val) {
			const data___ = await dispatch(actions.GEOGRAPHIC_SINGLE_READ({ _key: val._key }));

			const state_ = JSON.parse(JSON.stringify(state));
			if (data___?.payload?.data?.type?.label === "Country") {
				state_.list[1].hidden = false;
				state_.list[1].required = true;
			} else {
				state_.list[1].required = false;
				state_.list[1].hidden = true;
			}

			if (data___?.payload?.data?.parentType?.value) {
				const data = await dispatch(actions.GEOGRAPHIC_PARENT_CODE({ id: data___?.payload?.data?.parentType?.value }));

			}
			setState({
				...state,
				...state_,
				open: true,
				_key_id: val._key,
				states: data___?.payload?.data
			});
		} else {
			setState({
				...state,
				open: true
			});
		}
		// if (val) {
		// 	setState({
		// 		...state,
		// 		open: true,
		// 		loading: false,
		// 		editIndex: null,
		// 		states: {
		// 		  type: val.type ?? "",
		// 		  geolocationISD: val.geoLocationISDCode ?? "",
		// 		  code: val.code ?? "",
		// 		  name: val.name ?? "",
		// 		  parentType: val.parentType ?? "",
		// 		  parentType: val.parentType ?? "",
		// 		  _id: val._id ?? "",
		// 		  id: val.id ?? "",
		// 		  status: val.status ?? "",
		// 		  _key: val._key ?? ""
		// 		},
		// 	  });
		// }
	};

	// const handleCloseForm = () => {
	// 	setEditData({
	// 		_id: "",
	// 		_key: "",
	// 		id: "",
	// 		name: "",
	// 		code: "",
	// 		type: "",
	// 		geolocationISD: "",
	// 		parentType: "",
	// 		parentCode: "",
	// 		status: true,
	// 	});
	// 	setOpen(false);
	// };

	const handleClick = async (type, id) => {
		if (selectedInfo[type] !== id) {
			setBackDrop({
				open: true,
				message: "Fetching...",
			});
			const newInfo = {
				country: null,
				state: null,
				district: null,
				city: null,
				pincode: null
			};
			let updateType;
			if (type === "country") {
				newInfo.country = id;
				updateType = "state";
			}
			if (type === "state") {
				newInfo.country = selectedInfo.country;
				newInfo.state = id;
				updateType = "district";
			}
			if (type === "district") {
				newInfo.country = selectedInfo.country;
				newInfo.state = selectedInfo.state;
				newInfo.district = id;
				updateType = "city";
			}
			if (type === "city") {
				newInfo.country = selectedInfo.country;
				newInfo.state = selectedInfo.state;
				newInfo.district = selectedInfo.district;
				newInfo.city = id;
			}
			if (type === "pincode") {
				newInfo.country = selectedInfo.country;
				newInfo.state = selectedInfo.state;
				newInfo.district = selectedInfo.district;
				newInfo.city = selectedInfo.city;
				newInfo.pincode = id;
			}
			await setSelectedInfo(newInfo);
			//
			fetchData(type, id, newInfo);
			if (updateType) {
				if (type === "country") {
					setData("state", []);
					setData("district", []);
					setData("city", []);
					setData("pincode", []);
				}
				if (type === "state") {
					setData("district", []);
					setData("city", []);
					setData("pincode", []);
				}
				if (type === "district") {
					setData("city", []);
					setData("pincode", []);
				}
				if (type === "city") {
					setData("pincode", []);
				}
			} else {
				setBackDrop({
					open: false,
					message: "",
				});
			}
		}
	};

	const getData = (type) => {
		if (type === "country") {
			return [...countryData];
		}
		if (type === "state") {
			return [...stateData];
		}
		if (type === "district") {
			return [...districtDate];
		}
		if (type === "city") {
			return [...cityData];
		}
		if (type === "pincode") {
			return [...pincodeData];
		}
	};

	const setData = (type, data) => {
		if (type === "country") {
			setCountryData(data);
		}
		if (type === "state") {
			setStateData(data);
		}
		if (type === "district") {
			setDistrictData(data);
		}
		if (type === "city") {
			setCityData(data);
		}
		if (type === "pincode") {
			setPincodeData(data);
		}
	};

	const handleStatus = async (type, val, e) => {

		// make api call
		setBackDrop({
			open: true,
			message: "Updating Status...",
		});
		let data_ = await dispatch(actions.GEOGRAPHIC_STATUS_CHANGE({ _key: val._key, status: e.target.checked })); //? "Active" : "In-Active"
		//
		if (data_?.payload?.data?.Code === 201) {
			setSnack("update");
		} else if (data_?.payload?.data?.Code === 1111) {
			setSnack("error");
		} else {
			setSnack("error");
		}
		await fetchDataStatus();
		setBackDrop({
			open: false,
			message: "",
		});
	};

	const condition = (val) => {
		if (val?.hidden) {
			return false;
		} else {
			return true;
		}
	}

	return (
		<Card
			id={`${parent_id}-parent-card`}
			style={{ borderRadius: "12px" }}>
			<div
				id={`${parent_id}-parent-div`}
				style={{ width: "100%" }}>
				<Card
					id={`${parent_id}-parent-sub-card`}
					style={{ borderRadius: "12px" }}>
					<div
						id={`${parent_id}-parent-sub-div`}
						style={{
							borderRadius: "6px",
						}}
					>
						<div
							id={`${parent_id}-title-div`}
							style={{
								backgroundColor: "#fff",
								padding: "10px 20px",
							}}
						>
							<div
								id={`${parent_id}-title-sub-div`}
								style={{ display: "flex" }}>
								<Typography
									id={`${parent_id}-title-typography`}
									className={classes.numbersEdit}>
									Geographic Master
								</Typography>
								<div
									id={`${parent_id}-styled-div`}
									style={{ flexGrow: 1 }}></div>
								<Button
									id={`${parent_id}-Add-New-button`}
									className={classes.btn}
									onClick={() => handleOpenForm()}
									contained
								>
									+ Add New
								</Button>
							</div>
						</div>
						<div
							id={`${parent_id}-title-parent-div`}
							style={{ padding: "0px 24px 24px" }}>
							<Grid
								id={`${parent_id}-title-parent-grid`}
								spacing={1} container direction="row">
								<Grid
									id={`${parent_id}-title-parent-sub-grid`}
									item md={5} xs={12}>
									<Grid
										id={`${parent_id}-title-sub-grid`}
										spacing={1} container direction="row">
										<Grid
											id={`${parent_id}-Country-title-grid`}
											item md={6} xs={12}>
											<Card
												id={`${parent_id}-Country-title-card`}
											// style={{
											// 	borderRadius: "8px",
											// 	maxHeight: "65vh",
											// 	height: "65vh",
											// 	overflow: "scroll",
											// }}
											>
												<Typography
													id={`${parent_id}-Country-title-typography`}
													className={classes.locationType}>
													Country
												</Typography>
												<div
													id={`${parent_id}-Country-List-div`}
													style={{
														borderRadius: "8px",
														maxHeight: "58vh",
														height: "58vh",
														overflow: "scroll",
													}}>
													<List
														id={`${parent_id}-Country-List`}
														className={classes.root}>
														{countryData?.length > 0 ? (
															countryData?.map((c, i) => (
																<SingleList
																	parent_id={'SingleList' + i}
																	info={c}
																	handleClick={() => handleClick("country", c.code)}
																	selected={selectedInfo.country === c.code}
																	key={c._id}
																	handleOpenForm={handleOpenForm}
																	handleStatus={(e) => handleStatus("country", c, e)}
																/>
															))
														) : (
															<Div
																id={`${parent_id}-State-Loading-and-No Records-List`}
															>{geoCountryRead_?.loading ? "Loading..." : "No Records Found"}</Div>
														)}
													</List>
												</div>
											</Card>
										</Grid>
										<Grid
											id={`${parent_id}-State-title-grid`}
											item md={6} xs={12}>
											<Card
												id={`${parent_id}-State-title-card`}
											// style={{
											// 	borderRadius: "8px",
											// 	maxHeight: "65vh",
											// 	height: "65vh",
											// 	overflow: "scroll",
											// }}
											>
												<Typography
													id={`${parent_id}-State-title-typography`}
													className={classes.locationType}>
													State
												</Typography>
												<div
													id={`${parent_id}-State-title-div`}
													style={{
														borderRadius: "8px",
														maxHeight: "58vh",
														height: "58vh",
														overflow: "scroll",
													}}>
													<List
														id={`${parent_id}-State-title-List`}
														className={classes.root}>
														{selectedInfo.country ? (
															stateData?.length > 0 ? (
																stateData?.map((c, i) => (
																	<SingleList
																		parent_id={'SingleList' + i}
																		info={c}
																		handleClick={() => handleClick("state", c.code)}
																		selected={selectedInfo.state === c.code}
																		key={c._id}
																		handleOpenForm={handleOpenForm}
																		handleStatus={(e) => handleStatus("state", c, e)}
																	/>
																))
															) : (
																<Div
																	id={`${parent_id}-State-Loading-and-No Records-List`}
																>
																	{geoStateRead_?.loading ? "Loading..." : "No Records Found"}
																</Div>
															)
														) : (
															<Div
																id={`${parent_id}-State-Select-Country-div`}
																icon={state_}>Select Country to show State</Div>
														)}
													</List>
												</div>
											</Card>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									id={`${parent_id}-parent-grid`}
									item md={7} xs={12}>
									<Grid
										id={`${parent_id}-parent-row`}
										spacing={1} container direction="row">
										<Grid
											id={`${parent_id}-District-grid`}
											item md={4} xs={12}>
											<Card
												id={`${parent_id}-District-card`}
											// style={{
											// 	borderRadius: "8px",
											// 	maxHeight: "65vh",
											// 	height: "65vh",
											// 	overflow: "scroll",
											// }}
											>
												<Typography
													id={`${parent_id}-District-title-typography`}
													className={classes.locationType}>
													District
												</Typography>
												<div
													id={`${parent_id}-District-SingleList-div`}
													style={{
														borderRadius: "8px",
														maxHeight: "58vh",
														height: "58vh",
														overflow: "scroll",
													}}>
													<List
														id={`${parent_id}-District-SingleList-List`}
														className={classes.root}>
														{selectedInfo.state ? (
															districtDate?.length > 0 ? (
																districtDate?.map((c, i) => (
																	<SingleList
																		parent_id={'SingleList' + i}
																		info={c}
																		handleClick={() => handleClick("district", c.code)}
																		selected={selectedInfo.district === c.code}
																		key={c._id}
																		handleOpenForm={handleOpenForm}
																		handleStatus={(e) => handleStatus("district", c, e)}
																	/>
																))
															) : (
																<Div
																	id={`${parent_id}-District-Loading-and-No Records-List`}
																>
																	{geoDistrictRead_?.loading ? "Loading..." : "No Records Found"}
																</Div>
															)
														) : (
															<Div
																id={`${parent_id}-District-Select-State-div`}
																icon={district}>Select State to show District</Div>
														)}
													</List>
												</div>
											</Card>
										</Grid>
										<Grid
											id={`${parent_id}-City-parent-grid`}
											item md={4} xs={12}>
											<Card
												id={`${parent_id}-City-parent-card`}
											// style={{
											// 	borderRadius: "8px",
											// 	maxHeight: "65vh",
											// 	height: "65vh",
											// 	overflow: "scroll",
											// }}
											>
												<Typography
													id={`${parent_id}-City-title-typography`}
													className={classes.locationType}>
													City
												</Typography>
												<div
													id={`${parent_id}-City-SingleList-div`}
													style={{
														borderRadius: "8px",
														maxHeight: "58vh",
														height: "58vh",
														overflow: "scroll",
													}}>
													<List
														id={`${parent_id}-City-SingleList-List`}
														className={classes.root}>
														{selectedInfo.district ? (
															cityData?.length > 0 ? (
																cityData?.map((c, i) => (
																	<SingleList
																		parent_id={'SingleList' + i}
																		info={c}
																		handleClick={() => handleClick("city", c.code)}
																		selected={selectedInfo.city === c.code}
																		key={c._id}
																		handleOpenForm={handleOpenForm}
																		handleStatus={(e) => handleStatus("city", c, e)}
																	/>
																))
															) : (
																<Div
																	id={`${parent_id}-city-Loading-and-No Records-List`}
																>
																	{geoCityRead_?.loading ? "Loading..." : "No Records Found"}
																</Div>
															)
														) : (
															<Div
																id={`${parent_id}-District-Select-District-div`}
																icon={city}>Select District to show City</Div>
														)}
													</List>
												</div>
											</Card>
										</Grid>
										<Grid
											id={`${parent_id}-Pin-Code-parent-grid`}
											item md={4} xs={12}>
											<Card
												id={`${parent_id}-Pin-Code-parent-card`}
											// style={{
											// 	borderRadius: "8px",
											// 	maxHeight: "65vh",
											// 	height: "65vh",
											// 	overflow: "scroll",
											// }}
											>
												<Typography
													id={`${parent_id}-Pin-Code-title-typography`}
													className={classes.locationType}>
													Pin Code
												</Typography>
												<div
													id={`${parent_id}-Pin-Code-SingleList-div`}
													style={{
														borderRadius: "8px",
														maxHeight: "58vh",
														height: "58vh",
														overflow: "scroll",
													}}>
													<List
														id={`${parent_id}-Pin-Code-SingleList-List`}
														className={classes.root}>
														{selectedInfo.city ? (
															pincodeData?.length > 0 ? (
																pincodeData?.map((c, i) => (
																	<SingleList
																		parent_id={'SingleList' + i}
																		info={c}
																		handleClick={() => handleClick("pincode", c.code)}
																		selected={selectedInfo.pincode === c.code}
																		key={c._id}
																		handleOpenForm={handleOpenForm}
																		handleStatus={(e) => handleStatus("pincode", c, e)}
																	/>
																))
															) : (
																<Div
																	id={`${parent_id}-pincode-Loading-and-No Records-List`}
																>
																	{geoPincodeRead_?.loading ? "Loading..." : "No Records Found"}
																</Div>
															)
														) : (
															<Div
																id={`${parent_id}-District-Select-City-div`}
																icon={state_}>Select City to show Pincode</Div>
														)}
													</List>
												</div>
											</Card>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</div>
				</Card>
			</div>
			{/* <GenerateForm
				nocancel
				open={open}
				handlecancel={handleCloseForm}
				header={"Add New Reason Master"}
				loading={false}
				json={[
					{
						componet_type: "select",
						options:
							geoGraphicSlice?.geoGraphicTypeRead?.data ??
							geoGraphicSlice?.geoGraphicTypeRead?.data,
						multi: false,
						label: "Type",
						state_name: "type",
						value: editData.type ?? null,
						required: true,
						disableClearable: true,
					},
					{
						componet_type: "text",
						label: "Code",
						state_name: "code",
						value: editData.code ?? "",
						required: true,
					},
					{
						componet_type: "text",
						label: "Name",
						state_name: "Name",
						value: editData.name ?? "",
						required: true,
					},
					{
						componet_type: "select",
						options: [],
						multi: false,
						label: "Parent Type",
						state_name: "parentType",
						value: editData.parentType ?? null,
						required: true,
						disableClearable: true,
					},
					{
						componet_type: "select",
						options: [],
						multi: false,
						label: "Parent Code",
						state_name: "parentCode",
						value: editData.parentCode ?? null,
						required: true,
						disableClearable: true,
					},
				]}
				save={saveFn}
			/> */}

			<Drawer
				id={`${parent_id}-parent-drawer`}
				className={classes_.drawer}
				anchor="right"
				open={state.open}
				classes={{
					paper: classes_.drawerPaper,
				}}
			>
				{
					<div
						id={`${parent_id}-parent-drawer-div`}
					>
						<Avatar
							id={`${parent_id}-close-Avatar`}
							src="/images/close.svg"
							className={classes_.large}
							onClick={() => handleDrawerClose()}
						/>
						<div
							id={`${parent_id}-sub-parent-div`}
						>
							<div
								id={`${parent_id}-title-div`}
								className={classes_.drawerHead}>
								<Typography
									id={`${parent_id}-title-typography`}
									className={classes_.headerMain}>
									{state.editIndex !== null ? "Edit Geographic Master" : 'Add Geographic Master'}
								</Typography>
							</div>

							<Grid
								id={`${parent_id}-button-grid`}
								container
								direction="row"
								style={{ padding: "10px 20px" }}
							>
								<Grid
									id={`${parent_id}-button-sub-grid`}
									item xs={12}>
									{state?.list?.length > 0 &&
										state?.list?.map((val) => {
											let opt = val.state_name === "type" ? storeData?.geoMasterMaster.data :
												val.state_name === "parentType" ? storeData?.geoMasterMaster?.data :
													val.state_name === "parentCode" ? storeData?.geoMasterParentCode?.data : [];
											if (condition(val)) {
												return generateComponent({
													data: {
														...val,
														options: opt,
														disabled: (state?._key_id && val?.state_name === "code") ? true : false,
													},
													handleChange: handleChange,
													state: state,
													classes: classes_
												});
											} else {
												return false;
											}
										})}
									<div
										id={`${parent_id}-button-sub-div`}
										className={classes_.btnPosition}>
										<Button
											id={`${parent_id}-Saving-and-Save-button`}
											disabled={storeData?.geoGraphicInsert?.loading ?? false}
											variant={"contained"}
											color={"primary"}
											style={{ marginTop: 20 }}
											size={"small"}
											contained
											onClick={() => saveGeographicMaster()}
										>
											{storeData?.geoGraphicInsert?.loading ? "Saving..." : "Save"}
										</Button>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				}
			</Drawer>

		</Card>
	);
};

export default withAllContexts(GeographicMasters);
