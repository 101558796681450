import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import EditIcon from "../../assets/icons - Edit.svg";
import DeleteIcon from "../../assets/icons8-trash.svg";

const useStyles = makeStyles(() => ({
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    indexWrap: {
        background: '#E0E0E0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '0px 8px 8px 0px',
        fontSize: 14,
        color: '#101010',
        fontFamily: 'pc_medium',
        padding: 8
    },
    title: {
        color: '#101010',
        fontSize: '14px',
        fontFamily: 'pc_medium',
    },
    listCaption: {
        color: '#6F6F6F',
        fontSize: 12,
        fontFamily: 'pc_medium'
    },
    root: props => ({
        backgroundColor: '#F9F9F9',
        border: '1px solid #E0E0E0',
        borderRadius: 8,
        padding: 15,
        paddingLeft: 0,
        marginBottom: props?.marginBottom
    })
}));

export const CustomListing = (props) => {
    const classes = useStyles(props);
    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}-parent-div`}
            className={classes.root}>
            <div
                id={`${parent_id}-parent-sub-div`}
                className={classes.flexCenter}>
                <div
                    id={`${parent_id}-parent-sublevel-div`}
                    className={classes.flexCenter} style={{ flex: 1 }}>
                    <div
                        id={`${parent_id}-parent-sublevel-div`}
                        className={classes.indexWrap}>{props?.index}</div>
                    <div style={{ flex: 1, marginLeft: 15 }}>
                        <Typography
                            id={`${parent_id}-${props?.title.replaceAll(" ", "-")}-title-typography`}
                            variant="body1" className={classes.title}>{props?.title}</Typography>
                        <div
                            id={`${parent_id}-list-div`}
                            className={classes.flexCenter}>
                            {
                                props?.list && props?.list?.map((_, i) => {
                                    return (
                                        <Typography
                                            id={`${parent_id}-${i}-title-typography`}
                                            variant="caption" className={classes.listCaption}>
                                            {_}
                                            {i < props?.list?.length - 1 && <span style={{ margin: '0px 5px' }}>•</span>}
                                        </Typography>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={classes.flexCenter}>
                    {
                        props?.edit &&
                        <img
                            src={EditIcon}
                            onClick={props.handleEdit}
                            width="12px"
                            style={{ cursor: "pointer" }}
                            alt='pic'
                        />
                    }
                    {
                        props?.delete &&
                        <img
                            src={DeleteIcon}
                            width="12px"
                            style={{ cursor: "pointer", marginLeft: 15 }}
                            onClick={props.handleDelete}
                            alt='pic'
                        />
                    }
                </div>
            </div>
        </div>
    )
}

CustomListing.defaultProps = {
    index: '',
    title: '',
    list: [],
    edit: true,
    delete: true,
    handleEdit: null,
    handleDelete: null,
    marginBottom: 0

}