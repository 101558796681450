import React from "react";
import { CommonTable, GenerateForm } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import moment from "moment";
import { withAllContexts } from "../../../HOCs";
import { Grid, Button } from "@material-ui/core";

function Speciality(props) {
	const dispatch = useDispatch();
	const organizationSlice = useSelector((state) => state.organizationSlice);
	const { parent_id } = props;
	const [open, setOpen] = React.useState(false);
	const [state, setState] = React.useState({
		openEditFrom: false,
		saveloading: false,
		edit_data: null,
		status: true,
	});

	const handleCloseForm = () => {
		setState({
			openEditFrom: false,
			saveloading: false,
			edit_data: null,
		});
		setOpen(true);
		setOpen(false);
	};
	React.useLayoutEffect(() => {
		const getdata = async () => {
			await dispatch(actions.ORGANIZATION_GET_SPECIALTY_DROP());
		};
		getdata();
	}, []);
	const handleEdit = (e, data, i) => {
		let getdata = {
			code: data?.code,
			specialty: data?.dropdown,
			effFrom: data?.effFrom?moment(data?.effFrom, "DD-MM-YYYY"):"",
			effTo: data?.effTo?moment(data?.effTo, "DD-MM-YYYY"):"",
			index: i,
		};
		setState({
			openEditFrom: true,
			edit_data: getdata,
			status: data?.status,
		});
		setOpen(true);
	};

	const saveForm = async (values) => {
		let val = {};
		// table data
		val.effFrom = moment(values?.effFrom).format("DD-MM-YYYY");
		val.effTo = moment(values?.effTo).format("DD-MM-YYYY");
		val.code = values?.specialty?.value;
		val.specialty = values?.specialty?.name;

		// // form data
		val.fromDate = values?.effFrom;
		val.toDate = values?.effTo;
		val.dropdown = values?.specialty;
		
		var A = state?.edit_data;
		props?.onChangeAll(
			values,
			val,
			"specialtyDetails",
			state?.edit_data?.index + 1
		);
		handleCloseForm();
	};

	const handleClick = () => {
		setOpen(true);
	};
	return (
		<React.Fragment>
			<div
				id={`${parent_id}-parent-div`}
				style={{ padding: 20 }}>
				<Grid
					id={`${parent_id}-parent-grid`}
					container>
					<Grid
						id={`${parent_id}-Speciality-grid`}
						item xs={8} style={{ textAlign: "left" }}>
						<p
							id={`${parent_id}-Speciality-title-p`}
							style={{ fontFamily: "poppinsemibold", marginTop: "0px" }}>
							Applicable Speciality{" "}
						</p>
					</Grid>
					<Grid
						id={`${parent_id}-Add-New-grid`}
						item xs={4}>
						<Grid
							id={`${parent_id}-Add-New-sub-grid`}
							container
							direction="row"
							justify="flex-end"
							alignItems="center"
						>
							<Button
								id={`${parent_id}-Add-New-button`}
								// className={classes.newRes}
								color="primary"
								onClick={handleClick}
							>
								+ Add New
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<CommonTable
					parent_id={'CommonTable'}
					rightAlign={["Action"]}
					handleEdit={handleEdit}
					Header={[
						"S.No",
						"Specialty Code",
						"Specialty Name",
						"Effective From",
						"Effective To",
						"Action",
					]}
					dataList={props?.specialtyDetails}
					tableData={[
						{ type: ["INCRIMENT"], name: "" },
						{ type: ["TEXT"], name: "code" },
						{ type: ["TEXT"], name: "specialty" },
						{ type: ["TEXT"], name: "effFrom" },
						{ type: ["TEXT"], name: "effTo" },
						{ type: ["EDIT"], name: "", align: "right" },
					]}
				/>
				<GenerateForm
					parent_id={'GenerateForm'}
					nocancel
					// open={(open && openIndex === 5) }
					open={open}
					handlecancel={() => handleCloseForm()}
					save={saveForm}
					header={state?.edit_data ? "Edit Applicable Speciality" : "Add New Applicable Speciality"}
					json={[
						{
							componet_type: "select",
							label: "Specialty",
							state_name: "specialty",
							value: state?.edit_data?.specialty ?? "",
							required: true,
							options: organizationSlice?.specialtyDrop?.data
								? organizationSlice?.specialtyDrop?.data
								: [],
						},
						{
							componet_type: "date",
							label: "Effective From",
							state_name: "effFrom",
							value: state?.edit_data?.effFrom ?? "",
							required: true,
							dateFormat: "dd/MM/yyyy",
						},
						{
							componet_type: "date",
							label: "Effective To",
							state_name: "effTo",
							value: state?.edit_data?.effTo ?? "",
							required: true,
							dateFormat: "dd/MM/yyyy",
							minDateState: new Date(),
						},
					]}
				/>
			</div>
		</React.Fragment>
	);
}

export default withAllContexts(Speciality);
