import React, { useState } from "react";
import { Typography, Grid, TextField, Button } from "@material-ui/core";
import { withAllContexts } from "../../../HOCs";
import styles from "./styles";
import Config from "../../../config";
import { v4 as uuidv4 } from "uuid";
import { upsertDocument } from "../../../function/commonapi";

function AddNewReports(props) {
  const classes = styles();

  const [reportData, setreportData] = useState({
    reportName: props?.reportsParams?.elementname
      ? props?.reportsParams?.elementname
      : "",
  });
  const [error, setError] = useState({
    reportName: false,
    msg: "Please enter the field",
  });

  const handlereportData = (event) => {
    setreportData({ ...reportData, [event.target.name]: event.target.value });
  };

  const duplicate = props.incomingReportsData.filter(
    (itm) => itm.Repository.elementname === reportData.reportName
  );

  const validation = () => {
    let isValidate = false;

    if (reportData?.reportName?.trim()?.length > 0) {
      error.reportName = false;
    } else {
      isValidate = true;
      error.reportName = true;
    }
    if (duplicate.length > 0) {
      isValidate = true;
      error.reportName = true;
      error.msg = "Report already exists";
    } else {
      error.reportName = false;
    }

    setError({
      ...error,
    });
    return isValidate;
  };
  const editreportsSubmit = async () => {
    const { drawer } = props;
    let check = validation();
    if (check) {
      return;
    }
    let list = {
      elementname: reportData?.reportName,
    };
    let sendReportsData = {
      entity: Config.repositoryEntity,
      metadataId: Config.metadataid,
      isedit: true,
      id: props?.reportsParams?.rep_id,
      list,
      keyvalue: "rep_id",
    };

    let addEditReportsUpsert = await upsertDocument(sendReportsData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          props.getRepositoryData();

          props.handleAlerts("Report edited successfully!", true);
          drawer.setDrawer({
            ...drawer,
            open: false,
            component: <></>,
          });
        } else {
          props.handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        props.handleAlerts("Something  went wrong", false);
      });
  };

  const submitreports = async () => {
    let submittedreports = {
      reportName: reportData.reportName,
      //   route: formData.route,
    };
    const { drawer } = props;
    let check = validation();
    if (check) {
      return;
    }
    let list = {
      rep_id: uuidv4(),
      rep_type: "Reports",
      elementname: submittedreports.reportName,
      is_active: true,
    };

    let sendReportsData = {
      entity: Config.repositoryEntity,
      metadataId: Config.metadataid,
      list,
    };

    let addReportsUpsert = await upsertDocument(sendReportsData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          props.getRepositoryData();

          props.handleAlerts("Report created successfully!", true);
          drawer.setDrawer({
            ...drawer,
            open: false,
            component: <></>,
          });
        } else {
          props.handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        props.handleAlerts("Something  went wrong", false);
      });
  };

  return (
    <div>
      <div className={classes.drawerHead}>
        <Typography className={classes.headerMain}>
          {props.editName ? "Edit reports" : "Add new report"}
        </Typography>
      </div>

      <Grid container direction="row">
        <Grid item xs={12}>
          <div className={classes.textTotalArea}>
            <Typography className={classes.drawerSubHead}>
              Report Name
            </Typography>
            <TextField
              label={"Report"}
              variant="outlined"
              fullWidth
              size="small"
              name="reportName"
              onChange={handlereportData}
              value={reportData.reportName}
              required
              inputProps={{ style: { fontFamily: "poppin", fontSize: "12px" } }}
              InputLabelProps={{
                style: { fontFamily: "poppin", fontSize: "12px" },
              }}
              error={error?.reportName ? true : false}
              helperText={error?.reportName ? error.msg : ""}
            />
          </div>
          <div className={classes.btnPosition}>
            <Button
              contained
              className={classes.addDrawerBtn}
              onClick={props.editName ? editreportsSubmit : submitreports}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withAllContexts(AddNewReports);
