import React from "react";
import { withHoverDrawer } from "../../HOCs";
import OrganizationConfigTabPanel from "./organizationconfigtab";
class OrganizationConfig extends React.Component {
  render() {
    return (
      <div
        id={'OrganizationConfigTabPanel-parent-div'}
      >
        <OrganizationConfigTabPanel
          id={'OrganizationConfigTabPanel'}
        />
      </div>
    );
  }
}

export default withHoverDrawer(OrganizationConfig);
