import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { CommonTable, OverlayCompt } from "../../../../../components";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FlexAdd from "./flexAdd";
import searchicon from "../../../../../assets/icons - Search.svg";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
// import { validate } from "uuid";
import { withAllContexts } from "../../../../../HOCs";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: "white",
    padding: 12,
    border: "1px solid #ddd",
    borderRadius: 12,
  },
  titleText: {
    fontSize: 16,
    color: "#101010",
    margin: 0,
    fontFamily: "pc_semibold",
  },
  inputText: {
    fontSize: 14,
    margin: "8px 0px 12px 0px",
    "& input": {
      padding: "10px 12px",
    },
    backgroundColor: "#00000008",
    border: "1px solid #1010100D",
  },
  addBtnGrid: {
    width: "18%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "4px",
  },
  TitleDiv: {
    display: "flex",
    alignItems: "center",
  },
  DivBtn: {
    display: "flex",
  },
  DivBtn1: {
    aliginItem: "center",
    justifyContent: "end",
  },
  DivBtn1Input: {
    width: "58%",
  },
  btnAdd: {
    padding: "5px 12px",
  },
  btnSearch: {
    fontSize: 21,
  },
  btInputDive: {
    justifyContent: "end",
  },
}));

const FlexAttribute = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const flexAttributeList = useSelector(
    (state) => state?.flexAttributeSlice?.flexFull_Read?.data
  );
  const [addNewOpen, setAddNewOpen] = useState(false);
  const handleAddNew = () => {
    setState({
      ...state,
      attributeCode: "",
      attributeLable: "",
      attributeType: "",
      forText: [
        {
          seqNo: 1,
          validCode: "",
          displayValidCode: "",
          active: true,
        },
      ],
      attributeSize: "",
      numberUOM: "",
      numberMax: "",
      numberMin: "",
      boolean: [
        {
          seqNo: 1,
          validCode: "Y",
          displayValidCode: "Yes",
          active: true,
        },
        {
          seqNo: 2,
          validCode: "N",
          displayValidCode: "No",
          active: false,
        },
      ],
    });
    setEditKey(null);
    setAddNewOpen(true);
  };
  const handleCloseAddNew = () => {
    setAddNewOpen(false);
  };
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(
      actions.FLEX_ATTRIBUTE_READ({
        page: (currentPage - 1) * PerPage,
        perpage: PerPage,
        search: searchData,
      })
    );
  };

  const getFlexMasterData = async () => {
    await dispatch(
      actions.FLEX_ATTRIBUTE_READ({
        page: page,
        perpage: perPage,
        search: searchData,
      })
    );
  };

  useEffect(() => {
    getFlexMasterData();
  }, []);

  const [state, setState] = useState({
    attributeCode: "",
    attributeLable: "",
    attributeType: "",
    forText: [
      {
        seqNo: 1,
        validCode: "",
        displayValidCode: "",
        active: true,
      },
    ],
    attributeSize: "",
    numberUOM: "",
    numberMax: "",
    numberMin: "",
    boolean: [
      {
        seqNo: 1,
        validCode: "Y",
        displayValidCode: "Yes",
        active: true,
      },
      {
        seqNo: 2,
        validCode: "N",
        displayValidCode: "No",
        active: false,
      },
    ],
  });
  const handleFormState = (name, value) => {
    if (name === "attributeType") {
      setState({
        ...state,
        [name]: value,
        forText: [
          {
            seqNo: 1,
            validCode: "",
            displayValidCode: "",
            active: true,
          },
        ],
        attributeSize: "",
        numberUOM: "",
        numberMax: "",
        numberMin: "",
        boolean: [
          {
            seqNo: 1,
            validCode: "Y",
            displayValidCode: "Yes",
            active: true,
          },
          {
            seqNo: 2,
            validCode: "N",
            displayValidCode: "No",
            active: false,
          },
        ],
      });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  const getSeq = () => {
    let seq = 0;
    state.forText.forEach((itm) => {
      if (itm.seqNo > seq) {
        seq = itm.seqNo;
      }
    });
    return seq + 1;
  };

  const handleValidAdd = () => {
    const addData = {
      seqNo: getSeq(),
      validCode: "",
      displayValidCode: "",
      active: false,
    };
    state.forText.push(addData);
    setState({ ...state });
  };

  const handleValidDelete = (i) => {
    let deleteState = state;
    deleteState.forText.splice(i, 1);
    setState({ ...deleteState });
  };

  const handleForType = (name, value, index) => {
    if (name === "active") {
      state.forText.forEach((item, i) => {
        if (i === index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    } else {
      state.forText[index][name] = value;
    }
    setState({ ...state });
  };

  const handleForBoolean = (name, value, index) => {
    state.boolean.forEach((item, i) => {
      if (i === index) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setState({ ...state });
  };

  const [error, setError] = useState({});
  const validate = () => {
    let err = {};
    if (!state?.attributeCode) {
      err.attributeCode = true;
    }

    if (!state?.attributeType) {
      err.attributeType = true;
    }

    if (!state?.attributeLable) {
      err.attributeLable = true;
    }

    if (state?.attributeLable && state?.attributeCode && state?.attributeType) {
      if (state?.attributeType?.label === "Numeric") {
        if (!state?.attributeSize) {
          err.attributeSize = true;
        }
        if (state?.numberMax && state?.numberMin) {
          if (state?.numberMin > state?.numberMax) {
            err.numberMin = true;
          }
          if (!state?.numberUOM) {
            err.numberUOM = true;
          }
        }
      }
      if (state?.attributeType?.label === "Text Box") {
        if (!state?.attributeSize) {
          err.attributeSize = true;
        }
      }
    }
    setError(err);
    return Object.keys(err).length > 0;
  };
  const saveForm = async () => {
    let isErr = validate();
    if (isErr) {
      if (error?.numberMin) {
        props.alert.setSnack(
          "error",
          "Minimum number is greater than maximum number"
        );
      } else {
        props.alert.setSnack("mandatory");
      }
    } else {
      const saved = await dispatch(
        actions.FLEX_ATTRIBUTE_INSERT({ list: state, key: editKey })
      );
      if (saved?.payload?.error === false) {
        if (
          saved?.payload?.data?.validation_error?.[0]?.Errormsg ===
          '["attribcode"] fields must be unique'
        ) {
          props.alert.setSnack("unique", "Attribute Code must be unique");
        } else {
          props.alert.setSnack(editKey ? "update" : "success");
          getFlexMasterData();
          handleCloseAddNew();
        }
      }
      // if (saved?.payload?.error === true) {
      //   props.alert.setSnack("error");
      // }
    }
  };

  const [editKey, setEditKey] = useState(null);
  const handleEdit = async (e, val, i) => {
    const singleRead = await dispatch(
      actions.FLEX_ATTRIBUTE_SINGLE_READ({ key: val?._key })
    );
    let editState = singleRead?.payload?.data[0];
    setState({
      ...state,
      attributeCode: editState.attribcode ?? "",
      attributeLable: editState.attriblabel ?? "",
      attributeType: {
        label: editState?.attribtype?.display ?? "",
        value: editState?.attribtype?._id ?? "",
      },
      forText: editState?.AttribValidValues?.map((val) => {
        return {
          seqNo: val?.dispSeq,
          validCode: val?.validCode,
          displayValidCode: val?.validdisplayValue,
          active: val?.Dfltflag,
        };
      }),
      attributeSize:
        editState?.attributeType?.label === "Numeric" || "Text Box"
          ? editState?.attribsize
          : "",
      numberUOM: {
        label: editState?.attribnumUOM?.shortdesc ?? "",
        value: editState?.attribnumUOM?._id ?? "",
      },
      numberMax: editState?.attribnumMax ?? "",
      numberMin: editState?.attribnumMin ?? "",
      boolean: [
        {
          seqNo: 1,
          validCode: "Y",
          displayValidCode: "Yes",
          active: val?.AttribValidValues?.[0]?.Dfltflag ?? true,
        },
        {
          seqNo: 2,
          validCode: "N",
          displayValidCode: "No",
          active: val?.AttribValidValues?.[1]?.Dfltflag ?? false,
        },
      ],
    });
    setEditKey(val?._key);
    setAddNewOpen(true);
  };

  const statusChange = async (e, val, i) => {
    await dispatch(
      actions.FLEX_ATTRIBUTE_STATUS({ list: e.target.checked, key: val?._key })
    );
    props.alert.setSnack("update");
    getFlexMasterData();
  };

  const [searchData, setSearch] = useState("");

  const [code, setCode] = useState(true)
  const [label, setLabel] = useState(false)
  const [type, setType] = useState(false)
  const [size, setSize] = useState(false)

  const searchChange = async (event) => {
    const searchFlex = await dispatch(
      actions.FLEX_ATTRIBUTE_READ({
        page: page,
        perpage: perPage,
        search: event.target.value,
      })
    );
    setSearch(event.target.value);
  };

  const handleSort = async (val, key, direction) => {

    let sort_val = ''
    if(val === 'Attribute Code'){
      sort_val = code
      setCode(!code)
    } else if (val === 'Attribute Label'){
      sort_val = label
      setLabel(!label)
    } else if (val === 'Attribute Type'){
      sort_val = type
      setType(!type)
    } else {
      sort_val = size
      setSize(!size)
    }

    await dispatch(
      actions.FLEX_ATTRIBUTE_READ({
        page: page,
        perpage: perPage,
        sort: val,
        direction: sort_val
      })
    );
  }

  return (
    <div id={"flex-master-parent-div"} className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={6} className={classes.TitleDiv}>
          <Typography className={classes.titleText}>Flex Attribute</Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container className={classes.btInputDive}>
            <Grid className={classes.DivBtn1Input}>
              <div>
                <TextField
                  id="outlined-search"
                  placeholder="Search Code & Description"
                  size="small"
                  onChange={searchChange}
                  style={{
                    padding: "8px 20px",
                  }}
                  type="search"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: "poppin",
                      fontSize: "12px",
                      background: "#f6f6f6",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={searchicon} alt="Icon" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { fontSize: 12 },
                  }}
                />
              </div>
            </Grid>
            <Grid className={classes.addBtnGrid}>
              <Button
                variant="contained"
                color="primary"
                className={classes.btnAdd}
                onClick={handleAddNew}
              >
                + Add New
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* {JSON.stringify(tableDataRead[0]?.TotalCount)} */}
      <CommonTable
        parent_id={"drugMaster"}
        style={{ height: "calc(100vh - 257px)" }}
        handleEdit={handleEdit}
        handleCheckBox={statusChange}
        Header={[
          "S.No",
          {label:"Attribute Code", showSort: true, sortValue: code, onclick: (val) => handleSort(val, 'code', code)},
          {label:"Attribute Label", showSort: true, sortValue: label, onclick: (val) => handleSort(val, 'label', label)},
          {label:"Attribute Type", showSort: true, sortValue: type, onclick: (val) => handleSort(val, 'type', type)},
          {label:"Attribute Size", showSort: true, sortValue: size, onclick: (val) => handleSort(val, 'size', size)},
          "Status",
          "Action",
        ]}
        dataList={flexAttributeList?.result}
        tableData={[
          { type: ["INCRIMENT"], name: "" },
          { type: ["TEXT"], name: "attribcode" },
          { type: ["TEXT"], name: "attriblabel" },
          { type: ["TEXT"], name: "attribtype" },
          { type: ["TEXT"], name: "attribsize" },
          { type: ["CHECKBOX"], name: "status", align: "center" },
          { type: ["EDIT"], name: "", align: "center" },
        ]}
        // condition={condition}
        handlepageChange={(currentPage, PerPage) =>
          handlepageChange(currentPage, PerPage)
        }
        TableCount={flexAttributeList?.result?.[0]?.TotalCount}
        incrementCount={page}
        showPagination={true}
        rowsPerPageOptions={[
          { label: "10 Rows", value: 10 },
          { label: "50 Rows", value: 50 },
          { label: "100 Rows", value: 100 },
        ]}
      />
      <FlexAdd
        open={addNewOpen}
        handleCloseAddNew={handleCloseAddNew}
        handleFormState={handleFormState}
        state={state}
        handleValidAdd={handleValidAdd}
        handleValidDelete={handleValidDelete}
        handleForType={handleForType}
        saveForm={saveForm}
        handleForBoolean={handleForBoolean}
        editKey={editKey}
      />
    </div>
  );
};

export default withAllContexts(FlexAttribute);
