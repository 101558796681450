import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { PendingListUi } from './pendingList';
import { PatientListUi } from './patientList';

const useStyles = makeStyles(() => ({
    root: {
        border: '1px solid #DCDCDC',
        borderRadius: 10,
        backgroundColor: '#FFFFFF',
        height: "calc(100vh - 136px)",
        overflow: 'auto',
        padding: 25

    }
}));

export const PendingResheduleList = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const { parent_id } = props;

    const [state, setState] = React.useState({
        viewPatientList: false,
        selectedDoctor: {},
        pendinglist: [],
    })

    React.useEffect(() => {
        const getdata = async () => {
            await dispatch(actions.GET_PENDING_RESCHEDULE_LIST());
        };
        getdata();
    }, []);

    const pendingRescheduleList = useSelector(
        (state) => state.pendingRescheduleListSlice
    );

    const handleState = (viewPatientList = false, selectedDoctor, pendinglist) => {
        setState({
            ...state,
            viewPatientList,
            selectedDoctor,
            pendinglist
        })
    }

    const viewClick = async (data) => {
        let pendinglist = await dispatch(actions.VIEW_PENDING_PATIENT_LIST({ id: data?.id }));
        let formatData = pendinglist?.payload?.data?.map(v => ({ select: false, ...v }))
        handleState(true, data, formatData)
    }

    const navigateBack = async () => {
        await dispatch(actions.GET_PENDING_RESCHEDULE_LIST());
        handleState(false, {}, [])
    }

    return (
        <div
            id={`${parent_id}-parent-dv`}
            className={classes.root}>
            {
                state?.viewPatientList ? (
                    <PatientListUi
                        parent_id={'PatientListUi'}
                        header={state?.selectedDoctor?.['resource name']}
                        navigateBack={navigateBack}
                        data={state?.pendinglist}
                        doctorDetails={state?.selectedDoctor}
                        viewClick={viewClick}
                    />
                ) : (
                    <PendingListUi
                        parent_id={'PendingListUi'}
                        viewClick={viewClick}
                        navigateBack={navigateBack}
                        data={pendingRescheduleList?.pending_reschedule_list?.data}
                    />
                )
            }
        </div>
    )
}