import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Admin from "./admin";
import Patient from "./patient";
import Others from "./others";
import Appointment from "./appointment";
import { CommonTabList } from "../../components/common";
import "../../components/font.css";
import { checkWithCasbin } from "../../utils"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,

    "& .MuiTab-textColorPrimary": {
      "&.Mui-selected": {
        color: "#0071F2",
      },
    },
    "&.MuiPaper-elevation4": {
      shadow: "none",
    },
    "& .MuiTab-root": {
      [theme.breakpoints.up("xs")]: {
        minWidth: "inherit !important",
      },
    },
    "& .MuiAppBar-root": {
      width: "80%",
    },
  },
  MuiTab: {
    root: {
      minWidth: 0,
      "@media (min-width: 0px)": {
        minWidth: 0,
      },
    },
  },
  box: {
    padding: "24px",
    paddingBottom: "43px",
    backgroundColor: "#F6F6F6",
    // height: "100vh",
    height: "100%",
    paddingTop: "14px",
  },
}));
function ParameterSetupTabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.box}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

ParameterSetupTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();

  const [listData, setListData] = React.useState([])

  const json = {
    admin: {
      name: "Admin",
      component: <Admin
        parent_id={'Admin'}
        type={"ADMIN"} header={"Admin"} />
    },
    patient: {
      name: "Patient",
      component: <Patient
        parent_id={'Patient'}
        type={"PATIENT"} header={"Patient"} />
    },
    appointment: {
      name: "Appointment",
      component: <Appointment
        parent_id={'Appointment'}
        type={"APPOINTMENT"} header={"Appointment"} />
    },
    others: {
      name: "Others",
      component: <Others
        parent_id={'Others'}
        type={"OTHERS"} header={"Others"} />
    }
  }
  React.useEffect(() => {
    const baseFun = async () => {
      let permission = await checkWithCasbin(["parameterSetup"]);
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      let arr = [];
      var unique = [...permission.read, ...permission.write].filter(onlyUnique);
      unique.map(val => {
        arr.push(json[val])
      })
      setListData(arr)
    }
    baseFun()
  }, [])

  return (
    <Grid
      id={'Organization-Config-parent-grid'}
      container spacing={0}>
      <Grid
        id={'Organization-Config-parent-sub-grid'}
        item md={12}>
        <div
          id={'Organization-Config-parent-div'}
          className={classes.root}>
          <CommonTabList
            parent_id={'CommonTabList'}
            title="Parameter Setup"
            list={listData}
          />
        </div>
      </Grid>
    </Grid>
  );
}
