import React from "react";

export const EditIcon = (props) => {
//   let width = props.width ? props.width : "40.8";
//   let height = props.height ? props.height : "40.8";
  let color = props.color ? props.color : "#252525";
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <path id="icons_-_Edit" data-name="icons - Edit" class="cls-1" d="M18.057,5.01a2.938,2.938,0,0,0-2.084.86L6.65,15.191a1.474,1.474,0,0,0-.375.638L5.025,20.2a.632.632,0,0,0,.781.781l4.376-1.25h0a1.477,1.477,0,0,0,.637-.374l9.322-9.323A2.944,2.944,0,0,0,18.057,5.01Zm0,1.256a1.677,1.677,0,0,1,1.19.5h0a1.675,1.675,0,0,1,0,2.382l-.817.817L16.049,7.58l.817-.817A1.679,1.679,0,0,1,18.057,6.266Zm-2.9,2.207,2.382,2.382L9.926,18.468a.213.213,0,0,1-.091.053l-3.282.938.938-3.283h0a.206.206,0,0,1,.053-.09Z" transform="translate(-5.001 -5.01) " fill={color}/>
</svg>
  );
};