import React, { useState,useEffect } from "react";
import { Div } from "qdm-component-library";
import TopNavbar from "../navbar/topNavBar";
import { Button, CircularProgress } from "@material-ui/core";
import PersonIdentification from "./personIdentification";
import PersonNames from "./personNames";
import PersonCotactPoint from './contactPoint';
import PersonAddress from "./address";
import { routes } from "../../../router/routes";
import Config from "../../../config";
import { withAllContexts } from "../../../HOCs";
import { withRouter } from "react-router-dom";
import { withHoverDrawer } from "../../../HOCs";

import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { AlertProps, ValidateEmail } from "../../../utils";
import moment from "moment";
import {  addUserToKeyClock } from '../../../function/commonapi';
import './style.css';
// moment().unix(),
// val.expiry_date ? moment(val.expiry_date).unix() : null,
function AddNewPerson(props){

   
 const [state,setState ] = useState({
  activeId: 1
 });
//  const classes = styles();
  const dispatch = useDispatch();
  const personMasterList = useSelector((state) => state?.personSlice);
  const allMasterList = useSelector((state) => state?.practitionerMasterSlice?.practitioner_masters?.data);

  // const id_type_master = useSelector((state) => state?.personSlice?.id_type_master?.data?.map(_=>{
  //   _.label = _.display;
  //   _.value = _._id;
  //   return _;
  // }));


  React.useLayoutEffect(() => {
    // dispatch(actions.ROLE_READ());
    dispatch(actions.CONTACTSYSTEMMASTER())
    // dispatch(actions.ENTITYNAMEMASTER())

    dispatch(actions.ENTITYTYPEMASTER())

    dispatch(actions.GENDERMASTER())
    dispatch(actions.IDTYPEMASTER())
    dispatch(actions.PREFIXMASTER())
    dispatch(actions.PRIORITYMASTER())
    dispatch(actions.SURFFIXMASTER())
    dispatch(actions.USEMASTER())
    dispatch(actions.PRACTITIONER_MASTERS())
  }, []);
   useEffect(() =>{
    //loading true
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Fetching Persons details...",
    });
    //loading true
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });

    if(props?.location.state?.id){
      handleEditPersonInfo();
    }
    
  },[])

 const backHandle = async () => {
    props.history.push({
      pathname: routes.repository,
      state: { index: 3 }
    });
  };

  //person  identification
  const [identifierStruct,] = useState(
    {
      "id_type":"",
      "id_number":"",
      "expiry_date":null,
      "upload_document":[],
      error:{
        "id_type":false,
        "id_number":false,
        "expiry_date":false,
      },
      errorMsg:{
        "id_type":"Please select field",
        "id_number":"Please enter the field",
        "expiry_date":"Please select date",
      }
    }
  );
  const [personIdentification,setPersonIdentification] = useState({
    practiesnerList:[
      JSON.parse(JSON.stringify(identifierStruct))
    ]
  })
  console.log("personIdentification",personIdentification);

  //add personIdentifier

  const addPersonIdentifier = () =>{
    let  identifierStruct_ = JSON.parse(JSON.stringify(identifierStruct));
    let practiesnerList = [...personIdentification.practiesnerList,identifierStruct_];
    setPersonIdentification({
      ...personIdentification,
      practiesnerList:practiesnerList
    });

  }

  //handle person state

    const handlePersonIdentifier = (name,value,index)  =>{
      
      let practiesnerList = personIdentification?.practiesnerList;
      practiesnerList = practiesnerList?.map((_,_index)=>{
        if(index===_index){
          let keyofIdentity = Object.keys(identifierStruct.error);
          if(validation(name,value,keyofIdentity)){
            _.error[name] = true;
          }
          else{
            _.error[name] = false;

          }
          _[name] = value;
        }
        return _;
      })
      setPersonIdentification({
        practiesnerList
      });
    }
    const handleIdentifierDelete = (index)=>{
      let practiesnerList = personIdentification?.practiesnerList;
       practiesnerList = practiesnerList?.filter((_,_index)=>index!==_index);
      setPersonIdentification({
        practiesnerList
      });
    }

    //person details

    const [detailStruct,] = useState(
      {
        "use":"",
        "prefix":"",
        "first_name":"",
        "middle_name":"",
        "last_name":"",
        "suffix":"",

        error:{
          "use":false,
          "prefix":false,
          "first_name":false,
          "middle_name":false,
          "last_name":false,
          "suffix":false,
        },
        errorMsg:{
          "use":"Please select field",
          "prefix":"Please select field",
          "first_name":"Please enter the field",
          "middle_name":"Please enter the field",
          "last_name":"Please enter the field",
          "suffix":"Please select field",
        }
      }
    );
    const [personDetails,setPersonDetails] = useState({
      personDetailList:[
        JSON.parse(JSON.stringify(detailStruct))
      ],
      gender:"",
      date_of_birth:null,
      managing_entity_type:"",
      managing_entity_name:"",
      person_id:"",
      // email_id:"",
      upload_image:[],
      error:{
        "gender":false,
        "date_of_birth":false,
        "managing_entity_type":false,
        "managing_entity_name":false,
        // "email_id":false,
        // "person_id":true,
      },
      errorMsg:{
        "gender":"Please select field",
        "date_of_birth":"Please select field",
        "managing_entity_name":"Please enter the field",
        "person_id":"Please enter the field",
        "managing_entity_type":"Please select field",
      }
    })
  
    //add personIdentifier
  
    const addPersonDetails = () =>{
      let  detailStruct_ = JSON.parse(JSON.stringify(detailStruct));
      let personDetailList = [...personDetails.personDetailList,detailStruct_];
      setPersonDetails({
        ...personDetails,
        ...personDetails,
        personDetailList:personDetailList
      });
  
    }
  
    //handle person state
  
      const handlePersonDetailsLoop = (name,value,index,limit)  =>{
        if (limit) {
          if (limit < value.length) {
            return false;
          }
        }
        let personDetailList = personDetails?.personDetailList;
        personDetailList = personDetailList?.map((_,_index)=>{
          if(index===_index){
            let keyofIdentity = Object.keys(detailStruct.error);
            if(validation(name,value,keyofIdentity)){
              _.error[name] = true;
            }
            else{
              _.error[name] = false;
  
            }
            _[name] = value;
          }
          return _;
        })
        setPersonDetails({
          ...personDetails,
          personDetailList
        });
      }
      const handlePersonDetails = (name,value)  =>{
  
          // alert( value)
            if(name === "managing_entity_type"){
              const showfilter = (list, comp_value) => {
                if (list?.length > 0) {
                  return list.filter(_ => _._id === comp_value)[0]
                }
                return {};
              }
              let val = showfilter(personMasterList?.entity_type_master?.data ?? [], value)
              
              dispatch(actions.ENTITYNAMEMASTER(val?.display?.toLocaleLowerCase()))
            }
       
            let error = personDetails.error;
            let keyofIdentity = Object.keys(personDetails.error);
            if(validation(name,value,keyofIdentity)){
              error[name] = true;
            }
            else{
              error[name] = false;
  
            }
      
        setPersonDetails({
          ...personDetails,
          [name]:value,
          error:error
        });
      }
      const handleDetailsDelete = (index)=>{
        let personDetailList = personDetails?.personDetailList;
         personDetailList = personDetailList?.filter((_,_index)=>index!==_index);
        setPersonDetails({
          ...personDetails,
          personDetailList
        });
      }

//contact person integration

const [contactStruct,] = useState(
  {
    "contact_mode":"",
    "email":"",
    "use":"",
    "priority":"",
    country_code:''
  
  }
);
const [PersonContact,setPersonContact] = useState({
  personContactList:[
    {
      "contact_mode":'Email',
      "email":"",
      "use":"",
      "priority":"",
      country_code:''
   
    },
    {
      "contact_mode":'Phone',
      "email":"",
      "use":"",
      "priority":"",
      country_code:''
    }
  ]
})

//add personIdentifier
const [, forceUpdate] = React.useReducer(x => x + 1, 0);

const addPersonContact = () =>{
  
  let  contactStruct_ = JSON.parse(JSON.stringify(contactStruct));
  let personContactList = [...PersonContact.personContactList,contactStruct_];
  setPersonContact({
    ...PersonContact,
    personContactList
  });
  forceUpdate();

}

//handle person state

  const handlePersonContact = (name,value,index,limit)  =>{
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    let personContactList = PersonContact?.personContactList;
    personContactList = personContactList?.map((_,_index)=>{
      if(index===_index){
        _[name] = value;
      }
      return _;
    })
    setPersonContact({
      personContactList
    });
  }
  const handlePersonDelete = (index)=>{
    let personContactList = PersonContact?.personContactList;
     personContactList = personContactList?.filter((_,_index)=>index!==_index);
    setPersonContact({
      personContactList
    });
  }
//person  identification
// const [addressStruct,] = useState(
//   {
//     "use":"",
//     "type":"",
//     "line1":"",
//     "line2":"",
//     "city":"",
//     "district":"",
//     "state":"",
//     "country":"",
//     "pincode":"",
//     error:{
//       "use":false,
//     "type":false,
//     "line1":false,
//     "city":false,
//     "district":false,
//     "state":false,
//     "country":false,
//     "pincode":false,
//     },
//     errorMsg:{
//       "id_type":"Please select field",
//       "use":"Please select field",
//       "type":"Please select field",
//       "line1":"Please enter the field",
//       "city":"Please select field",
//       "district":"Please select field",
//       "state":"Please select field",
//       "country":"Please select field",
//       "pincode":"Please select field",
//     }
//   }
// );
//.................................
const [addressList,setAddress] = useState([]);
  //.............................................................................

  const  submitValidation  = ()=>{
    //identityfier validation
    let is_error =  false;
    if(personIdentification?.practiesnerList?.length>0){
      for(let i=0;i<personIdentification?.practiesnerList?.length;i++){
        let _data_ = personIdentification?.practiesnerList[i];
        if(_data_?.id_number<=0 || _data_?.id_type<=0 || _data_?.expiry_date<=0 ){
           is_error =  true;
           break
        } 
      }
    } else {
      is_error =  true;
    }
    if(is_error){
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill identifier mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      })
      return true;
    }

    //persondetails validation 

    if(personDetails?.personDetailList?.length>0){
      for(let i=0;i<personDetails?.personDetailList?.length;i++){
        let _data_ = personDetails?.personDetailList[i];
        if(_data_?.use<=0 || _data_?.first_name<=0 || _data_?.last_name<=0 || _data_?.middle_name<=0 || _data_?.prefix<=0 || _data_?.suffix<=0  ){
           is_error =  true;
           break
        } 
      }
    } else {
      is_error =  true;
    }
    if(personDetails?.gender<=0 || personDetails?.date_of_birth<=0 || personDetails?.managing_entity_type<=0 || personDetails?.managing_entity_name<=0 ){
      is_error =  true;
    }
    PersonContact?.personContactList?.map(_=>{
      if(_?.contact_mode==='Email'){
       if(!is_error){
        let is_mail = ValidateEmail(_.email)
        is_error = !is_mail;
       }
         
      }
    })
    // if(PersonContact?.personContactList[0]?.email<=0){
    //   is_error=true;
    // } 
    if(is_error){
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: PersonContact?.personContactList[0]?.email<=0  ? "Please enter  your valid email id in contact details": "Please fill person details mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      })
      return true;
    }
    return false;
  }
  const handleSubmit = async() =>{
    if(submitValidation()){
      return;
    }
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Submiting...",
    });;
   let   keyclockError = false;
    let addUserKeyClock = await addUserToKeyClock( "", PersonContact?.personContactList[0]?.email)
     .then((res) => {
      if (res?.data?.Code === 201) {
        // alert("User created successfully!", true);
      } else {
        if(res?.data?.error){
          keyclockError = true
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.warning,
            msg: res?.data?.errorMessage??"Something  went wrong Keyclock mail filed",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
          });
          // return alert(res?.data?.errorMessage);
        }
      }
    })
    .catch((error) => {
      keyclockError = true
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Something  went wrong Keyclock mail filed",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      // return alert("Something  went wrong", false);
    });
    if(keyclockError){
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
        message: "",
      });
      return false
    }
    let identifier = personIdentification.practiesnerList.map((_)=>{ 
        return {
          "_id": "",
          "user": "",
          "system": "",
          "value": _.id_number,//idnumber
          "period": [
            {
              "_id": "",
              "start": 0,
              "end": _?.expiry_date ??  0,
              "id": 0
            }
          ],
          "assigner": "",
          "Type": _.id_type, //type
          "id": 0,
          "files": _.upload_document //document upload
        }
    });
    let person_name = personDetails?.personDetailList?.map(_=>{
      return  {
        "_id": "",
        "use": _.use, //use
        "text": _.first_name,//firstname
        "family": _.last_name, //lastname
        "given": _.middle_name, //middle
        "prefix": _.prefix, //prefix
        "suffix": _.suffix,  //suffix
        "period": [
          {
            "_id": "",
            "start": 0,
            "end": 0,
            "id": 0
          }
        ],
        "id": 0
      }
    });
    let contact =  PersonContact?.personContactList?.map(_=>{
       return       {
        "_id": "",
        "system": _?.contact_mode,//contactmode
        "value": _?.email,//email  or phone value
        "use": _?.use,  //use master
        "rank": _?.priority,  //priority
        "period": [
          {
            "_id": "",
            "start": 0,
            "end": 0,
            "id": 0
          }
        ],
        "id": 0,
        "valueprefix": ""
      };
    });
    let address_list =  addressList?.length>0 ? addressList?.map(_=>{
      return{
        "_id": "",
        "use": _?.use?.coding?.[0]?._id ?? "",
        "text": "",
        "line": _?.addressLine1 ?? "",
        "city": _?.city?.value ?? "",
        "district": _?.district?.value  ?? "",
        "state": _?.state?.value ?? "",
        "postalCode":  _?.pincode?.value ?? "",
        "country": _?.country?.value,
        "period": [
          {
            "_id": "",
            "start": 0,
            "end": 0,
            "id": 0
          }
        ],
        "latitude": "",
        "longitude": "",
        "Type": _?.type?.coding?.[0]?._id,
        "id": 0
      }
    }): [];
    let insertJson =  {
      "identifier": identifier,
      "name": person_name,
      "telecom": contact,
      "gender": personDetails?.gender,
      "birthDay": personDetails?.date_of_birth,
      "address": address_list,
      "photo": personDetails?.upload_image,
      "active": false,
      "link": [
        {
          "isPractitioner": true,
          "asurrance": "",
          "patientID": [],
          "PractitionerID": []
        }
      ],
      "Id": 0,
      "RelatedPersonID": [],
      "OrgID": [personDetails?.managing_entity_name], //entity name
      "alias": "",
      "usertype": "",
      "orgType": personDetails?.managing_entity_type //entitytype
    };
    const { alert } = props;
    let { setSnack } = alert;
    try{
      let personInsert = await dispatch(actions.PERSON_UPSERT(insertJson));
      if(personInsert?.payload?.error){
        setSnack({
          ...alert,
          horizontal: "right",
          msg: "Person creation failed!",
          open: true,
          severity: "error",
          vertical: "top",
        });
        return;
      }
      setSnack({
        ...alert,
        horizontal: "right",
        msg: "Person created successfully !",
        open: true,
        severity: "success",
        vertical: "top",
      });
    }catch(error){
      setSnack({
        ...alert,
        horizontal: "right",
        msg: "Person creation failed !",
        open: true,
        severity: "error",
        vertical: "top",
      });
    }
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "Submiting...",
    });;
    // props.history.push(routes.repository); 
    debugger
    props.history.push({
      pathname: routes.repository,
      state: { index: 3 },
    });
  };

//handle update person

const handleUpdate = async() =>{
  props.backDrop.setBackDrop({
    ...props.backDrop,
    open: true,
    message: "Updating...",
  });;
  if(submitValidation()){
    return;
  }
  let identifier = personIdentification.practiesnerList.map((_)=>{
      return {
     
        "user": "",
        "system": "",
        "value": _.id_number,//idnumber
        "period": [
          {
         
            "start": 0,
            "end": _?.expiry_date,
                    }
        ],
        "assigner": "",
        "Type": _.id_type, //type
      
        "files": _.upload_document //document upload
      }
  });
  let person_name = personDetails?.personDetailList?.map(_=>{
    return  {
   
      "use": _.use, //use
      "text": _.first_name,//firstname
      "family": _.last_name, //lastname
      "given": _.middle_name, //middle
      "prefix": _.prefix, //prefix
      "suffix": _.suffix,  //suffix
      "period": [
        {
       
          "start": 0,
          "end": 0,
                }
      ],
        }
  });
  let contact =  PersonContact?.personContactList?.map((_,i)=>{
     return       {
   
      "system": i==0 ? 'Email' : i== 1 ? 'Phone' : _?.contact_mode,//contactmode
      "value": _?.email,//email  or phone value
      "use": _?.use,  //use master
      "rank": _?.priority,  //priority
      "period": [
        {
       
          "start": 0,
          "end": 0,
                }
      ],
    
      "valueprefix": _?.country_code?.value  ?? ''
    };
  })
  let address_list =  addressList?.length>0 ? addressList?.map(_=>{
    return{
      "use": _?.use?.coding?.[0]?._id ?? "",
      "text": "",
      "line": _?.addressLine1 ?? "",
      "city": _?.city?.value ?? "",
      "district": _?.district?.value  ?? "",
      "state": _?.state?.value ?? "",
      "postalCode":  _?.pincode?.value ?? "",
      "country": _?.country?.value,
      "Type": _?.type?.coding?.[0]?._id,
    }
  }): [];
  let insertJson =  {
    _key:props?.location?.state?.id ?? "",
    "identifier": identifier,
    "name": person_name,
    "telecom": contact,
    "gender": personDetails?.gender,
    "birthDay": personDetails?.date_of_birth,
    "address": address_list,
    "photo": personDetails?.upload_image,
    // "active": false,
    "link": [
      {
        "isPractitioner": true,
        "asurrance": "",
        "patientID": [],
        "PractitionerID": []
      }
    ],

    "RelatedPersonID": [],
    "OrgID": [personDetails?.managing_entity_name], //entity name
    "alias": "",
    "usertype": "",
    "orgType": personDetails?.managing_entity_type //entitytype
  };
  const { alert } = props;
  let { setSnack } = alert;
  try{
    let personInsert = await dispatch(actions.PERSON_UPSERT(insertJson));
    if(personInsert?.payload?.error  || personInsert?.payload?.data?.Code !== 201){
      setSnack({
        ...alert,
        horizontal: "right",
        msg: "Person creation failed !",
        open: true,
        severity: "error",
        vertical: "top",
      });
      props.backDrop.setBackDrop({
        ...props.backDrop,
        open: false,
        message: "",
      });
      return;
    }
    setSnack({
      ...alert,
      horizontal: "right",
      msg: "Updated Successfully",
      open: true,
      severity: "success",
      vertical: "top",
    });
  }catch(error){
    setSnack({
      ...alert,
      horizontal: "right",
      msg: "Person creation failed !",
      open: true,
      severity: "error",
      vertical: "top",
    });
  }
  props.backDrop.setBackDrop({
    ...props.backDrop,
    open: false,
    message: "",
  });
  // props.history.push(routes.repository);
  props.history.push({
    pathname: routes.repository,
    state: { index: 3 },
  });

};

const makeGeoData = (data) => ({
  id: data?.parentGeogLevelCode,
  value: data?._id,
  title: data?.geogLevelName,
  code: data?.geogLevelCode,
})

const makeData = (data = {}) => ({
  ...data,
  value: data?._id,
  label: data?.display,
})


// edit person

const handleEditPersonInfo = async()=>{


  let list = {
    _key:props?.location.state?.id
  }

  try{
    let fetchpersonInfo =await dispatch(actions.SINGLE_PERSON_READ(list));
    if(fetchpersonInfo?.payload?.error){
      return;
    }
    let  cloneData= fetchpersonInfo?.payload?.data[0]  ?? {};
    let practiesnerList = cloneData?.identifier?.length>0 ? cloneData?.identifier?.map(_=>{
      return {
        id_number:_.value,
        expiry_date:_?.period[0]?.end ??  null,
        id_type:_?.Type?._id ?? "",
        upload_document:_.files,
        error:{
          "id_type":false,
          "id_number":false,
          "expiry_date":false,
        },
        errorMsg:{
          "id_type":"Please select field",
          "id_number":"Please enter the field",
          "expiry_date":"Please select date",
        }
      }
    }) : [JSON.parse(JSON.stringify(identifierStruct))];
   let personDetailList= cloneData?.name?.length>0 ? cloneData?.name?.map(_=>{
     return  {
      "use":_?.use?._id ?? "",
      "prefix":_?.prefix?._id ?? "",
      "first_name":_?.text ??  "",
      "middle_name":_?.given ??  "",
      "last_name":_?.family ?? "",
      "suffix":_?.suffix?._id ?? "",
      error:{
        "use":false,
        "prefix":false,
        "first_name":false,
        "middle_name":false,
        "last_name":false,
        "suffix":false,
      },
      errorMsg:{
        "use":"Please select field",
        "prefix":"Please select field",
        "first_name":"Please enter the field",
        "middle_name":"Please enter the field",
        "last_name":"Please enter the field",
        "suffix":"Please select field",
      }
     }
   }) : [JSON.parse(JSON.stringify(detailStruct))];
   let personDetails={
      personDetailList,
      gender:cloneData?.gender?._id ?? "",
      date_of_birth:cloneData?.birthDay ?? null,
      managing_entity_type:cloneData?.orgType?._id ?? "",
      managing_entity_name:cloneData?.OrgID[0]?._id ?? "",
      person_id:cloneData?.Id,
      upload_image:cloneData?.photo,
      error:{
        "gender":false,
        "date_of_birth":false,
        "managing_entity_type":false,
        "managing_entity_name":false,
        // "person_id":true,
      },
      errorMsg:{
        "gender":"Please select field",
        "date_of_birth":"Please select field",
        "managing_entity_name":"Please enter the field",
        "person_id":"Please enter the field",
        "managing_entity_type":"Please select field",
      }
   };

  let personContactList =  cloneData?.telecom?.length>0 ? cloneData?.telecom?.map(_=>{
    return   {
      "contact_mode":_?.system  ?? "",
      "email":_?.value,
      "use":_?.use?._id ?? "",
      "priority":_?.rank?._id ?? "",
      country_code:_?.valueprefix?.length>0 ? allMasterList.country_code.filter(_f=>_f.value===_?.valueprefix)[0] : ''
    }
  })  : [JSON.parse(JSON.stringify(contactStruct))];

  let address_list =  cloneData?.address?.length>0 ? cloneData?.address?.map(_=>{
    return{
      "use": _?.use ? makeData(_.use) : {},
      "text": "",
      "addressLine1": _?.line ?? "",
      "city": _?.city ? makeGeoData(_?.city) : {},
      "district": _?.district ? makeGeoData(_?.district) : {},
      "state": _?.state ? makeGeoData(_?.state) : {},
      "pincode":  _?.postalCode ? makeGeoData(_?.postalCode) : {},
      "country": _?.country ? makeGeoData(_?.country) : {},
      "type": _?.Type ? makeData(_.Type) : {},
      addressLine2:"",
      addressLine3:""
    }
  }): [];

  // fetch ENTITYNAMEMASTER slist
  if(personDetails?.managing_entity_name){
    let val = cloneData?.orgType;
    await dispatch(actions.ENTITYNAMEMASTER(val?.display?.toLocaleLowerCase()))
  }
  
  setPersonDetails({
    ...personDetails
  });
  setPersonContact({
    personContactList
  })
  setPersonIdentification({
    practiesnerList
  });
  setAddress(address_list);
  }catch(error){
    console.log(error)
  }

}
  //validation

  const validation = (name,value,keys)=>{
    
    let is_error = false;
    if(keys.indexOf(name)!==-1){
      if(Array.isArray(value) && value?.length<=0){
        is_error=true;
  
      }
       else if(typeof value === 'object' &&  value?.length<=0){
        is_error=true;
      } else if(typeof value === 'string' && value?.length<=0){
        is_error= true;
      }
      else if(typeof value === 'object' && !value){
        is_error= true;
  
      }
       else {
        is_error= false;
      }
    }
    
    return is_error;
  }

    return (
      <Div className="light-background-color">
        <TopNavbar
          activeId={state.activeId}
          //   navClick={this.setActive}
          // successfun={this.successfun}
          title={props?.history?.location?.state?.id ? "Edit Person" : "Add New Person "}
          //   navBarArr={patientRegister}
          backHandle={backHandle}
          // handleDrawerClose={this.props.handleDrawerClose}
        />
        {false && false ? (
          <div >
            <CircularProgress />
          </div>
        ) : (
          <Div
            style={{
              padding: "5px 15px",
              color: "#101010",
              overflow: "hidden",
            }}
          >
            <PersonIdentification
              saveto={"personData"}
              setTo={"setpersonData"}
              addPersonIdentifier={addPersonIdentifier}
              personIdentification={personIdentification.practiesnerList}
              idTypeMaster = {personMasterList?.id_type_master?.data ?? []}
              handlePersonIdentifier ={handlePersonIdentifier}
              handleIdentifierDelete = {handleIdentifierDelete}
            />
            <PersonNames 
              saveto={"personData"} 
              setTo={"setpersonData"} 
              use_master = {personMasterList?.use_master?.data ?? []}
              prefix_master = {personMasterList?.prefix_master?.data ?? []}
              surffix_master = {personMasterList?.surffix_master?.data ?? []}
              gender_master = {personMasterList?.gender_master?.data ?? []}

              entity_type_master = {personMasterList?.entity_type_master?.data ?? []}
              entity_name_master = {personMasterList?.entity_name_master?.data ?? []}
              person_details = {personDetails}
              handlePersonDetailsLoop= {handlePersonDetailsLoop}
              addPersonDetails={addPersonDetails}
              handlePersonDetails={handlePersonDetails}
              handleDetailsDelete={handleDetailsDelete}
            />
            <PersonCotactPoint 
                saveto={"personData"} 
                setTo={"setpersonData"}
                allMasters={allMasterList}
                addPersonContact={addPersonContact}
                handlePersonContact={handlePersonContact}
                handlePersonDelete={handlePersonDelete}
                PersonContact = {PersonContact}
             />
            <PersonAddress saveto={"personData"} setTo={"setpersonData"} 
               allMasterList={allMasterList}
               addressList={addressList}
               setAddress={setAddress}
            />

            <Div className="p-continue-action">
              <Button
                variant="text"
                className={"topNav-actions cancelBtn"}
                onClick={() => {
                  backHandle();
                }}
              >
                Cancel
              </Button>
              <Button
                variant={"contained"}
                color={"primary"}
                // disabled={true}
                // disabled={this.props?.practitioner_inset?.loading ?? false}
                onClick={() => props?.location?.state?.id  ? handleUpdate() : handleSubmit() }
                style={{ marginLeft: 10 }}
              >
                {props?.location?.state?.id ? (
                  <>{false ? "Updating..." : "Update"}</>
                ) : (
                  <>{false ? "Saving..." : "Save"}</>
                )}
              </Button>
            </Div>
          </Div>
        )}
      </Div>
    );
  }


export default withRouter(withAllContexts(withHoverDrawer(AddNewPerson)));
