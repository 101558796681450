import React from "react";
import {
  Div,
  Paper,
  Select,
  H6,
  Col,
  Row,
  TextInput,
  Text,
  Image,
} from "qdm-component-library";
import {Grid, Typography } from "@material-ui/core";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
import AddPublication from "./addPublication";

class Publication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setDetails = (name, value, index, type) => {

    let patientData = this.props[this.props.saveto];
    if (patientData.publication?.error) {
      patientData.publication.error[name] = false;
    }
    patientData.publication[index][name] = value;
    patientData[this.props.setTo]({
      ...patientData,
    });
  };

  OpenAddress = async (e) => {
    let patientData = this.props[this.props.saveto];
    patientData.publication.push({});
    patientData[this.props.setTo]({
      ...patientData,
    });
  };

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    borderStyle1: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      // width: "98px",
    },
    borderStyle2: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      // width: "98px",
      // marginLeft: "-65px",
    },
    labelStyle2: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
      // marginLeft: "-65px",
    }
  };

  ondelete = (index) => {

    if (this.props?.ondelete) {
      this.props.ondelete(index)
    } else {
      let patientData = this.props[this.props.saveto];
      patientData.publication.splice(index, 1);
      patientData[this.props.setTo]({
        ...patientData,
      });
    }
  }

  render() {
    let { publication, parent_id } = this.props[this.props.saveto];

    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root">
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-title-div`}
          >
            <H6
              id={`${parent_id}-title-h6`}
              className="pi-title">Publication</H6>
            <Text
              id={`${parent_id}-Add-New-text`}
              className="pi-addbtn"
              onClick={(e) => {
                this.OpenAddress(e);
              }}
            >
              + Add New
            </Text>
          </Div>
          {publication?.length > 0 &&
          <Grid container spacing={0} style={{marginTop: "5px"}}>
            <Grid item md={6}>
              <Typography  id={`${parent_id}-Description-title-typography`}
              style={{ fontSize: "12px", color: "gray", fontfamily: "poppin"}}>Description</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography id={`${parent_id}-link-title-typography`}
              style={{ fontSize: "12px", color: "gray", fontfamily: "poppin", marginLeft: "-24px"}}>Link</Typography></Grid>
          </Grid>
          }
          {publication?.length > 0 && publication.map((val, i) => {
            return <>
              <Div
                id={`${parent_id}-AddPublication-div`}
                className="pa-showList">
                <div
                  id={`${parent_id}-AddPublication-sub-div`}
                  style={{ marginBottom: 12 }}>
                  <AddPublication
                    parent_id={'AddPublication'}
                    value={val}
                    setDetails={this.setDetails}
                    ondelete={this.ondelete}
                    index={i}
                  />
                </div>
              </Div>
            </>
          })}
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(Publication);
