import React from "react";
import {
  VerticalList,
  CommonTable,
  OverlayCompt,
  TimeTableForm,
} from "../../components";
import { withAllContexts } from "../../HOCs";
import { Row, Col } from "qdm-component-library";
import { connect } from "react-redux";
import { FilterSelect } from "./filterSelect";
import { actions } from "primary_care_admin_binder";
import { AlertProps } from "../../utils";

const list = [
  "GET_APPLICABLE_APP_TYPES",
  "GET_SCHEDULE_MODE",
  "GET_DAY_TYPE",
  "GET_CONSULTATION_TYPE",
  "GET_RESOURCE_TYPE",
  "GET_RESOURCE_ROLE",
  "READ_GROUPS",
];
class TimeTableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      list: [],
      isOverRide: false,
      editData: null,
      entity_type: null,
      entity_name: null,
      perPage: 10,
      page: 0,
      search: "",
    };
    this.handleForm = this.handleForm.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    });
    await this.props.GET_TIMETABLE_LIST({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    });
  };

  async componentDidMount() {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Fetching...",
    });
    await this.props.GET_TIMETABLE_LIST({
      page: this.state.page,
      perPage: this.state.perPage,
    });
    await this.props.GET_ENTITY_TYPE();
    await Promise.all(
      list.map(async (val) => {
        return await this.props[val]();
      })
    );
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "Fetching...",
    });
  }

  handleForm = (type) => {
    let { openForm, isOverRide } = this.state;
    this.setState({
      openForm: !openForm,
      isOverRide: type === 1 ? false : !isOverRide,
      editData: null,
    });
  };

  handleEdit = (e, data, index) => {
    this.setState({
      editData: data,
      openForm: true,
      isOverRide: false,
    });
  };

  handleOverRide = (e, data, index) => {
    this.setState({
      editData: data,
      openForm: true,
      isOverRide: true,
    });
  };

  updateState = async (name, value) => {
    if (name === "entity_type") {
      let payload = await this.props.GET_ENTITY_NAME_WITH_TYPE({
        type: value.value,
      });
      this.setState({ [name]: value, list: payload.payload.data }, async () => {
        await this.props.GET_TIMETABLE_LIST({
          entity_type: this.state.entity_type,
          entity_name: this.state.entity_name,
          page: this.state.page,
          perPage: this.state.perPage,
        });
      });
    } else {
      this.setState({ [name]: value }, async () => {
        await this.props.GET_TIMETABLE_LIST({
          entity_type: this.state.entity_type,
          entity_name: this.state.entity_name,
          page: this.state.page,
          perPage: this.state.perPage,
        });
      });
    }
  };

  handleCheckBox = async (e, data, index) => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Status Updating",
    });
    await this.props.UPDATE_STATUS({
      editId: data._id,
      status: e.target.checked,
    });
    await this.props.GET_TIMETABLE_LIST({
      page: this.state.page,
      perPage: this.state.perPage,
    });
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
    this.props.alert.setSnack("update");
  };

  handleSearch = async (value = "") => {
    if (value?.length > 0) {
      await this.props.GET_TIMETABLE_LIST({
        search:value.trim(),
        page: this.state.page,
        perPage: this.state.perPage
      });
      this.setState({
        ...this.state,
        search: value.trim(),
      });
    } else if (!value) {
     await this.props.GET_TIMETABLE_LIST({
      page: this.state.page,
      perPage: this.state.perPage
     });
    }
    this.setState({
      ...this.state,
      search: "",
    });
  };

  render() {
    const { openForm, editData, entity_type, entity_name, list, isOverRide } =
      this.state;
    const { parent_id } = this.props;
    return (
      <React.Fragment>
        <Row
          id={`${parent_id}-parent-row`}
          style={{ justifyContent: "flex-end", paddingBottom: "15px" }}
        >
          <Col
            id={`${parent_id}-FilterSelect-col`}
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            inLineStyles={{ padding: "0px 0px 0px 8px" }}
          >
            <FilterSelect
              parent_id={"entity-type"}
              value={entity_type}
              onChange={(value) => this.updateState("entity_type", value)}
              options={this.props.entity_type_list.data}
              label="Entity Type :"
            />
          </Col>
          <Col
            id={`${parent_id}-FilterSelect-col`}
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            inLineStyles={{ padding: "0px 0px 0px 8px" }}
          >
            <FilterSelect
              parent_id={"entity-name"}
              value={entity_name}
              onChange={(value) => this.updateState("entity_name", value)}
              options={list}
              label="Entity Name :"
            />
          </Col>
        </Row>
        {/* {console.log(this.props.timetable_list.data?.[0]?.TotalCount)} */}

        <VerticalList
          parent_id={"timetable"}
          addFunc={() => this.handleForm(1)}
          // searchCmpProps starts here
          id={"timetable"}
          placeholder={"search Title, Type & Role"}
          // loading={this.props?.searchLoading}
          onSearchChange={this?.handleSearch}
          hideSearchBar={false}
          hideFilter={true}
          // onFilterClicked={this.props?.onFilterClicked}
          // isFilterApplied={this.props?.isFilterApplied}
          TabList={[
            {
              name: "Time Table",
              component: (
                <CommonTable
                  parent_id={"timetable"}
                  rightAlign={["Status", "Action"]}
                  handleEdit={this.handleEdit}
                  handleOverRide={this.handleOverRide}
                  handleCheckBox={this.handleCheckBox}
                  Header={[
                    {label: "S.No", showSort: false, showFilter: false},
                    {label: "Title", showSort: true, showFilter: false},
                    {label: "Resource Type",  showSort: true, showFilter: false},
                    {label: "Resource Role",  showSort: false, showFilter: false},
                    {label: "Start Date",  showSort: false, showFilter: false},
                    {label: "End Date",  showSort: false, showFilter: false},
                    {label: "Status",  showSort: false, showFilter: false},
                    {label: "Action",  showSort: false, showFilter: false},
                    // "S.No",
                    // "Title",
                    // "Resource Type",
                    // "Resource Role",
                    // "Start Date",
                    // "End Date",
                    // "Status",
                    // "Action"
                  ]}
                  dataList={this.props.timetable_list.data}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    {
                      type: ["TEXT"],
                      name: "timetable_title",
                      cond: { key: "isoverride", value: true },
                    },
                    { type: ["TEXT"], name: "resource_type" },
                    { type: ["TEXT"], name: "resource_role" },
                    { type: ["TEXT"], name: "start_date" },
                    { type: ["TEXT"], name: "end_date" },
                    { type: ["CHECKBOX"], name: "status", align: "right" },
                    { type: ["EDIT", "OVERRIDE"], align: "right" },
                  ]}
                  handlepageChange={(currentPage, PerPage) =>
                    this.handlepageChange(currentPage, PerPage)
                  }
                  TableCount={this.props.timetable_list.data?.[0]?.TotalCount}
                  incrementCount={this.state.page}
                  showPagination={true}
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                />
              ),
            },
          ]}
        />
        <OverlayCompt
          parent_id={"timetable"}
          open={openForm}
          children={
            <React.Fragment>
              {openForm && (
                <TimeTableForm
                  ttOverRide={isOverRide}
                  parent_id={"timetableform"}
                  editData={editData}
                  closeForm={this.handleForm}
                />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  entity_type_list: state.timetableMasterSlice.entity_type_list,
  timetable_list: state.timetableMasterSlice.timetable_list,
  entitynamewithType_list: state.timetableMasterSlice.entitynamewithType_list,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(TimeTableTable));
