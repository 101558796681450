import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  withStyles,
  Chip,
  AccordionDetails,
  Accordion,
  Button,
} from "@material-ui/core";
import { SelectBox } from "../../../../../../components/common/smartForm/component";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { CommonTable } from "../../../../../../components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "../../../../../../assets/icons - Edit.svg";
import DeleteIcon from "../../../../../../assets/icons8-trash.svg";
import { AlertProps } from "../../../../../../utils";
import { withAllContexts } from "../../../../../../HOCs";
import { actions } from "primary_care_admin_binder";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    borderRadius: 8,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  Accordion: {
    boxShadow: "none",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    marginTop: 10,

    "&:before": {
      display: "none",
    },

    "& .MuiAccordionSummary-root": {
      borderRadius: "8px",
    },
    "&:last-child": {
      borderRadius: 8,
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },

  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  subtitle: {
    fontSize: "12px",
    marginRight: 4,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  cardGrid: {
    display: "flex",
    marginTop: 2,
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
  firstGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
  SecondGrid: {
    marginTop: 15,
  },
  btntitle: {
    color: "#0071F2",
    cursor: "pointer",
    fontSize: "14px",
  },
  deleteGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  detailGird: {
    display: "flex",
    "& .MuiChip-root": {
      height: "40px",
      borderRadius: "0px 8px 8px 0px",
      marginRight: 10,
      "& .MuiChip-label": {
        paddingLeft: 4,
      },
    },
  },
  tableGrid: {
    marginTop: 20,
  },
}));

const AccordionSummary = withStyles({
  root: {
    background: "whitesmoke",
    paddingLeft: 0,
  },
  expandIcon: {
    //order: -1,
    width: "10px",
    height: "10px",
    border: "1px solid rgba(17, 15, 71, 0.08)",

    color: "black",
    backgroundColor: "white !important",
  },
  expanded: {
    background: "#fff",
  },
})(MuiAccordionSummary);

const errorList = ["gender", "ageRange", "Unit"];

function ReferencesRanges(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [ageRangeOptionData, setAgeRangeOptionData] = React.useState([]);
  const [minMaxError, setMinMaxError] = React.useState(false);

  const [state, setState] = React.useState({
    gender: null,
    ageRange: null,
    Unit: null,
    tableData: [],
    editIndex: null,
    error: {},
  });

  let dispatch = useDispatch();
  const storeData = useSelector((state) => state?.vitalsMasterSectionSlice);
  let ccMasters = storeData.reference_ageranges.data;

  // React.useEffect(() => {
  //   //getMasterData();
  // }, []);

  // const getMasterData = async () => {
  //   // await dispatch(actions.MASTER_GENDER());
  //   // await dispatch(actions.REFERENCE_UNIT_ALLOWED());
  //   // await dispatch(actions.REFERENCE_AGERANGES());
  // };

  React.useLayoutEffect(() => {
    let datalist = reference.map((val) => val.ageRange.value);
    let masterData = ccMasters.filter(
      (val) => datalist.indexOf(val.value) === -1
    );
    setAgeRangeOptionData(masterData);
  }, [ccMasters, state]);

  React.useEffect(async () => {
    let datalist = reference.map((val) => val?.ageRange?.value);
    let masterData = await ccMasters.filter(
      (val) => datalist.indexOf(val.value) === -1
    );
    setAgeRangeOptionData(masterData);
  }, [props.editData]);

  const ReferenceTable = async (value) => {
    let referenceData = await dispatch(
      actions.REFERENCE_AGERANGES_CATEGORY({
        ageRangeId: value?.value,
      })
    );
    let Data = referenceData?.payload?.data?.map((v, i) => {
      return {
        _id: v._id ? v._id : "",
        description: v.description,
        category: v.category,
        ageMin: v.ageMin,
        ageMax: v.ageMax,
        unit: v.unit,
        normalMin: v.normalMin,
        normalMax: v.normalMax,
        validationMin: v.validationMin,
        validationMax: v.validationMax,
        error: {},
      };
    });

    state["tableData"] = Data;
  };

  // forStateChange;
  const handleState = async (name, value) => {
    if (name === "ageRange" && value) {
      await ReferenceTable(value);
    }
    state[name] = value;
    state.error[name] = false;
    setState({ ...state });
  };

  // reference hide and show
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  //tabel select
  const handleSelect = (val, row, name, index) => {
    state.tableData[index][name] = val;
    if (state?.tableData[index]?.error?.[name]) {
      state.tableData[index].error[name] = false;
    }
    setState({ ...state });
  }
  //tabel textfield
  const handleTextfield = (e, row, name, i) => {
    state.tableData[i][name] = e.target.value;
    if (state?.tableData[i]?.error?.[name]) {
      state.tableData[i].error[name] = false;
    }
    if (
      +state.tableData[i]["normalMax"] < +state.tableData[i]["validationMax"] &&
      +state.tableData[i]["normalMax"] > +state.tableData[i]["validationMin"] &&
      state.tableData[i]["normalMax"]?.length > 0
    ) {
      state.tableData[i].error["normalMax"] = false;
    }
    if (
      +state.tableData[i]["normalMin"] < +state.tableData[i]["normalMax"] &&
      +state.tableData[i]["normalMin"] > +state.tableData[i]["validationMin"] &&
      +state.tableData[i]["normalMin"] < +state.tableData[i]["validationMax"] &&
      state.tableData[i]["normalMin"]?.length > 0
    ) {
      state.tableData[i].error["normalMin"] = false;
    }
    if (
      +state.tableData[i]["validationMin"] <
      +state.tableData[i]["validationMax"]
    ) {
      state.tableData[i].error["validationMin"] = false;
    }
    setState({ ...state });
  };
  //table edit
  const handleReferenceEdit = (data, i) => {
    let Data = reference[i].table.map((v, i) => {
      return {
        _id: v._id,
        category: v.category,
        description: v.description,
        ageMin: v.ageMin,
        ageMax: v.ageMax,
        unit: v.unit,
        normalMin: v.normalMin,
        normalMax: v.normalMax,
        validationMin: v.validationMin,
        validationMax: v.validationMax,
        error: v.error ?? {},
      };
    });
    setState({
      ...state,
      ageRange: reference[i].ageRange,
      gender: reference[i].gender,
      Unit: reference[i].Unit,
      tableData: Data,
      editIndex: i,
    });
    handleOpen();
  };

  //table delete
  const handleReferenceDelete = (i) => {
    let referenceData = JSON.parse(JSON.stringify(reference));
    referenceData.splice(i, 1);
    handleFormState("reference", referenceData);
  };

  // function cancel
  const handleOpen = (func) => {
    if (func === "cancel") {
      setState({
        ...state,
        editIndex: null,
        ageRange: null,
        gender: null,
        Unit: null,
        tableData: [],
      });
    }
    setcollapseOpen(!collapseOpen);
  };
  //add in table
  const AddReference = () => {
    let error = false;
    errorList.map((val) => {
      if (!state[val]) {
        error = true;
        state.error[val] = true;
        props.alert.setSnack("mandatory");
      }
    });
    let keys = ["validationMin", "validationMax", "normalMin", "normalMax", "unit"];
    state.tableData.forEach((val, i) => {
      Object.keys(val).forEach((v) => {
        if (keys.includes(v)) {

          if (!state.tableData[i][v]) {
            error = true;
            state.tableData[i].error[v] = true;
            props.alert.setSnack("mandatory");
          }
          if (v === "validationMin") {
            if (
              +state.tableData[i]["validationMin"] >=
              +state.tableData[i]["validationMax"]
            ) {
              error = true;
              state.tableData[i].error["validationMin"] = true;
              props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: `Validation min value should be less than the mentioned validation max value`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });
            }
          }
          if (v === "normalMin") {
            if (
              +state.tableData[i]["normalMin"] >=
              +state.tableData[i]["normalMax"]
            ) {
              error = true;
              state.tableData[i].error["normalMin"] = true;
              props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: `Normal min value should be less than the mentioned normal max value`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });
            }
            if (
              +state.tableData[i]["normalMin"] <=
                +state.tableData[i]["validationMin"] ||
              +state.tableData[i]["normalMin"] >=
                +state.tableData[i]["validationMax"]
            ) {
              error = true;
              state.tableData[i].error["normalMin"] = true;
              props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: `Normal range value should be between the mentioned validation range value`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });
            }
          }
          if (v === "normalMax") {
            if (
              +state.tableData[i]["normalMax"] <=
                +state.tableData[i]["validationMin"] ||
              +state.tableData[i]["normalMax"] >=
                +state.tableData[i]["validationMax"]
            ) {
              error = true;
              state.tableData[i].error["normalMax"] = true;
              props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: `Normal range value should be between the mentioned validation range value`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });
            }
          }
          if (error == false) {
            state.tableData[i].error[v] = false;
          }
        }
      });
    });
    if (error) {
      setState({
        ...state,
      });
    } else {
      let referenceData = JSON.parse(JSON.stringify(reference));
      let obj = {
        ageRange: state.ageRange,
        gender: state.gender,
        Unit: state.Unit,
        table: state.tableData,
      };
      if (state.editIndex || state.editIndex === 0) {
        referenceData[state.editIndex] = obj;
        setState({
          ...state,
          editIndex: null,
          ageRange: null,
          gender: null,
          Unit: null,
          tableData: [],
          error: {},
        });
        handleFormState("reference", referenceData);
      } else {
        referenceData.push(obj);
        setState((prevState) => ({
          ...prevState,
          ageRange: null,
          gender: null,
          Unit: null,
          tableData: [],
          error: {},
        }));
      }
      handleFormState("reference", referenceData);
      handleOpen();
    }
  };

  const { gender, ageRange, Unit, editIndex, tableData } = state;
  const { reference, handleFormState, parent_id, unitAllowed } = props;
  return (
    <React.Fragment>
      <Paper id={`${parent_id}_parent_paper`} className={classes.root}>
        <Grid id={`${parent_id}_REFERENCE_grid`} className={classes.firstGrid}>
          <Typography
            id={`${parent_id}_REFERENCE_label_typography`}
            className={classes.title}
            variant="h6"
          >
            REFERENCE RANGES
          </Typography>

          <Typography
            id={`${parent_id}_AddNew_label_typography`}
            onClick={() => handleOpen()}
            className={classes.btntitle}
            variant="h6"
          >
            + Add New
          </Typography>
        </Grid>

        {reference?.map((v, index) => {
          return (
            <Accordion
              id={`${parent_id}_${index}_expanded_accordion`}
              key={index}
              expanded={expanded === "panel1" + index}
              onChange={handleChange("panel1" + index)}
              className={classes.Accordion}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.Accordioncontent}
                aria_controls="panel1a_content"
                id="panel1a_header"
              >
                <Grid id={`${parent_id}_${index}_Categories_grid`} container>
                  <Grid
                    id={`${parent_id}_${index}_Categories_sub_grid`}
                    item
                    xs={10}
                  >
                    <Grid
                      id={`${parent_id}_${index}_Categories_Chip_grid`}
                      className={classes.detailGird}
                    >
                      <Grid>
                        <Chip
                          id={`${parent_id}_${index}_Categories_Chip`}
                          label={index + 1}
                        />
                      </Grid>
                      <Grid id={`${parent_id}_${index}_Categories_ageRange`}>
                        <Typography
                          id={`${parent_id}_${index}_${v.ageRange?.label.replaceAll(
                            " ",
                            "-"
                          )}_ageRange_title`}
                          className={classes.title}
                          variant="h6"
                        >
                          {v.ageRange?.label}
                        </Typography>

                        <Grid
                          id={`${parent_id}_${index}_Categories_titles`}
                          className={classes.cardGrid}
                        >
                          <Typography
                            id={`${parent_id}_${index}_${v.gender?.label.replaceAll(
                              " ",
                              "-"
                            )}_ageRange_title`}
                            className={classes.subtitle}
                            variant="h6"
                          >
                            {v.gender?.label}
                          </Typography>
                          <Typography
                            id={`${parent_id}_${index}_${v.Unit?.label.replaceAll(
                              " ",
                              "-"
                            )}_ageRange_title`}
                            className={classes.subtitle}
                            variant="h6"
                          >
                            • {v.Unit?.label}
                          </Typography>
                          <Typography
                            id={`${parent_id}_${index}_ageRange_title`}
                            className={classes.subtitle}
                            variant="h6"
                          >
                            • {v?.table?.length} Age Categories
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    id={`${parent_id}_${index}_img_grid`}
                    className={classes.deleteGrid}
                    item
                    xs={2}
                  >
                    <img
                      id={`${parent_id}_${index}_EditIcon_img`}
                      src={EditIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReferenceEdit(v, index);
                      }}
                      width="12px"
                      style={{ marginRight: "15px", cursor: "pointer" }}
                    />
                    <img
                      id={`${parent_id}_${index}_DeleteIcon_img`}
                      src={DeleteIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReferenceDelete(index);
                      }}
                      width="12px"
                      style={{ marginRight: "15px", cursor: "pointer" }}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                id={`${parent_id}_${index}_accordion_details`}
                className={classes.AccordionDetails}
              >
                <CommonTable
                  parent_id={"reference"}
                  Header={[
                    "Age Range Code",
                    "Age Category",
                    "Age Min",
                    "Age Max",
                    "Normal Min",
                    "Normal Max",
                    "Validation Min",
                    "Validation Max",
                    "Unit",
                  ]}
                  dataList={v?.table}
                  tableData={[
                    { type: ["TEXT"], name: "category", optionLabel: "code" },
                    { type: ["TEXT"], name: "description" },
                    { type: ["TEXT"], name: "ageMin" },
                    { type: ["TEXT"], name: "ageMax" },
                    { type: ["TEXT"], name: "normalMin" },
                    { type: ["TEXT"], name: "normalMax" },
                    { type: ["TEXT"], name: "validationMin" },
                    { type: ["TEXT"], name: "validationMax" },
                    { type: ["TEXT"], name: "unit", optionLabel: "label" },
                  ]}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}

        {collapseOpen && (
          <Grid
            id={`${parent_id}_ADD_REFERENCE_grid`}
            className={classes.SecondGrid}
          >
            <Typography
              id={`${parent_id}_ADD_REFERENCE_typography`}
              className={classes.title}
              variant="h6"
            >
              {editIndex || editIndex === 0 ? "UPDATE REFERENCE RANGES" : "ADD REFERENCE RANGES"}
            </Typography>
            <Grid
              id={`${parent_id}_ADD_REFERENCE_typography`}
              container
              className={classes.form}
            >
              <Grid md="4" lg="4" className={classes.formParent}>
                <Typography
                  id={`${parent_id}_Gender_typography`}
                  className={classes.formtitle}
                  variant="body1"
                >
                  Gender <span style={{ color: "red" }}>*</span>
                </Typography>
                <SelectBox
                  parent_id={"vital_master_reference_range_gender"}
                  list={storeData.master_gender.data ?? []}
                  placeholder={"Select"}
                  value={gender}
                  onchange={(e, value) => handleState("gender", value)}
                  error={state?.error?.gender}
                />
              </Grid>

              <Grid
                id={`${parent_id}_Group_Code_div`}
                md="4"
                lg="4"
                className={classes.formParent}
              >
                <Typography
                  id={`${parent_id}_Group_Code_typography`}
                  className={classes.formtitle}
                  variant="body1"
                >
                  Age Range Group Code <span style={{ color: "red" }}>*</span>
                </Typography>
                <SelectBox
                  parent_id={"vital_master_reference_range_agerange_group_code"}
                  //list={storeData.reference_ageranges.data ?? []}
                  list={ageRangeOptionData}
                  placeholder={"Select"}
                  value={ageRange}
                  onchange={(e, value) => handleState("ageRange", value)}
                  error={state?.error?.ageRange}
                />
              </Grid>
              <Grid
                id={`${parent_id}_Unit_grid`}
                md="4"
                lg="4"
                className={classes.formParent}
              >
                <Typography
                  id={`${parent_id}_Unit_title_typography`}
                  className={classes.formtitle}
                  variant="body1"
                >
                  Unit of vital <span style={{ color: "red" }}>*</span>
                </Typography>
                <SelectBox
                  parent_id={"vital_master_reference_range_unit"}
                  // list={storeData.reference_unit_allowed.data ?? []}
                  list={props.unitData}
                  placeholder={"Select"}
                  value={Unit}
                  onchange={(e, value) => handleState("Unit", value)}
                  error={state?.error?.Unit}
                />
              </Grid>
              <Grid
                id={`${parent_id}_tableData_grid`}
                className={classes.tableGrid}
                item
                xs={12}
              >
                <CommonTable
                  parent_id={`${parent_id}_tableData_grid`}
                  handleTextfield={handleTextfield}
                  handleSelect={handleSelect}
                  enableStart={[
                    "Normal Min",
                    "Normal Max",
                    "Validation Min",
                    "Validation Max",
                    "Unit for Reference Range",
                  ]}
                  Header={[
                    "Age Range Code",
                    "Age Category",
                    "Age Min",
                    "Age Max",
                    "Unit for Reference Range",
                    "Normal Min",
                    "Normal Max",
                    "Validation Min",
                    "Validation Max",
                  ]}
                  dataList={tableData}
                  tableData={[
                    { type: ["TEXT"], name: "category", optionLabel: "code" },
                    { type: ["TEXT"], name: "description" },
                    { type: ["TEXT"], name: "ageMin" },
                    { type: ["TEXT"], name: "ageMax" },
                    { type: ["SELECT"], name: "unit", options: props.unitData },
                    { type: ["TEXTFIELD"], name: "normalMin" },
                    { type: ["TEXTFIELD"], name: "normalMax" },
                    { type: ["TEXTFIELD"], name: "validationMin" },
                    { type: ["TEXTFIELD"], name: "validationMax" },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid
              id={`${parent_id}_button_grid`}
              style={{ textAlign: "center", marginTop: 20 }}
            >
              <Button
                id={`${parent_id}_Cancel_button`}
                onClick={() => handleOpen("cancel")}
                style={{ marginRight: "20px" }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                id={`${parent_id}_ADD_button`}
                onClick={() => AddReference()}
                variant="contained"
                color="primary"
              >
                {editIndex || editIndex === 0 ? "Update" : "ADD"}
              </Button>
            </Grid>
          </Grid>
        )}
      </Paper>
    </React.Fragment>
  );
}

export default withAllContexts(ReferencesRanges);
