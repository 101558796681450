import React from "react";
import PropTypes from "prop-types";
import {
  Div,
  Paper,
  H6,
  //TextInput,
  Divider,
  Row,
  Col,
  Image,
  //Select,
} from "qdm-component-library";
import "./navBar.css";
import { withRouter } from "react-router-dom";
//  import { 
//    //Popover,
//    makeStyles } from "@material-ui/core";
// import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { routes } from "../../../router/routes";

// const useStyles = makeStyles((theme) => ({
//   muiPopovers: {
//     boxShadow: "0px 25px 50px #00000029",
//     borderRadius: 8,
//     marginTop: 10,
//     padding: 10,
//   },
//   labelStyle: {
//     // color: '#6F6F6F',
//     // fontSize: '12px',
//     // marginBottom: '6px',
//     // fontFamily: `pc_regular !important`,
//   },
//   borderStyle: {
//     borderRadius: 8,
//     borderColor: "#fff",
//     border: "none",
//   },
//   heigthinput: {
//     border: "blue",
//     "& input": {
//       height: 0,
//       color: "#101010",
//     },
//   },
//   entity: {
//     width: "45% ",
//     marginTop: "10px",
//     color: "#6F6F6F",
//     marginLeft: "8px",
//   },
// }));

//const optiondata = [{ value: "Facility" }];
export const Component = (props) => {
  const {
    navBarArr = [],
    title,
    //activeId = 1,
    //navClick = () => null,
    //putOnHold,
    parent_id
  } = props;
  // const redirectLink = (data) => {
  //   navClick(data.id);
  // };
  const goback = () => {
    
    props.history.push({ pathname: routes.administration, state: { index: 2 } });
  };

  //const classes = useStyles();
  return (
    <div
      id={`${parent_id}-parent-div`}
      style={{ height: "56px" }}>
      <Paper
        id={`${parent_id}-parent-paper`}
        style={{
          margin: "0px",
          position: "fixed",
          zIndex: "1",
          background: "white",
          boxShadow: "0px 3px 6px #0000000A",
        }}
      >
        <Row
          id={`${parent_id}-parent-row`}
          className={
            navBarArr.length > 0 ? "topNav-top-Parent" : "topNav-top-Parent1"
          }
        >
          <Col
            id={`${parent_id}-parent-col`}
          >
            <Div
              id={`${parent_id}-parent-div`}
              className={"topNav-parent"}>
              {/* <i onClick={() => goback()} className="fa fa-angle-left topNav-backIcon" aria-hidden="true"></i> */}
              <Div
                id={`${parent_id}-uploadIcon-div`}
                className="uploadIcon"
                style={{ backgroundColor: "#DCDCDC", marginRight: "3px" }}
                onClick={() => goback()}
              >
                <Image
                  id={`${parent_id}-uploadIcon-image`}
                  src="images/icons8-left.svg" alt="left" />
                {/* <ArrowBackIosIcon /> */}
              </Div>
              <H6
                id={`${parent_id}-uploadIcon-${title}-h6`}
                className={"topNav-title"}>{title}</H6>
              {navBarArr.length > 0 ? (
                <Divider
                  id={`${parent_id}-divider`}
                  className={"topNav-divider"} orientation="vertical" />
              ) : (
                <></>
              )}
            </Div>
          </Col>
        </Row>
      </Paper>
    </div>
  );
};

Component.propTypes = {
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  navBarArr: PropTypes.array,
  titleIcon: PropTypes.element,
  onTitleIconClicked: PropTypes.func,
};

Component.defaultProps = {
  withBackButton: false,
};

const TopNavbar = (props) => <Component {...props} />;

export default withRouter(TopNavbar);
