import React from "react";
import { Card } from "@material-ui/core";
// import UploadFiles from "./uploadFiles";
import "../../font.css";

import { Typography } from "@material-ui/core";
import {
  TextInput,
  Upload,
  Button,
  Col
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
import { Filenameui } from "./commonUI";
import { AlertProps, uploadImage } from "../../../utils";

import CustomInputDatePicker from "../../common/datePicker/inputDatePicker";
import moment from "moment";

class AddNewAchievements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: ""
    };
  }

  componentDidMount() {
    const { editData } = this.props;
    if (editData) {
      this.setState({ ...editData, erorr: {} });
    } else {
      this.Clear();
    }
  }

  onChangeState = (key, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  validateCheck = (data) => {
    if (data?.organization?.length > 0 && data?.title?.length > 0 && data?.type?.length > 0) {
      return false
    } else {
      let errors = {
        organization: data?.organization ? false : true,
        title: data?.title ? false : true,
        type: data?.type ? false : true
      }
      this.setState({ ...this.state, erorr: errors });
      return true
    }
  }

  saveAddress = () => {
    if (this.props?.saveAddress) {
      if (this.validateCheck(this.state)) {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill Acheivement/Award mandatory fields",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        })
        return false;
      }
      this.props.saveAddress(this.state);
    }

    // const { onclose, index, handleClear } = this.props;
    // let patientData = this.props[this.props.saveto];
    // if (index !== null) {
    //   patientData.awards[index] = this.state;
    // } else {
    //   patientData.awards.push(this.state);
    // }
    // patientData[this.props.setTo]({
    //   ...patientData,
    // });
    // onclose();
    // handleClear();
    // this.Clear();
  };
  Clear = () => {
    this.setState({
      organization: null,
      type: null,
      title: null,
      notes: null,
      role: null,
      date: null,
    });
  };

  setCustomContact = (name, value, index) => {
    if (name === "mode") {
      this.state.contact[index]["mobile"] = "";
    }
    this.state.contact[index][name] = value;
    this.setState({
      ...this.state,
    })
  }

  addNewContact = () => {
    this.state.contact.push({});
    this.setState({
      ...this.state,
    })
  }

  deleteContect = (index) => {
    let contact = this.state.contact.splice(0, index)
    this.setState({
      ...this.state,
      contact: contact
    })
  }

  handleClear = () => {
    this.Clear();
    if (this.props?.handleClear) {
      this.props.handleClear()
    }
  }


  setDataUpload = async (name, value, next, limit) => {
    var UploadedFiles = [];
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    UploadedFiles = await uploadImage([value]);
    this.onChangeState(name, UploadedFiles);
  };



  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "10px",
      marginBottom: "6px",
      fontFamily: `poppin !important`,
    },
    borderStyle: {
      borderRadius: "8px",
      borderColor: "#E0E0E0",
      width: "514px",
      height: "40px",
    },
    borderStyle2: {
      borderRadius: "8px",
      height: "40px",
      borderColor: "#E0E0E0",
      width: "180px",
    },
    root: {
      borderRadius: "8px !important",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
    bordererror: {
      borderRadius: 8,
      border: "1px solid red",
      width: "514px",
      height: "40px",
    }
  };

  render() {
    const { labelStyle, borderStyle, bordererror, borderStyle2, containedButton } =
      this.styles;
    const { parent_id } = this.props;
    return (
      <div
        id={`${parent_id}-parent-div`}
      >
        <Card
          id={`${parent_id}-parent-card`}
          style={{
            paddingBottom: "20px",
            paddingLeft: "20px",
            paddingTop: "15px",
            margin: 6
          }}
        >
          <div
            id={`${parent_id}-Awards-Details-title-div`}
          >Achievements/Awards Details</div>
          <div
            id={`${parent_id}-Awards-Details-div`}
            style={{ display: "flex" }}>
            <div
              id={`${parent_id}-Organization-div`}
            >
              <Typography
                id={`${parent_id}-Organization-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  margin: "8px 0px",
                }}
              >
                Organization <span
                  id={`${parent_id}-Organization-star-span`}
                  style={{ color: "red" }}>*</span>
              </Typography>
              <TextInput
                id={`${parent_id}-Organization-textinput`}
                isRequired={true}
                className="root"
                onChange={(e) =>
                  this.onChangeState("organization", e.target.value)
                }
                value={this.state.organization}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "514px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={this?.state?.erorr?.organization ? bordererror : borderStyle}
                placeholder={"Enter Organization"}
              />
            </div>
            <div
              id={`${parent_id}-Type-div`}
              style={{ marginLeft: "15px" }}>
              <Typography
                id={`${parent_id}-Type-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  margin: "8px 0PX",
                }}
              >
                Type <span
                  id={`${parent_id}-Type-star-span`}
                  style={{ color: "red" }}>*</span>
              </Typography>
              <TextInput
                id={`${parent_id}-Type-textinput`}
                isRequired={true}
                variant="outlined"
                onChange={(e) => this.onChangeState("type", e.target.value)}
                value={this.state.type}
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "514px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                // style={borderStyle}
                style={this?.state?.erorr?.type ? bordererror : borderStyle}
                placeholder={"Enter Type"}
              />
            </div>
          </div>
          <div
            id={`${parent_id}-Title-div`}
            style={{ display: "flex", marginTop: "16px" }}>
            <div
              id={`${parent_id}-Title-sub-div`}
            >
              <Typography
                id={`${parent_id}-Title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Title <span
                  id={`${parent_id}-Title-star-span`}
                  style={{ color: "red" }}>*</span>
              </Typography>
              <TextInput
                id={`${parent_id}-Title-textinput`}
                isRequired={true}
                //labelStyle={labelStyle}
                inLineStyles={borderStyle}
                variant="outlined"
                onChange={(e) => this.onChangeState("title", e.target.value)}
                value={this.state.title}
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "514px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                // style={borderStyle}
                style={this?.state?.erorr?.title ? bordererror : borderStyle}
                placeholder={"Enter Title"}
              />
            </div>
            <div
              id={`${parent_id}-Notes-div`}
              style={{ marginLeft: "15px" }}>
              <Typography
                id={`${parent_id}-Notes-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Notes
              </Typography>
              <TextInput
                id={`${parent_id}-Notes-textinput`}
                //labelStyle={labelStyle}
                inLineStyles={borderStyle}
                variant="outlined"
                onChange={(e) => this.onChangeState("notes", e.target.value)}
                value={this.state.notes}
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "514px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle}
                placeholder={"Enter Notes"}
              />
            </div>
          </div>
          <div
            id={`${parent_id}-Date-div`}
            style={{ display: "flex", marginTop: "16px" }}>
            <div
              id={`${parent_id}-Date-sub-div`}
            >
              <Typography
                id={`${parent_id}-Date-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Date
              </Typography>
              {/* <TextInput
                id={`${parent_id}-Date-textinput`}
                //labelStyle={labelStyle}
                inLineStyles={borderStyle2}
                variant="outlined"
                onChange={(e) => this.onChangeState("date", e.target.value)}
                value={this.state.date}
                fullWidth
                size="small"
                type="date"
                name="pageName"
                //style={{ width: "180px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle2}
              /> */}
              <CustomInputDatePicker
                disabled={false}
                placeholderText="dd / mm / yyyy"
                selectedDate={
                  this.state.date
                    ? new Date(this.state.date)
                    : null
                }
                // maxDate={new Date()}
                // minDate={new Date(this.state.start_date)}
                handleChange={(value) =>
                  this.onChangeState("date", value)
                }
                inputField={
                  <TextInput
                    id={`${parent_id}-Date-textinput`}
                    fullWidth
                    placeholder="dd / mm / yyyy"
                    variant="outlined"
                    required={true}
                    disabled={false}
                    type={"text"}
                    value={
                      this.state.date
                        ? moment(new Date(this.state.date)).format(
                          "DD/MM/YYYY"
                        )
                        : null
                    }
                    inputProps={{
                      style: {
                        padding: "10.5px 14px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        borderRadius: 8,
                        borderColor: "#e0e0e0",
                      },
                    }}
                  />
                }
              />
            </div>
            {this.state?.doc?.[0]?.filename ?
              <div
                id={`${parent_id}-Filenameui-div`}
                style={{ padding: "0px 16px" }}>
                <Filenameui
                  id={`${parent_id}-Filenameui`}
                  fileName={this.state?.doc?.[0]?.filename ?? " "}
                  delete={() => this.onChangeState("doc", null)}
                />
              </div>
              :
              <Col
                id={`${parent_id}-Upload-col`}
                md={3}
                lg={3}
                sm={6}
                xs={12}
                inLineStyles={{ padding: 10 }}
              >
                <div
                  id={`${parent_id}-Document-Upload-div`}
                  style={{ marginTop: "26px", marginLeft: "15px" }}>
                  {/* <UploadFiles /> */}
                  <Upload
                    id={`${parent_id}-Document-Upload`}
                    label="Upload Document"
                    className="pi-upload-btn"
                    handleChange={(e, value) => this.setDataUpload("doc", value)}
                    labelColor="#0071F2"
                    iconColor="#0071F2"
                    iconSize={14}
                    imgUrl="images/uploadBlue.svg"
                    labelStyle={{
                      fontSize: 14,
                      fontFamily: "pc_medium !important",
                    }}
                  />
                </div>
              </Col>}
          </div>
        </Card>


        <div
          id={`${parent_id}-buttons-div`}
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
          <Button
            id={`${parent_id}-Cancel-button`}
            variant="text"
            className={"topNav-actions cancelBtn"}
            onClick={() => this.handleClear()}
          >
            Cancel
          </Button>
          <Button
            id={`${parent_id}-Save-button`}
            onClick={() => this.saveAddress()}
            inLineStyles={containedButton}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default withAllContexts(AddNewAchievements);
