import React from "react";
import { CommonTable, OverlayCompt } from "../../../../../components";
import AlertRestrictionForm from "./form";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../../../../HOCs";
//import { AlertProps } from "../../../../../utils"
class AlertRestrictions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: null,
      tableDatas: [],
      openEditFrom: false,
      perPage : 10,
      page: 0
    };
    // this.saveForm = this.saveForm.bind(this)
    this.handleEdit = this.handleEdit.bind(this);
    // this.handleCheckBox = this.handleCheckBox.bind(this)
  }

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    })
    const data = await this.props.READ_ALERT({ page: (currentPage - 1) * PerPage, perPage: PerPage });
    var arr = [];
    const tableData = data?.payload?.data?.map(v => {
      return arr.push({
        ...v,
        service_availablity: v?.service_applicablity?.display ?? '',
        performing_organization: v?.orgn_code?.name ?? '',
        rule_types: v?.rule_type?.display ?? '',
        previlage_type: v?.privilege_type?.display ?? '',
        status: v?.status,

      })
    })
    this.setState({
      ...this.state,
      tableDatas: arr
    })
    
  }

  handleForm = async () => {
    let state = this.state;
    let { closeFrom } = this.props;
    const data = await this.props.READ_ALERT({page: this.state.page, perPage: this.state.perPage})
    var arr = [];
    const tableData = await data?.payload?.data?.map(v => {
      return arr.push({
        ...v,
        service_availablity: v?.service_applicablity?.display ?? '',
        performing_organization: v?.orgn_code?.name ?? '',
        rule_types: v?.rule_type?.display ?? '',
        previlage_type: v?.privilege_type?.display ?? '',
        status: v?.status,
      })
    })
    this.setState({
      ...state,
      openEditFrom: false,
      tableDatas: arr,
      editData: null
    })
    this.props.changeState("openIndex", 6)
    await closeFrom();
  };
  handleEdit = async (e, data, index) => {
    let { closeFrom } = this.props;
    this.props.iseditfuncy()
    this.props.changeState(
      "open", false
    )
    this.setState({
      editData: data,
      openEditFrom: true,
    });
    closeFrom();
  };

  async componentDidMount() {
    this.props?.currentTab("Approvals");
    const data = await this.props.READ_ALERT({page: this.state.page, perPage: this.state.perPage})
    var arr = [];
    const tableData = data?.payload?.data?.map(v => {
      return arr.push({
        ...v,
        service_availablity: v?.service_applicablity?.display ?? '',
        performing_organization: v?.orgn_code?.name ?? '',
        rule_types: v?.rule_type?.display ?? '',
        previlage_type: v?.privilege_type?.display ?? '',
        status: v?.status,

      })
    })
    this.setState({
      ...this.state,
      tableDatas: arr
    })
  }
  handleCheckBox = async (e, values, index) => {
    await this.props.UPSERT_ALERT_STATUS({ list: e.target.checked, _key: values?._key });
    this.props.alert.setSnack("update")
    const data = await this.props.READ_ALERT({page: this.state.page, perPage: this.state.perPage})
    var arr = [];
    const tableData = data?.payload?.data?.map(v => {
      return arr.push({
        ...v,
        service_availablity: v?.service_applicablity?.display ?? '',
        performing_organization: v?.orgn_code?.name ?? '',
        rule_types: v?.rule_type?.display ?? '',
        previlage_type: v?.privilege_type?.display ?? '',
        status: v?.status,

      })
    })
    this.setState({
      ...this.state,
      tableDatas: arr
    })
  }

  render() {
    const { open } = this.props;
    const { editData, 
      //openIndex, 
      tableDatas, 
      //parent_id 
    } = this.state;
    return (
      <React.Fragment>
        <CommonTable
          style={{ height: "calc(100vh - 245px)" }}
          parent_id={"AlertRestrictions"}
          handleEdit={this.handleEdit}
          handleCheckBox={this.handleCheckBox}
          Header={[
            "S.No",
            "Performing Organization",
            "Service Availability",
            "Rule Type",
            "Previlage Type",
            "Status",
            "Action",
          ]}
          dataList={tableDatas || []}
          tableData={[
            { type: ["INCRIMENT"], name: "" },
            { type: ["TEXT"], name: "performing_organization" },
            { type: ["TEXT"], name: "service_availablity" },
            { type: ["TEXT"], name: "rule_types" },
            { type: ["TEXT"], name: "previlage_type" },
            { type: ["CHECKBOX"], name: "status" },
            { type: ["EDIT"], name: "" },
          ]}
          handlepageChange={(currentPage, PerPage) =>
            this.handlepageChange(currentPage, PerPage)
          }
          TableCount={ tableDatas?.[0]?.TotalCount}
          incrementCount={this.state.page}
          showPagination={true}
          rowsPerPageOptions={[
            { label: "10 Rows", value: 10 },
            { label: "50 Rows", value: 50 },
            { label: "100 Rows", value: 100 },
          ]}
        />
        <OverlayCompt
          parent_id={"AlertRestrictions"}
          open={open}
          children={
            <React.Fragment>
              {open && (
                <AlertRestrictionForm
                  parent_id={"AlertRestrictions"}
                  editData={editData}
                  closeForm={this.handleForm}
                  isedit={this.state.openEditFrom}
                />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(withAllContexts(AlertRestrictions))