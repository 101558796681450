import React from "react";
import { Card, CircularProgress } from "@material-ui/core";
import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { 
  //GenerateForm,
   CommonTable } from "../../components/common";
import { useHistory } from "react-router";
import { routes } from "../../router/routes";
import { withAllContexts } from "../../HOCs";

function SpecialityTable(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  // const listData = [
  //   {
  //     code: "HL032",
  //     description: "Allied Health",
  //     children:[
  //       {
  //         code:'HL032.1',
  //         description: "Allied Health.1",
  //       },
  //       {
  //         code:'HL032.2',
  //         description: "Allied Health.2",
  //       },
  //     ]
  //   },
  //   {
  //     code: "HL034",
  //     description: "Clinical Neuropsychologist",
  //     children:[
  //       {
  //         code: "HL034.1",
  //         description: "Clinical Neuropsychologist.1",
  //       }
  //     ]
  //   },
  //   {
  //     code: "HL035",
  //     description: "Smallville Hospital"
  //   }
  // ]
  // const storeData_speciality_read = useSelector((state) => state?.organizationConfigSlice.speciality_read); 
  // const storeData_speciality_child_read = useSelector((state) => state?.organizationConfigSlice.speciality_child_read); 
  const loading = useSelector(
    (state) => state?.organizationConfigActions?.speciality_read?.loading
  );

  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
    specialityData : []
  });

  const handleDrawerOpen = () => {
    history.push({
      pathname: routes.addspeciality
    });
  };

  React.useLayoutEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Loading...",
    });
    let data_ = await dispatch(actions.SPECIALITY_READ());
    
    setState({
      ...state,
      specialityData : data_?.payload?.data
    });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  }

  const handleEdit = (e, values, index,parentIndex) => {
    history.push({
      pathname: routes.addspeciality,
      state: {
        _key: values._key,
      },
    });
  };

  const collapseCondition = async (value, type, index, parentIndex) => {
    
    if (type === "INCRIMENT") {
        return false
    }
    else {
        return true
    }
  }

  const collapseToggle = async (data, index) => {
    
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Loading...",
    });
    let data_ = await dispatch(actions.SPECIALITY_CHILD_READ({id : data._id}));
    let obj = {
          id: data.id ?? "",
          _id: data._id ?? "",
          _key: data._key ?? "",
          specialtyCode: data.specialtyCode ?? "",
          description: data.description ?? "",
          status: data.active  ?? true,
          children : data_?.payload?.data ?? []
    }
    const state_ = JSON.parse(JSON.stringify(state));
    state_.specialityData[index] = obj;
    setState({ ...state, ...state_ });
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    
  }

  return (
    <Card style={{ borderRadius: "12px" }}>
      <div style={{ width: "100%" }}>
        <Card style={{ borderRadius: "12px" }}>
          <div
            style={{
              borderRadius: "6px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ marginLeft: "18px" }}>
                <p
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              <div style={{ marginTop: "16px", width: "10%", float: "right" }}>
                <button
                  style={{
                    backgroundColor: "#0071F2",
                    color: "white",
                    border: "none",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                    fontFamily: "poppin",
                    fontSize: "12px",
                  }}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </button>
              </div>
            </div>

            {/* table */}
            <div style={{ padding: 20, height: "71vh", overflow: "auto" }}>
              {loading && state.edit_data === null ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "66vh",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <CommonTable
                  Header={[
                    "S.No",
                    "Code",
                    "Description",
                    "Action",
                  ]}
                  dataList={state.specialityData}
                  rightAlign={["Status", "Action"]}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "specialtyCode" },
                    { type: ["TEXT"], name: "description" },
                    { type: ["EDIT","COLLAPSE"], name: "", align: "right" },
                  ]}
                  collapseCondition={collapseCondition}
                  collapseToggle= {collapseToggle}
                  handleEdit={handleEdit}
                  parent_id="speciality_table"
                />
              )}
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
}

export default withAllContexts(SpecialityTable)
