import React from "react";
import { CommonTable } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";

function DrugType(props) {
  const dispatch = useDispatch();

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const drugTypelist = useSelector(
    (state) => state?.drugCategorySlice?.drug_type_list?.data
  );

  React.useLayoutEffect(() => {
    dispatch(actions.DRUG_TYPE_READ({ page: page, perPage: perPage}));
    props?.currentTab("drugType");
  }, []);

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(
      actions.DRUG_TYPE_READ({
        page: (currentPage - 1) * PerPage,
        perPage: PerPage,
      })
    );
  };

  // handleCloseForm = () => {
  //     const { changeState } = this.props
  //     changeState("open", false)
  //     this.setState({
  //         openEditFrom: false,
  //         // edit_data: null
  //     })
  // }
  // render() {
  //     const { open, openIndex } = this.props;
  return (
    <React.Fragment>
      <CommonTable
        style={{ height: "calc(100vh - 245px)" }}
        parent_id={"drugType"}
        // handleEdit={this.handleEdit}
        Header={["S.No", "Drug Type", "Short Description", "Long Description"]}
        dataList={drugTypelist}
        tableData={[
          { type: ["INCRIMENT"], name: "" },
          { type: ["TEXT"], name: "drug_type" },
          { type: ["TEXT"], name: "short_description" },
          { type: ["TEXT"], name: "long_description" },
        ]}
        handlepageChange={(currentPage, PerPage) =>
          handlepageChange(currentPage, PerPage)
        }
        TableCount={drugTypelist?.[0]?.total_count}
        incrementCount={page}
        showPagination={true}
        rowsPerPageOptions={[
          { label: "10 Rows", value: 10 },
          { label: "50 Rows", value: 50 },
          { label: "100 Rows", value: 100 },
        ]}
      />
    </React.Fragment>
  );
}

export default DrugType;
