import React from "react";
import {
  Div,
  Paper,
  //Select,
  TextInput,
  //Upload,
  H6,
  Col,
  Row,
  // Text,
  // Icons,
  // Image,
  // Avatar,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
// import { uploadImage } from "../../../utils";
// import Delete from "../../../assets/icons8-trash.svg";
// import { DateTimePicker } from "../../common/smartForm/component";
// import moment from "moment";
import { SelectBox } from "../../common/smartForm/component";
import { Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox } from "@material-ui/core";

//const optiondata = [{ value: "data" }];
class SpecialityDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
    },
  };
  
  render() {
    const { labelStyle, borderStyle } = this.styles;

    return (
      <Div className="pi-root">
        <Paper className="pi-paper">
          <Div>
            <H6 className="pi-title">
              {"SPECIALTY DETAILS"}
            </H6>
          </Div>
              <Row>
                <Col
                  md={3}
                  lg={3}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <TextInput
                    value={this.props.specialityCode ? this.props.specialityCode :""}
                    onChange={(e) =>
                      this.props.onInputChange("specialityCode", e.target.value)
                    }
                    label="Specialty Code"
                    isRequired={true}
                    placeholder="Specialty code"
                    labelStyle={labelStyle}
                    style={borderStyle}
                    hoverColor="#0071F2"
                  />
                </Col>
                <Col
                  md={3}
                  lg={3}
                  sm={6}
                  xs={12}
                  inLineStyles={{ padding: 10 }}
                >
                  <TextInput
                    value={this.props.specialityDescription ? this.props.specialityDescription :""}
                    onChange={(e) =>
                      this.props.onInputChange("specialityDescription", e.target.value)
                    }
                    label="SerSpecialtyvice Description"
                    isRequired={true}
                    placeholder="Description"
                    labelStyle={labelStyle}
                    style={borderStyle}
                    hoverColor="#0071F2"
                  />
                </Col>
                <Col
                  md={3}
                  lg={3}
                  sm={6}
                  xs={12}
                  className="no-padding-left"
                  inLineStyles={{ padding: 10, color: "#101010" }}
                >
                  <Typography
                    style={{
                      fontSize: "12px",

                      Fontfamily: "pc_regular",
                      color: "#6F6F6F",
                    }}
                    variant="body1"
                  >
                    Parent Specialty
                  </Typography>
                  <SelectBox
                    list={this.props?.parentSpecialityMasterData ?? []} 
                    placeholder={"select..."}
                    disableClearable
                    value={this.props.parentSpeciality ? this.props.parentSpeciality : {}}
                    onchange={(e, value) => this.props.onSelectChange("parentSpeciality", value)}
                  />
                </Col>
                <Col
                  md={3}
                  lg={3}
                  sm={6}
                  xs={12}
                  className="no-padding-left"
                  inLineStyles={{ padding: 10, color: "#101010" }}
                >
                  <H6 className="default-size pc_regular">
                      Last Level
                    </H6>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color={"primary"}
                          checked={this.props.lastLevel}
                          onChange={(e) =>
                            this.props.onChangeState(
                              "lastLevel",
                              e.target.checked
                            )
                          }
                          name="lastLevel"
                        />
                      }
                    />
                </Col>
                </Row> 
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(SpecialityDetail);
