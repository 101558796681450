import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from "@material-ui/core/colors";
// import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Typography, Grid, Paper, IconButton } from "@material-ui/core";
import styles from "./styles";
import "../font.css";
// import { withHoverDrawer } from "../../HOCs";

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
//   font: {
//     fontSize: "14px",
//     marginLeft: "10px",
//   },
//   grayfont: {
//     fontSize: "12px",
//     color: "gray",
//     fontfamily: "poppin",
//   },
//   checkbox: {
//     "& .MuiTypography-body1": {
//       fontSize: "12px",
//       fontfamily: "Poppinsemibold !important",
//     },
//   },
//   root: {
//     "& .MuiOutlinedInput-root": {
//       borderRadius: "8px",
//       height: "40px",
//     },
//   },
//   cbox: {
//     "& .Component-root-107": {
//       "& .Component-checked-108": {
//         color: "#0071F2",
//       },
//     },
//   },
// }));
const GreenCheckbox = withStyles({
  root: {
    color: "#0071F2",
    "&$checked": {
      color: "#0071F2",
    },
    "& .MuiTypography-body1 ": {
      fontSize: "12px !important",
      fontFamily: "poppinsemibold !important",
      color: "gray",
    },
    "&..MuiCheckbox-root": {
      color: "#0071F2",
    },
  },
  cbox: {
    "&$checked": {
      color: "#3D70B2",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default function AddHoliday(props) {
  // const classes = useStyles();
  // const theme = useTheme();
  const classes = styles();
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [state, setState] = React.useState({
    checkedG: true,
  });
  // const [open, setOpen] = React.useState(false);

  const handleChangebox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    // <List>
    //   <div className={classes.drawerHeader}></div>
    //   <div>
    //     <p className={classes.font}>Add New Holiday</p>
    //   </div>
    //   <hr style={{ borderTop: "1px solid lightgray", marginLeft: "5px" }} />
    //   <form
    //     className={classes.root}
    //     noValidate
    //     autoComplete="off"
    //     style={{ marginLeft: "20px" }}
    //   >
    //     <div style={{ marginTop: "20px" }}>
    //       <label for="reason" className={classes.grayfont}>
    //         Reason for Holiday
    //       </label>
    //       <br />
    //       <div style={{ width: "300px" }}>
    //         <TextField
    //           id="reason"
    //           variant="outlined"
    //           inputProps={{
    //             style: { fontSize: 12, fontFamily: "poppin" },
    //           }}
    //           style={{
    //             width: "300px",
    //             fontfamily: "poppin",
    //             fontSize: "12px",
    //             height: "40px",
    //           }}
    //           size="small"
    //         />
    //       </div>
    //     </div>
    //     <br />
    //     <div style={{ width: "300px" }}>
    //       <FormControl
    //         variant="outlined"
    //         className={classes.formControl}
    //         style={{
    //           margin: "0px",
    //           width: "300px",
    //         }}
    //         size="small"
    //       >
    //         <label id="type" className={classes.grayfont}>
    //           Holiday type
    //         </label>
    //         <Select
    //           labelId="demo-simple-select-outlined-label"
    //           id="type"
    //
    //           value={age}
    //           style={{ fontfamily: "poppin", fontSize: "12px" }}
    //           onChange={handleChange}
    //         >
    //           <MenuItem value="">
    //             <em>None</em>
    //           </MenuItem>
    //           <MenuItem value={"Public"}>Public</MenuItem>
    //           <MenuItem value={"Local"}>Local</MenuItem>
    //         </Select>
    //       </FormControl>{" "}
    //     </div>
    //     <br />
    //     <div style={{ marginTop: "0px" }}>
    //       <label for="date" className={classes.grayfont}>
    //         Date
    //       </label>
    //       <br />
    //       <div style={{ width: "300px" }}>
    //         <TextField
    //           id="date"
    //           type="date"
    //           variant="outlined"
    //           style={{ width: "300px", height: "40px" }}
    //           size="small"
    //         />
    //       </div>
    //     </div>
    //     <br />
    //     <FormControlLabel
    //       className={classes.checkbox}
    //       // style={{ fontFamily: "poppinsemibold", fontSize: "12px" }}
    //       label="Repeat every year"
    //       InputLabelProps={{
    //         style: { fontSize: 12, fontFamily: "poppin" },
    //       }}
    //       control={
    //         <GreenCheckbox
    //           className={classes.cbox}
    //           checked={state.checkedG}
    //           onChange={handleChangebox}
    //           name="checkedG"
    //         />
    //       }
    //     />
    //     <br />
    //     <Button
    //       variant="contained"
    //       style={{
    //         marginLeft: "80px",
    //         height: "40px",
    //         paddingLeft: "20px",
    //         paddingRight: "20px",
    //         width: "124px",
    //         marginTop: "20px",
    //         backgroundColor: "#0071F2",
    //         color: "white",
    //       }}
    //     >
    //       Save
    //     </Button>
    //   </form>
    // </List>

    <div>
      <div className={classes.drawerHead}>
        <Typography className={classes.headerMain}>
          {props.editName ? "Edit Page" : "Add new page"}
        </Typography>
      </div>

      <Grid container direction="row">
        <Grid item xs={12}>
          <div className={classes.textTotalArea}>
            <Typography className={classes.drawerSubHead}>
              Reason For Holiday
            </Typography>
            <TextField
              label={"Page"}
              variant="outlined"
              fullWidth
              size="small"
              name="pageName"
              required
              inputProps={{ style: { fontFamily: "poppin", fontSize: "12px" } }}
              InputLabelProps={{
                style: { fontFamily: "poppin", fontSize: "12px" },
              }}
            />
            <div style={{ paddingTop: "20px" }}>
              <Typography className={classes.drawerSubHead}>
                Holiday Type
              </Typography>
              <Select
                variant="outlined"
                labelId="demo-simple-select-outlined-label"
                id="type"
                value={age}
                style={{
                  fontfamily: "poppin",
                  fontSize: "12px",
                  width: "299px",
                  height: "36px",
                }}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"Public"}>Public</MenuItem>
                <MenuItem value={"Local"}>Local</MenuItem>
              </Select>
            </div>
            <div style={{ paddingTop: "20px" }}>
              <Typography className={classes.drawerSubHead}>Date</Typography>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                type="date"
                name="route"
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
              />
            </div>
          </div>
          <div className={classes.btnPosition}>
            <Button contained className={classes.addDrawerBtn}>
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
