import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { CircularProgress } from "@material-ui/core";
import { CommonTable } from "../../components";
import { GenerateForm, SearchWithFilter } from "../../components";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { AlertProps } from "../../utils";
import { withAllContexts } from "../../HOCs";

const drawerWidth = 360;
const useStyles = makeStyles((theme) => ({
  first: {
    "& .MuiBackdrop-root": {
      backgroundColor: "gray",
    },
  },
  table: {
    minWidth: 650,
    marginLeft: "18px",
    width: "97%",
    border: "1px solid lightgrey",
    borderRadius: "2px",
  },
  bgcolor: {
    backgroundColor: "#F9F9F9",
    paddingTop: "10px",
    fontFamily: "poppin",
    color: "gray",
    fontSize: "12px",
  },
  font: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  boxsize: {
    "& .MuiOutlinedInput-inputMarginDense": {
      height: "10px !important",
    },
  },
  root: {
    "& .MuiTableCell-sizeSmall": {
      paddingBottom: "0px",
      paddingTop: "0px",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiBackdrop-root": {
      backgroundColor: "gray",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    "& .MuiBackdrop-root": {
      backgroundColor: "gray",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

function AppointmentTypeTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { parent_id } = props;

  const listData = useSelector((state) => state?.availabilityManagementSlice?.appointment_type_list?.data);
  const loading = useSelector((state) => state?.availabilityManagementSlice?.appointment_type_list?.loading);
  const saveloading = useSelector((state) => state?.availabilityManagementSlice?.codeable_concept_master_upsert?.loading);

  const [state, setState] = React.useState({
    open: false,
    edit_data: null
  });

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(actions.APPOINTMENT_TYPE_READ({ page: (currentPage - 1) * PerPage, perPage: PerPage }));

  };

  const handleDrawerOpen = () => {
    setState({
      ...state,
      open: true
    });
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      edit_data: null
    });
  };

  React.useLayoutEffect(() => {
    dispatch(actions.APPOINTMENT_TYPE_READ({page: page , perPage: perPage}));
  }, []);

  const saveFunction = async (values) => {
    let type = "success";
    let data = {
      code: values.code,
      description: values.type_description,
      type: "APPTYPE",
      status: true,
    }
    if (state?.edit_data) {
      data["_key"] = state?.edit_data._key
      data["id"] = state?.edit_data.id
      data["status"] = state?.edit_data.status
      data["coding_key"] = state?.edit_data?.coding_key??null
      type = "update";
    }
    await dispatch(actions.CODEABLE_CONCEPT_MASTER_UPSERT(data));
    await dispatch(actions.APPOINTMENT_TYPE_READ({page: page , perPage: perPage}));
    await handleDrawerClose()
    props.alert.setSnack(type);
  }

  const handleEdit = (e, values, index) => {
    let data = {
      code: values.code,
      description: values.description,
      type: props.type,
      status: values.status,
      _key: values._key,
      id: values.id,
      coding_key: values?.coding_key??null
    }
    setState({
      ...state,
      edit_data: data,
      open: true
    })
  }

  const statusChange = async (e, values, index) => {
    let data = {
      code: values.code,
      description: values.description,
      type: props.type,
      status: e.target.checked,
      _key: values._key,
      id: values.id
    }
    setState({
      ...state,
      edit_data: data,
    })
    await dispatch(actions.CODEABLE_CONCEPT_MASTER_STATUS_CHANGE(data));
    await dispatch(actions.APPOINTMENT_TYPE_READ({page: page , perPage: perPage}));
    setState({
      ...state,
      edit_data: null,
    })
    props.alert.setSnack("update")
  }


  const onSearchChange = (value = "") => {
    if (value?.length > 1) {
      dispatch(actions.APPOINTMENT_TYPE_READ({
        search: value.trim(),
        page: page,
        perPage: perPage
      }));
    } else if (!value) {
      dispatch(actions.APPOINTMENT_TYPE_READ({
        page: page,
        perPage: perPage
      }));
    }
  };

  return (
    <Card
      id={`${parent_id}-parent-card`}
      style={{ borderRadius: "12px" }} className={classes.first}>
      <div
        id={`${parent_id}-parent-div`}
        style={{
          borderRadius: "6px",
        }}
      >
        <div
          id={`${parent_id}-Appointment-div`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            id={`${parent_id}-AppointmentTypes-title-div`}
            style={{ marginLeft: "18px", marginTop: "12px " }}>
            <p
              id={`${parent_id}-AppointmentTypes-title-p`}
              style={{ fontFamily: "poppinsemibold" }}>Appointment Types</p>
          </div>
          <div
            id={`${parent_id}-AppointmentTypes-button-div`}
            style={{ marginTop: "16px", marginRight: "16px", display:'flex' }}
            className={classes.boxsize}
          >
            <SearchWithFilter
                  id="appointment"
                  placeholder="Search code and Description!"
                  //  loading={data?.length > 0 ? loading : false}
                  onSearchChange={onSearchChange}
                  hideSearchBar={false}
                  hideFilter={true}
                //  onFilterClicked={onFilterClicked}
                //  isFilterApplied={props?.isFilterApplied}
                />
            <button
              id={`${parent_id}-AppointmentTypes-Add-button`}
              onClick={handleDrawerOpen}
              style={{
                backgroundColor: "#0071F2",
                color: "white",
                border: "none",
                paddingTop: "5px",
                paddingBottom: "5px",
                borderRadius: "5px",
                fontFamily: "poppin",
                fontSize: "12px",
                width: "108px",
                height: "32px",
              }}
            >
              +Add New
            </button>

            <GenerateForm
              parent_id={'appointmenttype'}
              nocancel
              loading={saveloading}
              open={state.open}
              save={saveFunction}
              handlecancel={handleDrawerClose}
              header={state.edit_data ? "Edit From" : "Add Appointment type"}
              json={[
                {
                  componet_type: "text",
                  label: "Appointment type code",
                  state_name: "code",
                  value: state?.edit_data?.code ?? "",
                  required: true,
                  isUpperCase: true,
                  disabled: state.edit_data ? true : false
                },
                {
                  componet_type: "text",
                  label: "Appointment type description",
                  state_name: "type_description",
                  value: state?.edit_data?.description ?? "",
                  required: true,
                }
              ]}
            />
          </div>
        </div>
        <div
          id={`${parent_id}-CircularProgress-div`}
          style={{ padding: 20, height: "71vh", overflow: "auto" }}>
          {/* {(loading && state.edit_data === null) ?
            <div
              id={`${parent_id}-CircularProgress-sub-div`}
              style={{ display: 'flex', justifyContent: "center", alignItems: "center", minHeight: "66vh" }}>
              <CircularProgress
                id={`${parent_id}-CircularProgress`}
              />
            </div>
            : */}
            <CommonTable
              parent_id={'appointmenttype'}
              Header={["S.No", "Code", "Description", "Status", "Action"]}
              dataList={listData}
              tableData={[
                { type: ["INCRIMENT"], name: "" },
                { type: ["TEXT"], name: "code" },
                { type: ["TEXT"], name: "description" },
                { type: ["CHECKBOX"], name: "status" },
                { type: ["EDIT"], name: "action" },
              ]}
              handleCheckBox={statusChange}
              handleEdit={handleEdit}
              handlepageChange={(currentPage, PerPage) =>
                handlepageChange(currentPage, PerPage)
              }
              TableCount={listData?.[0]?.total_count}
              incrementCount={page}
              showPagination={true}
              rowsPerPageOptions={[
                { label: "10 Rows", value: 10 },
                { label: "50 Rows", value: 50 },
                { label: "100 Rows", value: 100 },
              ]}
            />
            {/* } */}
        </div>
      </div>
    </Card>
  );
}
export default withAllContexts(AppointmentTypeTable)