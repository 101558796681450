import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import ResourceCard from "./resourcecard";
import "../font.css";
import { withAllContexts, withHoverDrawer } from "../../HOCs";
import backarrow from "../../assets/icons8-expand-arrow.svg";
import { useHistory } from "react-router";
import { routes } from "../../router/routes";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import GeneralAppointmentCard from "./generalappointmentcard";
import SpecialAppointmentCard from "./specialappointmentcard";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
//import Snackbar from "@material-ui/core/Snackbar";
//import MuiAlert from "@material-ui/lab/Alert";
import { valitationFunc } from "../../utils";


const useStyles = makeStyles((theme) => ({
  req_red: {
    color: "red",
  },
}));

const errorlist = ["entityType", "ApptTypeCode", "organization_id", "generalInstruction"];
const CreateAppoointment = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const personMasterList = useSelector((state) => state?.personSlice);
  const classes = useStyles();
  // function Alert(props) {
  //   return <MuiAlert elevation={6} variant="filled" {...props} />;
  // }
  //const [Alertstate, setAlertState] = React.useState(false);
  const [state, setState] = useState({
    key: "",
    status: "true",
    organization_id: {},
    ApptTypeCode: {},
    entityType: {},
    HealthcareServiceCriteria: "",
    PatientInclusionCriteria: "",
    // dataArr
    ApptInstructions: [],
    TotNoOfResourcesRequired: [],
    generalInstruction: [],
    // EditArr
    ApptInstructionsEditArr: [],
    TotNoOfResourcesRequiredEditArr: [],
    generalInstructionEditArr: [],
    // editIndex
    editIndex: null,
    error:{}
  });
  const backfun = () => {
    history.push({pathname: routes.availabilitymanagement, state:{index: 2}});
  };
  const resourceAppointment = useSelector(
    (state) => state.resourceByAppointmentTypesSlice
  );
  React.useLayoutEffect((props) => {
    const getdata = async () => {
      await dispatch(actions.RESOURCE_BY_APPOINTMRNT_TYPES());
      await dispatch(actions.RESOURCE_BY_ENTITY_NAME_TYPES());
      await dispatch(actions.RESOURCE_BY_ENTITY_TYPE());
      if (history?.location?.state?.key) {
        await dispatch(
          actions.RES_LINE_LIST_READ_DOC({
            key: history?.location?.state?.key,
          })
        );
      }
    };
    getdata();
  }, []);

  React.useEffect(
    (props) => {
      if (history?.location?.state?.key) {
        let getState = resourceAppointment?.res_line_list_read_doc?.data;
        getState = JSON.stringify(getState);
        getState = JSON.parse(getState);
        setState({
          ...state,
          organization_id: getState?.organization_id
            ? getState?.organization_id
            : "",
          status: getState?.status ? getState?.status : "",
          ApptTypeCode: getState?.ApptTypeCode ? getState?.ApptTypeCode : "",
          entityType: getState?.entityType ? getState?.entityType : "",
          key: getState?._key ? getState?._key : "",
          TotNoOfResourcesRequired:
            getState?.TotNoOfResourcesRequired?.length > 0
              ? getState?.TotNoOfResourcesRequired
              : [],
          generalInstruction:
            getState?.generalInstruction?.length > 0
              ? getState?.generalInstruction
              : [],
          ApptInstructions:
            getState?.ApptInstructions?.length > 0
              ? getState?.ApptInstructions
              : [],
        });
      }
    },
    [resourceAppointment?.res_line_list_read_doc?.data]
  );

  const onChangeAll = async (e, val, types) => {
   
    if (state.error[types]) {
      state.error[types]=false
    }
    if(types === "entityType"){
      let entityNameData = await dispatch(actions.ENTITYNAMEMASTER(val?.title?.toLocaleLowerCase()));
    }
    if (
      types === "TotNoOfResourcesRequired" ||
      types === "generalInstruction" ||
      types === "ApptInstructions"
    ) {
      if (state.editIndex || state.editIndex === 0) {
        state[types][state.editIndex] = val;
        state.editIndex = null;
        state[types + "EditArr"] = [];
      } else {
        state[types].push(val);
      }
      setState({ ...state });
    } else {
      setState({ ...state, [types]: val });
    }
  };

  const deleteFun = (types, index) => {
    let filtered = state[types].filter(function (value, i, arr) {
      return i !== index;
    });
    state[types] = filtered;
    setState({ ...state });
  };

  const editFun = (types, index) => {
    let filtered = state[types].filter(function (value, i, arr) {
      return i === index;
    });
    state[types + "EditArr"] = filtered;
    state.editIndex = index;
    setState({ ...state });
  };
  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setAlertState(false);
  // };
  const saveFuntion = async () => {
    let { error, errorState } = await valitationFunc(state, errorlist);
    if (!error) {
      await dispatch(
        actions.RES_UPSERT_INSER_NEW({
          state,
          key: history?.location?.state?.key
            ? history?.location?.state?.key
            : "",
        })
      );
       props.alert.setSnack("success");
      backfun();
    } else {
      // setAlertState(true);
       props.alert.setSnack("mandatory");
       setState({
         ...state,
         error: errorState,
       });
    }
  };
  return (
    <div>
      {/* <Snackbar
        open={Alertstate}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        <Alert severity="error">Fill all Mandatory Fields</Alert>
      </Snackbar> */}
      <div style={{ backgroundColor: "#F6F6F6" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "white",
            padding: "8px 16px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={backfun}
              style={{
                backgroundColor: "#DCDCDC",
                width: 28,
                height: 28,
                marginRight: 12,
              }}
            >
              <img
                src={backarrow}
                alt="icon"
                style={{ transform: "rotate(90deg)" }}
              />
            </IconButton>
            <p> {history?.location?.state?.key ? "Edit Appointment type" : "Create New Appointment type"}</p>
          </div>
        </div>
        <hr style={{ marginTop: "0px" }} />
      </div>
      <Card
        style={{
          padding: "16px",
          width: "95%",
          marginLeft: "20px",
          marginTop: "16px",
          borderRadius: 6,
        }}
      >
        <div style={{ marginBottom: 12 }}>
          <span
            style={{
              color: "#101010",
              fontSize: 12,
              fontFamily: "poppinsemibold",
            }}
          >
            ORGANIZATION
          </span>
        </div>
        <div style={{ display: "flex" }}>

          <div style={{ marginRight: "2%" }}>
            <span style={{ color: "#6F6F6F", fontSize: 12 }}>
              Entity Type <span className={classes.req_red}>*</span>
            </span>
            <Autocomplete
              id="combo-box-demo"
              options={
                resourceAppointment?.resource_enitity_type?.data
                  ? resourceAppointment?.resource_enitity_type?.data
                  : null
              }
              getOptionLabel={(option) => option.title}
              style={{ width: 400, marginTop: "1%" }}
              size={"small"}
              renderInput={(params) => (
                <TextField {...params} error={state?.error?.entityType} variant="outlined" />
              )}
              onChange={(e, values) => onChangeAll(e, values, "entityType")}
              value={state.entityType}
            />
          </div>
          <div>
            <span style={{ color: "#6F6F6F", fontSize: 12 }}>
              Entity Name <span className={classes.req_red}>*</span>
            </span>
            <Autocomplete
              id="combo-box-demo"
              // options={
              //   resourceAppointment?.resource_enitity_name?.data
              //     ? resourceAppointment.resource_enitity_name.data
              //     : []
              // }
              options={personMasterList?.entity_name_master?.data ?? []}
              getOptionLabel={(option) => option.label}
              style={{ width: 600, marginTop: "1%" }}
              size={"small"}
              renderInput={(params) => (
                <TextField {...params} error={state?.error?.organization_id}  variant="outlined" />
              )}
              onChange={(e, values) =>
                onChangeAll(e, values, "organization_id")
              }
              value={state.organization_id}
            />
          </div>
        </div>
      </Card>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "1%",
          marginLeft: "2%",
        }}
      >
        <p style={{ color: "#6F6F6F", fontSize: 12 }}>
          APPOINTMENT TYPE <span className={classes.req_red}> *</span>
        </p>
        <Autocomplete
          id="combo-box-demo"
          options={
            resourceAppointment?.resource_appointment_type?.data
              ? resourceAppointment.resource_appointment_type.data
              : []
          }
          getOptionLabel={(option) => option.title}
          style={{ width: 300, marginLeft: "1%", backgroundColor: "white" }}
          size={"small"}
          renderInput={(params) => <TextField {...params} error={state?.error?.ApptTypeCode} variant="outlined" />}
          onChange={(e, values) => onChangeAll(e, values, "ApptTypeCode")}
          value={state.ApptTypeCode}
        />
      </div>
      <Card
        style={{
          padding: "16px",
          width: "95%",
          marginLeft: "20px",
          marginTop: "16px",
          borderRadius: 6,
        }}
      >
        <p style={{ fontFamily: "poppinsemibold" }}>
          SETUP RESOURCE REQUIRED BY APPOINTMENT TYPE
        </p>
        <Card style={{ padding: 16, paddingTop: 0 }} elevation="2">
          <p style={{ fontFamily: "poppinsemibold" }}>
            HEALTHCARE SERVICE CRITERIA
          </p>
          <Button variant="outlined" color="secondary">
            + Add New Rule
          </Button>
        </Card>
        <Card
          style={{ padding: 16, paddingTop: 0, marginTop: "1%" }}
          elevation="2"
        >
          <p style={{ fontFamily: "poppinsemibold" }}>
            PATIENT INCLUSION CRITERIA
          </p>
          <Button variant="outlined" color="secondary">
            + Add New Rule
          </Button>
        </Card>
      </Card>
      <div style={{ marginTop: "16px" }}>
        <ResourceCard
          data={state.TotNoOfResourcesRequired}
          editData={state.TotNoOfResourcesRequiredEditArr}
          onChangeAll={onChangeAll}
          deleteFun={deleteFun}
          editFun={editFun}
        />
      </div>
      <GeneralAppointmentCard
        data={state.generalInstruction}
        onChangeAll={onChangeAll}
        deleteFun={deleteFun}
        editFun={editFun}
        editData={state.generalInstructionEditArr}
        required={true}
      />
      <SpecialAppointmentCard
        data={state.ApptInstructions}
        onChangeAll={onChangeAll}
        deleteFun={deleteFun}
        editFun={editFun}
        editData={state.ApptInstructionsEditArr}
      />
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          float: "right",
          marginRight: "4%",
          paddingBottom: "30px",
        }}
      >
        <Button
          // variant="contained"
          variant="outlined"
          size="medium"
          style={{ marginRight: "14px" }}
          onClick={() => backfun()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => saveFuntion()}
        >
          {/* {history?.location?.state?.key ? "Edit" : "Save"} */}
          Save
        </Button>
      </div>
    </div>
  );
};
export default withHoverDrawer(withAllContexts(CreateAppoointment));
