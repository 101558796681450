import React from "react";
import {
  Div,
  Paper,
  Select,
  H6,
  Col,
  Row,
  // TextInput,
  // Text,
  // Button,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
// import deleteicon from "../../../assets/icons8-trash.svg";
// import { actions } from "primary_care_admin_binder";
// import { connect } from "react-redux";
import { InputComp } from "../../../components/common/smartForm/component";
class ContactDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount = async () => {
  //   await this.props?.LOCATION_MASTER_CONTACTMODE();
  //   await this.props?.LOCATION_MASTER_ISD();
  //   await this.props?.LOCATION_MASTER_USE();
  //   await this.props?.LOCATION_MASTER_PRIORITY();
  // };

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    borderStyle1: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      width: "98px",
    },
    borderStyle2: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      width: "98px",
      marginLeft: "-65px",
    },
    labelStyle2: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
      marginLeft: "-65px",
    },
  };

  render() {
    //let { contact } = this.props[this.props.saveto];
    const {
      labelStyle,
      borderStyle,
      //classes,
      borderStyle1,
      borderStyle2,
      labelStyle2,
    } = this.styles;
    const { parent_id } = this.props;
    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root" style={{ marginTop: "20px" }}>
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-title-div`}
          >
            <H6
              id={`${parent_id}-title-h6`}
              className="pi-title">CONTACT DETAILS</H6>

            {/* <Text
              inLineStyles={{
                color: "#0071F2",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
              }}
              className="pi-addbtn"
            >
              <Text
                className="pi-addbtn"
                style={{ marginRight: 10 }}
                onClick={(e) => this.props.handleClick(e)}
              >
                + Add New
              </Text>
            </Text> */}
          </Div>
          {this.props.contactDetails.map((mval, i) => {
            return (
              <Row
                id={`${parent_id}-${i}-parent-row`}
                className="pi-actions-root">
                <Col
                  id={`${parent_id}-${i}-contactMode-Select-col`}
                  md={2}
                  lg={2}
                  sm={6}
                  xs={12}
                  className="no-padding-left"
                //style={{ padding: "0" }}
                >
                  <Select
                    id={`${parent_id}-${i}-contactMode-select`}
                    showArrow
                    label={mval.contactMode.name}
                    placeholder="select..."
                    labelStyle={labelStyle}
                    inLineStyles={borderStyle}
                    hoverColor="#0071F2"
                    options={this.props.contactmodeData.data}
                    value={mval.contactMode.value?.value ?? null}
                    //onChangeValue={(e) => this.setDetails("mode", e)}
                    onChangeValue={
                      (value) =>
                        this.props.onChangeSelect("contactMode", value, i)
                      //this.setDetails("mode", e)
                    }
                  />
                </Col>
                {(mval.contactMode.value?.value === "Mobile" ||
                  mval.contactMode.value?.value === "Phone") && (
                    <Col
                      id={`${parent_id}-${i}-countryCode-col`}
                      md={1} lg={1} sm={6} xs={12} className="no-padding-left">
                      <br />
                      <Select
                        id={`${parent_id}-${i}-countryCode-select`}
                        value={mval.countryCode.value?.value ?? null}
                        options={this.props.isdData.data}
                        //placeholder={mval.countryCode.value?.value}
                        abelStyle={labelStyle}
                        style={borderStyle}
                        showArrow
                        placeholder="91"
                        labelStyle={labelStyle}
                        inLineStyles={borderStyle}
                        hoverColor="#0071F2"
                        onChangeValue={(e) =>
                          this.props.onChangeSelect("countryCode", e, i)
                        }
                      />
                    </Col>
                  )}

                <Col
                  id={`${parent_id}-${i}-contactModeType-col`}
                  md={2}
                  lg={2}
                  sm={6}
                  xs={12}
                  className="no-padding-left"
                //style={{ padding: "0" }}
                >
                  {/* <TextInput
                    id={`${parent_id}-${i}-contactModeType-textInput`}
                    type={
                      mval.contactMode.value?.value === "Mobile" ||
                        mval.contactMode.value?.value === "Phone"
                        ? "number"
                        : "type"
                    }
                    label={
                      mval.contactMode.value?.value
                        ? mval.contactMode.value?.value
                        : "Phone"
                    }
                    placeholder={
                      mval.contactMode.value?.value
                        ? mval.contactMode.value?.value
                        : "Phone"
                    }
                    helperText={
                      contact?.error && contact?.error["mobile"]
                        ? "Is required"
                        : ""
                    }
                    value={mval.contactModeType.value ?? ""}
                    // onChange={(e) =>
                    //   this.setDetails(
                    //     "mobile",
                    //     e.target.value,
                    //     this.label() === "Mobile" || this.label() === "Phone"
                    //       ? 10
                    //       : null
                    //   )
                    // }
                    onChange={(e) =>
                      this.props.onChangeText("contactModeType", e, i)
                    }
                    error={
                      contact?.error && contact?.error["mobile"] ? true : false
                    }
                    labelStyle={labelStyle}
                    style={borderStyle}
                    // isRequired={true}
                    // disabled={contact.mode ? false : true}
                    hoverColor="#0071F2"
                    maxLength={mval.contactMode.value?.value === "Phone" ? "10" : "50"}
                  /> */}
                  <InputComp
                  top_title={ mval.contactMode.value?.value
                        ? mval.contactMode.value?.value
                        : "Phone"}
                 id={`${parent_id}-${i}-contactModeType-textInput`}
                  value={mval.contactModeType.value ?? ""}
                 onChange={(e) =>
                      this.props.onChangeText("contactModeType", e, i)
                    }
                  InputProps={ mval.contactMode.value?.value === "Phone" &&{
                    inputProps: {
                      min: 0,
                      maxlength: 10,
                    },
                  }}
                  onInput={(e) => mval.contactMode.value?.value === "Phone" && 
                   ( e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                  }
                />
                </Col>
                <Col
                  id={`${parent_id}-${i}-use-col`}
                  md={2} lg={2} sm={6} xs={12} className="no-padding-left">
                  <Select
                    id={`${parent_id}-${i}-use-select`}
                    showArrow
                    label={mval.use.name}
                    placeholder="select..."
                    labelStyle={labelStyle}
                    inLineStyles={borderStyle1}
                    hoverColor="#0071F2"
                    options={this.props.useData.data}
                    value={mval.use?.value?.value ?? null}
                    //onChangeValue={(e) => this.setDetails("use", e)}
                    onChangeValue={(e) =>
                      this.props.onChangeSelect("use", e, i)
                    }
                  />
                </Col>
                <Col
                  id={`${parent_id}-${i}-priority-col`}
                  md={2}
                  lg={2}
                  sm={6}
                  xs={12}
                  className="no-padding-left"
                //style={{ padding: "0" }}
                >
                  <Select
                    id={`${parent_id}-${i}-priority-select`}
                    showArrow
                    label={mval.priority.name}
                    placeholder="select..."
                    labelStyle={labelStyle2}
                    inLineStyles={borderStyle2}
                    hoverColor="#0071F2"
                    options={this.props.priorityData.data}
                    value={mval.priority?.value?.value ?? null}
                    onChangeValue={(e) =>
                      this.props.onChangeSelect("priority", e, i)
                    }
                  />
                </Col>
                {/* <Col md={1} lg={1} sm={6} xs={12}>
                  <div
                    style={{ marginTop: "30px", marginLeft: "-130px" }}
                    onClick={() => this.props.DeleteCard(i)}
                  >
                    <img
                      src={deleteicon}
                      alt="Icon"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </Col> */}
              </Row>
            );
          })}
        </Paper>
      </Div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   contactmodeData: state?.locationMasterSlice?.location_masters_contactmode,
//   isdData: state?.locationMasterSlice?.location_masters_isd,
//   useData: state?.locationMasterSlice?.location_masters_use,
//   priorityData: state?.locationMasterSlice?.location_masters_priority,
// });
export default withAllContexts(ContactDetails);
// export default connect(
//   mapStateToProps,
//   actions
// )(withAllContexts(ContactDetails));
