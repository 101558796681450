/* eslint-disable */

import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  CircularProgress,
  List,
  ListItem,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Switch,
  TextField,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import AddNewPage from "./addNewPage";
import AddNewForms from "./addNewForms";
import AddNewReports from "./addNewReports";
import AddNewprocesss from "./addNewProcess";
import { DrawerProps, treeData } from "../../../utils";
import { withAllContexts } from "../../../HOCs";
import styles from "./styles";
import {
  readDocument,
  deleteDocument,
  upsertDocument,
} from "../../../function/commonapi";
import Config from "../../../config";
import DeleteComponent from "./deleteComp";
import EditIcon from "../../../assets/icons - Edit.svg";
import DeleteIcon from "../../../assets/icons8-trash.svg";
import { withStyles } from "@material-ui/core/styles";
import searchicon from "../../../assets/icons - Search.svg";
import TreeViewComponent from "./orgAccess";
import Tree from "../../../components/tree";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";

const repositoryInputs = [
  { name: "Pages" },
  { name: "Forms" },
  { name: "Reports" },
  { name: "Processes" },
];

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#0071F2",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 12,
    height: 12,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(Switch);

function RepositoryMain(props) {
  const classes = styles();
  const [loader, setLoader] = useState(true);
  const [statusCheck, setStatusCheck] = useState({
    statusActive: {},
  });
 
  const [pageData, setPageData] = useState([]);
  const [pageData1, setPageData1] = useState([]);

  const [formRepData, setFromRepData] = useState([]);
  const [formRepData1, setFromRepData1] = useState([]);

  const [reportsData, setReportsData] = useState([]);
  const [reportsData1, setReportsData1] = useState([]);

  const [processData, setprocessData] = useState([]);
  const [processData1, setprocessData1] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectedIndex, setSelectedIndex] = React.useState("Pages");

  const handleListItemClick = (e, item) => {
    setSelectedIndex(item?.name);
  };

  const handleStatusCheck = (e, index, item) => {
    let { statusActive } = statusCheck;
    if (
      statusActive?.[selectedIndex]?.indexOf(item?.Repository?.elementname) > -1
    ) {
      statusActive?.[selectedIndex].splice(
        statusActive?.[selectedIndex]?.indexOf(item?.Repository?.elementname),
        1
      ) ?? false;
    } else {
      statusActive[selectedIndex] = statusActive[selectedIndex]
        ? statusActive[selectedIndex]
        : [];
      statusActive[selectedIndex].push(item?.Repository?.elementname);
    }
    setStatusCheck({
      ...statusCheck,
      statusActive,
    });
  };

  //API CALL FOR CHANGE PAGE STATUS
  const handlePageStatus = async (event, item) => {
    //alert(JSON.stringify(item));

    let list = {
      elementname: item?.Repository?.elementname,
      route: item?.Repository?.route,
      is_active: !item?.Repository?.is_active,
    };
    let sendPageData = {
      entity: Config.repositoryEntity,
      metadataId: Config.metadataid,
      isedit: true,
      id: item?.Repository?.rep_id,
      list,
      keyvalue: "rep_id",
    };

    let addEditpageUpsert = await upsertDocument(sendPageData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          getRepositoryData();

          handleAlerts("Page status changed successfully!", true);
        } else {
          handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        handleAlerts("Something  went wrong", false);
      });
  };

  //API CALL FOR CHANGE FORM STATUS
  const handleFormStatus = async (event, item) => {
    let list = {
      elementname: item?.Repository?.elementname,
      is_active: !item?.Repository?.is_active,
    };
    let sendFormData = {
      entity: Config.repositoryEntity,
      metadataId: Config.metadataid,
      isedit: true,
      id: item?.Repository?.rep_id,
      list,
      keyvalue: "rep_id",
    };

    let addEditpageUpsert = await upsertDocument(sendFormData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          getRepositoryData();

          handleAlerts("Form status changed successfully!", true);
        } else {
          handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        handleAlerts("Something  went wrong", false);
      });
  };

  //API CALL FOR CHANGE REPORT STATUS
  const handleReportStatus = async (event, item) => {
    let list = {
      elementname: item?.Repository?.elementname,
      is_active: !item?.Repository?.is_active,
    };
    let sendReportsData = {
      entity: Config.repositoryEntity,
      metadataId: Config.metadataid,
      isedit: true,
      id: item?.Repository?.rep_id,
      list,
      keyvalue: "rep_id",
    };

    let addEditpageUpsert = await upsertDocument(sendReportsData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          getRepositoryData();

          handleAlerts("Report status changed successfully!", true);
        } else {
          handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        handleAlerts("Something  went wrong", false);
      });
  };

  //API CALL FOR CHANGE PROCESS STATUS
  const handleProcessStatus = async (event, item) => {
    let list = {
      elementname: item?.Repository?.elementname,
      is_active: !item?.Repository?.is_active,
    };
    let sendProcessData = {
      entity: Config.repositoryEntity,
      metadataId: Config.metadataid,
      isedit: true,
      id: item?.Repository?.rep_id,
      list,
      keyvalue: "rep_id",
    };

    let addEditpageUpsert = await upsertDocument(sendProcessData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          getRepositoryData();

          handleAlerts("Process status changed successfully!", true);
        } else {
          handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        handleAlerts("Something  went wrong", false);
      });
  };

  const handleClickOpen = (item) => {
    setDeleteId(item?.Repository?.rep_id);
    setOpen(true);
  };

  const handleClose = async (status) => {
    const { alert } = props;
    let { setSnack } = alert;
    if (status) {
      let sendPageData = {
        entity: Config.repositoryEntity,
        metadataId: Config.metadataid,
        id: [deleteId],
        keyvalue: ["rep_id"],
      };
      await deleteDocument(sendPageData)
        .then((res) => {
          if (res?.data?.data) {
            setSnack({
              ...alert,
              horizontal: "right",
              msg: "Document deleted successfully",
              open: true,
              autoHideDuration: 6000,
              severity: "success",
              vertical: "top",
            });
          }
        })
        .catch((error) => {
          alert("Document not deleted.");
        });
      setOpen(false);
      getRepositoryData();
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getRepositoryData();
  }, []);

  const getRepositoryData = async () => {
    try {
      let readDocParams = {
        entity: Config.repositoryEntity,
      };
      let getreadDocument = await readDocument(readDocParams);
      setTimeout(() => {
        // setLoader(false);
        let filterForms = getreadDocument?.data?.result?.filter(
          (i) => i.Repository.rep_type === "Forms"
        );
        let filterPages = getreadDocument?.data?.result?.filter(
          (i) => i.Repository.rep_type === "Pages"
        );
        let filterReports = getreadDocument?.data?.result?.filter(
          (i) => i.Repository.rep_type === "Reports"
        );
        let filterProcess = getreadDocument?.data?.result?.filter(
          (i) => i.Repository.rep_type === "Processes"
        );
        setPageData(filterPages);
        setPageData1(filterPages);

        setFromRepData(filterForms);
        setFromRepData1(filterForms);

        setReportsData(filterReports);
        setReportsData1(filterReports);

        setprocessData(filterProcess);
        setprocessData1(filterProcess);
        setLoader(false);
      }, 1000);
    } catch (error) {
      setPageData([]);
    }
  };
  const handleAlerts = (message, status) => {
    const { alert } = props;
    let { setSnack } = alert;
    setSnack({
      ...alert,
      horizontal: "right",
      msg: message,
      open: true,
      autoHideDuration: 6000,
      severity: status ? "success" : "error",
      vertical: "top",
    });
  };
  const drawerOpening = (e, item) => {
    const { drawer } = props;
    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddNewPage
          getRepositoryData={getRepositoryData}
          editName={item?.Repository?.elementname}
          pageParams={item?.Repository}
          handleAlerts={handleAlerts}
          incomingPageData={pageData}
        />
      ),
    });
  };

  const formdrawerOpening = (e, item) => {
    const { drawer } = props;
    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddNewForms
          getRepositoryData={getRepositoryData}
          editName={item?.Repository?.elementname}
          formParams={item?.Repository}
          handleAlerts={handleAlerts}
          incomingFormData={formRepData}
        />
      ),
    });
  };

  const reportsOpening = (e, item) => {
    const { drawer } = props;
    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddNewReports
          getRepositoryData={getRepositoryData}
          editName={item?.Repository?.elementname}
          reportsParams={item?.Repository}
          handleAlerts={handleAlerts}
          incomingReportsData={reportsData}
        />
      ),
    });
  };

  const processOpening = (e, item) => {
    const { drawer } = props;
    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      // isLarge:true,
      component: (
        <AddNewprocesss
          getRepositoryData={getRepositoryData}
          editName={item?.Repository?.elementname}
          processParams={item?.Repository}
          handleAlerts={handleAlerts}
          incomingProcesssData={processData}
        />
      ),
    });
  };

  //SEARCH FOR PAGE
  const searchPageChange = (event) => {
    console.log(pageData);
    let val = event.target.value.toLowerCase();
    const filterDate = pageData.filter((item) => {
      return item.Repository?.elementname.toLowerCase().includes(val);
    });
    setPageData1(filterDate);
  };
  //SEARCH FOR FORMS
  const searchFormsChange = (event) => {
    console.log(formRepData);
    let val = event.target.value.toLowerCase();
    const filterDate = formRepData.filter((item) => {
      return item.Repository?.elementname.toLowerCase().includes(val);
    });
    setFromRepData1(filterDate);
  };
  //SEARCH FOR REPORTS
  const searchReportsChange = (event) => {
    console.log(reportsData);
    let val = event.target.value.toLowerCase();
    const filterDate = reportsData.filter((item) => {
      return item.Repository?.elementname.toLowerCase().includes(val);
    });
    setReportsData1(filterDate);
  };
  //SEARCH FOR PROCESS
  const searchProcessChange = (event) => {
    console.log(processData);
    let val = event.target.value.toLowerCase();
    const filterDate = processData.filter((item) => {
      return item.Repository?.elementname.toLowerCase().includes(val);
    });
    setprocessData1(filterDate);
  };
  // useEffect(() => {
  //   setInitialize();
  // }, []);
  const dispatch = useDispatch();

  const repository_list = useSelector((state) => state?.repositorySlice?.repository_read?.data);
  const repositoryTree = repository_list?.length>0 ? repository_list[0] : {};
  React.useLayoutEffect(() => {
    // dispatch(actions.ROLE_READ());
    dispatch(actions.REPOSITORY_READ_DOCUMENT())
  }, []);
  return (
    <div className={classes.contentBox}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              // padding: "10px 20px",
              border: "1px solid #DCDCDC",
            }}
          >
            <Typography className={classes.numbersEdit}>Repository</Typography>
            <Divider />
            {
              repository_list?.length >0 ? 
                <div style={{ padding: "10px 20px 6px 38px" }}>
                  <Tree data={repositoryTree?.project_component} />
                </div>
                : 
                <div style={{ padding: "10px 20px 6px 38px" }} className={classes.nodatafound}>
                  <span>No data found.</span>
              </div>
            }
            
          </div>
        </Grid>
        
        {/* -------------------------------- delete ----------------------------------  */}
        <DeleteComponent open={open} deleteClose={handleClose} />
      </Grid>
    </div>
  );
}

export default withAllContexts(RepositoryMain);
