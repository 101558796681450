import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Button, IconButton } from "@material-ui/core";
// Components
import { InputBase, Autocomplete } from "..";
// Icons
import { TrashIcon } from "../svg";
// Styles
import qdmstyles from "./styles.json";

function Mappeddespensablecomp(props) {
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {}, [state]);

  const {
    data,
    index,
    options,
    statevalue = [],
    stateName,
    onAddNewClick = () => {},
    onDeleteClick = () => {},
    setFun = () => {},
  } = props;

  return (
    <React.Fragment>
      <Grid
        key={"0"}
        id={"hDIJk"}
        container={true}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {/* Header Starts */}
        <Grid
          key={"0"}
          id={"TgstR"}
          container={true}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          lg={"auto"}
          style={qdmstyles.rTexp}
        >
          <Grid
            key={"0"}
            id={"TgstR"}
            container={""}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item={true}
            lg={"6"}
          >
            <Typography
              key={"0"}
              id={"mapped_dispensable_header"}
              align={"left"}
              color={"initial"}
              display={"initial"}
              variant={"body1"}
              children={"MAPPED DISPENSABLES"}
              aliasName={"mappeddispensableheader"}
              style={qdmstyles.NAXiU}
            />
          </Grid>
          <Grid
            key={"1"}
            id={"TgstR"}
            container={""}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item={true}
          >
            <Button
              key={"0"}
              id={"mapped_dispensable_add_new_button"}
              variant={"text"}
              color={"primary"}
              children={"+ Add New"}
              aliasName={"mappeddispensableaddnewbutton"}
              style={qdmstyles.NBvD}
              onClick={() => onAddNewClick(stateName)}
            />
          </Grid>
        </Grid>
        {/* Header Ends */}

        {/* Body Starts */}

        <Grid
          key={"1"}
          id={"OeOjQ"}
          container={true}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          lg={"12"}
          md={"12"}
          sm={"12"}
          xl={"12"}
          xs={"12"}
        >
          <Grid
            key={"0"}
            id={"7uXWP"}
            container={true}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"baseline"}
            lg={"12"}
            item={true}
            md={"12"}
            sm={"12"}
            xl={"12"}
            xs={"12"}
            spacing={1}
          >
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6} md={5}>
                <Typography
                  key={"0"}
                  id={"item_code_description_header"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"Item Code & Description"}
                  aliasName={"itemcodedescriptionheader"}
                  style={qdmstyles.fWx}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography
                  key={"0"}
                  id={"preferred_sequence_header"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"Preferred Sequence"}
                  aliasName={"preferredsequenceheader"}
                  style={qdmstyles.HRAX}
                />
              </Grid>
            </Grid>

            {Array.isArray(statevalue) &&
              statevalue?.map((item, index) => {
                const rowState = statevalue?.[index];
                return (
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12} sm={6} md={5}>
                      <Autocomplete
                        id={"8VQ95"}
                        style={qdmstyles?.VQ}
                        getOptionLabel={(option) => {
                          const labelOption = [
                            option?.["DrugCode"] ?? "",
                            option?.["label"] ?? "",
                          ];
                          if (labelOption.some((x) => !!x)) {
                            return labelOption
                              .filter((item) => item)
                              .join(" - ");
                          } else {
                            return "";
                          }
                        }}
                        options={options?.item_code ?? []}
                        size={"small"}
                        onLoad={() =>
                          setFun(
                            stateName,
                            "dispensedrugcode",
                            rowState?.dispensedrugcode,
                            index
                          )
                        }
                        value={rowState?.dispensedrugcode ?? null}
                        onChange={(event, newValue) =>
                          setFun(stateName, "dispensedrugcode", newValue, index)
                        }
                      />
                    </Grid>
                    <Grid container item xs={12} sm={6} md={3} spacing={1}>
                      <Grid item xs>
                        <InputBase
                          key={"0"}
                          id={"preferred_sequence_value"}
                          label={""}
                          placeholder={""}
                          type={"number"}
                          select={""}
                          size={"small"}
                          fullWidth={true}
                          variant={"outlined"}
                          margin={"none"}
                          aliasName={"preferredsequencevalue"}
                          inputProps={{
                            maxlength: 2,
                            min: 0,
                            max: 99,
                          }}
                          value={rowState?.seqno}
                          onChange={(event) => {
                            if (event?.target?.value?.length > 2) return;
                            setFun(
                              stateName,
                              "seqno",
                              parseInt(event.target.value),
                              index
                            );
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => onDeleteClick(stateName, index)}
                        >
                          <TrashIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withRouter(Mappeddespensablecomp);
