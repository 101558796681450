import React, { useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
} from "@material-ui/core";
import { withAllContexts } from "../../../HOCs";
import styles from "./styles";
import { v4 as uuidv4 } from "uuid";
import Config from "../../../config";
import { upsertDocument } from "../../../function/commonapi";

function CreatePermission(props) {
  const classes = styles();
  const [permissionData, setpermissionData] = useState({
    permission: props?.permissionParams
      ? props?.permissionParams?.permissionName
      : "",
  });
  const [error, setError] = useState({
    permission: false,
    msg: "Please enter the field",
  });

  const duplicate = props.incomingPermission.filter(
    (itm) =>
      itm?.PermissionManagement?.permissionName === permissionData.permission
  );

  const validation = () => {
    let isValidate = false;

    if (permissionData?.permission?.trim()?.length > 0) {
      error.permission = false;
    } else {
      isValidate = true;
      error.permission = true;
    }
    if (duplicate.length > 0) {
      isValidate = true;
      error.permission = true;
      error.msg = "Permission already exists";
    } else {
      error.pageName = false;
    }
    setError({
      ...error,
    });
    return isValidate;
  };

  const submitEdit = async () => {
    const { drawer } = props;
    let check = validation();
    if (check) {
      return;
    }
    let list = {
      permissionName: permissionData?.permission,
    };
    let sendPermissionData = {
      entity: Config.permissionEntity,
      metadataId: Config.metadataid,
      isedit: true,
      id: props?.permissionParams?.id,
      list,
      keyvalue: "id",
    };

    let addPermissionUpsert = await upsertDocument(sendPermissionData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          props.getPermissionData();
          props.handleAlerts("Permission edited successfully!", true);
          drawer.setDrawer({
            ...drawer,
            open: false,
            component: <></>,
          });
        } else {
          props.handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        props.handleAlerts("Something  went wrong", false);
      });
  };

  const handlepermissionData = (event) => {
    setpermissionData({
      ...permissionData,
      [event.target.name]: event.target.value,
    });
  };

  const submitPermission = async () => {
    let submittedPermission = {
      permission: permissionData.permission,
    };

    const { drawer } = props;
    let check = validation();
    if (check) {
      return;
    }

    let list = {
      id: uuidv4(),
      permissionName: submittedPermission.permission,
      is_active: true,
      repo_mapping: {
        Pages: [],
        Forms: [],
        Reports: [],
        Processes: [],
      },
    };

    let sendPermissionData = {
      entity: Config.permissionEntity,
      metadataId: Config.metadataid,
      list,
    };

    let addPermissionUpsert = await upsertDocument(sendPermissionData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          props.getPermissionData();
          drawer.setDrawer({
            ...drawer,
            open: false,
            component: <></>,
          });
          props.handleAlerts("Permission created successfully!", true);
        } else {
          props.handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        props.handleAlerts("Something  went wrong", false);
      });
  };

  return (
    <div>
      <div className={classes.drawerHead}>
        <Typography className={classes.headerMain}>
          {props.editName ? "Edit Permission" : "Add New Permission"}
        </Typography>
      </div>

      <Grid container direction="row">
        <Grid item xs={12}>
          <Paper>
            <div className={classes.textTotalArea}>
              <Typography className={classes.drawerSubHead}>
                Permission Name
              </Typography>
              <TextField
                label={"Permission"}
                variant="outlined"
                fullWidth
                size="small"
                name="permission"
                onChange={handlepermissionData}
                value={permissionData.permission}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                error={error?.permission ? true : false}
                helperText={error?.permission ? error.msg : ""}
              />
            </div>
            <div className={classes.btnPosition}>
              <Button
                contained
                className={classes.addDrawerBtn}
                onClick={props.editName ? submitEdit : submitPermission}
              >
                Save
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default withAllContexts(CreatePermission);
