import React from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { Typography, CardContent, Card, withStyles } from "@material-ui/core";

const drawerWidth = 360;
const useStyles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  large: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    position: "fixed",
    right: "376px",
    cursor: "pointer",
    top: 10,
    "& img": {
      objectFit: "scale-down",
      backgroundColor: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      right: "313px",
    },
  },
  bottomnav: {
    position: "absolute",
    //bottom: 0,
    right: 0,
    width: "100%",
    padding: 5,
    paddingRight: 0,
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #DDDFE1",
    zIndex: "10000",
  },
  slot: {
    marginTop: "auto",
    marginBottom: "auto",
    paddingLeft: 10,
  },
  topGrid: {
    backgroundColor: "#fff",
    padding: 5,
  },
});

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weekendsVisible: true,
      currentEvents: [],
      clickCnt: 0,
      selInfo: null,
    };
  }

  handleEvents = (events) => {
    this.setState({
      currentEvents: events,
    });
    this.props.handleEvents(events);
  };
  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className="demo-app">
          <div className="demo-app-main">
            <FullCalendar
              id="fullcalendar"
              ref={this.props.calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={this.props.headerToolbar}
              initialView={this.props.initialView}
              editable={false}
              selectable={false}
              selectMirror={false}
              dayMaxEvents={1}
              initialDate={this.props.initialDate}
              weekends={this.state.weekendsVisible}
              initialEvents={this.props.initialEvents} // alternatively, use the `events` setting to fetch from a feed
              events={this.props.events}
              eventContent={RenderEventContent} // custom render function
              eventClick={this.props.handleEventClick}
              eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
              contentHeight={600}
              moreLinkClick={this.props.moreEventClick}

              // day cell tests
              // ----that daycell ui by kalanchiyam--
              // dayCellContent={this.dayCellContent}

              //eventContent={this.eventContent}
              // ----that hover effect disable by kalanchiyam--
              // dayCellDidMount={this.dayCellDidMount}

              /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
            />
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(useStyles)(Calendar);

function RenderEventContent(eventInfo) {
  eventInfo.backgroundColor = "#f5f5f5";
  eventInfo.borderColor = eventInfo?.event?._def?.extendedProps?.isBlocked
    ? "#FF4D4A"
    : "#f5f5f5";
  eventInfo.border = "1px solid";

  const classes = {
    label: {
      fontSize: "12px",
      color: "#6F6F6F",
      marginBottom: 8,
    },
    dialog: {
      minWidth: "700px",
      "& .MuiDialog-paperWidthSm": {
        minWidth: "700px",
      },
    },
    avatar: {},
    cardroot: {
      boxShadow: "none",
      cursor:"pointer",
      background: "transparent",
      borderRadius: "8px",
      "& .MuiCardContent-root": {},
      "& .MuiCardContent-root:last-child": {
        paddingBottom: 10,
      },
    },
    content: {
      backgroundColor: "#F0F0F0",
    },
    timediv: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 5,
    },
    timeGrid: {
      margin: 5,
    },
    cardcontent: {
      padding: 4,
    },
  };
  return (
    <div title="Slots">
      {eventInfo?.event?._def?.extendedProps?.isBlocked ? (
        <div style={{ padding: "10px", color: "#FF4D4A", cursor: "pointer" }}>
          Blocked
        </div>
      ) : (
        <Card style={classes.cardroot}>
          <CardContent style={classes.cardcontent}>
            <Typography style={{ fontSize: "12px" }} variant="h6" component="p">
              {eventInfo.event.title}
            </Typography>
            <div style={classes.timediv}>
              <div>
                <img
                  src="/images/icons8-appointment-scheduling.svg"
                  alt="Generate"
                />
                &nbsp; {eventInfo?.event?._def?.extendedProps?.appointment}
              </div>
              <div>
                <img src="/images/icons8-step-over.svg" alt="Generate" />
                &nbsp; {eventInfo?.event?._def?.extendedProps?.slab}
              </div>
              <div>
                <img src="/images/icons8-clock.svg" alt="Generate" />
                &nbsp; {eventInfo?.event?._def?.extendedProps?.overTime}
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
  /*
    var styleCell = { border: "solid" };

    return (
      <div style={styleCell} title="Add event">
        {eventInfo.event.startStr}
      </div>
    );  
  */
  /*
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
  */
}
