import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import { routes } from "../../router/routes";
import { CommonTable, SearchWithFilter } from "../../components";
import { Row } from "qdm-component-library";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { withAllContexts } from "../../HOCs";
import { AlertProps } from "../../utils";

const useStyles = makeStyles((theme) => ({
  boxsize: {
    "& .MuiOutlinedInput-inputMarginDense": {
      height: "10px !important",
      width: "150px",
    },
  },
  filterselectDiv: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    marginRight: "1%",
    border: "1px solid #E0E0E0",
  },
  filterselect: {
    backgroundColor: "white",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

const ResourcebyTypeTable = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { parent_id } = props;
  const [entityTypeQ, setentityType] = useState("");
  const [entityNameQ, setentityName] = useState("");

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(actions.RESOURCE_BY_TABLE_DATA({ entityTypeQ: entityTypeQ, entityNameQ: entityNameQ,  page: (currentPage - 1) * PerPage, perPage: PerPage }));

  };

  const handleDrawerOpen = () => {
    history.push(routes.createappointment);
  };
  const dispatch = useDispatch();
  const resourceAppointment = useSelector(
    (state) => state.resourceByAppointmentTypesSlice
  );
  React.useLayoutEffect(() => {
    const getdata = async () => {
      await dispatch(actions.RESOURCE_BY_ENTITY_NAME_TYPES());
      await dispatch(actions.RESOURCE_BY_ENTITY_TYPE());
      await dispatch(
        actions.RESOURCE_BY_TABLE_DATA({ entityTypeQ, entityNameQ, page, perPage })
      );
    };
    getdata();
  }, []);

  const statusChange = async (e, values) => {
    let id = values.allData._key;
    let checked = e.target.checked;
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Status Updating",
    });
    await dispatch(
      actions.UPDATE_STATUS_RES_LINE_LIST({
        id,
        checked,
      })
    );

    await dispatch(
      actions.RESOURCE_BY_TABLE_DATA({ entityTypeQ, entityNameQ })
    );
     props.backDrop.setBackDrop({
       ...props.backDrop,
       open: false,
       message: "",
     });
      props.alert.setSnack("update");
  };

  const handleEdit = (e, values, index) => {
    history.push({
      pathname: routes.createappointment,
      state: {
        key: values.allData._key,
      },
    });
  };
  const entityFunCall = async (e, values, swis) => {
    let entityTypeQuey = "";
    let entityNameQuey = "";
    if (swis === "type") {
      if (values?.title) {
        entityTypeQuey = values?.title
          ? `AND DOCUMENT(ResourcebyApptType.entityType).display=='${values?.title}'`
          : "";
        setentityType(entityTypeQuey);
      } else {
        setentityType("");
      }
    } else if (swis === "name") {
      if (values?.title) {
        entityNameQuey = values?.title
          ? `AND DOCUMENT(ResourcebyApptType.organization_id).name=='${values?.title}'`
          : "";
        setentityName(entityNameQuey);
      } else {
        setentityName("");
      }
    }
  };

  React.useEffect(() => {
    const getdata = async () => {
      await dispatch(
        actions.RESOURCE_BY_TABLE_DATA({ entityTypeQ, entityNameQ, page, perPage })
      );
    };
    getdata();
  }, [entityTypeQ, entityNameQ]);


  const onSearchChange = (value = "") => {
    if (value?.length > 1) {
      dispatch(actions.RESOURCE_BY_TABLE_DATA({
        search: value.trim(),
        page: page,
        perPage: perPage
      }));
    } else if (!value) {
      dispatch(actions.RESOURCE_BY_TABLE_DATA({
        page: page,
        perPage: perPage
      }));
    }
  };

  return (
    <div
      id={`${parent_id}-parent-div`}
    >
      <div
        id={`${parent_id}-parent-sub-div`}
        style={{ marginBottom: 14 }}>
        <Row
          id={`${parent_id}-parent-row`}
          style={{ justifyContent: "flex-end" }}>
          <div
            id={`${parent_id}-Entity-Type-div`}
            className={classes.filterselectDiv}>
            <span
              id={`${parent_id}-Entity-Type-span`}
              style={{ paddingLeft: 12, color: "#6F6F6F" }}>
              Entity Type :
            </span>
            <Autocomplete
              id={`${parent_id}-Entity-Type-autocomplete`}
              options={
                resourceAppointment?.resource_enitity_type?.data
                  ? resourceAppointment?.resource_enitity_type?.data
                  : null
              }
              getOptionLabel={(option) => option.title}
              style={{ width: 200, border: "none" }}
              size={"small"}
              className={classes.filterselect}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              onChange={(e, values) => entityFunCall(e, values, "type")}
            />
          </div>
          <div
            id={`${parent_id}-Entity-name-div`}
            className={classes.filterselectDiv}>
            <span
              id={`${parent_id}-Entity-name-span`}
              style={{ paddingLeft: 12, color: "#6F6F6F" }}>
              Entity Name :
            </span>
            <Autocomplete
              id={`${parent_id}-Entity-name-autocomplete`}
              options={
                resourceAppointment?.resource_enitity_name?.data
                  ? resourceAppointment.resource_enitity_name.data
                  : []
              }
              getOptionLabel={(option) => option.title}
              style={{ width: 400, border: "none" }}
              size={"small"}
              className={classes.filterselect}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              onChange={(e, values) => entityFunCall(e, values, "name")}
            />
          </div>
        </Row>
      </div>
      <Card
        id={`${parent_id}-Entity-name-card`}
        style={{ borderRadius: 10, paddingnBottom: "2%" }}>
        <div
          id={`${parent_id}-Add-New-div`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "1%",
          }}
        >
          <div
            id={`${parent_id}-${props.header.replaceAll(" ", "-")}-div`}
            style={{ marginLeft: "18px" }}>
            <p
              id={`${parent_id}-${props.header.replaceAll(" ", "-")}-p`}
              style={{ fontFamily: "poppinsemibold" }}>{props.header}</p>
          </div>
          <div
            id={`${parent_id}-Add-New-button-div`}
            style={{ marginRight: "18px", display:'flex' }}>
              <SearchWithFilter
                  id="appointment"
                  placeholder="Search code and Description!"
                  //  loading={data?.length > 0 ? loading : false}
                  onSearchChange={onSearchChange}
                  hideSearchBar={true}
                  hideFilter={true}
                //  onFilterClicked={onFilterClicked}
                //  isFilterApplied={props?.isFilterApplied}
                />
            <Button
              id={`${parent_id}-Add-New-button`}
              variant="contained"
              color="primary"
              onClick={handleDrawerOpen}
              size="medium"
              style={{height:"35px"}}
            >
              + Add New
            </Button>
          </div>
        </div>
        <div
          id={`${parent_id}-CommonTable-div`}
          style={{
            padding: 20,
            height: "61vh",
            overflow: "auto",
            paddingTop: 0,
          }}
        >
          {console.log(resourceAppointment?.resource_enitity_table_data?.data?.[0]?.total_count)}
          <CommonTable
            parent_id={'resourceappointment'}
            Header={[
              "S.No",
              "Code",
              "Description",
              "Resources",
              "Status",
              "Action",
            ]}
            dataList={
              resourceAppointment?.resource_enitity_table_data?.data
                ? resourceAppointment.resource_enitity_table_data?.data
                : []
            }
            tableData={[
              { type: ["INCRIMENT"], name: "" },
              { type: ["TEXT"], name: "code" },
              { type: ["TEXT"], name: "display" },
              { type: ["TEXT"], name: "resources" },
              { type: ["CHECKBOX"], name: "status" },
              { type: ["EDIT"], name: "action" },
            ]}
            handleCheckBox={statusChange}
            handleEdit={handleEdit}
            handlepageChange={(currentPage, PerPage) =>
              handlepageChange(currentPage, PerPage)
            }
            TableCount={resourceAppointment.resource_enitity_table_data?.data?.[0]?.total_count}
            incrementCount={page}
            showPagination={true}
            rowsPerPageOptions={[
              { label: "10 Rows", value: 10 },
              { label: "50 Rows", value: 50 },
              { label: "100 Rows", value: 100 },
            ]}
          />
        </div>
      </Card>
    </div>
  );
};
export default withAllContexts(ResourcebyTypeTable);
