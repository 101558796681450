import React from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Button,
  IconButton,
  Checkbox,
  Divider,
  makeStyles,
  Modal,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
// Components
import {
  Autocomplete,
  TextfieldWithSelect,
  CustomButton,
  RichTextfield,
} from "..";
// Icons
import { CreateIcon, TrashIcon } from "../svg";
// Styles
import qdmstyles from "./styles.json";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    "&.Mui-checked": {
      color: "#0071F2",
    },
  },
  returnPolicySection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 12,
  },
  returnPolicy: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 8,
  },
  returnPolicyTitle: {
    color: "#6A7888",
    fontSize: 12,
    textTransform: "capitalize",
    fontFamily: "pc_regular",
  },
  policyDescPaper: {
    borderRadius: 8,
    border: "1px solid #DEE5EC",
    padding: 16,
  },
  policyTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  policyTitleDivider: {
    backgroundColor: "#DEE5EC",
  },
  policyDescTitle: {
    color: "#6A7888",
    fontSize: 12,
  },
  policyDescText: {
    width: "100%",
    color: "#2A3C50",
    fontSize: 14,
    lineHeight: "20px",
    letterSpacing: 0,
    textAlign: "left",
    // textTransform: "capitalize",
    padding: 2,
  },

  // Modal Styles
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalPaper: {
    width: 740,
    backgroundColor: theme.palette.background.paper,
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
    "& > .MuiTypography-root": {
      fontSize: 14,
      color: "rgba(0, 28, 60, 1)",
      fontWeight: 600,
      textTransform: "uppercase",
    },
    "& > .MuiIconButton-root": {
      background: "rgba(1, 32, 92, 0.1)",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.1rem",
    },
  },
  modalHeaderDivider: {
    background: "#DEE5EC",
  },
  modalBodyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: "20px 16px",
  },
  modalInputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    "& > .MuiTypography-root": {
      color: "rgb(106, 120, 136)",
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0px",
      textAlign: "left",
      textTransform: "capitalize",
      margin: "0px",
    },
  },
  modalInput: {
    width: "345px",
  },
  modalActionContainer: {
    display: "flex",
    gap: 8,
    marginLeft: "auto",
  },
  modalSaveAndAdd: {
    color: "#2A60BC",
    backgroundColor: "#F1F6FC",
    borderRadius: "8px",
  },
  modalAdd: {
    backgroundColor: "#01205C",
    borderRadius: "8px",
  },
  instructionValueContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
}));

function ReturnApplicable(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    permissionData: null,
    showAddNewReturnPolicyModal: false,
    isEdit: "",

    // returnPolicy
    returnPolicy: "",
  });

  const changeState = (key, value) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  React.useEffect(() => {}, [state]);

  const handleModalOpen = () => {
    setState({
      ...state,
      showAddNewReturnPolicyModal: true,
    });
  };
  const handleModalClose = () => {
    changeState("showAddNewReturnPolicyModal", false);
  };

  const handleAddNewClick = () => {
    handleModalOpen("");
  };

  const handleSaveClick = () => {
    const { returnPolicy } = state;
    setState((prev) => ({
      ...prev,
      returnPolicy: "",
      showAddNewReturnPolicyModal: false,
    }));
    changeOtherState("returnPolicy", returnPolicy);
  };

  const handleEditClick = () => {
    setState((prev) => ({
      ...prev,
      returnPolicy: returnPolicy,
      isEdit: true,

      // Open the modal
      showAddNewReturnPolicyModal: true,
    }));

    // handleModalOpen(index);
  };

  const {
    data,
    index,
    options,
    statevalue = [],
    stateName,
    onAddNewClick = () => {},
    onDeleteClick = () => {},
    setFun = () => {},

    // Other States
    returnPeriodVal,
    returnPeriodUOM,
    partialQtyAllow,
    returnPolicy,
    changeOtherState,
  } = props;

  return (
    <React.Fragment>
      <Grid
        key={"0"}
        id={"OXKhr"}
        container={""}
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        item={true}
      >
        <Paper key={"0"} id={"2CyVg"} elevation={"{3}"} style={qdmstyles.bdBel}>
          {/* Top Section Starts */}
          <Grid
            key={"1"}
            id={"CZVZN"}
            container={true}
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            lg={"12"}
            item={true}
            md={"12"}
            sm={"12"}
            spacing={2}
            xl={"12"}
            xs={"12"}
            style={qdmstyles.vFYR}
          >
            <Grid
              key={"0"}
              id={"2MXs9"}
              container={true}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              item={true}
              lg={"3"}
              md={"3"}
              sm={"3"}
              xl={"3"}
              xs={"3"}
              style={qdmstyles.ixFX}
            >
              <Grid
                key={"0"}
                id={"d2nWC"}
                container={""}
                direction={"column"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                item={true}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                style={qdmstyles.LLDye}
              >
                <Typography
                  key={"0"}
                  id={"returnPeriodHeading_typogrpahy"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"Return Period"}
                  aliasName={"returnPeriodHeading"}
                  style={qdmstyles.oJhzE}
                />
              </Grid>
              <Grid
                key={"1"}
                id={"d2nWC"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
              >
                <TextfieldWithSelect
                  InputProps={{
                    id: `ReturnPeriod`,
                    type: "text",
                    fullWidth: true,
                    inputProps: {
                      maxLength: 10,
                    },
                    onKeyPress: (event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    },
                    value: returnPeriodVal,
                    onChange: (event) =>
                      changeOtherState(
                        "returnPeriodVal",
                        event.target.value ? parseInt(event.target.value) : 0
                      ),
                  }}
                  AutocompleteProps={{
                    id: `ReturnPeriodUOM`,
                    getOptionLabel: (option) => option?.["label"],
                    options: options?.duration_uom ?? [],
                    value: returnPeriodUOM,
                    onLoad: () =>
                      changeOtherState("returnPeriodUOM", returnPeriodUOM),
                    onChange: (event, newValue) =>
                      changeOtherState("returnPeriodUOM", newValue),
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              key={"1"}
              id={"2MXs9"}
              container={true}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              item={true}
              lg={"3"}
              sm={"3"}
              md={"3"}
              xl={"3"}
              xs={"3"}
            >
              <Grid
                key={"0"}
                id={"d2nWC"}
                container={""}
                direction={"column"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                item={true}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                style={qdmstyles.UY}
              >
                <Typography
                  key={"0"}
                  id={"partialQuantityAllowedHeading_Typography"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"Partial Quantity Allowed ?"}
                  aliasName={"partialQuantityAllowedHeading"}
                  style={qdmstyles.PoAZ}
                />
              </Grid>
              <Grid
                key={"1"}
                id={"d2nWC"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                lg={"12"}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
              >
                <Checkbox
                  key={"0"}
                  id={"B7aaxsnCd"}
                  color={"default"}
                  size={"small"}
                  checked={partialQtyAllow}
                  onChange={(event) =>
                    changeOtherState("partialQtyAllow", event.target.checked)
                  }
                  inputProps={{
                    "aria-label": "Partial Quantity Allowed ?",
                  }}
                  className={classes.checkbox}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Return Policy Section Starts */}
          <div className={classes.returnPolicySection}>
            <div className={classes.returnPolicy}>
              <Typography className={classes.returnPolicyTitle}>
                Return Policy
              </Typography>
              {!Boolean(returnPolicy) && (
                <Button
                  key={"0"}
                  id={"add_new_return_policy"}
                  variant={"text"}
                  color={"primary"}
                  children={"+ Add New"}
                  aliasName={"returnApplicableAddnewbutton"}
                  style={qdmstyles.UQJ}
                  onClick={handleAddNewClick}
                />
              )}
            </div>
            {Boolean(returnPolicy) && (
              <div className={classes.policyDescPaper}>
                <div className={classes.policyTitleContainer}>
                  <Typography className={classes.policyDescTitle}>
                    Policy Description
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => handleEditClick(true)}
                  >
                    <CreateIcon color="#6A7888" />
                  </IconButton>
                </div>
                <Divider
                  key={"0"}
                  id={"yohaAtb"}
                  variant={"fullWidth"}
                  light={true}
                  className={classes.policyTitleDivider}
                  // style={qdmstyles.divider}
                ></Divider>
                <Typography
                  key={"0"}
                  id={"cTAwT"}
                  align={"inherit"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  gutterBottom={""}
                  className={classes.policyDescText}
                >
                  <div className={classes.instructionValueContainer}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: returnPolicy,
                      }}
                    />
                    <div style={{ alignSelf: "flex-end" }}>
                      {/* <Button
                                  key={"0"}
                                  id={"seemore"}
                                  color={"primary"}
                                  children={"See More"}
                                  style={{
                                    ...qdmstyles.gPw,
                                    lineHeight: "1",
                                    padding: "0px",
                                    textDecoration: "underline",
                                  }}
                                  disableRipple
                                  onClick={() => {}}
                                ></Button> */}
                    </div>
                  </div>
                </Typography>
              </div>
            )}
          </div>
          {/* Return Policy Section Ends */}
          {/* Top Section Ends */}

          {/* Divider Section Starts */}
          <Grid
            key={"2"}
            id={"n1RM4"}
            container={""}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            lg={"12"}
          >
            <Divider
              key={"0"}
              id={"yohAt"}
              variant={"fullWidth"}
              flexItem={""}
              orientation={"horizontal"}
              light={true}
              style={qdmstyles.divider}
            ></Divider>
          </Grid>
          {/* Divider Section Ends */}

          {/* Header Starts */}
          <Grid
            key={"0"}
            id={"TgstR"}
            container={true}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            lg={"12"}
            item={true}
            xl={"12"}
            xs={"12"}
            sm={"12"}
            md={"12"}
            style={qdmstyles.FzOFq}
          >
            <Grid
              key={"0"}
              id={"TgstR"}
              container={""}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              item={true}
              lg={"5"}
              md={"10"}
              sm={"6"}
              xl={"6"}
              xs={"6"}
            >
              <Typography
                key={"0"}
                id={"ADD_NEW_RETURN_PARAMETERSHeading_Typography"}
                align={"left"}
                color={"initial"}
                display={"initial"}
                variant={"body1"}
                children={"ADD NEW RETURN PARAMETERS"}
                aliasName={"ADD_NEW_RETURN_PARAMETERS_Heading"}
                style={qdmstyles.UENRN}
              />
            </Grid>
            <Grid
              key={"1"}
              id={"TgstR"}
              container={""}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              item={true}
              lg={"2"}
              md={"2"}
              sm={"2"}
              xl={"2"}
              xs={"2"}
              style={qdmstyles.Zoos}
            >
              <Button
                key={"0"}
                id={"returnApplicableAddnewbutton_Button"}
                variant={"text"}
                color={"primary"}
                children={"+ Add New"}
                aliasName={"returnApplicableAddnewbutton"}
                style={qdmstyles.UQJ}
                onClick={() => onAddNewClick(stateName)}
              />
            </Grid>
          </Grid>
          {/* Header Ends */}

          {/* Body Starts */}
          <Grid
            key={"1"}
            id={"0PNTj"}
            container={true}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            item={true}
            wrap={"wrap"}
            spacing={""}
            lg={"12"}
            md={"12"}
            sm={"12"}
            xl={"12"}
            xs={"12"}
          >
            <Grid
              key={"0"}
              id={"7uXWP"}
              container={true}
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"baseline"}
              lg={"12"}
              item={true}
              md={"12"}
              sm={"12"}
              xl={"12"}
              xs={"12"}
              spacing={1}
            >
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography
                    key={"0"}
                    id={"reasonForReturnHeading_Typography"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Reason For Return"}
                    aliasName={"reasonForReturnHeading"}
                    style={qdmstyles.HScd}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Typography
                    key={"0"}
                    id={"Stock_Reflection_RequiredHeading_Typography"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Stock Reflection Required"}
                    aliasName={"Stock_Reflection_RequiredHeading"}
                    style={qdmstyles.DoNfh}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Typography
                    key={"0"}
                    id={"Approval_RequiredHeading_Typography"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Approval Required"}
                    aliasName={"Approval_RequiredHeading"}
                    style={qdmstyles.micoB}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography
                    key={"0"}
                    id={"Bill_Reflection_Required_header"}
                    align={"inherit"}
                    color={"initial"}
                    display={"initial"}
                    variant={"body1"}
                    children={"Bill Reflection Required"}
                    aliasName={"Bill_Reflection_Requiredheader"}
                    style={qdmstyles.FBQ}
                  />
                </Grid>
              </Grid>

              {Array.isArray(statevalue) &&
                statevalue?.map((item, index) => {
                  const rowState = statevalue?.[index];
                  return (
                    <Grid container item xs={12} spacing={1}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                          id={"kXfMY"}
                          style={qdmstyles?.kXfMY}
                          getOptionLabel={(option) => option?.["label"]}
                          options={options?.return_reason ?? []}
                          size={"small"}
                          placeholder=""
                          onLoad={() =>
                            setFun(
                              stateName,
                              "returnReason",
                              rowState?.returnReason,
                              index
                            )
                          }
                          value={rowState?.returnReason ?? null}
                          onChange={(event, newValue) =>
                            setFun(stateName, "returnReason", newValue, index)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Checkbox
                          key={"0"}
                          id={"B7asnCd"}
                          color={"default"}
                          size={"small"}
                          checked={rowState?.stockReflection}
                          onChange={(event) =>
                            setFun(
                              stateName,
                              "stockReflection",
                              event.target.checked,
                              index
                            )
                          }
                          inputProps={{
                            "aria-label": "Stock Reflection Required",
                          }}
                          className={classes.checkbox}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <Checkbox
                          key={"0"}
                          id={"B7asnCd"}
                          color={"default"}
                          size={"small"}
                          checked={rowState?.approvalReq}
                          onChange={(event) =>
                            setFun(
                              stateName,
                              "approvalReq",
                              event.target.checked,
                              index
                            )
                          }
                          inputProps={{
                            "aria-label": "Approval Required",
                          }}
                          className={classes.checkbox}
                        />
                      </Grid>
                      <Grid container item xs={12} sm={6} md={3} spacing={1}>
                        <Grid item xs>
                          <Checkbox
                            key={"0"}
                            id={"B7asnCd"}
                            color={"default"}
                            size={"small"}
                            checked={rowState?.billReflection}
                            onChange={(event) =>
                              setFun(
                                stateName,
                                "billReflection",
                                event.target.checked,
                                index
                              )
                            }
                            inputProps={{
                              "aria-label": "Bill Reflection Required",
                            }}
                            className={classes.checkbox}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() => onDeleteClick(stateName, index)}
                          >
                            <TrashIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
          {/* Body Ends */}

          {/* Modal Section Starts */}
          <Modal
            open={state.showAddNewReturnPolicyModal}
            className={classes.modal}
            onClose={handleModalClose}
          >
            <Paper className={classes.modalPaper}>
              <div className={classes.modalHeader}>
                <Typography style={{ fontFamily: "pc_semibold" }}>
                  EDIT RETURN POLICY
                </Typography>

                <IconButton
                  size="small"
                  onClick={() =>
                    changeState(
                      "showAddNewReturnPolicyModal",
                      !state.showAddNewReturnPolicyModal
                    )
                  }
                >
                  <CloseRoundedIcon htmlColor="rgba(1, 32, 92, 1)" />
                </IconButton>
              </div>

              <Divider className={classes.modalHeaderDivider} />

              <div className={classes.modalBodyContainer}>
                <div className={classes.modalInputContainer}>
                  <Typography
                    variant={"body1"}
                    style={{ fontFamily: "pc_regular", fontSize: 12 }}
                  >
                    Return Policy
                  </Typography>

                  <RichTextfield
                    initialValue={state?.returnPolicy}
                    onChange={(rawValue) =>
                      changeState("returnPolicy", rawValue)
                    }
                  />
                </div>

                <div className={classes.modalActionContainer}>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    // style={qdmstyles.CPPs}
                    size="small"
                    className={classes.modalAdd}
                    onClick={() => handleSaveClick()}
                  >
                    {state?.isEdit ? "Update" : "Add"}
                  </Button>
                </div>
              </div>
            </Paper>
          </Modal>
          {/* Modal Section Ends */}
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

export default withRouter(ReturnApplicable);
