import React from "react";
import {
  Div,
  Paper,
  //Select,
  TextInput,
  //Upload,
  H6,
  Col,
  Row,
  // Text,
  // Icons,
  // Image,
  // Avatar,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
// import { uploadImage } from "../../../utils";
// import Delete from "../../../assets/icons8-trash.svg";
// import { DateTimePicker } from "../../common/smartForm/component";
// import moment from "moment";
import { SelectBox } from "../../common/smartForm/component";
import { Typography } from "@material-ui/core";
class HealthCareServiceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
    },
  };

  render() {
    const { labelStyle, borderStyle, errorBorderStyle } = this.styles;
    const { parent_id,error } = this.props;
    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root">
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-parent-sub-div`}
          >
            <H6
              id={`${parent_id}-title-h6`}
              className="pi-title">
              {"HEALTH CARE SERVICE DETAILS"}
            </H6>
          </Div>
          <Row
            id={`${parent_id}-parent-row`}
          >
            <Col
              id={`${parent_id}-serviceCode-col`}
              md={2}
              lg={2}
              sm={6}
              xs={12}
              inLineStyles={{ padding: 10 }}
            >
              <TextInput
                id={`${parent_id}-serviceCode-textInput`}
                value={this.props.serviceCode ? this.props.serviceCode : ""}
                onChange={(e) =>
                  this.props.onInputChange("serviceCode", e.target.value, 20,"uppercase")
                }
                label="Service Code"
                isRequired={true}
                placeholder="Enter Code"
                labelStyle={labelStyle}
                style={error?.serviceCode ? errorBorderStyle :  borderStyle}
                hoverColor="#0071F2"
              />
            </Col>
            <Col
              id={`${parent_id}-serviceDescription-col`}
              md={4}
              lg={4}
              sm={6}
              xs={12}
              inLineStyles={{ padding: 10 }}
            >
              <TextInput
                id={`${parent_id}-serviceDescription-textInput`}
                value={this.props.serviceDescription ? this.props.serviceDescription : ""}
                onChange={(e) =>
                  this.props.onInputChange("serviceDescription", e.target.value,60)
                }
                label="Service Description"
                isRequired={true}
                placeholder="Description"
                labelStyle={labelStyle}
                style={ error?.serviceDescription ? errorBorderStyle :  borderStyle}
                hoverColor="#0071F2"
              />
            </Col>
            <Col
              id={`${parent_id}-Service-Category-col`}
              md={3}
              lg={3}
              sm={6}
              xs={12}
              className="no-padding-left"
              inLineStyles={{ padding: 10, color: "#101010" }}
            >
              <Typography
                id={`${parent_id}-Service-Category-typography`}
                style={{
                  fontSize: "12px",

                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Service Category<span
                  id={`${parent_id}-Service-Category-star-span`}
                  style={{ color: "red" }}>*</span>
              </Typography>
              <SelectBox
                id={`${parent_id}-ServiceCategory-selectBox`}
                list={this.props.allMasters.serviceCategory ?? []}
                placeholder={"select..."}
                disableClearable
                value={this.props.serviceCategory ? this.props.serviceCategory : {}}
                onchange={(e, value) => this.props.onSelectChange("serviceCategory", value)}
                 error={error?.serviceCategory}
              />
            </Col>
            <Col
              id={`${parent_id}-Servicetype-col`}
              md={3}
              lg={3}
              sm={6}
              xs={12}
              className="no-padding-left"
              inLineStyles={{ padding: 10, color: "#101010" }}
            >
              <Typography
                id={`${parent_id}-Servicetype-Typography`}
                style={{
                  fontSize: "12px",

                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Service Type<span
                  id={`${parent_id}-Servicetype-star-span`}
                  style={{ color: "red" }}>*</span>
              </Typography>
              <SelectBox
                id={`${parent_id}-Servicetype-selectBox`}
                list={this.props.allMasters.serviceType ?? []}
                placeholder={"select..."}
                disableClearable
                value={this.props.serviceType ? this.props.serviceType : {}}
                onchange={(e, value) => this.props.onSelectChange("serviceType", value)}
                 error={error?.serviceType}
              />
            </Col>
            <Col
              id={`${parent_id}-Speciality-col`}
              md={3}
              lg={3}
              sm={6}
              xs={12}
              className="no-padding-left"
              inLineStyles={{ padding: 10, color: "#101010" }}
            >
              <Typography
                id={`${parent_id}-Speciality-typography`}
                style={{
                  fontSize: "12px",

                  Fontfamily: "pc_regular",
                  color: "#6F6F6F",
                }}
                variant="body1"
              >
                Specialty<span
                  id={`${parent_id}-Speciality-star-span`}
                  style={{ color: "red" }}>*</span>
              </Typography>
              <SelectBox
                id={`${parent_id}-Speciality-selectBox`}
                list={this.props.allMasters.speciality ?? []}
                placeholder={"select..."}
                disableClearable
                value={this.props.speciality ? this.props.speciality : {}}
                onchange={(e, value) => this.props.onSelectChange("speciality", value)}
                error={error?.speciality}
              />
            </Col>
            <Col
              id={`${parent_id}-Comment-col`}
              md={4}
              lg={4}
              sm={6}
              xs={12}
              inLineStyles={{ padding: 10 }}
            >
              <TextInput
                id={`${parent_id}-Comment-textInput`}
                value={this.props.comment ? this.props.comment : ""}
                onChange={(e) =>
                  this.props.onInputChange("comment", e.target.value)
                }
                label="Comment"
                //isRequired={true}
                placeholder="Enter Comment"
                labelStyle={labelStyle}
                style={borderStyle}
                hoverColor="#0071F2"
                maxLength="50"
              />
            </Col>
            <Col
              id={`${parent_id}-Notes-col`}
              md={5}
              lg={5}
              sm={6}
              xs={12}
              inLineStyles={{ padding: 10 }}
            >
              <TextInput
                id={`${parent_id}-Notes-textInput`}
                value={this.props.notes ? this.props.notes : ""}
                onChange={(e) =>
                  this.props.onInputChange("notes", e.target.value)
                }
                label="Notes"
                //isRequired={true}
                placeholder="Enter Notes"
                labelStyle={labelStyle}
                style={borderStyle}
                hoverColor="#0071F2"
              />
            </Col>
          </Row>
        </Paper>
      </Div>
    );
  }
}

export default withAllContexts(HealthCareServiceDetail);
