import React from 'react';
import { makeStyles, Switch, Typography } from '@material-ui/core';
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
    root: {
    },
    label: {
        color: '#6F6F6F',
        fontSize: 12,
        fontFamily: 'pc_regular',
        marginBottom: 8
    }
}))

export const SwitchLabel = (props) => {

    const classes = useStyles(props);
    const { parent_id } = props;


    return (
        <div
            id={`${parent_id}-parent-div`} className={classes.root}>
            {
                props.label &&
                <Typography
                    id={`${parent_id}-${props?.label.replaceAll(" ", "-")}-typography`} className={classes.label}>{props?.label}
                    {props?.required && <span style={{ color: "red" }} >*</span>}
                </Typography>
            }
            <Switch
                id={`${parent_id}-Switch`}
                checked={props?.value ?? false}
                onChange={(e) => props?.onChange(e.target.checked)}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />

        </div>
    )
}



SwitchLabel.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.bool,
    onChange: PropTypes.func,
}

SwitchLabel.defaultProps = {
    required: false,
    label: '',
    value: false,
    onChange: null,
}