import React, { useState } from "react";
import {
  Typography,
  Grid,
  //Paper,
  TextField,
  Button,
  //IconButton,
} from "@material-ui/core";
import { withAllContexts } from "../../../HOCs";
import styles from "./styles";
import Config from "../../../config";
import { v4 as uuidv4 } from "uuid";
import { upsertDocument } from "../../../function/commonapi";

function AddNewForms(props) {
  const classes = styles();
  const [formData, setformData] = useState({
    formName: props?.formParams?.elementname
      ? props?.formParams?.elementname
      : "",
  });
  const [error, setError] = useState({
    formName: false,
    msg: "Please enter the field",
  });

  const handleformData = (event) => {
    setformData({ ...formData, [event.target.name]: event.target.value });
  };

  const duplicate = props.incomingFormData.filter(
    (itm) => itm.Repository.elementname === formData.formName
  );

  const validation = () => {
    let isValidate = false;

    if (formData?.formName?.trim()?.length > 0) {
      error.formName = false;
    } else {
      isValidate = true;
      error.formName = true;
    }
    if (duplicate.length > 0) {
      isValidate = true;
      error.formName = true;
      error.msg = "Form already exists";
    } else {
      error.formName = false;
    }

    setError({
      ...error,
    });
    return isValidate;
  };
  const editFormsSubmit = async () => {
    const { drawer } = props;
    let check = validation();
    if (check) {
      return;
    }
    let list = {
      elementname: formData?.formName,
    };
    let sendFormData = {
      entity: Config.repositoryEntity,
      metadataId: Config.metadataid,
      isedit: true,
      id: props?.formParams?.rep_id,
      list,
      keyvalue: "rep_id",
    };
    let addEditfromUpsert = await upsertDocument(sendFormData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          props.getRepositoryData();

          props.handleAlerts("Form edited successfully!", true);
          drawer.setDrawer({
            ...drawer,
            open: false,
            component: <></>,
          });
        } else {
          props.handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        props.handleAlerts("Something  went wrong", false);
      });
  };

  const submitForms = async () => {
    let submittedForms = {
      formName: formData.formName,
      //   route: formData.route,
    };
    const { drawer } = props;
    let check = validation();
    if (check) {
      return;
    }

    let list = {
      rep_id: uuidv4(),
      rep_type: "Forms",
      elementname: submittedForms.formName,
      is_active: true,
    };

    let sendFormData = {
      entity: Config.repositoryEntity,
      metadataId: Config.metadataid,
      list,
    };

    let addPagesUpsert = await upsertDocument(sendFormData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          props.getRepositoryData();

          props.handleAlerts("Form created successfully!", true);
          drawer.setDrawer({
            ...drawer,
            open: false,
            component: <></>,
          });
        } else {
          props.handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        props.handleAlerts("Something  went wrong", false);
      });
  };

  return (
    <div>
      <div className={classes.drawerHead}>
        <Typography className={classes.headerMain}>
          {props.editName ? "Edit Forms" : "Add new form"}
        </Typography>
      </div>

      <Grid container direction="row">
        <Grid item xs={12}>
          <div className={classes.textTotalArea}>
            <Typography className={classes.drawerSubHead}>Form Name</Typography>
            <TextField
              label={"Form"}
              variant="outlined"
              fullWidth
              size="small"
              name="formName"
              onChange={handleformData}
              value={formData.formName}
              required
              inputProps={{ style: { fontFamily: "poppin", fontSize: "12px" } }}
              InputLabelProps={{
                style: { fontFamily: "poppin", fontSize: "12px" },
              }}
              error={error?.formName ? true : false}
              helperText={error?.formName ? error.msg : ""}
            />
          </div>
          <div className={classes.btnPosition}>
            <Button
              contained
              className={classes.addDrawerBtn}
              onClick={props.editName ? editFormsSubmit : submitForms}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withAllContexts(AddNewForms);
