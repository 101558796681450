import React from "react";
import {
  Div,
  Paper,
  //Checkbox,
  H6,
  Col,
  Row,
  Select,
  //Text,
  TextInput,
  //Divider,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
// import Chip from '@material-ui/core/Chip';
// import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import { actions } from "primary_care_admin_binder";
// import { connect } from "react-redux";

class LocationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedOptions: [],
    };
  }

  // componentDidMount = async () => {
  //   await this.props?.LOCATION_MASTERS_LOCATIONTYPE();
  //   await this.props?.LOCATION_MASTERS_OPERATIONALSTATUS();
  //   await this.props?.LOCATION_MASTERS_PARENT_LOCATION_ID_DESCRIPTION();
  //   await this.props?.LOCATION_MASTERS_LOCATIONROLETYPE();
  // };

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      border: "1px solid #E0E0E0"
    },
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
      marginRight: 10,
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
    },
  };

  render() {
    //let { communication } = this.props.patientData;
    const {
      labelStyle,
      borderStyle,
      errorBorderStyle,
      //outlinedButton, containedButton
    } = this.styles;
    const { parent_id ,error } = this.props;
    return (
      <Div id={`${parent_id}-parent-div`} className="pi-root">
        <Paper id={`${parent_id}-parent-paper`} className="pi-paper">
          <Div id={`${parent_id}-LOCATION-div`}>
            <H6 id={`${parent_id}-title-h6`} className="pi-title">
              LOCATION DETAILS
            </H6>
          </Div>
          <Row id={`${parent_id}-parent-row`} className="pi-actions-root">
            <Col
              id={`${parent_id}-Location-Type-col`}
              md={3}
              lg={3}
              sm={6}
              xs={12}
              inLineStyles={{ padding: 5 }}
            >
              <Select
                id={`${parent_id}-Location-Type-Select`}
                showArrow
                allowClear={false}
                label="Location Type"
                placeholder="Location Type"
                labelStyle={labelStyle}
                //inLineStyles={borderStyle}
                inLineStyles={
                  error?.locationType ? errorBorderStyle : borderStyle
                }
                hoverColor="#0071F2"
                options={this.props.locationTypeData.data}
                onChangeValue={(value) =>
                  this.props.onChangeState("locationType", value)
                }
                value={this.props.locationType?.value ?? ""}
                isRequired={"true"}
                disabled={this.props?._key_id??false}
              />
            </Col>
            <Col
              id={`${parent_id}-Location-ID-col`}
              md={2}
              lg={2}
              sm={12}
              xs={12}
              inLineStyles={{ padding: 5 }}
            >
              <TextInput
                id={`${parent_id}-Location-ID-textInput`}
                placeholder="Location ID"
                onChange={(e) =>
                  this.props.onTextChangeState("locationId", e.target.value?.replaceAll(" ", "")?.toLocaleUpperCase(), 30)
                }
                value={this.props.locationId ?? ""}
                label="Location ID"
                labelStyle={labelStyle}
                style={error?.locationId ? errorBorderStyle : borderStyle}
                hoverColor="#0071F2"
                isRequired={"true"}
                maxLength="20"
                disabled={this.props?._key_id??false}
              />
            </Col>
            <Col
              id={`${parent_id}-Short-Description-col`}
              md={3}
              lg={3}
              sm={12}
              xs={12}
              inLineStyles={{ padding: 5 }}
            >
              <TextInput
                id={`${parent_id}-Short-Description-textInput`}
                placeholder="Short Description"
                onChange={(e) =>
                  this.props.onTextChangeState(
                    "shortDescription",
                    e.target.value,
                    60
                  )
                }
                value={this.props.shortDescription ?? ""}
                label="Short Description"
                labelStyle={labelStyle}
                style={error?.shortDescription ? errorBorderStyle : borderStyle}
                hoverColor="#0071F2"
                isRequired={"true"}
                maxLength="60"
              />
            </Col>
            <Col
              id={`${parent_id}-Long-Description-col`}
              md={4}
              lg={4}
              sm={12}
              xs={12}
              inLineStyles={{ padding: 5 }}
            >
              <TextInput
                id={`${parent_id}-Long-Description-textInput`}
                placeholder="Long Description"
                onChange={(e) =>
                  this.props.onTextChangeState(
                    "longDescription",
                    e.target.value,
                    100
                  )
                }
                value={this.props.longDescription ?? ""}
                label="Long Description"
                labelStyle={labelStyle}
                style={error?.longDescription ? errorBorderStyle : borderStyle}
                hoverColor="#0071F2"
                isRequired={"true"}
                maxLength="100"
              />
            </Col>
            {/* <Divider
              className="p-dashed-divider "
              inLineStyles={{ margin: "5px 0px" }}
            /> */}
            <Col
              id={`${parent_id}-Operational-Status-col`}
              md={3}
              lg={3}
              sm={6}
              xs={12}
              inLineStyles={{ padding: 5 }}
            >
              <Select
                id={`${parent_id}-Operational-Status-select`}
                showArrow
                label="Operational Status"
                placeholder="Operational Status"
                labelStyle={labelStyle}
                //inLineStyles={borderStyle}
                hoverColor="#0071F2"
                options={this.props.operationalStatusData.data}
                onChangeValue={(value) =>
                  this.props.onChangeState("operationalStatus", value)
                }
                value={this.props.operationalStatus?.value ?? ""}
                inLineStyles={
                  error?.operationalStatus ? errorBorderStyle : borderStyle
                }
                isRequired={"true"}
              />
            </Col>
            <Col
              id={`${parent_id}-Location-ID-Description-col`}
              md={4}
              lg={4}
              sm={6}
              xs={12}
              inLineStyles={{ padding: 5 }}
            >
              <Select
                id={`${parent_id}-Location-ID-Description-select`}
                showArrow
                label="Parent Location ID and Description"
                placeholder="Parent Location ID and Description"
                labelStyle={labelStyle}
                inLineStyles={borderStyle}
                hoverColor="#0071F2"
                options={this.props.parentLocationData.data}
                onChangeValue={(value) =>
                  this.props.onChangeState(
                    "parentLocationIdandDescription",
                    value
                  )
                }
                value={this.props.parentLocationIdandDescription?.value ?? ""}
                // isRequired={"true"}
              />
            </Col>
            <Col
              id={`${parent_id}-Location-Role-Type-col`}
              md={3}
              lg={3}
              sm={6}
              xs={12}
              inLineStyles={{ padding: 5 }}
            >
              <Select
                id={`${parent_id}-Location-Role-Type-select`}
                showArrow
                label="Location Role Type"
                placeholder="Location Role Type"
                labelStyle={labelStyle}
                //inLineStyles={borderStyle}
                hoverColor="#0071F2"
                options={this.props.locationRoleTypeData.data}
                onChangeValue={(value) =>
                  this.props.onChangeState("locationRoleType", value)
                }
                value={this.props.locationRoleType?.value ?? ""}
                inLineStyles={
                  error?.locationRoleType ? errorBorderStyle : borderStyle
                }
                isRequired={"true"}
              />
            </Col>
          </Row>
        </Paper>
      </Div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   locationTypeData: state?.locationMasterSlice?.location_masters_locationtype,
//   operationalStatusData:
//     state?.locationMasterSlice?.location_masters_operationalstatus,
//   parentLocationData:
//     state?.locationMasterSlice?.location_masters_parent_location_id,
//   locationRoleTypeData:
//     state?.locationMasterSlice?.location_masters_locationroletype,
// });

// export default connect(
//   mapStateToProps,
//   actions
// )(withAllContexts(LocationDetails));

export default withAllContexts(LocationDetails);
