import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  //TextField,
  Button,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { CommonTable, GenerateForm } from "../../../../../../components";
import { withAllContexts } from "../../../../../../HOCs";
import { AlertProps } from "../../../../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  addBtn: {
    float: "right",
    fontSize: "14px",
    fontWeight: 600,
  },
  title: {
    fontFamily: "poppinsemibold",
    display: "inline",
    fontSize: "12px",
  },
  form: {
    paddingTop: 15,
  },
}));

function Applicability(props) {
  const [state, setState] = React.useState({
    open: false,
    saveloading: false,
    edit_data: false,
    editIndex: null,
  });

  const vitalCode = useSelector(
    (state) => state?.vitalsApplicableSlice?.vital_code?.data
  );
  const classes = useStyles();
  const { applicability, handleFormState, parent_id } = props;

  const handleDrawerClose = () => {
    setState({ ...state, open: false, editIndex: null });
  };

  //handle save applicable table
  const saveAgeRange = (data) => {
    let obj = { ...data };
    Object.keys(obj).map((key, i) => {
      if (key === "mandatory") {
        if (obj[key] === true) {
          obj[key] = "Yes";
          obj["mandatoryBoolean"] = true;
        } else {
          obj[key] = "No";
          obj["mandatoryBoolean"] = false;
        }
      }
    });
    let list = JSON.parse(JSON.stringify(applicability));
    const hasSameSequence = list?.find((data, index) =>
      state.editIndex || state.editIndex === 0
        ? state.editIndex !== index && +data?.sequences === +obj?.sequences
        : +data?.sequences === +obj?.sequences
    );
    if (hasSameSequence) {
      return props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: `There is an another vital applicability with same vitals Sequence`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
    if (state.editIndex || state.editIndex === 0) {
      list[state.editIndex] = {
        ...list[state.editIndex],
        ...obj,
      };
    } else {
      list.push(obj);
    }
    handleFormState("applicability", list);
    handleDrawerClose();
  };
  //handle delete applicable
  const handleDelete = (e, data, index) => {
    let list = JSON.parse(JSON.stringify(applicability));
    list.splice(index, 1);
    handleFormState("applicability", list);
  };
  const condition = (value, type) => {
    if (type === "DELETE") {
      if (value._id) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  return (
    <React.Fragment>
      <Paper id={`${parent_id}-parent-paper`} className={classes.root}>
        <Typography
          id={`${parent_id}-VITALS-typography`}
          className={classes.title}
          variant="h6"
        >
          VITALS APPLICABILITY
        </Typography>
        <Button
          id={`${parent_id}-Add-New-button`}
          className={classes.addBtn}
          onClick={() => setState({ ...state, open: true })}
          color="primary"
        >
          + Add New
        </Button>
        <Grid
          id={`${parent_id}-CommonTable-grid`}
          container
          className={classes.form}
        >
          <CommonTable
            parent_id={"applicapility"}
            handleEdit={(e, data, index) =>
              setState({ ...state, editIndex: index, open: true })
            }
            handleDelete={handleDelete}
            rightAlign={["Action"]}
            Header={["Vitals", "Vitals Sequences", "Mandatory", "Action"]}
            enableStart={["Vitals", "Vitals Sequences", "Mandatory"]}
            dataList={applicability}
            tableData={[
              { type: ["TEXT"], name: "code", optionLabel: "label" },
              { type: ["TEXT"], name: "sequences" },
              { type: ["TEXT"], name: "mandatory" },

              { type: ["EDIT", "DELETE"], name: "", align: "right" },
            ]}
            condition={condition}
          />
        </Grid>
        <GenerateForm
          parent_id={"applicapility"}
          nocancel
          loading={state.saveloading}
          open={state.open}
          save={saveAgeRange}
          handlecancel={handleDrawerClose}
          header={
            state.editIndex !== null
              ? "Edit vitals Applicability"
              : "Add New vitals Applicability"
          }
          json={[
            {
              componet_type: "select",
              label: "Vitals",
              options: vitalCode,
              state_name: "code",
              value: applicability?.[state.editIndex]?.code ?? "",
              required: true,
            },
            {
              componet_type: "text",
              label: "Vitals Sequence",
              state_name: "sequences",
              value: applicability?.[state.editIndex]?.sequences ?? "",
              required: true,
              type: "number"
            },
            {
              componet_type: "checkbox",
              label: "Mandatory",
              state_name: "mandatory",
              value:
                applicability?.[state.editIndex]?.mandatory === "Yes"
                  ? true
                  : false ?? "",
              required: true,
            },
          ]}
        />
      </Paper>
    </React.Fragment>
  );
}

export default withAllContexts(Applicability);
