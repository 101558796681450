import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  //Paper,
  TextField,
  Button,
 // CircularProgress,
} from "@material-ui/core";
import { withAllContexts } from "../../../HOCs";
import styles from "./styles";
import {
  addUserToKeyClock,
  upsertDocument,
  //getUserId,
  updateUserToKeyClock,
  //readDocument,
} from "../../../function/commonapi";
import Config from "../../../config";
import { v4 as uuidv4 } from "uuid";

function CreateNewUser(props) {
  const classes = styles();
  const [userData, setUserData] = useState({
    username: "",
    id: "",
  });
  const [loader, setLoader] = useState(false);
  const [nameSet, setNameSet] = useState({
    userName: "",
    email: "",
  });

  console.log("userData", userData);
  // console.log("nameSet", nameSet);

  const [errorMgsUser, setErrorMgsUser] = useState("");
  useEffect(() => {
    if (props.editName) {
      setUserData({
        username: props.editName,
        id: props.userParams._id,
      });
    }
  }, []);

  const duplicate = props.incomingUserdata.filter(
    (itm) => itm?.name[0].text === userData.username
  );

  const duplicateEmail = props.incomingUserdata.filter(
    (itm) => itm?.telecom[0]?.value === userData.username
  );

  const handleUserData = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
    let valid = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      event.target.value
    );
    if (valid === true) {
      setNameSet({ ...nameSet, email: event.target.value, userName: "" });
    } else {
      setNameSet({ ...nameSet, userName: event.target.value, email: "" });
    }
  };

  const validation = () => {
    if (userData?.username.length > 0) {
      return true;
    }
    return false ;
  };

  // SUBMIT EDIT
  const submitEditUsers = async () => {
    const { drawer } = props;

    let submittedFields = {
      userName: nameSet.userName,
      email: nameSet.email,
    };
    if (!validation) {
      return ;
    }
   if(userData.username.length === 0){
      setErrorMgsUser("Please fill the required field");
    }else if (duplicate.length > 0 || duplicateEmail.length > 0) {
      setErrorMgsUser("User name or email already exist");
    } else {
      setLoader(true);
      let list = {
        name: [
          {
            use: "string",
            text: submittedFields.userName,
            family: "",
            given: submittedFields.userName,
            prefix: "",
            suffix: "",
            period: [
              {
                start: Math.floor(Date.now() / 1000),
                end: 0,
              },
            ],
          },
        ],
        telecom: [
          {
            system: "Email",
            value: submittedFields.email || "", // number
            use: "Work", // phone
            rank: "1", // 1
            period: [
              {
                start: Math.floor(Date.now() / 1000),
                end: 0,
              },
            ],
          },
        ],
      };
      // list.push(newobj)
      let tokenData = localStorage.getItem("payload");
      let parsedToken = JSON.parse(tokenData);

      let sendUserData = {
        entity: Config.personEntity,
        metadataId: Config.metadataid,
        isedit: true,
        id: props?.userParams?.Id,
        list,
        keyvalue: "Id",
      };
      let addUserKeyClock = await updateUserToKeyClock(userData.username);

      let addUserUpsert = await upsertDocument(sendUserData);
      let readDocParams = {
        entity: Config.personEntity,
      };
      props.getUserData();
      drawer.setDrawer({
        ...drawer,
        open: false,
        component: <></>,
      });
      props.handleAlerts("User edited successfully!", true);
    }
  };

  // SUBMIT
  const submitUsers = async () => {
    //;
    let submittedUsers = {
      username: userData.username,
    };
    const { drawer } = props;

    let submittedFields = {
      userName: nameSet.userName,
      email: nameSet.email,
    };

    if (userData.username.length == 0) {
      setErrorMgsUser("Please fill the required field");
    } else if (duplicate.length > 0 || duplicateEmail.length > 0) {
      setErrorMgsUser("User name or email already exist");
    } else {
      setLoader(true);
      if (userData.username.length > 0) {
        // let list = [];
        let list = {
          identifier: [],
          name: [
            {
              use: "string",
              text: submittedFields.userName,
              family: "",
              given: submittedFields.userName,
              // "prefix": gender.toLowerCase() === "male" ? "Mr" : "Mrs",
              prefix: "",
              suffix: "",
              period: [
                {
                  start: Math.floor(Date.now() / 1000),
                  end: 0,
                },
              ],
            },
          ],
          telecom: [
            //   {
            //     "system": "Phone",
            //     "value": phone, // number
            //     "use": "Mobile", // phone
            //     "rank": "1", // 1
            //     "period": [{
            //         "start": Math.floor(Date.now() / 1000),
            //         "end": 0
            //     }]
            // },
            {
              system: "Email",
              value: submittedFields.email || "", // number
              use: "Work", // phone
              rank: "1", // 1
              period: [
                {
                  start: Math.floor(Date.now() / 1000),
                  end: 0,
                },
              ],
            },
          ],
          gender: "",
          // "birthDay": dob.replace(/-/g, '/'), // DD/MM/YYYY
          birthDay: "",
          address: [],
          photo: [
            //   {
            //   "fileName": "",
            //   "fileid": "",
            //   "filetype": "",
            //   "objectid": "",
            // }
          ],
          active: true,
          link: [],
          Id: 0,
          RelatedPersonID: [],
          OrgID: [],
        };
        // list.push(newobj)
        let tokenData = localStorage.getItem("payload");
        // 
        let parsedToken = JSON.parse(tokenData);

        let sendUserData = {
          entity: Config.personEntity,
          metadataId: Config.metadataid,
          list,
        };
        console.log("sendUserData", sendUserData);
        let addUserKeyClock = await addUserToKeyClock(
          parsedToken.access_token,
          submittedUsers.username
        );
        let addUserUpsert = await upsertDocument(sendUserData)
          .then((res) => {
            if (res?.data?.Code === 201) {
              props.getUserData();
              drawer.setDrawer({
                ...drawer,
                open: false,
                component: <></>,
              });
              props.handleAlerts("User created successfully!", true);
            } else {
              props.handleAlerts("Something  went wrong", false);
            }
          })
          .catch((error) => {
            props.handleAlerts("Something  went wrong", false);
          });
      } 
      // else if (valid === false) {
      //   setErrorMgsUser("Please enter a valid email address");
      // }
    }
    console.log("submittedUsers", submittedUsers);
  };

  return (
    <div>
      <div className={classes.drawerHead}>
        <Typography className={classes.headerMain}>
          {props.editName ? "Edit User" : "Add New User"}
        </Typography>
      </div>

      <Grid container direction="row">
        <Grid item xs={12}>
          <div className={classes.textTotalArea}>
            <Typography className={classes.drawerSubHead}>User Name</Typography>
            <TextField
              label={"Users"}
              variant="outlined"
              fullWidth
              size="small"
              name={"username"}
              onChange={handleUserData}
              value={userData.username}
              required
              inputProps={{ style: { fontFamily: "poppin", fontSize: "12px" } }}
              InputLabelProps={{
                style: { fontFamily: "poppin", fontSize: "12px" },
              }}
              helperText={errorMgsUser}
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
              error={errorMgsUser.length > 0 ? true : false}
            />
          </div>
          <div className={classes.btnPosition}>
            {loader ? (
              <Button
              contained
              className={classes.addDrawerBtn}
              disabled
            >
              Loading ...
            </Button>
            ) : (
              <Button
                contained
                className={classes.addDrawerBtn}
                onClick={props.editName ? submitEditUsers : submitUsers}
              >
                Save
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withAllContexts(CreateNewUser);
