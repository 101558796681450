import React from "react";
import { Paper, Typography, makeStyles, Grid, TextField } from "@material-ui/core";
import { TableSwitch } from "../../../../../components";
import { InputComp } from "../../../../../components/common/smartForm/component";
const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10
    },
    title: {
        fontFamily: "poppinsemibold",
        fontSize: "12px",
    },
    form: {
        paddingTop: 15
    },
    formtitle: {
        fontSize: "12px",
        paddingBottom: 10
    },
    formParent: {
        paddingRight: 10
    }
}));

function RangeDetails(props) {

    const classes = useStyles();
    const {
      code,
      longdes,
      shortdes,
      status,
      handleFormState,
      parent_id,
      error,
    } = props;
    return (
        <React.Fragment>
            <Paper
                id={`${parent_id}_parent_paper`}
                className={classes.root}>
                <Typography
                    id={`${parent_id}_title_typography`}
                    className={classes.title} variant="h6">AGE RANGES DETAILS</Typography>
                <Grid
                    id={`${parent_id}_parent_grid`}
                    container className={classes.form}>
                    <Grid
                        id={`${parent_id}_code_grid`}
                        md="3" lg="3" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}_Range_Code_typography`}
                            className={classes.formtitle} variant="body1">Age Range Code <span style={{ color: "red" }}>*</span></Typography>
                        {/* <TextField
                            id={`${parent_id}_Range_Code_TextField`}
                            onChange={e => handleFormState("code", e.target.value)}
                            value={code}
                            fullWidth
                            type="text"
                            variant="outlined"
                            size="small"
                        /> */}
                        <InputComp
                        disabled={props?.isEdit??false}
                        id={`${parent_id}_Range_Code_TextField`}
                        value={code}
                        onChange={e => handleFormState("code", e.target.value, 20)}
                        onInput={(e) => {
                                // e.target.value = e.target.value.replace(/[^A-Za-z]/g, "")?.toLocaleUpperCase();
                                e.target.value = e.target.value?.replaceAll(" ", "")?.toLocaleUpperCase()
                            }}
                            error={error?.code}
                         />
                    </Grid>
                    <Grid
                        id={`${parent_id}_Description_grid`}
                        md="4" lg="4" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}_Description_typography`}
                            className={classes.formtitle} variant="body1">Short Description <span style={{ color: "red" }}>*</span></Typography>
                        <TextField
                            id={`${parent_id}_Description_TextField`}
                            value={shortdes} onChange={e => handleFormState("shortdes", e.target.value, 60)}
                            fullWidth
                            type="text"
                            variant="outlined"
                            size="small"
                            error={error?.shortdes}
                        />
                    </Grid>
                    <Grid
                        id={`${parent_id}_Long_grid`}
                        md="5" lg="5" className={classes.formParent}>
                        <Typography
                            id={`${parent_id}_Long_typography`}
                            className={classes.formtitle} variant="body1">Long Description <span style={{ color: "red" }}>*</span></Typography>
                        <TextField
                            id={`${parent_id}_Long_TextField`}
                            value={longdes} onChange={e => handleFormState("longdes", e.target.value, 100)}
                            fullWidth
                            type="text"
                            variant="outlined"
                            size="small"
                             error={error?.longdes}
                        />
                    </Grid>
                    {/* <Grid md="3" lg="3" className={classes.formParent}>
                        <Typography className={classes.formtitle} variant="body1">Status <span style={{ color: "red" }}>*</span></Typography>
                        <div>
                            <TableSwitch checked={status} onChange={(e) => handleFormState("status", e.target.checked)} name="checkbox" />
                        </div>
                    </Grid> */}
                </Grid>
            </Paper>
        </React.Fragment>
    )
}

export default RangeDetails;