import React, { useEffect, useState } from "react";
//import { makeStyles } from "@material-ui/core/styles";
import { Card, Button } from "@material-ui/core";
import {
  CircularProgress,
  //Drawer
} from "@material-ui/core";
//import expandarrow from "../../../assets/icons8-expand-arrow.svg";
import { CommonTable, SearchWithFilter } from "../../../components";
//import { Row, Col } from "qdm-component-library";
//import { AlertProps } from "../../../utils";
import {
  useDispatch,
  // useSelector
} from "react-redux";
import { actions } from "primary_care_admin_binder";
//import moment from "moment";
//import Avatar from "@material-ui/core/Avatar";
import { routes } from "../../../router/routes";
import { useHistory } from "react-router";


//const drawerWidth = "94%";
// const useStyles = makeStyles((theme) => ({
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//   },
// }));

//  const persons =[{
//      name:"kathir",

//  }]

function Person(props) {
  //const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
  });

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);


  const [search, setSearch] = React.useState('');

  const statusChange = () => { };

  const handleEdit = (data, row, index) => {

    history.push({
      pathname: routes.addperson,
      state: { id: row._key }
    });
  };

  //integration by manikandan

  const dispatch = useDispatch();

  // React.useLayoutEffect(() => {
  //   // dispatch(actions.ROLE_READ());
  //   //  dispatch(actions.PERSON_READ())
  // }, []);
  useEffect(() => {
    constructTableJson(search, page, perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [tableState, setTableState] = useState({
    person_list: []
  });
  const [tableLoading, setTableLoading] = useState(true)
  const addNewPersonHandler = () => {
    setState({
      ...state,
      open: true,
    });
    history.push({
      pathname: routes.addperson,
    });
  };

  const constructTableJson = async (search, page, perPage) => {
    let personList = await dispatch(actions.PERSON_READ({ withoutLimit: false, search: search, page: page, perPage: perPage }))
    let personData = personList?.payload?.data?.map(_ => {
      let pht = null;
      if (_.photo && _.photo.length > 0) {
        pht = _.photo?.[0]?.fileid
      }
      return {
        TotalCount: _?.TotalCount,
        Name: _?.name[0]?.text === "" ? _?.telecom[0]?.value : _?.name[0]?.text,
        DOB: _?.birthDay ?? "",
        Gender: _?.gender?.display ?? "",
        Email: _?.telecom[0]?.system === 'Email' ? _?.telecom[0]?.value : "",
        Mobile: _?.telecom[0]?.system === 'Phone' ? _?.telecom[0]?.value : "",
        _key: _?._key,
        pht: pht,
        active: _?.active ? 'Active' : 'InActive'
      }
    });
    setTableState({
      person_list: personData
    });
    setTableLoading(false)
  }

  const handlepageChange = (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    constructTableJson(search, (currentPage - 1) * PerPage, PerPage)
  };

  const onSearchChange = async (value = "") => {
    setSearch(value);
    if (value?.length > 0) {
      constructTableJson(value.trim(), 0, 10);
    } else {
      setSearch('');
      setPage(0);
      setPerPage(10);
      await dispatch(actions.PERSON_READ({ withoutLimit: false, search: search, page: 0, perPage: 10 }));
      constructTableJson("", page, perPage);
    }
  };

  return (
    <div style={{ borderRadius: "12px" }}>
      <div style={{ width: "100%" }}>
        <Card style={{ borderRadius: "12px" }}>
          <div
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px 20px",
              }}
            >
              <div>
                <p
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  Person
                </p>
              </div>
              <div style={{ marginTop: "16px", float: "right", display: 'flex' }}>
                <SearchWithFilter
                  id="Holiday"
                  placeholder="Search Name, Mail & Mobile!"
                  //  loading={data?.length > 0 ? loading : false}
                  onSearchChange={onSearchChange}
                  hideSearchBar={false}
                  hideFilter={true}
                //  onFilterClicked={onFilterClicked}
                //  isFilterApplied={props?.isFilterApplied}
                />
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => addNewPersonHandler()}
                >
                  +Add New
                </Button>
              </div>
            </div>
            <div style={{ padding: 20, height: "71vh", overflow: "auto" }}>
              {
                  <CommonTable
                    loading={tableLoading} 
                    Header={[
                      "S.No",
                      "Name",
                      "DOB",
                      "Gender",
                      "Email",
                      "Mobile",
                      "Status",
                      "Action",
                    ]}
                    dataList={

                      tableState?.person_list ?? []
                    }
                    tableData={[
                      { type: ["INCRIMENT"], name: "" },
                      { type: ["PROFILE"], name: "Name" },
                      { type: ["TEXT"], name: "DOB" },
                      { type: ["TEXT"], name: "Gender" },
                      { type: ["TEXT"], name: "Email" },
                      { type: ["TEXT"], name: "Mobile" },
                      { type: ["TEXT"], name: "active" },
                      { type: ["EDIT"], name: "action" },
                    ]}
                    handleCheckBox={statusChange}
                    handleEdit={handleEdit}
                    handlepageChange={(currentPage, PerPage) =>
                      handlepageChange(currentPage, PerPage)
                    }
                    TableCount={tableState?.person_list?.[0]?.TotalCount}
                    incrementCount={page}
                    showPagination={true}
                    rowsPerPageOptions={[
                      { label: "10 Rows", value: 10 },
                      { label: "50 Rows", value: 50 },
                      { label: "100 Rows", value: 100 },
                    ]}
                  />
              }
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
export default Person;
