import React from "react";
import { Paper, Typography, makeStyles, Grid, Button } from "@material-ui/core";
import { CommonTable, GenerateForm } from "../../../../../../components";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";


const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: "0px 10px 25px #0000000A",
        padding: 20,
        borderRadius: 8,
        "& .MuiOutlinedInput-root": {
            borderRadius: 8,
        },
    },
    addBtn: {
        fontSize: "14px",
        fontWeight: 600,
        textTransform: "none"
    },
    title: {
        fontFamily: "poppinsemibold",
        display: "inline",
        fontSize: "12px",
    },
}));

function PerformingDetails(props) {
    const classes = useStyles();
    let dispatch = useDispatch();
    const storeData = useSelector((state) => state?.performLocationSlice);

    const { performingData, handleFormState, parent_id } = props;
    const [state, setState] = React.useState({
        open: false, saveloading: false, edit_data: false, editIndex: null, editDetail : {}
    })
    const handleDrawerClose = () => {
        setState({ ...state, open: false, editIndex: null, editDetail: {} })
    }

    const handleDelete = (e, data, index) => {
        let list = JSON.parse(JSON.stringify(performingData));
        list.splice(index, 1)
        handleFormState("performingData", list);
    }

    const onchangefun = (type, value, val) => {
        if( type === "location_type" && val && val.label){
            dispatch(actions.SOURCELOCATION({ list: val?.label }))
        }
    }

    const saveDetails = (data) => {
        let list = JSON.parse(JSON.stringify(performingData));
        if (state.editIndex || state.editIndex === 0) {
            list[state.editIndex] = {
                ...list[state.editIndex],
                ...data
            }
        } else {
            list.push(data)
        }
        handleFormState("performingData", list);
        handleDrawerClose()
    }

    return (
        <React.Fragment>
            <Paper
                id={`${parent_id}-parent-paper`}
                className={classes.root}>
                <div
                    id={`${parent_id}-parent-div`}
                    style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                        id={`${parent_id}-PERFORMING-typography`}
                        className={classes.title} variant="h6">PERFORMING DETAILS</Typography>
                    <div
                        id={`${parent_id}-PERFORMING-Add-New-div`}
                        style={{ flexGrow: 1 }}></div>
                    <Button
                        id={`${parent_id}-PERFORMING-Add-New-button`}
                        className={classes.addBtn}
                        onClick={() => setState({ ...state, open: true })}
                        color="primary" >+ Add New
                    </Button>
                </div>

                <Grid
                    id={`${parent_id}-CommonTable-grid`}
                    container>
                    <CommonTable
                        parent_id={"Performing"}
                        handleEdit={(e, data, index) => setState({ ...state, editIndex: index, open: true, editDetail : data })}
                        handleDelete={handleDelete}
                        rightAlign={["Action"]}
                        Header={["S.No", "Performing Facility", "Location Role Type", "Performing Location", "Action"]}
                        dataList={performingData}
                        tableData={[
                            { type: ["INCRIMENT"], name: "" },
                            { type: ["TEXT"], name: "performing_facility", optionLabel: "label" },
                            { type: ["TEXT"], name: "location_type", optionLabel: "label" },
                            { type: ["TEXT"], name: "performing_location", optionLabel: "label" },
                            { type: ["EDIT", "DELETE"], name: "", align: "right" },
                        ]}
                    // condition={condition}
                    />
                </Grid>
                <GenerateForm
                    parent_id={"Performing"}
                    nocancel
                    loading={state.saveloading}
                    open={state.open}
                    save={saveDetails}
                    handlecancel={handleDrawerClose}
                    onchangefun={onchangefun}
                    header={state.editIndex !== null ? "Edit Performing Organization" : 'Add New Performing Organization'}
                    json={[{
                        componet_type: "select",
                        options: storeData?.order_facility?.data ?? [],
                        label: "Performing Facility",
                        state_name: "performing_facility",
                        value: state?.editDetail?.performing_facility ?? null,
                        required: true
                    },
                    {
                        componet_type: "select",
                        options: storeData.source_location_type?.data ?? [],
                        label: "Location Role Type",
                        state_name: "location_type",
                        value: state?.editDetail?.location_type ?? null,
                        required: true
                    },
                    {
                        componet_type: "select",
                        options: storeData.source_location?.data ?? [],
                        label: "Performing Location",
                        state_name: "performing_location",
                        value: state?.editDetail?.performing_location ?? null,
                        required: true
                    },
                    ]}
                />
            </Paper>
        </React.Fragment>
    )
}

export default PerformingDetails;