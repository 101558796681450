import React, { useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { withAllContexts } from "../../../HOCs";
import styles from "./styles";
import Config from "../../../config";
import { v4 as uuidv4 } from "uuid";
import { upsertDocument } from "../../../function/commonapi";

function AddNewPage(props) {
  const classes = styles();
  // const [editPages, setEditpages] = useState({
  //   editPageName: "",
  //   editRoute: "",
  // });

  const [pageData, setPageData] = useState({
    pageName: props?.pageParams ? props?.pageParams?.elementname : "",
    route: props?.pageParams ? props?.pageParams?.route : "",
  });
  console.log("pageData", pageData);
  // const [errorMgsPage, setErrorMgsPage] = useState("");
  // const [errorMgsRoutes, setErrorMgsRoutes] = useState("");
  const [error, setError] = useState({
    pageName: false,
    route: false,
    msg: "Please fill the required field",
  });
  const handlePageData = (event) => {
    ;
    setPageData({ ...pageData, [event.target.name]: event.target.value });
    if (event.target.name === "pageName") {
      if (event.target.value) {
        error.pageName = false;
      } else {
        error.pageName = true;
      }
    } else if (event.target.name === "route") {
      if (event.target.value) {
        error.route = false;
      } else {
        error.route = true;
      }
    }
  };

  const duplicate = props.incomingPageData.filter(
    (itm) => itm.Repository.elementname === pageData.pageName
  );
  // const editPageData = (event) => {
  //   setEditpages({...editPages, [event.target.name]: event.target.value })
  // }

  const validation = () => {
    ;
    let isValidate = false;

    if (pageData?.route?.trim()?.length > 0) {
      error.route = false;
    } else {
      isValidate = true;
      error.route = true;
    }
    if (pageData?.pageName?.trim()?.length > 0) {
      error.pageName = false;
    } else {
      isValidate = true;
      error.pageName = true;
    }
    // if (duplicate.length > 0) {
    //   isValidate = true;
    //   error.pageName = true;
    //   error.msg = "Page already exists";
    // } else {
    //   error.pageName = false;
    // }
    setError({
      ...error,
    });
    return isValidate;
  };

  const editPageSubmit = async () => {
    const { drawer } = props;
    let check = validation();
    if (check) {
      return;
    }
    let list = {
      elementname: pageData?.pageName,
      route: pageData.route,
    };
    let sendPageData = {
      entity: Config.repositoryEntity,
      metadataId: Config.metadataid,
      isedit: true,
      id: props?.pageParams?.rep_id,
      list,
      keyvalue: "rep_id",
    };

    let addEditpageUpsert = await upsertDocument(sendPageData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          props.getRepositoryData();

          props.handleAlerts("Page edited successfully!", true);
          drawer.setDrawer({
            ...drawer,
            open: false,
            component: <></>,
          });
        } else {
          props.handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        props.handleAlerts("Something  went wrong", false);
      });
  };

  const submitPages = async () => {
    // let submittedPages = {
    //   pageName: pageData.pageName,
    //   route: pageData.route,
    // };
    const { drawer } = props;
    let check = validation();
    if (check) {
      return;
    }
    // if (pageData.pageName.length > 0 && pageData.route.length > 0){
    let list = {
      rep_id: uuidv4(),
      rep_type: "Pages",
      elementname: pageData.pageName,
      route: pageData.route,
      is_active: true,
    };

    let sendPageData = {
      entity: Config.repositoryEntity,
      metadataId: Config.metadataid,
      list,
    };

    let addPagesUpsert = await upsertDocument(sendPageData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          props.getRepositoryData();
          drawer.setDrawer({
            ...drawer,
            open: false,
            component: <></>,
          });
          props.handleAlerts("Page created successfully!", true);
        } else {
          props.handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        props.handleAlerts("Something  went wrong", false);
      });
  };

  return (
    <div>
      <div className={classes.drawerHead}>
        <Typography className={classes.headerMain}>
          {props.editName ? "Edit Page" : "Add new page"}
        </Typography>
      </div>

      <Grid container direction="row">
        <Grid item xs={12}>
          <div className={classes.textTotalArea}>
            <Typography className={classes.drawerSubHead}>Page Name</Typography>
            <TextField
              label={"Page"}
              variant="outlined"
              fullWidth
              size="small"
              name="pageName"
              onChange={handlePageData}
              value={pageData.pageName}
              required
              inputProps={{ style: { fontFamily: "poppin", fontSize: "12px" } }}
              InputLabelProps={{
                style: { fontFamily: "poppin", fontSize: "12px" },
              }}
              error={error?.pageName ? true : false}
              helperText={error?.pageName ? error.msg : ""}
            />
            <div style={{ paddingTop: "20px" }}>
              <Typography className={classes.drawerSubHead}>Route</Typography>
              <TextField
                label={"Route"}
                variant="outlined"
                fullWidth
                size="small"
                onChange={handlePageData}
                name="route"
                value={pageData.route}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                error={error?.route ? true : false}
                helperText={error?.route ? error.msg : ""}
              />
            </div>
          </div>
          <div className={classes.btnPosition}>
            <Button
              contained
              className={classes.addDrawerBtn}
              onClick={props.editName ? editPageSubmit : submitPages}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withAllContexts(AddNewPage);
