import React from "react";
import AgeRangeForm from "./form";
import {
  VerticalList,
  CommonTable,
  OverlayCompt,
} from "../../../../components";
import { withAllContexts } from "../../../../HOCs";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { AlertProps } from "../../../../utils";
class AgeRangesMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      editData: null,
      perPage : 10,
      page: 0,
      serach:'',
      search: '',
      code_sort: true,
      short_sort: true,
      long_sort: true,
    };
    this.handleForm = this.handleForm.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    })
    await this.props.GET_AGE_RANGE_MASTER({ page: (currentPage - 1) * PerPage, perPage: PerPage });

  };
  async componentDidMount() {
    await this.props.GET_AGE_RANGE_MASTER({page: this.state.page, perPage: this.state.perPage});
    await this.props.UNIT_UOM();
  }
  handleForm = () => {
    let { openForm, editData } = this.state;
    this.setState({
      openForm: !openForm,
      editData: openForm ? null : editData,
    });
  };
  handleEdit = (e, data, index) => {
    this.setState({
      editData: data,
      openForm: true,
    });
  };
  handleCheckBox = async (e, data, index) => {
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Updating...",
    });
    await this.props.UPDATE_AGE_RANGE({
      editId: data._id,
      status: e.target.checked,
    });
    await this.props.GET_AGE_RANGE_MASTER({page: this.state.page, perPage: this.state.perPage});
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
    this.props.alert.setSnack("update");
  };

  handleSearch = async(value = "") => {
    if (value?.length > 0) {
      await this.props.GET_AGE_RANGE_MASTER({
        page: this.state.page, 
        perPage: this.state.perPage,
        search:value.trim(),
        // page: this.state.page,
        // perPage: this.state.perPage
      });
      this.setState({
        ...this.state,
        search: value.trim()
      });
    } else if (!value) {
     await this.props.GET_AGE_RANGE_MASTER({
      page: this.state.page,
      perPage: this.state.perPage
     });
    }
    this.setState({
      ...this.state,
      search: ""
    });
  }


  handleSort = async (value, key,  direction) => {
    await this.props.GET_AGE_RANGE_MASTER({
      page: this.state.page, 
      perPage: this.state.perPage,
      search:this.state.search?.trim(),
      sort: value,
      direction: direction,
      // page: this.state.page,
      // perPage: this.state.perPage
    });
    this.setState({
      ...this.state,
      [key]: !this.state?.[key]
    })
  }

  render() {
    const { openForm, editData } = this.state;

    
    return (
      <React.Fragment>
        <VerticalList
          parent_id={"consult_master"}
          addFunc={this.handleForm}
          // searchCmpProps starts here
          id={"ageRange"}
          placeholder={"search code & description"}
          // loading={this.props?.searchLoading}
          onSearchChange={this?.handleSearch}
          hideSearchBar={false}
          hideFilter={true}
          // onFilterClicked={this.props?.onFilterClicked}
          // isFilterApplied={this.props?.isFilterApplied}
          
          TabList={[
            {
              name: "Age Ranges",
              component: (
                <CommonTable
                  parent_id={"consult_master"}
                  rightAlign={["Status", "Action"]}
                  handleEdit={this.handleEdit}
                  handleCheckBox={this.handleCheckBox}
                  Header={[
                    { label: "S.No", showSort: false, showFilter: false},
                    { label: "Code", showSort: true, sortValue: this.state.code_sort, onclick:(val) => this.handleSort(val, 'code_sort', !this.state.code_sort), showFilter: false, filter_options: [{label: "opt-1", value:"opt-1", isChecked: true}, {label: "opt-2", value:"opt-2", isChecked: false}, {label: "opt-3", value:"opt-3", isChecked: false}, {label: "opt-4", value:"opt-4", isChecked: false}]},
                    { label: "Short Description",  showSort: true, sortValue: this.state.short_sort, onclick:(val) => this.handleSort(val,'short_sort', !this.state.short_sort), showFilter: false, filter_options: [{label: "opt-1", value:"opt-1", isChecked: false}, {label: "opt-2", value:"opt-2", isChecked: false}, {label: "opt-3", value:"opt-3", isChecked: false}, {label: "opt-4", value:"opt-4", isChecked: false}]},
                    { label: "Long Description",  showSort: true, sortValue: this.state.long_sort, onclick:(val) => this.handleSort(val,'long_sort', !this.state.long_sort), showFilter: false, filter_options: [{label: "opt-1", value:"opt-1", isChecked: false}, {label: "opt-2", value:"opt-2", isChecked: false}, {label: "opt-3", value:"opt-3", isChecked: false}, {label: "opt-4", value:"opt-4", isChecked: false}]},
                    { label: "Status",  showSort: false, showFilter: false},
                    { label: "Action",  showSort: false, showFilter: false},
                    // "S.No",
                    // "Code",
                    // "Short Description",
                    // "Long Description",
                    // "Status",
                    // "Action",
                  ]}
                  dataList={this.props?.ageRangeList?.data ?? []}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "agerangecode" },
                    { type: ["TEXT"], name: "shortdesc" },
                    { type: ["TEXT"], name: "longdesc" },
                    { type: ["CHECKBOX"], name: "status", align: "right" },
                    { type: ["EDIT"], name: "", align: "right" },
                  ]}
                  handlepageChange={(currentPage, PerPage) =>
                    this.handlepageChange(currentPage, PerPage)
                  }
                  TableCount={this.props?.ageRangeList?.data?.[0]?.TotalCount}
                  incrementCount={this.state.page}
                  showPagination={true}
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                />
              ),
            },
          ]}
        />
        <OverlayCompt
          parent_id={"consult-master"}
          open={openForm}
          children={
            <React.Fragment>
              {openForm && (
                <AgeRangeForm
                  parent_id={"consult-master"}
                  editData={editData}
                  closeForm={this.handleForm}
                  ageRangeState={this.state}
                />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ageRangeList: state?.ageRangeMasterSlice?.age_range_list,
  ageRangeUom: state?.ageRangeMasterSlice?.unit_uom_list,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(AgeRangesMaster));
