import React from "react";
import { Div } from "qdm-component-library";
import TopNavbar from "../navbar/topNavBar";
import PatientAddress from "./address";
import PatientCommunication from "./communication";
import PatientCotactPoint from "./contactPoint";
import PatientIdentification from "./patientIdentification";
import PatientNames from "./patientNames";
import Qualification from "./qualification";
import Publication from "./publication";
import Training from "./training";
import JobExp from "./jobExperience";
import Awards from "./aawardsAndAchievements";
import { withAllContexts } from "../../../HOCs";
import Practitionerbyrole from "../addPractotionerRole/practitionerbyrole";
import { withHoverDrawer } from "../../../HOCs";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
import { AlertProps, checkMaxDateValidation, checkDateValidation } from "../../../utils";
import { Button, CircularProgress } from "@material-ui/core";
import { routes } from "../../../router/routes";
import { withRouter } from "react-router-dom";
import Biography from "./biography";
import { addUserToKeyClock, upsertDocument } from "../../../function/commonapi";
import Config from "../../../config";
import moment from "moment";
const practitionerInitialstate = {
  identification: [{}],
  names: [{}],
  details: {},
  practitioner_description: {},
  communication: {},
  contact: [{}],
  Submitrole: {},
  address: [],
  publication: [{}],
  reporting: {},
  practitionerbyrole: [],
  qualification: [],
  training: [],
  job: [],
  awards: [],
};
class Addpractitioner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: 1,
    };
    this.successfun = this.successfun.bind(this);
  }
  async componentDidMount() {
    //loading true
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Fetching practitioner details...",
    });
    await this.getallmasters();
    await this.getCountryData();
    let urlParams = this.props?.location?.state?.Id ?? null;
    if (urlParams) {
      await this.getPractitionerById(urlParams);
    }
    //loading true
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  }

  getPractitionerById = async (id) => {
    const data = await this.props.PRACTITIONER_GET_BY_ID({ id: id });
    if (data.payload.data) {
      let updateddata = JSON.parse(JSON.stringify(data.payload.data));
      let patientData = this.props.patientData;
      
      patientData.setPatientData({
        ...patientData,
        ...updateddata,
      });
    }
  };

  getallmasters = async () => {
    const data = await this.props.PRACTITIONER_MASTERS();
    this.setState({
      allMasters: data?.payload?.data,
    });
  };

  getCountryData = async () => {
    const data = await this.props.PRACTITIONER_COUNTRY_MASTER();
    this.setState({
      countryDropdownData: data?.payload?.data,
    });
  };

  validation = (data) => {
    let edit = this.props?.location?.state?.Id ?? null;

    // PRACTITIONER IDENTIFICATION
    const identificationValidation = () => {
      if (data.identification?.length === 0) {
        return false;
      } else {
        // id_number, id_type
        let patientData = this.props.patientData;
        let errorFiles = {};

        let arr = []

        console.clear()
        debugger
        
        let error = data.identification.map((val, i) =>
          val?.id_type?.label?.length > 0 &&
          val?.id_number?.length > 0 &&
          val?.expiry_date?.length > 0 &&
          checkDateValidation(
            (edit && this.props.practitionerbyid?.data?.identification?.[i]?.expiry_date) 
            ? this.props.practitionerbyid?.data?.identification?.[i]?.expiry_date 
            : moment(new Date()).format("YYYY-MM-DD"), val?.expiry_date) === null &&
          arr.push(this.props.practitionerbyid?.data?.identification?.[i]?.expiry_date === val?.expiry_date )
        );
        console.log(arr)
        data.identification.map((val, i) => {
          if (
            !val?.id_type?.label?.length > 0 ||
            !val?.id_number?.length > 0 ||
            !val?.expiry_date||
            checkDateValidation( edit ? this.props.practitionerbyid?.data?.identification?.[i]?.expiry_date : moment(new Date()).format("YYYY-MM-DD"), val?.expiry_date)
          ) {
            errorFiles = {
              id_type: val?.id_type?.label?.length > 0 ? false : true,
              id_number: val?.id_number?.length > 0 ? false : true,
              expiry_date: val?.expiry_date ? false : true,
              expiry_date: checkDateValidation( edit ? this.props.practitionerbyid?.data?.identification?.[i]?.expiry_date : moment(new Date()).format("YYYY-MM-DD"), val?.expiry_date) ? true : false
            };
            console.log(errorFiles)
          }
          
          patientData.identification[i]["error"] = {...errorFiles};
          errorFiles = {};
        });
        ;
        patientData.setPatientData({
          ...patientData,
        });

        
        return error.includes(arr.includes(true) ? false : true);
        // return error.includes(true)
      }
    };

    // PRACTITIONER DETAILS
    const detailsValidation = () => {
      debugger
      if (data.names?.length === 0) {
        return true;
      } else {
        // first_name, last_name, middle_name, prefix, suffix, use, dob, gender
        let error = data.names.map((val) =>
          val?.prefix?.label?.length > 0 &&
            val?.suffix?.label?.length > 0 &&
            val?.use?.label?.length > 0 &&
            val?.middle_name?.length > 0 &&
            val?.last_name?.length > 0 &&
            val?.first_name?.length > 0
            ? true
            : false
        );
        debugger
        error.push(data?.details?.dob ? data?.details?.dob?.toString().length > 0 ? true : false : false);
        error.push(data?.details?.gender?.label?.length > 0 ? true : false);
        error.push(data?.details?.nationality?.label?.length > 0 ? true : false);
        error.push(data?.details?.practitoner_id?.length > 0 ? true : false);
        // error.push(data?.details?.email?.length > 0 ? true : false);

        //  set error
        let patientData = this.props.patientData;
        let errorFiles = {};
        let errorDetailsFiles = {
          dob: data?.details?.dob ? data?.details?.dob?.toString().length > 0 ? false : true : false,
          gender: data?.details?.gender?.label?.length > 0 ? false : true,
          nationality: data?.details?.nationality?.label?.length > 0 ? false : true,
          practitoner_id: data?.details?.practitoner_id?.length > 0 ? false : true,
          // email: data?.details?.email?.length > 0 ? false : true,
        };
        patientData.details["error"] = errorDetailsFiles;
        data.names.map((val, i) => {
          if (
            !val?.prefix?.label?.length > 0 ||
            !val?.suffix?.label?.length > 0 ||
            !val?.use?.label?.length > 0 ||
            !val?.middle_name?.length > 0 ||
            !val?.last_name?.length > 0 ||
            !val?.first_name?.length > 0
          ) {
            errorFiles = {
              first_name: val?.first_name?.length > 0 ? false : true,
              last_name: val?.last_name?.length > 0 ? false : true,
              middle_name: val?.middle_name?.length > 0 ? false : true,
              prefix: val?.prefix?.label?.length > 0 ? false : true,
              suffix: val?.suffix?.label?.length > 0 ? false : true,
              use: val?.use?.label?.length > 0 ? false : true,
            };
          }
          patientData.names[i]["error"] = errorFiles;
          errorFiles = {};
        });
        patientData.setPatientData({
          ...patientData,
        });
        debugger
        return error.includes(false);
      }
    };

    // practitionerRoleValidation
    const practitionerRoleValidation = () => {
      if (data.practitionerbyrole?.length === 0) {
        alert("Please fill at leaset one practitioner by role");
        return true;
      } else {
        let error = data?.practitionerbyrole?.map(
          (val) => val?.primary_specialty
        )?.[0]
          ? false
          : true;
        if (error) {
          alert("Please select a primary specialty");
        }
        return error;
      }
    };

    // Practitioner Description
    // const descriptionValidation = () => {
    //   if(data.practitioner_description?.length === 0){
    //     return false;
    //   }else{
    //     // practitioner_type
    //      let error = [];
    //      error.push(data?.practitioner_description?.practitioner_type?.label?.length > 0 ? true : false);
    //      return error.includes(false);
    //   }
    // }

    // CONTACT DETAILS
    // const contactValidation = () => {
    //   if(data.contact?.length === 0){
    //     return false;
    //   }else{
    //     // mode, mobile
    //      let error = data.contact.map(val => (val?.mode?.label?.length > 0 && ( val?.mode?.label === "Phone" ? (val?.mobile?.length > 9 ? true : false): (val?.mobile?.length > 0 ? true : false) ) ) ? true : false );
    //      return error.includes(false);
    //   }
    // }

    // const emailValidationIncon = () => {
    //   if (data?.contact?.filter(val => val?.mode?.label?.toLocaleLowerCase() === "email").length > 0 && data?.contact?.filter(val => val?.mode?.label?.toLocaleLowerCase() === "email")?.[0]?.mobile) {
    //     return false
    //   } else {
    //     return true
    //   }
    // }

    const alert = (mage) => {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: mage,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    };

    // if (emailValidationIncon()) {
    //   alert("Please fill e-mail detail");
    //   return true;
    // } else 
    if (identificationValidation()) {
      alert("Please fill identification mandatory fields");
      return true;
    } else if (detailsValidation()) {
      alert("Please fill practitioner details mandatory fields");
      return true;
    } else if (practitionerRoleValidation()) {
      // alert("Please fill practitioner by role mandatory fields");
      return true;
    }
    // else if(descriptionValidation()){
    //   alert("Please fill practitioner description mandatory fields");
    //   return true;
    // }else
    // if(contactValidation()){
    //   alert("Please fill practitioner contact mandatory fields");
    //   return true;
    // }
  };

  async successfun() {
    let data = this.props.patientData;
    if (this.validation(data)) {
    } else {
      let edit = this.props?.location?.state?.Id ?? null;

      //loading true
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: true,
        message: edit ? "Updating practitioner..." : "Creating practitioner...",
      });

      let personId = null;
      if (!edit) {
        //keyclock user creation
        let keyclockError = false;
        let tokenData = localStorage.getItem("payload");
        let parsedToken = JSON.parse(tokenData);

        let email_id = data?.contact?.filter(val => val?.mode?.label === "Email")?.[0]?.mobile ?? null
        let addUserKeyClock = await addUserToKeyClock(
          parsedToken.access_token,
          email_id
        ).then((res) => {
          if (res?.data?.Code === 201) {
            // alert("User created successfully!", true);
          } else {
            if (res?.data?.error) {
              keyclockError = true
              this.props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: res?.data?.errorMessage ?? "Something  went wrong Keyclock mail filed",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });
              // return alert(res?.data?.errorMessage);
            }
          }
        })
          .catch((error) => {
            keyclockError = true
            this.props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something  went wrong Keyclock mail filed",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
            // return alert("Something  went wrong", false);
          });
        if (keyclockError) {
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false,
            message: "",
          });
          return false
        }

        //user creation
        let list = {
          identifier: [],
          name: [
            {
              use: "string",
              text: "",
              family: "",
              given: "",
              prefix: "",
              suffix: "",
              period: [
                {
                  start: Math.floor(Date.now() / 1000),
                  end: 0,
                },
              ],
            },
          ],
          telecom: [
            {
              system: "Email",
              value: email_id ?? "", // number
              use: "Work", // phone
              rank: "1", // 1
              period: [
                {
                  start: Math.floor(Date.now() / 1000),
                  end: 0,
                },
              ],
            },
          ],
          gender: "",
          birthDay: "",
          address: [],
          photo: [],
          active: true,
          link: [],
          Id: 0,
          RelatedPersonID: [],
          OrgID: [],
        };
        let sendUserData = {
          entity: Config.personEntity,
          metadataId: Config.metadataid,
          list,
        };
        let personerror = false;
        let addUserUpsert = await upsertDocument(sendUserData)
          .then((res) => {
            if (res?.data?.Code === 201) {
              
              personId = res?.data?.Result?.[0]?.properties?.doc?.Id ?? 0;
              // alert("User created successfully!", true);
            } else {
              personerror = true
              this.props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: res?.data?.errorMessage ?? "Something  went wrong person creation",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });
              // return alert(res?.data?.errorMessage);
            }
          })
          .catch((error) => {
            personerror = true
            this.props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something  went wrong person creation",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
            });
            // return alert("Something  went wrong", false);
          });
        if (personerror) {
          this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false,
            message: "",
          });
          return false
        }


      }

      // PRACTITIONER_UPSERT
      let urlParams = this.props?.location?.state?.Id ?? null;
      let upsertRec = await this.props.PRACTITIONER_UPSERT({
        data: data,
        _key: urlParams,
        personId: personId
      });

      if (
        upsertRec.payload.data.error &&
        upsertRec.payload.data.error.length > 0
      ) {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg:
            upsertRec?.payload?.data?.validation_error?.[0]?.Errormsg ??
            "Somr thing error",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      } else {
        this.props.alert.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: urlParams
            ? "Practitioner updated successfuly"
            : "Practitioner created successfuly",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        // practitionermaster
        let patientData = this.props.patientData;
        await patientData.setPatientData({
          ...practitionerInitialstate,
        });
        await this.props.history.push(routes.practitionermaster);
      }

      //loading false
      this.props.backDrop.setBackDrop({
        ...this.props.backDrop,
        open: false,
        message: "",
      });

    }
    // this.props.handleDrawerClose();
  }
  setActive = (id) => {
    this.setState({
      activeId: id,
    });
  };
  styles = {
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
    loadscreen: {
      display: "flex",
      justifyContent: "center",
      margin: "16px",
      borderRadius: "6px",
      background: "#fff",
      alignItems: "center",
      minHeight: "76vh",
    },
  };
  saveAddress = (index) => { };

  backHandle = async () => {
    let patientData = this.props.patientData;
    await patientData.setPatientData({
      ...practitionerInitialstate,
    });
    await this.props.history.push(routes.practitionermaster);
  };

  render() {
    const activeId = this.props.IsactiveRole ? 2 : this.state.activeId;
    const patientRegister = [
      {
        id: 1,
        link: "/",
        label: "Practitioner",
      },
      {
        id: 2,
        link: "/",
        label: "Practitioner By Role",
      },
    ];

    return (
      <Div
        id={'addpractitioner-parent-div'}
        className="light-background-color">
        <TopNavbar
          parent_id={'topnavbar'}
          activeId={activeId}
          navClick={this.setActive}
          successfun={this.successfun}
          title={
            this.props?.location?.state?.Id
              ? "Edit Practitioner"
              : "Add New Practitioner"
          }
          navBarArr={patientRegister}
          backHandle={this.backHandle}
        // handleDrawerClose={this.props.handleDrawerClose}
        />

        {this.props.practitionerbyid.loading &&
          this.props.practitioner_masters.loading ? (
          <div
            id={'addpractitioner-circularprogress-div'}
            style={this.styles.loadscreen}>
            <CircularProgress
              id={'addpractitioner-parent-div'}
            />
          </div>
        ) : (
          <Div
            id={'addpractitioner-parent-div'}
            style={{
              padding: "5px 15px",
              color: "#101010",
              overflow: "hidden",
            }}
          >
            {/* PRACTITIONER IDENTIFICATION */}
            <PatientIdentification
              parent_id={'patientidentification'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
              edit={this.props?.location?.state?.Id??null}
              oldData={this.props.practitionerbyid}
            />
            {/* PRACTITIONER DETAILS */}
            <PatientNames
              parent_id={'PatientNames'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
            />

            {/* Practitioner Description */}
            <PatientCommunication
              parent_id={'Patientcommunication'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
            />
            {/* CONTACT DETAILS */}
            <PatientCotactPoint
              parent_id={'Patientcotactpoint'}
              // subtitle_message={"  ( At leaset add one email )"}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
            />
            {/* Practitioner By Role */}
            <Practitionerbyrole
              parent_id={'Practitionerbyrole'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
            />

            {/* ADDRESS */}
            <PatientAddress
              parent_id={'PatientAddress'}
              allMasters={this?.state?.allMasters}
              countryDropdownData={this?.state?.countryDropdownData}
              saveto={"patientData"}
              setTo={"setPatientData"}
            />

            {/* QUALIFICATION */}
            <Qualification
              parent_id={'Qualification'}
              saveto={"patientData"} setTo={"setPatientData"} />

            {/* biography */}
            <Biography
              parent_id={'Biography'}
              saveto={"patientData"} setTo={"setPatientData"} />

            {/* Publication÷ */}
            <Publication
              parent_id={'Publication'}
              saveto={"patientData"} setTo={"setPatientData"} />

            {/* Training */}
            <Training
              parent_id={'Training'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
            />

            {/* Job Experience */}
            <JobExp
              parent_id={'JobExp'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
            />

            {/* Awards / Achievements */}
            <Awards
              parent_id={'Awards'}
              allMasters={this?.state?.allMasters}
              saveto={"patientData"}
              setTo={"setPatientData"}
            />
            <Div
              id={'addpractitioner-buttons-div'}
              className="p-continue-action">
              <Button
                id={'addpractitioner-Cancel-button'}
                variant="text"
                className={"topNav-actions cancelBtn"}
                onClick={() => {
                  this.backHandle()
                }}
              >
                Cancel
              </Button>
              <Button
                id={'addpractitioner-Update-Save-button'}
                variant={"contained"}
                color={"primary"}
                // disabled={true}
                disabled={this.props?.practitioner_inset?.loading ?? false}
                onClick={() => this.successfun()}
                style={{ marginLeft: 10 }}
              >
                {this.props?.location?.state?.Id ? (
                  <>
                    {this.props?.practitioner_inset?.loading
                      ? "Updating..."
                      : "Update"}
                  </>
                ) : (
                  <>
                    {this.props?.practitioner_inset?.loading
                      ? "Saving..."
                      : "Save"}
                  </>
                )}
              </Button>
            </Div>
          </Div>
        )}
      </Div>
    );
  }
}

// export default withRouter(withAllContexts(Addpractitioner));
const mapStateToProps = (state) => ({
  // oraganizationRegisterPatient: state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
  practitioner_inset: state?.practitionerSlice?.practitioner_upsert,
  practitionerbyid: state?.practitionerMasterSlice?.practitionerbyid,
  practitioner_masters: state?.practitionerMasterSlice?.practitioner_masters,
});
export default connect(
  mapStateToProps,
  actions
)(withRouter(withAllContexts(withHoverDrawer(Addpractitioner))));
