import React from "react";
import { Button, Grid } from "@material-ui/core";
import { CommonTabList } from "../../../../../components";
import { AlertProps, valitationFunc } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";
import RangeDetails from "./rangeDetails";
import CategoryDetails from "./categoryDetails";
import { connect } from "react-redux";
import { actions } from 'primary_care_admin_binder'

const errorList = ["code", "longdes", "shortdes", "status"]
class AgeRangeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "", longdes: "", shortdes: "", status: true,
            categoryDetail: [],
            error:{}
        }
        this.handleFormState = this.handleFormState.bind(this);
    }
    componentDidMount() {
        if (this.props.editData) {
            const editData = this.props.editData
            let AgeCategory = JSON.parse(JSON.stringify(editData.AgeCategory));

            AgeCategory = AgeCategory.map(val => {
                // val.UOM = this.props.ageRangeUom?.data.filter(value => value.label === val.UOM)[0]
                val.UOM = {
                    label: val.UOM?.[0]?.coding?.display ?? "",
                    value: val.UOM?.[0]?._id ?? "",
                    code: val.UOM?.[0]?.coding?.code ?? ""
                }
                return val
            })
            this.setState({
                code: editData.agerangecode, longdes: editData.longdesc, shortdes: editData.shortdesc, status: editData.status,
                categoryDetail: AgeCategory,
                editData: editData
            })
        }
    }
    handleFormState = (name, value, limit) => {
        debugger
        if(limit){
            if(value.length > limit){
                return false;
            }
        }
         let errorObj = JSON.parse(JSON.stringify(this.state.error));
         errorObj[name] = false;
        this.setState({ [name]: value, error: errorObj });
    }

    saveForm = async () => {
        // let error = false;
        // errorList.map(val => {
        //     if (!this.state[val]) {
        //         error = true;
        //     }
        // })
        let { error, errorState } = await valitationFunc(this.state, errorList);
        if (error) {
            this.props.alert.setSnack("mandatory")
            this.setState({
              ...this.state,
              error: errorState,
            });
        } else {
            this.props.backDrop.setBackDrop({
                ...this.props.backDrop,
                open: true,
                message: "Updating...",
            });
            let payload = await this.props.UPSERT_AGE_RANGE({ dataList: this.state })
            if (payload?.payload?.data?.validation_error?.[0]?.Errormsg?.includes("unique")) {
                this.props.backDrop.setBackDrop({
                    ...this.props.backDrop,
                    open: false,
                    message: "",
                });
                this.props.alert.setSnack("unique")
                return false
            } else {
                await this.props.GET_AGE_RANGE_MASTER({page: this.props.ageRangeState.page, perPage: this.props.ageRangeState.perPage}); 
                this.props.backDrop.setBackDrop({
                    ...this.props.backDrop,
                    open: false,
                    message: "",
                });
                let type = this.props.editData ? "update" : "success";
                 this.props.alert.setSnack(type);
                this.props.closeForm()

            }
        }
    }

    render() {
        const { closeForm = () => null, parent_id } = this.props;
        return (
            <React.Fragment>
                <CommonTabList
                    parent_id={"agerange_form"}
                    backbtn
                    backFun={closeForm}
                    title={this.props.editData ? "Edit Age Ranges" : "Add New Age Ranges"}
                    list={[]}
                />
                <Grid
                    id={"agerange_form_rangedetails"}
                    style={{ padding: "20px" }}>
                    <RangeDetails
                        parent_id={"agerange_form_rangedetails"}
                        handleFormState={this.handleFormState}
                        isEdit={this.props.editData}
                        {...this.state} 
                    />
                </Grid>
                <Grid
                    id={"agerange_form_rangedetails"}
                    style={{ padding: "20px" }}>
                    <CategoryDetails
                        parent_id={"agerange_form_CategoryDetails"}
                        handleFormState={this.handleFormState} {...this.state} />
                </Grid>
                <Grid
                    id={"agerange-form-rangedetails-grid"}
                    style={{ padding: "20px" }}>
                    <Button
                        id={"agerange_form_rangedetails_Save_button"}
                        onClick={() => this.saveForm()} style={{ float: "right" }} variant="contained" color="primary" >{this.props.editData  ? "Update" : "Save"}</Button>
                    <Button
                        id={"agerange_form_rangedetails_Cancel_button"}
                        onClick={() => closeForm()} style={{ float: "right", marginRight: "20px" }} variant="outlined">Cancel</Button>
                </Grid>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    ageRangeList: state?.ageRangeMasterSlice?.age_range_list,
    ageRangeUom: state?.ageRangeMasterSlice?.unit_uom_list,
});

export default connect(mapStateToProps, actions)(withAllContexts(AgeRangeForm));