import React from "react";
import { withAllContexts } from "../../../../HOCs";
import { FrequencyListingWrapper } from "clmm-masters";
import { useLocation, withRouter } from "react-router";
import { routes } from "../../../../router/routes";

function Frequency(props) {
  const location = useLocation();

  return (
    <FrequencyListingWrapper
      onAddClick={() => {
        props.history.push(routes.addFrequency);
      }}
      onEditClick={(_key) => {
        props.history.push({
          pathname: routes.editFrequency,
          state: {
            _key: _key,
          },
        });
      }}
      onViewClick={(_key) => {
        props.history.push({
          pathname: routes.editFrequency,
          state: {
            _key: _key,
            isView: true,
          },
        });
      }}
    />
  );
}

export default withAllContexts(withRouter(Frequency));
