import React from "react";
import {
  CommonTable,
  GenerateForm,
  OverlayCompt,
} from "../../../../../components";
import DrugUmoForm from "./form";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AlertProps } from "../../../../../utils";
import { withAllContexts } from "../../../../../HOCs";

class DrugUOM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: null,
      perPage : 10,
      page: 0
    };
  }

  componentDidMount() {
    this?.props?.currentTab("drugUOM");
    const getdata = async () => {
      await this.props.DRUG_UOM_READ({page: this.state.page, perPage: this.state.perPage});
    };
    getdata();
  }
  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    })
    await this.props.DRUG_UOM_READ({ page: (currentPage - 1) * PerPage, perPage: PerPage });

  };

  handleForm = () => {
    let { closeFrom } = this.props;
    this.setState({
      editData: null,
    });
    closeFrom();
  };

  handleEdit = (e, data, index) => {
    let { closeFrom } = this.props;
    this.setState({
      editData: data,
    });
    closeFrom();
  };

  statusChange = async (e, values, index) => {
    let data = {};
    await this.props.To_UOM_UPDATE_STATUS_FUN({
      status: e.target.checked,
      key: values.key,
    });
    await this.props.DRUG_UOM_READ({page: this.state.page, perPage: this.state.perPage});
    this.props.alert.setSnack("update");
  };

  render() {
    const { open, openIndex, parent_id } = this.props;
    const { editData } = this.state;
    return (
      <React.Fragment>
        <CommonTable
          style={{ height: "calc(100vh - 245px)" }}
          parent_id={"drugUOM"}
          handleEdit={this.handleEdit}
          handleCheckBox={this.statusChange}
          rightAlign={["Status", "Action"]}
          Header={[
            "S.No",
            "UOM Type",
            "UOM Code",
            "Short Description",
            "Long Description",
            "Status",
            "Action",
          ]}
          dataList={
            this.props?.drugMasterUOM?.drug_routes_direction_list?.data
              ? this.props?.drugMasterUOM?.drug_routes_direction_list?.data
              : []
          }
          tableData={[
            { type: ["INCRIMENT"], name: "" },
            { type: ["TEXT"], name: "type" },
            { type: ["TEXT"], name: "UOMCode" },
            { type: ["TEXT"], name: "shortDesc" },
            { type: ["TEXT"], name: "longDesc" },
            { type: ["CHECKBOX"], name: "status", align: "right" },
            { type: ["EDIT"], name: "", align: "right" },
          ]}
          handlepageChange={(currentPage, PerPage) =>
            this.handlepageChange(currentPage, PerPage)
          }
          TableCount={this.props?.drugMasterUOM?.drug_routes_direction_list?.data?.[0]?.total_count}
          incrementCount={this.state.page}
          showPagination={true}
          rowsPerPageOptions={[
            { label: "10 Rows", value: 10 },
            { label: "50 Rows", value: 50 },
            { label: "100 Rows", value: 100 },
          ]}
        />
        <OverlayCompt
          parent_id={"drugUOM"}
          open={open}
          children={
            <React.Fragment>
              {open && (
                <DrugUmoForm
                  parent_id={"DrugUmoForm"}
                  editData={editData} closeForm={this.handleForm} />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  drugMasterUOM: state?.drugMasterUOMSlice,
});

export default connect(mapStateToProps, actions)(withAllContexts(DrugUOM));
// export default DrugUOM;
