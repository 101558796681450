import React from "react";
import { Card, CircularProgress } from "@material-ui/core";
import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { GenerateForm, CommonTable } from "../../components/common";
import { SearchWithFilter } from "../../components";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";

export default function PractitionerRoleTable(props) {
  const dispatch = useDispatch();
  const { parent_id } = props;
  const alert = React.useContext(AlertContext);
  const listData = useSelector(
    (state) => state?.practitionerSlice?.practitioner_role_list?.data
  );
  const loading = useSelector(
    (state) => state?.practitionerSlice?.practitioner_role_list?.loading
  );
  const saveloading = useSelector(
    (state) => state?.practitionerSlice?.practitioner_role_upsert?.loading
  );

  const [state, setState] = React.useState({
    open: false,
    edit_data: null,
  });

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(actions.PRACTITIONER_ROLE_READ({ type: props?.type,  page: (currentPage - 1) * PerPage, perPage: PerPage }));
  };

  const handleDrawerOpen = () => {
    setState({
      ...state,
      open: true,
    });
  };
  const handleDrawerClose = () => {
    setState({
      ...state,
      open: false,
      edit_data: null,
    });
  };

  React.useLayoutEffect(() => {
    let type = props.type;
    dispatch(actions.PRACTITIONER_ROLE_READ({ type: type, page: page, perPage: perPage }));
  }, []);

  const savePractitionerRole = async (values) => {
    let data = {
      code: values.type_code,
      description: values.type_description,
      type: props.type,
      status: true,
      id: 0,
    };
    if (state?.edit_data) {
      data["_key"] = state?.edit_data._key;
      data["id"] = state?.edit_data.id;
      data["status"] = state?.edit_data.status;
      data["coding_key"] = state?.edit_data?.coding_key??null;
    }
    
    let data_res = await dispatch(actions.PRACTITIONER_ROLE_UPSERT(data));
    if(data_res?.payload?.error){
      alert.setSnack("error");
    } else if (data_res?.payload?.data?.error) {
      alert.setSnack("unique");
    } else {
      let type = state?.edit_data ? "update" : "success";
      alert.setSnack(type);
       await dispatch(actions.PRACTITIONER_ROLE_READ({ type: props.type, page: page, perPage: perPage  }));
      await handleDrawerClose();
    }
  };

  const handleEdit = (e, values, index) => {
    let data = {
      code: values.code_type,
      description: values.description,
      type: props.type,
      status: values.status,
      _key: values._key,
      id: values.id,
      coding_key: values?.coding_key??null
    };
    setState({
      ...state,
      edit_data: data,
      open: true,
    });
  };

  const statusChange = async (e, values, index) => {
    let data = {
      code: values.code_type,
      description: values.description,
      type: props.type,
      status: e.target.checked,
      _key: values._key,
      id: values.id,
      coding_key: values?.coding_key??null
    };
    setState({
      ...state,
      edit_data: data,
    });
    let data_res = await dispatch(actions.PRACTITIONER_ROLE_UPSERT(data));
    if(data_res?.payload?.data?.error){
      alert.setSnack("error");
    }else{
      alert.setSnack("update");
    }
    await dispatch(actions.PRACTITIONER_ROLE_READ({ type: props.type, page: page, perPage: perPage }));
    setState({
      ...state,
      edit_data: null,
    });
  };

  const onSearchChange = (value = "") => {
    if (value?.length > 2) {
      dispatch(actions.PRACTITIONER_ROLE_READ({
        type: props.type,
        page: page,
        perPage: perPage,
        search: value.trim(),
      }));
    } else if (!value) {
      dispatch(actions.PRACTITIONER_ROLE_READ({
        type: props.type,
        page: page,
        perPage: perPage
      }));
    }
  };

  return (
    <Card
      id={`${parent_id}-parent-card`}
      style={{ borderRadius: "12px" }}>
      <div
        id={`${parent_id}-parent-div`}
        style={{ width: "100%" }}>
        <Card
          id={`${parent_id}-parent-sub-card`}
          style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-sub-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-header-button-div`}
              style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                id={`${parent_id}-${props.header?.replaceAll(" ", "-")}-div`}
                style={{ marginLeft: "18px" }}>
                <p
                  id={`${parent_id}-${props.header?.replaceAll(" ", "-")}-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              <div
                id={`${parent_id}-button-div`}
                style={{ marginTop: "16px", float: "right", marginRight:'20px', display:'flex' }}>

                <SearchWithFilter
                  id="symptomyandspeciality"
                  placeholder="Search code and Description!"
                  //  loading={data?.length > 0 ? loading : false}
                  onSearchChange={onSearchChange}
                  hideSearchBar={false}
                  hideFilter={true}
                //  onFilterClicked={onFilterClicked}
                //  isFilterApplied={props?.isFilterApplied}
                />

                <button
                  id={`${parent_id}-Add-New-button`}
                  style={{
                    backgroundColor: "#0071F2",
                    color: "white",
                    border: "none",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                    fontFamily: "poppin",
                    fontSize: "12px",
                  }}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </button>
                <GenerateForm
                  parent_id={'practitionerrole'}
                  nocancel
                  loading={saveloading}
                  open={state.open}
                  save={savePractitionerRole}
                  handlecancel={handleDrawerClose}
                  header={
                    state.edit_data
                      ? `Edit ${props.header}`
                      : `Add ${props.header}`
                  }
                  json={[
                    {
                      componet_type: "text",
                      label: `${props.header} code`,
                      state_name: "type_code",
                      value: state?.edit_data?.code ?? "",
                      required: true,
                      isUpperCase: true,
                      disabled: state.edit_data ? true : false,
                      maxlength:props.codeLength ?? 20
                    },
                    {
                      componet_type: "text",
                      label: `${props.header} description`,
                      state_name: "type_description",
                      value: state?.edit_data?.description ?? "",
                      required: true,
                      maxlength:props?.descriptionLength ?? 60
                    },
                    // {
                    //   componet_type: "switch",
                    //   label: "Status",
                    //   state_name: "status",
                    //   value: state?.edit_data?.status ?? false
                    // }
                  ]}
                />
              </div>
            </div>

            {/* table */}
            <div
              id={`${parent_id}-circularprogress-div`}
              style={{ padding: 20, height: "71vh", overflow: "auto" }}>
              {/* {loading && state.edit_data === null ? (
                <div
                  id={`${parent_id}-circularprogress-div`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "66vh",
                  }}
                >
                  <CircularProgress
                    id={`${parent_id}-circularprogress`}
                  />
                </div>
              ) : ( */}
                <CommonTable
                  parent_id={'practitionerrole'}
                  Header={[
                    "S.No",
                    "Type Code",
                    "Description",
                    "Status",
                    "Action",
                  ]}
                  dataList={listData}
                  rightAlign={["Status", "Action"]}
                  tableData={[
                    { type: ["INCRIMENT"], name: "" },
                    { type: ["TEXT"], name: "code_type" },
                    { type: ["TEXT"], name: "description" },
                    { type: ["CHECKBOX"], name: "status", align: "right" },
                    { type: ["EDIT"], name: "", align: "right" },
                  ]}
                  handleCheckBox={statusChange}
                  handleEdit={handleEdit}
                  handlepageChange={(currentPage, PerPage) =>
                    handlepageChange(currentPage, PerPage)
                  }
                  TableCount={listData?.[0]?.total_count}
                  incrementCount={page}
                  showPagination={true}
                  rowsPerPageOptions={[
                    { label: "10 Rows", value: 10 },
                    { label: "50 Rows", value: 50 },
                    { label: "100 Rows", value: 100 },
                  ]}
                  loading = {loading}
                />
              {/* )} */}
              {/*  */}
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
}
