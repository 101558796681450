import React from "react";
import { Paper, Typography, makeStyles, Grid } from "@material-ui/core";
import { SelectBox } from "../../../../../../components/common/smartForm/component";
//import { actions } from "primary_care_admin_binder";
//import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    borderRadius: 8,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {},
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
}));

function Organisation(props) {
  const classes = useStyles();
  //let dispatch = useDispatch();
  const storeData = useSelector((state) => state?.vitalsApplicableSlice);

  React.useEffect(() => {
    getMasterData();
  }, []);

  const getMasterData = async () => {
    // await dispatch(actions.GET_ENTITY_NAME());
    //  await dispatch(actions.GET_ENTITY_TYPE());
  };
  const { entityType, entityName, handleFormState, parent_id,error } = props;
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}_ORGANIZATION_parent_paper`}
        className={classes.root}
      >
        <Typography
          id={`${parent_id}_ORGANIZATION_title_typography`}
          className={classes.title}
          variant="h6"
        >
          ORGANIZATION
        </Typography>
        <Grid
          id={`${parent_id}_Entity_title_grid`}
          container
          className={classes.form}
        >
          <Grid
            id={`${parent_id}_Entity_title_sub_grid`}
            md="4"
            lg="4"
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}_Entity_title_typography`}
              className={classes.formtitle}
              variant="body1"
            >
              Entity Type <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={`${parent_id}_entity_type`}
              list={storeData.entity_type.data ?? []}
              placeholder={"Select"}
              value={entityType}
              onchange={(e, value) => handleFormState("entityType", value)}
              error={error?.entityType}
            />
          </Grid>

          <Grid
            id={`${parent_id}_Entity_Name_title_grid`}
            md="6"
            lg="6"
            className={classes.formParent}
          >
            <Typography
              id={`${parent_id}_Entity_Name_title_grid`}
              className={classes.formtitle}
              variant="body1"
            >
              Entity Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={`${parent_id}_entity_name`}
              list={storeData.entity_name.data ?? []}
              placeholder={"Select"}
              value={entityName}
              onchange={(e, value) => handleFormState("entityName", value)}
              error={error?.entityName}
            />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default Organisation;
