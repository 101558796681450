import React from "react";
import { withHoverDrawer } from "../../HOCs";
import ParameterSetupTabPanel from "./parameterSetupTab";

class ParameterSetup extends React.Component {
  render() {
    return (
      <div
        id={'ParameterSetupTabPanel-parent-div'}
      >
        <ParameterSetupTabPanel
          id={'ParameterSetupTabPanel'}
        />
      </div>
    );
  }
}

export default withHoverDrawer(ParameterSetup);
