import React from "react";
import {
  Div,
  Select,
  Col,
  Row,
  TextInput,
  Button,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
import { AlertProps } from "../../../utils";
import { SelectBox } from "../../common/smartForm/component";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";
class PersonAddAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      use: null,
      type: null,
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: null,
      district: null,
      state: null,
      country: null,
      pincode: null,
    };
  }

  onChangeState = (key, value, limit) => {
    const stateValue = {...this.state};
    if (key === "state") {
      stateValue["country"] = null;
      this.props.ORGANIZATION_GET_COUNTRY({
        val: value?.id,
      })
    }
    if (key === "district") {
      stateValue["state"] = null;
      stateValue["country"] = null;
      this.props.ORGANIZATION_GET_AddSTATE({
        val: value?.id,
      })
    }
    if (key === "city") {
      stateValue["district"] = null;
      stateValue["state"] = null;
      stateValue["country"] = null;
      stateValue["pincode"] = null;
      const promArr = [
        this.props.ORGANIZATION_GET_ADD_DISTRICT({
          val: value.id,
        }),
        this.props.ORGANIZATION_GET_PINCODE({
          val: value?.value,
        })
      ];
      Promise.all(promArr);
    }
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    this.setState({
      ...stateValue,
      [key]: value,
    });
  };

  componentDidMount() {
    const { editData } = this.props;
    this.props.ORGANIZATION_GET_ADD_City();
    if (editData) {
      this.setState({ ...editData });
    } else {
      this.Clear();
    }
  }

  validateCheck = (data) => {
    if (
      data?.addressLine1?.length > 0 &&
      data?.use?.label?.length > 0 &&
      data?.type?.label?.length > 0 &&
      data?.city?.title?.length > 0 &&
      data?.country?.title?.length > 0 &&
      data?.district?.title?.length > 0 &&
      data?.pincode?.title?.length > 0 &&
      data?.state?.title?.length > 0
    ) {
      return false;
    } else {
      let errors = {
        addressLine1: data?.addressLine1?.length > 0 ? false : true,
        use: data?.use?.label?.length > 0 ? false : true,
        type: data?.type?.label?.length > 0 ? false : true,
        city: data?.city?.title?.length > 0 ? false : true,
        country: data?.country?.title?.length > 0 ? false : true,
        district: data?.district?.title?.length > 0 ? false : true,
        pincode: data?.pincode?.title?.length > 5 ? false : true,
        state: data?.state?.title?.length > 0 ? false : true,
      };
      this.setState({ ...this.state, erorr: errors });
      return true;
    }
  };

  saveAddress = () => {
    if (this.validateCheck(this.state)) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill address mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }
    if (this.props?.saveAddress) {
      this.props.saveAddress(this.state);
    }
    // const { onclose, index, handleClear } = this.props;
    // let patientData = this.props[this.props.saveto];
    // if (index !== null) {
    //     patientData.address[index] = this.state
    // } else {
    //     patientData.address.push(this.state)
    // }
    // patientData[this.props.setTo]({
    //     ...patientData,
    // })
    // onclose()
    // handleClear()
    // this.Clear()
  };

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
      marginRight: 10,
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontWeight: "600",
      fontSize: 12,
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
      // width: "514px",
      // height: "40px",
    },
  };

  Clear = () => {
    this.setState({
      use: null,
      type: null,
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: null,
      district: null,
      state: null,
      country: null,
      pincode: null,
    });
  };

  FlterOptions = (type) => {
    const { allMasters } = this.props;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        value: data[index]?.coding?.display,
      };
      lista.push(datatemp);
    }
    return lista;
  };

  handleClear = () => {
    this.Clear();
    if (this.props?.handleClear) {
      this.props.handleClear();
    }
  };
  render() {
    const { onclose } = this.props;
    const {
      labelStyle,
      borderStyle,
      outlinedButton,
      containedButton,
      errorBorderStyle,
    } = this.styles;

    return (
      <Div
        style={{
          padding: 16,
        }}
      >
        {/* <Paper className="pi-paper"> */}
        <Row className="pi-actions-root">
          <Col md={2} lg={2} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select
                  isRequired={true}
                  showArrow
                  label="use"
                  placeholder="Use"
                  labelStyle={labelStyle}
                  inLineStyles={
                    this.state?.erorr?.use ? errorBorderStyle : borderStyle
                  }
                  // inLineStyles={borderStyle}
                  hoverColor="#0071F2"
                  getOptionLabel="label"
                  options={this.props?.allMasters?.["Address_use"]}
                  onChangeValue={(value) => this.onChangeState("use", value)}
                  value={this.state.use?.label}
                /> */}
            <Typography
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              use <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              list={this.props?.allMasters?.["Address_use"] ?? []}
              placeholder={"use"}
              disableClearable
              value={this.state.use}
              onchange={(e, value) => this.onChangeState("use", value)}
            />
          </Col>
          <Col md={2} lg={2} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select
                  isRequired={true}
                  showArrow
                  label="Type"
                  placeholder="type"
                  labelStyle={labelStyle}
                  // inLineStyles={borderStyle}
                  inLineStyles={
                    this.state?.erorr?.type ? errorBorderStyle : borderStyle
                  }
                  hoverColor="#0071F2"
                  getOptionLabel="label"
                  options={this.props?.allMasters?.["address_type"]}
                  onChangeValue={(value) => this.onChangeState("type", value)}
                  value={this.state.type?.label}
                /> */}
            <Typography
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              Type <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              list={this.props?.allMasters?.["address_type"] ?? []}
              placeholder={"type"}
              disableClearable
              value={this.state.type}
              onchange={(e, value) => this.onChangeState("type", value)}
            />
          </Col>
          <Col md={4} lg={4} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            <TextInput
              isRequired={true}
              placeholder="addressLine1"
              onChange={(e) =>
                this.onChangeState("addressLine1", e.target.value, 30)
              }
              value={this.state.addressLine1 ? this.state.addressLine1 : ""}
              label="Address line 1"
              labelStyle={labelStyle}
              // style={borderStyle}
              style={
                this.state?.erorr?.addressLine1 ? errorBorderStyle : borderStyle
              }
              hoverColor="#0071F2"
            />
          </Col>
          <Col md={4} lg={4} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            <TextInput
              placeholder="addressLine2"
              onChange={(e) =>
                this.onChangeState("addressLine2", e.target.value, 30)
              }
              value={this.state.addressLine2}
              label="Address line 2"
              labelStyle={labelStyle}
              style={borderStyle}
              hoverColor="#0071F2"
            />
          </Col>
          {/* </Row> */}
          {/* <Row className="pi-actions-root"> */}
          <Col md={6} lg={2} sm={2} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select
                  isRequired={true}
                  options={this.FlterOptions("Town")}
                  onChangeValue={(value) => this.onChangeState("city", value)}
                  value={this.state.city?.label}
                  showArrow
                  label="City"
                  placeholder="select..."
                  labelStyle={labelStyle}
                  // inLineStyles={borderStyle}
                  hoverColor="#0071F2"
                  getOptionLabel="label"
                  options={this.props?.allMasters?.["city"]}
                  inLineStyles={
                    this.state?.erorr?.city ? errorBorderStyle : borderStyle
                  }
                /> */}
            <Typography
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              City <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              list={this.props.cityName ?? []}
              placeholder={"City"}
              disableClearable
              Optionlabel="title"
              value={this.state.city}
              onchange={(e, value) => this.onChangeState("city", value)}
            />
          </Col>
          <Col md={6} lg={2} sm={2} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select
                  isRequired={true}
                  // options={this.FlterOptions('District')}
                  onChangeValue={(value) =>
                    this.onChangeState("district", value)
                  }
                  value={this.state.district?.label}
                  showArrow
                  label="District"
                  placeholder="select..."
                  labelStyle={labelStyle}
                  // inLineStyles={borderStyle}
                  hoverColor="#0071F2"
                  getOptionLabel="label"
                  options={this.props?.allMasters?.["district"]}
                  inLineStyles={
                    this.state?.erorr?.district ? errorBorderStyle : borderStyle
                  }
                /> */}
            <Typography
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              District <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              list={this.state.city ? (this.props.districtName ?? []) : []}
              placeholder={"select..."}
              Optionlabel="title"
              disableClearable
              value={this.state.district}
              onchange={(e, value) => this.onChangeState("district", value)}
            />
          </Col>
          <Col md={6} lg={2} sm={2} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select
                  // options={this.FlterOptions('State')}
                  isRequired={true}
                  onChangeValue={(value) => this.onChangeState("state", value)}
                  value={this.state.state?.label}
                  showArrow
                  label="State"
                  placeholder="select..."
                  labelStyle={labelStyle}
                  // inLineStyles={borderStyle}
                  hoverColor="#0071F2"
                  getOptionLabel="label"
                  options={this.props?.allMasters?.["state"]}
                  inLineStyles={
                    this.state?.erorr?.state ? errorBorderStyle : borderStyle
                  }
                /> */}

            <Typography
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              State <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              list={this.state.district ? (this.props.stateName ?? []) : []}
              placeholder={"select..."}
              disableClearable
              Optionlabel="title"
              value={this.state.state}
              onchange={(e, value) => this.onChangeState("state", value)}
            />
          </Col>

          <Col md={4} lg={2} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <Select
                  isRequired={true}
                  options={this.FlterOptions("Country")}
                  onChangeValue={(value) =>
                    this.onChangeState("country", value)
                  }
                  value={this.state.country?.label}
                  showArrow
                  label="Country"
                  placeholder="select..."
                  labelStyle={labelStyle}
                  // inLineStyles={borderStyle}
                  hoverColor="#0071F2"
                  getOptionLabel="label"
                  options={this.props?.allMasters?.["country"]}
                  inLineStyles={
                    this.state?.erorr?.country ? errorBorderStyle : borderStyle
                  }
                /> */}

            <Typography
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              Country <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              list={this.state.state ? (this.props.countryName ?? []) : []}
              placeholder={"select..."}
              disableClearable
              Optionlabel="title"
              value={this.state.country}
              onchange={(e, value) => this.onChangeState("country", value)}
            />
          </Col>
          <Col md={4} lg={2} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
            {/* <TextInput
              isRequired={true}
              placeholder="pincode"
              maxLength={6}
              type="number"
              onChange={(e) => this.onChangeState("pincode", e.target.value)}
              value={this.state.pincode}
              label="pincode"
              labelStyle={labelStyle}
              // style={borderStyle}
              style={
                this.state?.erorr?.pincode ? errorBorderStyle : borderStyle
              }
              hoverColor="#0071F2"
            /> */}
            <Typography
              style={{
                fontSize: "12px",

                Fontfamily: "pc_regular",
                color: "#6F6F6F",
              }}
              variant="body1"
            >
              Pincode <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              list={this.state.city ? (this.props.pincode ?? []) : []}
              placeholder={"select..."}
              disableClearable
              Optionlabel="title"
              value={this.state.pincode}
              onchange={(e, value) => this.onChangeState("pincode", value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className="aa-actions-btn-root" md={12} lg={12} sm={12} xs={12}>
            <Button
              className="aa-actions-btn"
              variant="outlined"
              inLineStyles={outlinedButton}
              onClick={() => this.handleClear()}
            >
              Cancel
            </Button>
            <Button
              className="aa-actions-btn"
              variant="outlined"
              backgroundColor="#0071F2"
              inLineStyles={containedButton}
              onClick={() => this.saveAddress()}
            >
              Save
            </Button>
          </Col>
        </Row>
        {/* </Paper> */}
      </Div>
    );
  }
}
const mapStateToProps = (state) => ({
  cityName: state?.organizationSlice?.orgAddCity?.data ?? [],
  districtName: state?.organizationSlice?.orgAddDISTRICT?.data ?? [],
  pincode: state?.organizationSlice?.orgAddPINCODE?.data ?? [],
  stateName: state?.organizationSlice?.orgAddSTATE?.data ?? [],
  countryName: state?.organizationSlice?.orgAddCOUNTRY?.data ?? [],
});
export default connect(mapStateToProps, actions)(withAllContexts(PersonAddAddress));
