import React from 'react';
import { makeStyles, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
    root: {
        '& fieldset': {
            borderRadius: 8,
            borderColor: '#E0E0E0'
        },
        '& input': {
            fontSize: 12,
            fontFamily: 'pc_medium',
            // color: '#101010',
            padding: 10
        }
    },
    label: {
        color: '#6F6F6F',
        fontSize: 12,
        fontFamily: 'pc_regular',
        marginBottom: 8
    }
}))

export const TextFieldLabel = (props) => {

    const classes = useStyles(props);
    const { parent_id } = props;

    return (
        <div
            id={`${parent_id}-parent-div`}
            className={classes.root}>
            {
                props.label &&
                <Typography
                    id={`${parent_id}-${props?.label.replaceAll(" ", "-")}-typography`}
                    className={classes.label}>{props?.label}
                    {props?.required && <span style={{ color: "red" }} >*</span>}
                </Typography>
            }
            <TextField
                id={`${parent_id}-TextField`}
                value={props?.value}
                onChange={(e) => props?.onChange(e.target.value)}
                variant="outlined"
                fullWidth
                required={props?.required}
                type={props?.type}
                error={props?.error}
                disabled={props?.disabled}
            />
        </div>
    )
}



TextFieldLabel.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    variant: PropTypes.oneOf('standard' | 'outlined' | 'filled'),
    type: PropTypes.oneOf('text' | 'password'),
    disabled: PropTypes.bool
}

TextFieldLabel.defaultProps = {
    required: false,
    label: '',
    value: '',
    onChange: null,
    variant: 'outlined',
    error: false,
    type: 'text',
    disabled: false
}