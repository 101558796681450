import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import "../font.css";
import Drawer from "@material-ui/core/Drawer";
import AddHoliday from "./addnewholiday";
import door from "../../assets/Icon - View holidays for entities.svg";
import edit from "../../assets/icons - Edit.svg";
import Delete from "../../assets/icons8-trash.svg";
import Close from "../../assets/Icon - Close.svg";
import expandarrow from "../../assets/icons8-expand-arrow.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dropdown from "./dropdown";

// import DenseTabletwo from "./viewholidayentities";
// import Grid from "@material-ui/core/Grid";
const drawerWidth = 360;
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginLeft: "18px",
    width: "97%",
    border: "1px solid lightgrey",
    borderRadius: "2px",
  },
  bgcolor: {
    backgroundColor: "#F9F9F9",
    paddingTop: "10px",
  },
  root: {
    "& .MuiTableCell-sizeSmall": {
      paddingBottom: "0px",
      paddingTop: "0px",
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 30,
    height: 18,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#0071F2",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 12,
    height: 14,
    marginTop: "1px",
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(Switch);

// const AntSwitch = withStyles((theme) => ({
//   root: {
//     width: "28px",
//     height: "17px",
//     padding: "2px",
//     display: "flex",
//     marginTop: "4px",
//   },
//   switchBase: {
//     padding: 2,
//     color: theme.palette.grey[500],
//     "&$checked": {
//       transform: "translateX(12px)",
//       color: theme.palette.common.white,
//       "& + $track": {
//         opacity: 1,
//         backgroundColor: theme.palette.primary.main,
//         borderColor: theme.palette.primary.main,
//       },
//     },
//   },
//   thumb: {
//     width: 12,
//     height: 12,
//     marginTop: "1px",
//     boxShadow: "none",
//   },
//   track: {
//     border: `1px solid ${theme.palette.grey[500]}`,
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor: theme.palette.common.white,
//   },
//   checked: {},
// }))(Switch);

function createData(
  serialno,
  date,
  day,
  holidayreason,
  holidaytype,
  repeat,
  status,
  action
) {
  return {
    serialno,
    date,
    day,
    holidayreason,
    holidaytype,
    repeat,
    status,
    action,
  };
}

const rows = [
  createData("1", "15-01-21", "Sunday", "pongal", "Local", "Y"),
  createData("2", "16-01-21", "Monday", "Independance day", "Public", "N"),
  createData("3", "17-01-21", "Tuesday", "Ramzan", "Local", "N"),
  createData("4", "18-01-21", "Wednesday", "Diwali", "Local", "N"),
  createData("5", "19-01-21", "Thrsday", "Onam", "Local", "Y"),
  createData("6", "20-01-21", "Friday", "Christmas", "Public", "Y"),
  createData("7", "21-01-21", "Saturday", "Good friday", "Public", "Y"),
  createData("8", "22-01-21", "Sunday", "Bakrid", "Public", "Y"),
  createData("9", "23-01-21", "Monday", "Newyear", "Public", "Y"),
];

export default function DenseTable() {
  const classes = useStyles();

  // const [dropdown, setdropdown] = React.useState("");
  // const [dropdownfacility, setdropdownfacility] = React.useState("");

  // const handleChangedrop = (event) => {
  //   setdropdown(event.target.value);
  // };
  // const handleChangedropFac = (event) => {
  //   setdropdownfacility(event.target.value);
  // };

  // const [state, setState] = React.useState("checkedC: true");
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
    setclose(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setclose(false);
  };

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  const [show, setshow] = React.useState(false);
  const [entity, setentity] = React.useState(true);
  const [close, setclose] = React.useState(false);
  const handleshow = () => {
    setshow(true);
    setentity(false);
  };
  const handlehide = () => {
    setshow(false);
    setentity(true);
  };
  return (
    <Card style={{ borderRadius: "12px" }}>
      <div style={{ width: "100%" }}>
        {show && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{ border: "none", backgroundColor: "#F6F6F6" }}
              onClick={() => handlehide()}
            >
              <img
                src={expandarrow}
                alt="icon"
                style={{ transform: "rotate(90deg)" }}
              />
            </button>

            <div style={{ marginTop: "5px" }}>
              <Dropdown />
            </div>
          </div>
        )}
        <Card style={{ borderRadius: "12px" }}>
          <div
            style={{
              borderRadius: "6px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ marginLeft: "18px" }}>
                <p
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  List of Holidays
                </p>
              </div>
              <div
                style={{ marginTop: "16px", width: "26.4%", float: "right" }}
              >
                {entity && (
                  <button
                    style={{
                      border: "none",
                      color: "#0071F2",
                      textDecoration: "underline",
                      backgroundColor: "#ffffff",
                      fontSize: "12px",
                      fontFamily: "poppin",
                    }}
                    onClick={() => handleshow()}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ fontFamily: "poppin", fontSize: "12px" }}>
                        view holidays for entities
                      </div>
                      <img
                        src={door}
                        alt="Icon"
                        style={{ marginLeft: "5px" }}
                      />
                    </div>
                  </button>
                )}
                <button
                  style={{
                    backgroundColor: "#0071F2",
                    color: "white",
                    border: "none",
                    width: "108px",
                    height: "32px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    borderRadius: "5px",
                    fontFamily: "poppin",
                    fontSize: "12px",
                  }}
                  onClick={handleDrawerOpen}
                >
                  +Add New
                </button>
                {close && (
                  <button
                    onClick={handleDrawerClose}
                    style={{
                      position: "fixed",
                      right: "365px",
                      top: "8px",
                      // padding: "2px",
                      borderRadius: "50%",
                      border: "none",
                      // width: "25px",
                      height: "40px",
                      width: "40px",
                      zIndex: "1200",
                    }}
                  >
                    {" "}
                    <div style={{ marginTop: "5px" }}>
                      <img
                        src={Close}
                        alt="Icon"
                        style={{ marginBottom: "5px" }}
                      />
                    </div>
                  </button>
                )}
                <Drawer
                  className={classes.drawer}
                  variant="persistent"
                  anchor="right"
                  open={open}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  {
                    <div>
                      <AddHoliday />
                    </div>
                  }
                </Drawer>
              </div>
            </div>
            <TableContainer
              component={Paper}
              style={{
                borderRadius: "6px",
                marginTop: "20px",
                paddingBottom: "18px",
              }}
              className={classes.root}
            >
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.bgcolor}
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        color: "gray",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      className={classes.bgcolor}
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        color: "gray",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      className={classes.bgcolor}
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        color: "gray",
                      }}
                      align="left"
                    >
                      Day
                    </TableCell>
                    <TableCell
                      className={classes.bgcolor}
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        color: "gray",
                      }}
                      align="left"
                    >
                      Holiday Reason
                    </TableCell>
                    <TableCell
                      className={classes.bgcolor}
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        color: "gray",
                      }}
                      align="left"
                    >
                      Holiday Type
                    </TableCell>
                    <TableCell
                      className={classes.bgcolor}
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        color: "gray",
                      }}
                      align="left"
                    >
                      Repeat
                    </TableCell>
                    <TableCell
                      className={classes.bgcolor}
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        color: "gray",
                      }}
                      align="left"
                    >
                      Status
                    </TableCell>
                    <TableCell
                      className={classes.bgcolor}
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        color: "gray",
                      }}
                      align="left"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.serialno}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          width: "120px",
                          padding: "12px",
                          fontFamily: "poppinsemibold",
                          fontSize: "12px",
                        }}
                      >
                        {row.serialno}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          width: "120px",
                          padding: "12px",
                          fontFamily: "poppinsemibold",
                          fontSize: "12px",
                        }}
                      >
                        {row.date}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          width: "150px",
                          fontFamily: "poppinsemibold",
                          fontSize: "12px",
                        }}
                      >
                        {row.day}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          width: "185px",
                          fontFamily: "poppinsemibold",
                          fontSize: "12px",
                        }}
                      >
                        {row.holidayreason}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          width: "150px",
                          fontFamily: "poppinsemibold",
                          fontSize: "12px",
                        }}
                      >
                        {row.holidaytype}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ fontFamily: "poppinsemibold", width: "130px" }}
                      >
                        {row.repeat}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ fontFamily: "poppinsemibold", width: "230px" }}
                      >
                        <FormControlLabel
                          style={{ marginLeft: "0px", marginRight: "0px" }}
                          control={<IOSSwitch />}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <div
                          style={{
                            display: "flex",
                            padding: "13px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            paddingLeft: "0px",
                          }}
                        >
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                          >
                            <img
                              src={edit}
                              alt="Icon"
                              style={{
                                height: "12px",
                                backgroundColor: "white",
                                marginTop: "5px",
                              }}
                            />
                          </button>
                          {/* <AntSwitch
                            checked={state.checkedC}
                            onChange={handleChange}
                            name="checkedC"
                          /> */}

                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                          >
                            <img
                              src={Delete}
                              alt="Icon"
                              style={{
                                height: "12px",
                                backgroundColor: "transparent",
                                marginTop: "5px",
                              }}
                            />
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Card>
      </div>
    </Card>
  );
}
