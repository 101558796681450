import React from "react";

export const ArrowIcon = (props) => {
  let width = props.width ? props.width : "40.8";
  let height = props.height ? props.height : "40.8";
  let color = props.color ? props.color : "#252525";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.609"
      height="8"
      viewBox="0 0 14.609 8"
    >
      <path
        id="icons8-expand-arrow"
        class="cls-1"
        d="M16.422,12.2,10.3,18.321,4.188,12.2a.7.7,0,0,0-.984.984L9.813,19.8a.7.7,0,0,0,.984,0l6.609-6.609a.7.7,0,0,0-.984-.984Z"
        transform="translate(-3 -12)"
        fill={color}
      />
    </svg>
  );
};
