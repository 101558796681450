import React from "react";
import FrequencyMaster from "./frequencyMaster";
import { withHoverDrawer } from "../../HOCs";

class FrequencyMasterParent extends React.Component {
  render() {
    return <FrequencyMaster />;
  }
}

export default withHoverDrawer(FrequencyMasterParent);
