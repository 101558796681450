import React, { useState } from "react";
import { Typography, Grid, TextField, Button } from "@material-ui/core";
import { withAllContexts } from "../../../HOCs";
import styles from "./styles";
import Config from "../../../config";
import { v4 as uuidv4 } from "uuid";
import { upsertDocument } from "../../../function/commonapi";

function AddNewprocesss(props) {
  const classes = styles();
  const [processData, setprocessData] = useState({
    processName: props?.processParams?.elementname
      ? props?.processParams?.elementname
      : "",
  });
  const [error, setError] = useState({
    processName: false,
    msg: "Please enter the field",
  });
  const handleprocessData = (event) => {
    setprocessData({ ...processData, [event.target.name]: event.target.value });
  };

  const duplicate = props.incomingProcesssData.filter(
    (itm) => itm.Repository.elementname === processData.processName
  );

  const validation = () => {
    let isValidate = false;

    if (processData?.processName?.trim()?.length > 0) {
      error.processName = false;
    } else {
      isValidate = true;
      error.processName = true;
    }
    if (duplicate.length > 0) {
      isValidate = true;
      error.processName = true;
      error.msg = "Process already exists";
    } else {
      error.processName = false;
    }

    setError({
      ...error,
    });
    return isValidate;
  };

  const editprocesssSubmit = async () => {
    const { drawer } = props;
    let check = validation();
    if (check) {
      return;
    }
    let list = {
      elementname: processData?.processName,
    };
    let sendProcessData = {
      entity: Config.repositoryEntity,
      metadataId: Config.metadataid,
      isedit: true,
      id: props?.processParams?.rep_id,
      list,
      keyvalue: "rep_id",
    };

    let addEditProcessUpsert = await upsertDocument(sendProcessData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          props.getRepositoryData();

          props.handleAlerts("Process edited successfully!", true);
          drawer.setDrawer({
            ...drawer,
            open: false,
            component: <></>,
          });
        } else {
          props.handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        props.handleAlerts("Something  went wrong", false);
      });
  };

  const submitprocesss = async () => {
    let submittedprocess = {
      processName: processData.processName,
      //   route: formData.route,
    };
    const { drawer } = props;
    let check = validation();
    if (check) {
      return;
    }
    let list = {
      rep_id: uuidv4(),
      rep_type: "Processes",
      elementname: submittedprocess.processName,
      is_active: true,
    };

    let sendProcessData = {
      entity: Config.repositoryEntity,
      metadataId: Config.metadataid,
      list,
    };

    let addProcessUpsert = await upsertDocument(sendProcessData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          props.getRepositoryData();

          props.handleAlerts("Process edited successfully!", true);
          drawer.setDrawer({
            ...drawer,
            open: false,
            component: <></>,
          });
        } else {
          props.handleAlerts("Something  went wrong", false);
        }
      })
      .catch((error) => {
        props.handleAlerts("Something  went wrong", false);
      });
  };

  return (
    <div>
      <div className={classes.drawerHead}>
        <Typography className={classes.headerMain}>
          {props.editName ? "Edit processs" : "Add new process"}
        </Typography>
      </div>

      <Grid container direction="row">
        <Grid item xs={12}>
          <div className={classes.textTotalArea}>
            <Typography className={classes.drawerSubHead}>
              Process Name
            </Typography>
            <TextField
              label={"Process"}
              variant="outlined"
              fullWidth
              size="small"
              name="processName"
              onChange={handleprocessData}
              value={processData.processName}
              required
              inputProps={{ style: { fontFamily: "poppin", fontSize: "12px" } }}
              InputLabelProps={{
                style: { fontFamily: "poppin", fontSize: "12px" },
              }}
              error={error?.processName ? true : false}
              helperText={error?.processName ? error.msg : ""}
            />
          </div>
          <div className={classes.btnPosition}>
            <Button
              contained
              className={classes.addDrawerBtn}
              onClick={props.editName ? editprocesssSubmit : submitprocesss}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withAllContexts(AddNewprocesss);
