import { Dialog, withStyles } from "@material-ui/core";
import React from "react";
import { VerticalList } from "../../../components";
import TableFormData from "../generalMaster/tableFormData";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
import {CircularProgress} from "@material-ui/core";

const useStyles = (theme) => ({
  paper: { minWidth: "800px" },
});

class CustomGeneralMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openIndex: null,
      formData: [],
      perPage : 10,
      page: 0,
    };
    this.handleAddBtn = this.handleAddBtn.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  getlistData = async () => {
    // GENERAL_MASTERS_READ
    let dataslist_ = await this.props.GENERAL_MASTERS_READ();
    let dataslist = dataslist_?.payload?.data?.filter(
      (val) => val?.name === this.props?.type
    );
    this.state.formData = dataslist ?? [];
    this.setState({ ...this.state });
  };
  componentDidMount() {
    this.getlistData();
  }
  handleAddBtn = (index) => {
    let { open } = this.state;
    this.setState({
      open: !open,
      openIndex: index,
    });
  };
  changeState = (name, value) => {
    this.setState({ [name]: value });
  };
  changeTab = () => {
    this.setState({ open: false });
  };

  handleSearch = async(value = "", title) => {
    // console.log(value,"value",title,"title")
    if (value?.length > 0) {
      await this.props.GET_GENERAL_MASTER_TYPE({
        type: title,
        search:value.trim(),
        page: this.state.page,
        perPage: this.state.perPage
      });
      this.setState({
        ...this.state,
        search: value.trim()
      });
    } else if (!value) {
     await this.props.GET_GENERAL_MASTER_TYPE({
      type: title,
      page: this.state.page,
      perPage: this.state.perPage
     });
    }
    this.setState({
      ...this.state,
      search: ""
    });
  }

  render() {
    const { open, openIndex } = this.state;
    // console.log("data recieved", this.state);
    let { classes, disableList, uppercaseList } = this.props;

    // console.log(JSON.stringify(this.props.general_master_list))

    return (
      <React.Fragment>
        {this.props.general_master_list?.loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "64.5vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : this.state?.formData?.length > 0 ? (
          <VerticalList
            in_styles={{ overflow: "auto", height: "78vh" }}
            parent_id={"general_master"}
            isLeftList={false}
            addFunc={this.handleAddBtn}
            changeTab={this.changeTab}
            addNew={true}
            emptyData={this.state?.formData?.length === 0 ? true : false}
            // searchCmpProps starts here
            id={"timetable"}
            placeholder={"search code & Description"}
           // loading={this.props?.searchLoading}
           onSearchChange={this?.handleSearch}
           hideSearchBar={false}
           hideFilter={true}
           // onFilterClicked={this.props?.onFilterClicked}
           // isFilterApplied={this.props?.isFilterApplied}
            TabList={
              // this.props.general_master_list?.loading ? "loading..." :
              this.state?.formData?.map((val, i) => {
                return {
                  name: this.props.displayType || val?.name,
                  component: (
                    <TableFormData
                      changeState={this.changeState}
                      open={open}
                      disableList={disableList}
                      uppercaseList={uppercaseList}
                      openIndex={openIndex}
                      parent_id={this.props.displayType || val?.name}
                      data={val}
                      options={this?.props?.options??null}
                    />
                  ),
                };
              })
            }
          />
        ) : (
          <div id={`Nothing-Listed-div`} className={classes.emptyRight}>
            Please config the Column types JSON in General masters (
            {this.props.type})
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  general_master_list: state?.generalMasterSlice?.generalMastersRead,
});

export default connect(
  mapStateToProps,
  actions
)(withStyles(useStyles)(CustomGeneralMaster));
