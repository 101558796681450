import React from "react";
import { Paper, Typography, makeStyles, Grid, Switch } from "@material-ui/core";
import { SelectBox } from "../../../../../../components/common/smartForm/component";

const useStyles = makeStyles((theme) => ({
  root: {
    // boxShadow: "0px 10px 25px #0000000A",
    padding: "20px 20px 30px 20px",
    borderRadius: "0px 0px 8px 0px",
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {
    border: "1px solid #E0E0E0",
    borderRadius: 8
  },
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
  grid: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

function ConsentRequired(props) {
  const classes = useStyles();
  const { ConsentFormOption, ConsentForm, consentSwitch, handleFormState, parent_id } =
    props;
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}_parent_paper`}
        className={classes.root} elevation={0}>
        <Grid
          id={`${parent_id}_parent_container`}
          container className={classes.form} spacing={2}>
          <Grid
            id={`${parent_id}_CONSENT_grid`}
            item xs={6}>
            <Typography
              id={`${parent_id}_CONSENT_typography`}
              className={classes.title} variant="h6">
              CONSENT REQUIRED
            </Typography>
          </Grid>
          <Grid
            id={`${parent_id}_CONSENT_grid`}
            className={classes.grid} item xs={6}>
            <Switch
              id={`${parent_id}_CONSENT_Switch`}
              checked={consentSwitch}
              color="primary"
              onChange={(e) =>
                handleFormState("consentSwitch", e.target.checked)
              }
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </Grid>
          <Grid
            id={`${parent_id}_CONSENT_Form_grid`}
            item xs={12} style={{ paddingBottom: "13px" }}>
            <Typography
              id={`${parent_id}_CONSENT_Form_typography`}
              className={classes.formtitle} variant="body1">
              Consent Form
            </Typography>
            <SelectBox
              parent_id={`${parent_id}_consent_form`}
              list={ConsentFormOption ?? []}
              disabled={consentSwitch ? false : true}
              placeholder={"Select"}
              value={ConsentForm}
              onchange={(e, value) => handleFormState("ConsentForm", value)}
            />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default ConsentRequired;
