import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  Switch,
  FormControlLabel,
  Checkbox,
  Grid
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from "moment";
import { SelectBox, DateTimePicker } from "./component";
import { TextFieldWithSelect } from "../../../components";

import CustomInputDatePicker from "../datePicker/inputDatePicker";
// import moment from "moment";

export const generateComponent = ({
  data,
  handleChange = null,
  state,
  classes,
  parent_id,
  ...props
}) => {
  switch (data.componet_type) {
    case "text":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <Typography className={classes.drawerSubHead}>
            {data.label}
            {data?.required && <span style={{ color: "red" }}>*</span>}
          </Typography>
          <TextField
            id={`${parent_id}_${data.state_name ? data.state_name : ""}__text_field`}
            value={state?.states?.[data?.state_name] ?? ""}
            onChange={(e) => handleChange(data.state_name, e.target.value, data.isUpperCase, data?.textLength)}
            variant="outlined"
            fullWidth
            required={data?.required}
            // disabled={data?.disabled}
            type={data?.type ?? "text"}
            size="small"
            name="route"
            disabled={data?.disabled}
            multiline={data?.multiline ?? false} //props for text area
            maxRows={data?.maxRows} //props for text area - maximum height
            minRows={data?.minRows} //props for text area - minimum height
            inputProps={{
              style: { fontFamily: "poppin", fontSize: "12px", backgroundColor: data?.disabled ? "#E0E0E0" : "#fff" },
              maxlength: data?.maxlength,
            }}
            InputLabelProps={{
              style: { fontFamily: "poppin", fontSize: "12px" },
            }}
            error={state?.error?.[data?.state_name] ?? false}
          />
        </Grid>
      );
    case "switch":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12} >
          <Typography className={classes.drawerSubHead}>
            {data.label}
          </Typography>
          <Switch
            id={`${parent_id}_${data.state_name ? data.state_name : ""}__switch`}
            checked={state?.states?.[data?.state_name] ?? ""}
            onChange={(e) => handleChange(data.state_name, e.target.checked)}
            name={data?.state_name}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </Grid>
      );
    case "checkbox":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <FormControlLabel
            control={
              <Checkbox
                id={`${parent_id}_${data.state_name ? data.state_name : ""}__checkbox`}
                checked={state?.states?.[data?.state_name] ?? ""}
                onChange={(e) =>
                  handleChange(data.state_name, e.target.checked)
                }
                name="checkedA"
              />
            }
            label={data.label}
          />
        </Grid>
      );
    case "select":
      let option = data?.options ?? [];
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <SelectBox
            parent_id={`${parent_id}_${data.state_name ? data.state_name : ""}__selectbox`}
            requireStart={data.required}
            top_title={data.label}
            list={Array.isArray(option) ? option : []}
            placeholder={data?.placeholder ?? "Search here..."}
            value={state?.states?.[data?.state_name] ?? null}
            onchange={(e, value) => handleChange(data.state_name, value)}
            error={state?.error?.[data?.state_name] ?? false}
            multi={data.multi ?? false}
            disabled={data.disabled}
          />
        </Grid>
      );
    case "date":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          {/* <DateTimePicker
            id = {`${parent_id}_${data.state_name ? data.state_name : ""}__datepicker`}
            required={data?.required}
            label={data.label}
            dateFormat={data.dateFormat}
            disabled={data?.disabled}
            value={state?.states?.[data?.state_name] ?? null}
            onChange={(value) => handleChange(data.state_name, value)}
            error={state?.error?.[data?.state_name] ?? false}
            minDate={state?.states?.[data?.minDateState]?  
              moment(state?.states?.[data?.minDateState])
              :null} 
          /> */}
          <Typography className={classes.drawerSubHead}>
            {data.label}
            {data?.required && <span style={{ color: "red" }}>*</span>}
          </Typography>
          <CustomInputDatePicker
            disabled={data?.disabled}
            placeholderText="dd / mm / yyyy"
            minDate={state?.states?.[data?.minDateState] ? (new Date(state?.states?.[data?.minDateState])) : data?.minDateState ? data?.minDateState : null}
            maxDate={new Date(2100, 0, 1)}
            selectedDate={state?.states?.[data?.state_name] ? new Date(state?.states?.[data?.state_name]) : null}
            handleChange={(value) => handleChange(data.state_name, value)}
            inputField={
              <TextField
                id={`${parent_id}_${data.state_name ? data.state_name : ""}__datepicker`}
                //  label = {data.label}
                fullWidth
                variant="outlined"
                required={data?.required}
                disabled={data?.disabled}
                value={state?.states?.[data?.state_name] ? moment(new Date(state?.states?.[data?.state_name])).format("DD-MM-YYYY") : null}
                inputProps={{
                  style: { padding: "10.5px 14px", fontFamily: "poppin", fontSize: "12px", backgroundColor: data?.disabled ? "#E0E0E0" : "#fff" },
                }}
              />
            }
          />
        </Grid>
      );
    case "textField_select":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <TextFieldWithSelect
            id={`${parent_id}_${data.state_name ? data.state_name : ""}__text_field_with_select`}
            options={data?.options}
            label={data.label}
            value={state?.states?.[data?.state_name] ?? {}}
            onChange={(value) => handleChange(data.state_name, value)}
            required={data?.required}
            error={state?.error?.[data?.state_name] ?? false}
          />
        </Grid>
      );
    case "textCustom":
      return (
        <Grid style={{ padding: "10px" }} md={data?.grid?.md ?? 12}>
          <Typography className={classes.drawerSubHead}>
            {data.label}
            {data?.required && <span style={{ color: "red" }}>*</span>}
          </Typography>
          <TextField
            id={`${parent_id}_${data.state_name ? data.state_name : ""}__text_field`}
            value={typeof state?.states?.[data?.state_name] === "object" ? state?.states?.[data?.state_name].label : state?.states?.[data?.state_name] ?? ""}
            onChange={(e) => {
              let value = e.target.value;
              if (value) {
                value = Number(value)
                if (value > data?.maxNum) {
                  value = data?.maxNum
                }
                value += ""
              }
              handleChange(data.state_name, value, data.isUpperCase, data?.textLength)
            }}
            variant="outlined"
            fullWidth
            required={data?.required}
            disabled={data?.disabled}
            type={data?.type ?? "text"}
            size="small"
            name="route"
            multiline={data?.multiline ?? false} //props for text area
            maxRows={data?.maxRows} //props for text area - maximum height
            minRows={data?.minRows} //props for text area - minimum height
            InputProps={{
              style: { fontFamily: "poppin", fontSize: "12px", backgroundColor: data?.disabled ? "red" : "#fff" },
              endAdornment: (
                <InputAdornment position="end">
                  <Typography style={{ paddingRight: "10px", fontFamily: "poppin", fontSize: "12px", }}>
                    {data?.maxNum ?? ""}
                  </Typography>
                  <Typography style={{ color: "#B6B6B6", fontFamily: "poppin", fontSize: "12px", }}>
                    {data?.uomValue ?? ""}
                  </Typography>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { fontFamily: "poppin", fontSize: "12px" },
            }}
            error={state?.error?.[data?.state_name] ?? false}
            helperText={"* Entered Value Should Be Between 0 -" + data?.maxNum}
          />
        </Grid>
      );
    default:
      break;
  }
};
