import * as React from "react";
import PropTypes from "prop-types";

export const LinkIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      id="icons8-link"
      d="M15.006,4a2.993,2.993,0,0,0-2.122.878L11.217,6.549A3,3,0,0,0,10.5,9.625l1.2-1.2a1.651,1.651,0,0,1,.464-.936l1.667-1.667a1.667,1.667,0,0,1,2.358,2.357L14.517,9.849a1.652,1.652,0,0,1-.936.464l-1.2,1.2a2.991,2.991,0,0,0,3.077-.72l1.667-1.667A3,3,0,0,0,15.006,4ZM12.992,8.33a.667.667,0,0,0-.458.2l-4,4a.667.667,0,1,0,.943.943l4-4a.667.667,0,0,0-.485-1.145ZM8.821,10.345a2.986,2.986,0,0,0-2.271.871L4.882,12.883a3,3,0,0,0,4.243,4.243l1.667-1.667a3,3,0,0,0,.72-3.076l-1.2,1.2a1.651,1.651,0,0,1-.464.936L8.183,16.184a1.667,1.667,0,0,1-2.357-2.357l1.667-1.667a1.654,1.654,0,0,1,.936-.465l1.2-1.2A3.025,3.025,0,0,0,8.821,10.345Z"
      transform="translate(-4.005 -4.004)"
      fill={props?.color}
    />
  </svg>
);

LinkIcon.defaultProps = {
  color: "#0071F2",
};

LinkIcon.propTypes = {
  color: PropTypes.string,
};
