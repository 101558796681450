import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import editIcon from "../../../../../../assets/icons - Edit.svg";
import DeleteIcon from "../../../../../../assets/icons8-trash.svg";
// const detailsData = ["Encouter Type", "male", "age group", "age Category"];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F9F9F9",
    borderRadius: 8,
    padding: "20px 0px",
    alignItems: "center",
    marginBottom: 15,
  },
  numbPosition: {
    backgroundColor: "#E0E0E0",
    width: "22px",
    borderRadius: "0px 5px 5px 0px",
    padding: "5px",
    marginRight: 15,
    display: "flex",
    alignItems: "center",
  },
  mainGrid: {
    display: "flex",
  },
  details: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
    color: "#6F6F6F",
  },
  dot: {
    backgroundColor: "#6F6F6F",
    padding: "2px",
    borderRadius: "50%",
    margin: "0px 10px",
  },
}));

function DetailsCard(props) {
  const classes = useStyles();
  const { detailsData, handleDelete, handleEdit, parent_id } = props;
  return (
    <div
      id={`${parent_id}-parent-div`}
    >
      {detailsData?.length > 0 && (
        <>
          {detailsData?.map((item, i) => (
            <Grid
              id={`${parent_id}-${i}-parent-grid`}
              container className={classes.root}>
              <Grid
                id={`${parent_id}-${i}-sub-grid`}
                className={classes.mainGrid} item xs={11}>
                <Grid
                  id={`${parent_id}-${i}-sub-div`}
                  className={classes.numbPosition}>
                  <Typography
                    id={`${parent_id}-${i}-label-typography`}
                  >1</Typography>
                </Grid>
                <Grid
                  id={`${parent_id}-${i}-Details-div`}
                >
                  <Grid
                    id={`${parent_id}-${i}-Details-grid`}
                  >
                    <Typography
                      id={`${parent_id}-${i}-Details-title-typography`}
                      style={{ fontFamily: "poppinsemibold" }}>
                      Details
                    </Typography>
                  </Grid>
                  <div
                    id={`${parent_id}-${i}-Details-div`}
                    style={{ display: "flex" }}>
                    <Grid
                      id={`${parent_id}-${i}-Details-grid`}
                      style={{ display: "flex", alignItems: "center" }}
                      item
                    >
                      {item?.encounterType && (
                        <>
                          <Typography
                            id={`${parent_id}-${i}-Details-title-typography`}
                            className={classes.details}>
                            {item?.encounterType?.label}
                          </Typography>
                        </>
                      )}
                      {item?.gender && (
                        <>
                          <Typography
                            id={`${parent_id}-${i}-Details-gender-title-typography`}
                            className={classes.details}>
                            • {item?.gender?.label}
                          </Typography>
                        </>
                      )}
                      {item?.ageRange && (
                        <>
                          <Typography
                            id={`${parent_id}-${i}-Details-ageRange-title-typography`}
                            className={classes.details}>
                            • {item?.ageRange?.label}
                          </Typography>
                        </>
                      )}
                      {item?.agecategory && (
                        <>
                          {item?.agecategory?.map((v) => {
                            return (
                              <Typography
                                id={`${parent_id}-${i}-Details-label-title-typography`}
                                className={classes.details}>
                                • {v?.label}
                              </Typography>
                            );
                          })}
                        </>
                      )}
                      {item?.freqvalue && (
                        <>
                          <Typography
                            id={`${parent_id}-${i}-Details-freqvalue-title-typography`}
                            className={classes.details}>
                            • {item?.freqvalue} {item?.freqValSelect?.label}
                          </Typography>
                        </>
                      )}
                      {item?.freqrate && (
                        <>
                          <Typography
                            id={`${parent_id}-${i}-Details-freqrate-title-typography`}
                            className={classes.details}>
                            • {item?.freqrate} {item?.freqRatSelect?.label}
                          </Typography>
                        </>
                      )}
                      {item?.freqdura && (
                        <>
                          <Typography
                            id={`${parent_id}-${i}-Details-freqDuraSelect-title-typography`}
                            className={classes.details}>
                            • {item?.freqrate} {item?.freqDuraSelect?.label}
                          </Typography>
                        </>
                      )}
                      {item?.freqmax && (
                        <>
                          <Typography
                            id={`${parent_id}-${i}-Details-freqmax-title-typography`}
                            className={classes.details}>
                            • {item?.freqmax}
                          </Typography>
                        </>
                      )}
                      {item?.patientInstrction && (
                        <>
                          <Typography
                            id={`${parent_id}-${i}-Details-patientInstrction-title-typography`}
                            className={classes.details}>
                            • {item?.patientInstrction}
                          </Typography>
                        </>
                      )}
                      {item?.practitionerInstrction && (
                        <>
                          <Typography
                            id={`${parent_id}-${i}-Details-practitionerInstrction-title-typography`}
                            className={classes.details}>
                            • {item?.practitionerInstrction}
                          </Typography>
                        </>
                      )}
                      {item?.prepratoryInstrction && (
                        <>
                          <Typography
                            id={`${parent_id}-${i}-Details-prepratoryInstrction-title-typography`}
                            className={classes.details}>
                            • {item?.prepratoryInstrction}
                          </Typography>
                        </>
                      )}
                      {item?.departmentInstrction && (
                        <>
                          <Typography
                            id={`${parent_id}-${i}-Details-departmentInstrction-title-typography`}
                            className={classes.details}>
                            • {item?.departmentInstrction}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Grid
                id={`${parent_id}-${i}-Details-EditDelete-grid`}
                item xs={1}>
                <img
                  id={`${parent_id}-${i}-Details-Edit-img`}
                  src={editIcon}
                  onClick={() => handleEdit(item, i)}
                  width="12px"
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
                <img
                  id={`${parent_id}-${i}-Details-Delete-img`}
                  src={DeleteIcon}
                  onClick={() => handleDelete(item, i)}
                  width="12px"
                  style={{ marginRight: "10px", cursor: "pointer" }}
                />
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </div>
  );
}

export default DetailsCard;
