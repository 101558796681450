import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
} from "@material-ui/core";
import { SelectBox } from "../../../../../../components/common/smartForm/component";
//import { actions } from "primary_care_admin_binder";
//import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    borderRadius: 8,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
    "& .Mui-disabled": {
      background: "#f0f0f0",
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {},
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
}));

function Criteria(props) {
  const classes = useStyles();
  //let dispatch = useDispatch();
  const storeData = useSelector((state) => state?.vitalsApplicableSlice);

  React.useEffect(() => {
    getMasterData();
  }, []);

  const getMasterData = async () => {
    // await dispatch(actions.GENDER());
    // await dispatch(actions.SPECIALITY_CODE());
    // await dispatch(actions.DIAGNOSIS());
    // await dispatch(actions.AGE_RANGES_CODE());
  };
  const {
    gender,
    //diagnosisTextChange,
    specialCode,
    diagnosisGroup,
    ageRange,
    ageCategory,
    handleFormState,
    parent_id,
    error
  } = props;
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}_form_parent_paper`}
        className={classes.root}>
        <Typography
          id={`${parent_id}_form_title_typography`}
          className={classes.title} variant="h6">
          CRITERIA
        </Typography>
        <Grid
          id={`${parent_id}_form_Gender_grid`}
          container className={classes.form}>
          <Grid
            id={`${parent_id}_form_Gender_sub_grid`}
            md="2" lg="2" className={classes.formParent}>
            <Typography
              id={`${parent_id}_form_Gender_label`}
              className={classes.formtitle} variant="body1">
              Gender <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={`${parent_id}_gender`}
              list={storeData.gender.data ?? []}
              placeholder={"Select"}
              value={gender}
              onchange={(e, value) => handleFormState("gender", value)}
              error={error?.gender}
            />
          </Grid>
          <Grid
            id={`${parent_id}_form_Speciality_grid`}
            md="2" lg="2" className={classes.formParent}>
            <Typography
              id={`${parent_id}_form_Speciality_label`}
              className={classes.formtitle} variant="body1">
              Specialty
            </Typography>
            <SelectBox
              parent_id={`${parent_id}_speciality_code`}
              list={storeData.speciality_code_org.data ?? []}
              placeholder={"Select"}
              value={specialCode}
              onchange={(e, value) => handleFormState("specialCode", value)}
              error={error?.specialCode}
            />
          </Grid>
          <Grid
            id={`${parent_id}_form_Diagnosis_grid`}
            md="6" lg="6" className={classes.formParent}>
            <Typography
              id={`${parent_id}_form_Diagnosis_label`}
              className={classes.formtitle} variant="body1">
              Diagnosis Group
            </Typography>
            <SelectBox
              parent_id={`${parent_id}_diagnosisGroup`}
              onInputChange={(e) =>
                handleFormState("diagnosisTextChange", e.target.value)
              }
              search
              list={storeData.dignosis_master.data ?? []}
              multi
              placeholder={"Search diagnosis"}
              value={diagnosisGroup}
              onchange={(e, value) => handleFormState("diagnosisGroup", value)}
            />
          </Grid>
          <Grid
            id={`${parent_id}_form_Age_Range_grid`}
            md="3" lg="3" className={classes.formParent}>
            <Typography
              id={`${parent_id}_form_Age_Range_label`}
              className={classes.formtitle} variant="body1">
              Age Group <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={`${parent_id}_agerange_group_code`}
              list={storeData.ageranges_code.data ?? []}
              placeholder={"Select"}
              value={ageRange}
              onchange={(e, value) => handleFormState("ageRange", value)}
               error={error?.ageRange}
            />
          </Grid>
          <Grid
            id={`${parent_id}_form_Age_Category_grid`}
            md="3" lg="3" className={classes.formParent}>
            <Typography
              id={`${parent_id}_form_Age_Category_label`}
              className={classes.formtitle} variant="body1">
              Age Category <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={`${parent_id}_agecategory`}
              list={storeData.ageranges_category.data ?? []}
              placeholder={"Select"}
              value={ageCategory}
              onchange={(e, value) => handleFormState("ageCategory", value)}
               error={error?.ageCategory}
            />
          </Grid>
          <Grid
            id={`${parent_id}_form_Age_Minimum_grid`}
            md="2" lg="2" className={classes.formParent}>
            <Typography
              id={`${parent_id}_form_Age_Minimum_label`}
              className={classes.formtitle} variant="body1">
              Age Minimum <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              parent_id={`${parent_id}_agemin`}
              id={`${parent_id}__agemin_textField`}
              onChange={(e) => handleFormState("minAge", e.target.value)}
              value={ageCategory && ageRange ? ageCategory?.minAge : ""}
              disabled
              fullWidth
              type="text"
              variant="outlined"
              size="small"
            />
          </Grid>

          <Grid
            id={`${parent_id}_form_Age_Maximum_grid`}
            md="2" lg="2" className={classes.formParent}>
            <Typography
              id={`${parent_id}_form_Age_Maximum_label`}
              className={classes.formtitle} variant="body1">
              Age Maximum <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              id={`${parent_id}_agemax__textField`}
              value={ageCategory && ageRange ? ageCategory?.maxAge : ""}
              onChange={(e) => handleFormState("ageMax", e.target.value)}
              fullWidth
              disabled
              type="text"
              variant="outlined"
              size="small"
            />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default Criteria;
