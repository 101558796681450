import React from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
// import Grid from "@material-ui/core/Grid";
import availabilitylogo from "../../../assets/icons8-management (5).svg";
import practionerlogo from "../../../assets/icons8-user-folder (1).svg";
import FolderList from "../../list";

const drawerWidth = 265;
const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
    display: "block",
  },
  root: {
    display: 'flex',
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },

}));

export default function NavbarDrawer() {
  const classes = useStyles();
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };



  // const sideList = (side) => (
  //   <div
  //     className={classes.list}
  //     role="presentation"

  //     style={{ display: "flex", marginTop: "5px" }}
  //   >
  //     <img src={availabilitylogo} alt="logo" style={{ marginRight: "10px" }} />
  //     Availability management
  //   </div>
  // );

  return (
    // <Grid container spacing={0}>
    //   <Grid item md={3}>
    <div
      id={'sidenavbar-parent-div'}
      onMouseOver={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
      style={{
        width: "45px",
        backgroundColor: "#ffffff",
        height: "100vh",
        paddingTop: "14px",
        borderRight: "1px solid lightgrey"
      }}
    >
      <Button
        id={'sidenavbar-availabilitylogo-button'}
        style={{ width: "10px", padding: "0px" }}>

        <img
          id={'sidenavbar-availabilitylogo-img'}
          src={availabilitylogo}
          alt="logo"
          style={{ height: "18px", width: "18px" }}
        />
      </Button>
      <Button
        id={'sidenavbar-practionerlogo-button'}
        style={{ paddingTop: "18px" }}>
        <img
          id={'sidenavbar-practionerlogo-img'}
          src={practionerlogo}
          alt="logo"
          style={{ height: "18px", width: "18px" }}
        /></Button>
      <Button
        id={'sidenavbar-practionerlogo-button'}
        style={{ paddingTop: "18px" }}>
        <img
          id={'sidenavbar-practionerlogo-img'}
          src={practionerlogo}
          alt="logo"
          style={{ height: "18px", width: "18px" }}
        /></Button>
      <Drawer
        id={'sidenavbar-drawer'}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div
          id={'sidenavbar-FolderList'}
        > {<FolderList
          parent_id={'FolderList'}
        />} </div>
      </Drawer>
    </div>
    //   </Grid>
    // </Grid>
  );
}
