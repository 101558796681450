import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { SelectBox } from "../../../../../../components/common/smartForm/component";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    borderRadius: 8,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {},
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
  textFieldAlign: {
    marginTop: "3px",
  },
  labelCheckBox: {
    fontSize: "12px",
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
}));

const UmoDetails = (props) => {
  const classes = useStyles();
  const {
    uomCheckboxJson,
    handleCheckState,
    handleFormState,
    umoType,
    umoCode,
    shortdes,
    longdes,
    parent_id,
    error
  } = props;
  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}-parent-paper`}
        className={classes.root}>
        <Typography
          id={`${parent_id}-DRUG-UOM-DETAILS-typography`}
          className={classes.title} variant="h6">
          DRUG UOM DETAILS
        </Typography>
        <Grid
          id={`${parent_id}-parent-grid`}
          container className={classes.form}>
          <Grid
            id={`${parent_id}-UOM-Type-grid`}
            md="2" lg="2" className={classes.formParent}>
            <Typography
              id={`${parent_id}-UOM-Type-label-typography`}
              className={classes.formtitle} variant="body1">
              UOM Type <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              parent_id={"umoType"}
              list={props.typeDropDown ? props.typeDropDown : []}
              placeholder={"Select"}
              value={umoType}
              onchange={(e, value) => handleFormState("umoType", value)}
              error={error?.umoType}
            />
          </Grid>
          <Grid
            id={`${parent_id}-UOM-Code-grid`}
            md="3" lg="3" className={classes.formParent}>
            <Typography
              id={`${parent_id}-UOM-Code-label-typography`}
              className={classes.formtitle} variant="body1">
              UOM Code <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              id={`${parent_id}-UOM-Code-textField`}
              value={umoCode}
              onChange={(e) => handleFormState("umoCode", e.target.value?.replaceAll(/[.*+,><?^${}()"|[\]\\" "]/g,'')?.toLocaleUpperCase(), 20)}
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              className={classes.textFieldAlign}
              error={error?.umoCode}
              disabled={props?.editData}
            />
          </Grid>
          <Grid
            id={`${parent_id}-Short-Description-grid`}
            md="3" lg="3" className={classes.formParent}>
            <Typography
              id={`${parent_id}-Short-Description-label-typography`}
              className={classes.formtitle} variant="body1">
              Short Description <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              id={`${parent_id}-Short-Description-textField`}
              value={shortdes}
              onChange={(e) => handleFormState("shortdes", e.target.value,30)}
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              className={classes.textFieldAlign}
               error={error?.shortdes}
            />
          </Grid>
          <Grid
            id={`${parent_id}-Long-Description-grid`}
            md="4" lg="4" className={classes.formParent}>
            <Typography
              id={`${parent_id}-Long-Description-typography`}
              className={classes.formtitle} variant="body1">
              Long Description <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              id={`${parent_id}-Long-Description-textField`}
              value={longdes}
              onChange={(e) => handleFormState("longdes", e.target.value,60)}
              fullWidth
              type="text"
              variant="outlined"
              size="small"
              className={classes.textFieldAlign}
               error={error?.longdes}
            />
          </Grid>
        </Grid>
        <FormGroup
          id={`${parent_id}-Long-Description-FormGroup`}
          row style={{ marginTop: "10px" }}>
          {uomCheckboxJson?.map((_, i) => {
            return (
              <FormControlLabel
                id={`${parent_id}-${i}-Long-Description-FormGroup`}
                control={
                  <Checkbox
                    id={`${parent_id}-${i}-Long-Description-Checkbox`}
                    color="primary"
                    checked={_.checked}
                    onChange={(e) => handleCheckState(e.target.checked, i)}
                    size="small"
                  />
                }
                label={_.label}
                classes={{ label: classes.labelCheckBox }}
              />
            );
          })}
        </FormGroup>
      </Paper>
    </React.Fragment>
  );
};

export default UmoDetails;
