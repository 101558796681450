/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/12/202
 * @modify 12/12/202
 * @desc Providing the assemble registration from /src/context which is used in /src/App.js
 */

import React from "react";
import { PatientRegContext, VitalMastersContext } from "./";
const Practitionet = (props) => {
  let [patientData, setPatientData] = React.useState({
    identification: [{}],
    names: [{}],
    details: {},
    practitioner_description: {},
    communication: {},
    contact: [{}],
    Submitrole: {},
    address: [],
    publication: [{}],
    reporting: {},
    practitionerbyrole: [],
    qualification: [],
    training: [],
    job: [],
    awards: [],
  });
  let [vitalMaster, setVitalMaster] = React.useState({
    measureCode: [
      {
        column1: "KG/M2",
        column2: "kg/m2",
        column3: "kg/m2",
        status: "Active",
      },
      {
        column1: "POUNGS",
        column2: "lb",
        column3: "Pounds",
        status: "inactive",
      },
      {
        column1: "GRAM",
        column2: "Gram",
        column3: "Gram",
        status: "Active",
      },
    ],
    vitalMaster: [],
  });
  return (
    <PatientRegContext.Provider value={{ ...patientData, setPatientData }}>
      <VitalMastersContext.Provider value={{ ...vitalMaster, setVitalMaster }}>
        {props.children}
      </VitalMastersContext.Provider>
    </PatientRegContext.Provider>
  );
};

export default Practitionet;
