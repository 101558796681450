import React from "react";
import { Card } from "@material-ui/core";
import "../../font.css";
import { Typography } from "@material-ui/core";
import { TextInput, Upload, Col, Button, Text } from "qdm-component-library";
import "./style.css";
import PatientCotactPoint from "./contactPoint";
import PatientAddress from "./address";
import { withAllContexts } from "../../../HOCs";
import { Filenameui } from "./commonUI";
import { AlertProps, uploadImage, checkMaxDateValidation } from "../../../utils";
import moment from "moment";

import CustomInputDatePicker from "../../common/datePicker/inputDatePicker";
class AddNewJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: [{}],
      address: [],
    };
  }

  componentDidMount() {
    const { editData } = this.props;
    if (editData) {
      this.setState({ ...editData, erorr: {} });
    } else {
      this.Clear();
    }
  }

  onChangeState = (key, value, limit) => {
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    if (key === "start_date") {
      this.state["end_date"] = "";
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  validateCheck = (data) => {
    const job = this.props.totalJob
    const checkDate = job.map((val) => {
      return {
        startDate: moment(val.start_date, "YYYY-MM-DD").unix(),
        endDate: moment(val.end_date, "YYYY-MM-DD").unix()
      }
    })

    const currentStartD = moment(this.state?.start_date, "YYYY-MM-DD").unix();
    const currentEndD = moment(this.state.end_date, "YYYY-MM-DD").unix();

    const startDateCheck = checkDate.find((val) => {
      if (val.startDate <= currentStartD && val.endDate >= currentStartD) {
        return true
      }
      if (val.startDate <= currentEndD && val.endDate >= currentEndD) {
        return true
      }
      if (currentStartD <= val.startDate && currentEndD >= val.startDate) {
        return true
      }
      if (currentStartD <= val.endDate && currentEndD >= val.endDate) {
        return true
      }
      return false
    }
    )
    // const contactVal = () => {
    //   if(data.contact?.length === 0){
    //     return true;
    //   }else{
    //      let error = data.contact.map(val => val.mobile?.length > 0 ? true : false );
    //      return !error.includes(false);
    //   }
    // }
    if (!!startDateCheck) {
      return [true, "There is job experience for this date-intervals"]
    }

    if (this.state?.start_date?.length > 0 && this.state?.end_date?.length > 0) {
      if (checkMaxDateValidation(this.state?.start_date, this.state?.end_date)) {
        return [true, ""]
      }
    }

    if (
      data?.organization?.length > 0 &&
      data?.sequence_no?.length > 0 &&
      data?.department?.length > 0 &&
      data?.job_description?.length > 0
    ) {
      return [false, ""];
    } else {
      let errors = {
        organization: data?.organization?.length > 0 ? false : true,
        sequence_no: data?.sequence_no?.length > 0 ? false : true,
        department: data?.department?.length > 0 ? false : true,
        job_description: data?.job_description?.length > 0 ? false : true,
      };
      this.setState({ ...this.state, erorr: errors });
      return [true, ""];
    }
  };

  sequenceValidation(data) {
    debugger
    let arr = [];
    if (data?.length > 0 && this.props?.totalJob) {
      // this.props?.totalJob?.map((val, i) => {
      //   arr.push(val.sequence_no)
      // })
      // if(arr.includes(this.state.sequence_no)){
      //   return false;
      // } else {
      //   return true;
      // }
      return this.props?.totalJob?.some((v) => { return v.sequence_no === this.state.sequence_no })
    } else {
      return false;
    }
  }

  saveAddress = () => {
    debugger
    let jobData = this.props.totalJob;
    const [isError, message] = this.validateCheck(this.state)
    if (isError) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: message || "Please fill Job experience details mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }
    debugger
    if (!this.sequenceValidation(jobData)) {
      if (this.props?.saveAddress) {
        this.props.saveAddress(this.state);
      }
    } else {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please enter unique Sequence No",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return false;
    }

    // const { onclose, index, handleClear } = this.props;
    // let patientData = this.props[this.props.saveto];
    // if (index !== null) {
    //   patientData.job[index] = this.state;
    // } else {
    //   patientData.job.push(this.state);
    // }
    // patientData[this.props.setTo]({
    //   ...patientData,
    // });
    // onclose();
    // handleClear();
    // this.Clear();
  };

  Clear = () => {
    this.setState({
      organization: null,
      sequence_no: null,
      department: null,
      designation: null,
      role: null,
      start_date: null,
      end_date: null,
      reporting_to: null,
      pay_scale: null,
      grade: null,
      job_description: null,
    });
  };
  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "10px",
      marginBottom: "6px",
      fontFamily: `poppin !important`,
    },
    borderStyle: {
      borderRadius: "8px",
      borderColor: "#E0E0E0",
      width: "514px",
      height: "40px",
    },
    borderStyle2: {
      borderRadius: "8px",
      height: "40px",
      borderColor: "#E0E0E0",
      width: "187px",
    },
    borderStyle78: {
      borderRadius: "8px",
      height: "40px",
      borderColor: "#E0E0E0",
      width: "78px",
    },
    borderStyle504: {
      borderRadius: "8px",
      height: "40px",
      borderColor: "#E0E0E0",
      width: "504px",
    },
    borderStyle447: {
      borderRadius: "8px",
      height: "40px",
      borderColor: "#E0E0E0",
      width: "447px",
    },
    borderStyle484: {
      borderRadius: "8px",
      height: "40px",
      borderColor: "#E0E0E0",
      width: "484px",
    },
    root: {
      borderRadius: "8px !important",
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
    errorBorderStyle: {
      borderRadius: 8,
      border: "1px solid red",
      height: "40px",
      width: "504px",
    },
  };

  setCustomContact = (name, value, index) => {
    if (name === "mode") {
      this.state.contact[index]["mobile"] = "";
    }
    this.state.contact[index][name] = value;
    this.setState({
      ...this.state,
    });
  };

  addNewContact = () => {
    this.state.contact.push({});
    this.setState({
      ...this.state,
    });
  };

  deleteContect = (index) => {
    // let contact = this.state.contact.splice(0, index)
    this.state.contact.splice(index, 1);
    this.setState({
      ...this.state,
      // contact: contact
    });
  };

  handleClear = () => {
    this.Clear();
    if (this.props?.handleClear) {
      this.props.handleClear();
    }
  };

  setAddress = (data) => {
    this.state.address = data;
    this.setState({
      ...this.state,
    });
  };

  setDataUpload = async (name, value, next, limit) => {
    var UploadedFiles = [];
    if (limit) {
      if (limit < value.length) {
        return false;
      }
    }
    UploadedFiles = await uploadImage([value]);
    this.onChangeState(name, UploadedFiles);
  };

  render() {
    const {
      labelStyle,
      borderStyle2,
      borderStyle78,
      borderStyle504,
      borderStyle447,
      borderStyle484,
      containedButton,
      errorBorderStyle,
    } = this.styles;
    const {
      //onclose, 
      parent_id } = this.props;
    console.log("jobInner", this.state);

    return (
      <div
        id={`${parent_id}-parent-div`}
      >
        <Card
          id={`${parent_id}-parent-card`}
          style={{
            paddingBottom: "20px",
            paddingLeft: "20px",
            paddingTop: "15px",
            margin: 8,
          }}
        >
          <div
            id={`${parent_id}-Job-Details-div`}
            style={{ paddingBottom: 6 }}>Job Details</div>
          <div
            id={`${parent_id}-parent-div`}
            style={{ display: "flex", flexWrap: "wrap" }}>
            <div
              id={`${parent_id}-Sequence-div`}
              style={{ marginRight: "15px" }}>
              <Typography
                id={`${parent_id}-Sequence-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                  // width: "81px",
                }}
              >
                Sequence No <span
                  id={`${parent_id}-Sequence-star-span`}
                  style={{ color: "red" }}>*</span>
              </Typography>
              <TextInput
                id={`${parent_id}-Sequence-textinput`}
                className="root"
                //type="number"
                onChange={(e) =>
                  this.onChangeState("sequence_no", e.target.value, 2)
                }
                value={this.state.sequence_no}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "78px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={
                  this.state?.erorr?.sequence_no
                    ? { ...errorBorderStyle, width: "78px" }
                    : borderStyle78
                }
                placeholder={""}
              />
            </div>
            <div
              id={`${parent_id}-Organization-div`}
              style={{ marginRight: "15px" }}>
              <Typography
                id={`${parent_id}-Organization-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Organization <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextInput
                id={`${parent_id}-Organization-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("organization", e.target.value)
                }
                value={this.state.organization}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
                // style={{ width: "504px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={
                  this.state?.erorr?.organization
                    ? errorBorderStyle
                    : borderStyle504
                }
                // style={borderStyle504}
                placeholder={"Enter Organization"}
              />
            </div>
            <div
              id={`${parent_id}-Department-div`}
              style={{ marginRight: "15px" }}>
              <Typography
                id={`${parent_id}-Department-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  margin: "8px 0px",
                }}
              >
                Department <span
                  id={`${parent_id}-Department-star-span`}
                  style={{ color: "red" }}>*</span>
              </Typography>
              <TextInput
                id={`${parent_id}-Department-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("department", e.target.value)
                }
                value={this.state.department}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
                // style={{ width: "504px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                // style={borderStyle504}
                style={
                  this.state?.erorr?.department
                    ? errorBorderStyle
                    : borderStyle504
                }
                placeholder={"Enter Department"}
              />
            </div>
          </div>
          <div
            id={`${parent_id}-Designation-div`}
            style={{ display: "flex", marginTop: "16px", flexWrap: "wrap" }}>
            <div
              id={`${parent_id}-Designation-sub-div`}
              style={{ marginRight: "15px" }}>
              <Typography
                id={`${parent_id}-Designation-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Designation
              </Typography>
              <TextInput
                id={`${parent_id}-Designation-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("designation", e.target.value)
                }
                value={this.state.designation}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "504px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle504}
                placeholder={"Enter Designation"}
              />
            </div>
            <div
              id={`${parent_id}-Role-div`}
              style={{ marginRight: "15px" }}>
              <Typography
                id={`${parent_id}-Role-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Role
              </Typography>
              <TextInput
                id={`${parent_id}-Role-textinput`}
                className="root"
                onChange={(e) => this.onChangeState("role", e.target.value)}
                value={this.state.role}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "187px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle2}
                placeholder={"Enter Role"}
              />
            </div>
            <div
              id={`${parent_id}-Start-Date-div`}
              style={{ marginRight: "15px" }}>
              <Typography
                id={`${parent_id}-Start-Date-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Start Date
              </Typography>
              {/* <TextInput
                id={`${parent_id}-Start-Date-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("start_date", e.target.value)
                }
                value={this?.state?.start_date ?? ""}
                variant="outlined"
                fullWidth
                size="small"
                type="date"
                name="pageName"
                //style={{ width: "187px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle2}
                max={"9999-12-31"}
              /> */}
              <CustomInputDatePicker
                disabled={false}
                placeholderText="dd / mm / yyyy"
                selectedDate={
                  this?.state?.start_date
                    ? new Date(this?.state?.start_date)
                    : null
                }
                // maxDate={new Date()}
                //minDate={new Date(this.state.course_start_date)}
                handleChange={(value) =>
                  this.onChangeState("start_date", value)
                }
                inputField={
                  <TextInput
                    id={`${parent_id}-Start-Date-textinput`}
                    fullWidth
                    placeholder="dd / mm / yyyy"
                    variant="outlined"
                    required={true}
                    disabled={false}
                    type={"text"}
                    value={
                      this?.state?.start_date
                        ? moment(new Date(this?.state?.start_date)).format(
                          "DD/MM/YYYY"
                        )
                        : null
                    }
                    inputProps={{
                      style: {
                        padding: "10.5px 14px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        borderRadius: 8,
                        borderColor: "#e0e0e0",
                      },
                    }}
                  />
                }
              />
            </div>
            <div
              id={`${parent_id}-end-Date-div`}
              style={{ marginRight: "15px" }}>
              <Typography
                id={`${parent_id}-end-Date-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  margin: "8px 0px",
                }}
              >
                End Date
              </Typography>
              {/* <TextInput
                id={`${parent_id}-end-Date-textinput`}
                className="root"
                onChange={(e) => this.onChangeState("end_date", e.target.value)}
                value={this.state.end_date}
                variant="outlined"
                fullWidth
                size="small"
                type="date"
                name="pageName"
                //style={{ width: "187px" }}
                min={moment(this.state.start_date).format("YYYY-MM-DD")}
                max={"9999-12-31"}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle2}
              // helperText={(this.state?.start_date?.length > 0 && this.state?.end_date?.length > 0) ? checkMaxDateValidation(this.state?.start_date, this.state?.end_date)??" ":" "}
              // error={true}
              /> */}
              <CustomInputDatePicker
                disabled={false}
                placeholderText="dd / mm / yyyy"
                selectedDate={
                  this.state.end_date
                    ? new Date(this.state.end_date)
                    : null
                }
                // maxDate={new Date()}
                minDate={new Date(this.state.start_date)}
                handleChange={(value) =>
                  this.onChangeState("end_date", value)
                }
                inputField={
                  <TextInput
                    id={`${parent_id}-end-Date-textinput`}
                    fullWidth
                    placeholder="dd / mm / yyyy"
                    variant="outlined"
                    required={true}
                    disabled={false}
                    type={"text"}
                    value={
                      this.state.end_date
                        ? moment(new Date(this.state.end_date)).format(
                          "DD/MM/YYYY"
                        )
                        : null
                    }
                    inputProps={{
                      style: {
                        padding: "10.5px 14px",
                        fontFamily: "poppin",
                        fontSize: "12px",
                        borderRadius: 8,
                        borderColor: "#e0e0e0",
                      },
                    }}
                  />
                }
              />
              {(this.state?.start_date?.length > 0 && this.state?.end_date?.length > 0 && checkMaxDateValidation(this.state?.start_date, this.state?.end_date)) &&
                <Text
                  id={`${parent_id}-end-date-text`}
                  style={{ padding: "0px 4px", fontSize: "12px", color: "red" }}>
                  {checkMaxDateValidation(this.state?.start_date, this.state?.end_date) ?? " "}
                </Text>}
            </div>
          </div>
          <div
            id={`${parent_id}-Reporting-To-div`}
            style={{ display: "flex", marginTop: "16px", flexWrap: "wrap" }}>
            <div style={{ marginRight: "15px" }}>
              <Typography
                id={`${parent_id}-Reporting-To-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Reporting To
              </Typography>
              <TextInput
                id={`${parent_id}-Reporting-To-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("reporting_to", e.target.value)
                }
                value={this.state.reporting_to}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "534px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle504}
                placeholder={"Enter Reporting To"}
              />
            </div>
            <div
              id={`${parent_id}-Pay-scale-div`}
              style={{ marginRight: "15px" }}>
              <Typography
                id={`${parent_id}-Pay-scale-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Pay Scale
              </Typography>
              <TextInput
                id={`${parent_id}-Pay-scale-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("pay_scale", e.target.value)
                }
                value={this.state.pay_scale}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "534px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle504}
                placeholder={"Enter Pay Scale"}
              />
            </div>
          </div>
          <div
            id={`${parent_id}-Grade-div`}
            style={{ display: "flex", marginTop: "16px", flexWrap: "wrap" }}>
            <div
              id={`${parent_id}-Grade-div`}
              style={{ marginRight: "15px" }}>
              <Typography
                id={`${parent_id}-Grade-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Grade
              </Typography>
              <TextInput
                id={`${parent_id}-Grade-textinput`}
                className="root"
                onChange={(e) => this.onChangeState("grade", e.target.value)}
                value={this.state.grade}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "447px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                style={borderStyle447}
                placeholder={"Enter Grade"}
              />
            </div>
            <div
              id={`${parent_id}-job-description-div`}
              style={{ marginRight: "15px" }}>
              <Typography
                id={`${parent_id}-job-description-title-typography`}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  fontFamily: "poppin",
                  marginBottom: "8px",
                }}
              >
                Job Description <span
                  id={`${parent_id}-job-description-star-span`}
                  style={{ color: "red" }}>*</span>
              </Typography>
              <TextInput
                id={`${parent_id}-job-description-textinput`}
                className="root"
                onChange={(e) =>
                  this.onChangeState("job_description", e.target.value)
                }
                value={this.state.job_description}
                variant="outlined"
                fullWidth
                size="small"
                name="pageName"
                //style={{ width: "484px" }}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                labelStyle={labelStyle}
                // style={borderStyle484}
                style={
                  this.state?.erorr?.job_description
                    ? { ...errorBorderStyle, width: "484px" }
                    : borderStyle484
                }
                placeholder={"Enter Job Description"}
              />
            </div>
            {this.state?.doc?.[0]?.filename ? (
              <div
                id={`${parent_id}-Filenameui-div`}
                style={{ padding: "0px 16px" }}>
                <Filenameui
                  parent_id={'addnewjobexp'}
                  fileName={this.state?.doc?.[0]?.filename ?? ""}
                  delete={() => this.onChangeState("doc", null)}
                />
              </div>
            ) : (
              <Col
                id={`${parent_id}-Upload-col`}
                md={3} lg={3} sm={6} xs={12} inLineStyles={{ padding: 10 }}>
                <div
                  id={`${parent_id}-Upload-div`}
                  style={{ marginTop: "16px", marginRight: "15px" }}>
                  {/* <UploadFiles /> */}
                  <Upload
                    id={`${parent_id}-Document-Upload`}
                    label="Upload Document"
                    className="pi-upload-btn"
                    handleChange={(e, value) =>
                      this.setDataUpload("doc", value)
                    }
                    labelColor="#0071F2"
                    iconColor="#0071F2"
                    iconSize={14}
                    imgUrl="images/uploadBlue.svg"
                    labelStyle={{
                      fontSize: 14,
                      fontFamily: "pc_medium !important",
                    }}
                  />
                </div>
              </Col>
            )}
          </div>
        </Card>
        <div>
          <PatientCotactPoint
            parent_id={'newjobCotactPoint'}
            allMasters={this.props?.allMasters}
            saveto={"patientData"}
            setTo={"setPatientData"}
            setCustom={this.setCustomContact}
            addNewContact={this.addNewContact}
            ondelete={this.deleteContect}
            contactList={this.state?.contact ?? []}
          />
        </div>

        <PatientAddress
          parent_id={'newjobAddresst'}
          addressList={this.state?.address ?? []}
          setAddress={this.setAddress}
          allMasters={this?.props?.allMasters}
          saveto={"patientData"}
          setTo={"setPatientData"}
        />

        <div
          id={`${parent_id}-buttons-div`}
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Button
            id={`${parent_id}-Cancel-button`}
            variant="text"
            className={"topNav-actions cancelBtn"}
            onClick={() => this.handleClear()}
          >
            Cancel
          </Button>
          <Button
            id={`${parent_id}-Save-button`}
            onClick={() => this.saveAddress()}
            inLineStyles={containedButton}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default withAllContexts(AddNewJob);
