import React from 'react';
import { TextField, makeStyles, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #E0E0E0',
        borderRadius: 5,
        backgroundColor: '#fff',
        padding: 7
    },
    autocomplete: {
        flex: 1,
        display: 'flex',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0
        },
        '& .MuiInputBase-root': {
            padding: 0,
            '& input': {
                padding: '0px 8px !important',
                fontSize: 12,
                fontFamily: 'pc_medium'
            }
        }
    },
    label: {
        fontFamily: 'pc_regular',
        fontSize: 10,
        color: '#6F6F6F'
    }
}))

export const FilterSelect = (props) => {
    const { parent_id } = props;
    const classes = useStyles(props);

    return (
        <div
            id={`${parent_id}-parent-div`}
            className={classes.root}>
            {props?.label && <Typography
                id={`${parent_id}-${props?.label.replaceAll(" ", "-")}-typography`}
                className={classes.label}>{props?.label}</Typography>}
            <Autocomplete
                id={`${parent_id}-autocomplete`}
                className={classes.autocomplete}
                value={props?.value}
                onChange={(event, newValue) => {
                    props?.onChange && props?.onChange(newValue);
                }}
                options={props?.options}
                getOptionLabel={(option) => option?.label}
                renderInput={(params) => (
                    <TextField
                        id={`${parent_id}-textField`}
                        {...params}
                        variant="outlined"
                    />
                )}
            />
        </div>
    )
}

FilterSelect.propTypes = {
    options: PropTypes.array,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

FilterSelect.defaultProps = {
    options: {},
    label: '',
    value: '',
    onChange: null
}