import React from "react";
import { Card, 
  //CircularProgress, Drawer
 } from "@material-ui/core";
import "../../components/font.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primary_care_admin_binder";
import { CommonTable } from "../../components/common"
import { SearchWithFilter } from "../../components"
//import { makeStyles } from "@material-ui/core/styles";
// import { routes } from "../../router/routes";
// import { useHistory } from "react-router";
import { withAllContexts } from "../../HOCs";

// const drawerWidth = "94%";
// const useStyles = makeStyles((theme) => ({
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//   },
//   drawerPaper: {
//     width: drawerWidth,
//   },
// }));

function CareTypeTable(props) {
  const dispatch = useDispatch();
  const { parent_id } = props;
  const listData = useSelector((state) => state?.organizationConfigSlice?.care_type_list?.data);
  //const loading = useSelector((state) => state?.organizationConfigSlice?.care_type_list?.loading);
  //const [state, setState] = React.useState({});

  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlepageChange = async (currentPage, PerPage) => {
    setPage((currentPage - 1) * PerPage);
    setPerPage(PerPage);
    await dispatch(actions.CARE_TYPE_READ({ page: (currentPage - 1) * PerPage, perPage: PerPage }));

  };

  React.useLayoutEffect(() => {
    
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Loading...",
    });
    dispatch(actions.CARE_TYPE_READ({page : page, perPage: perPage}));
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
  }, []);


  const onSearchChange = (value = "") => {
    if (value?.length > 1) {
      dispatch(actions.CARE_TYPE_READ({
        search: value.trim(),
        page: page,
        perPage: perPage
      }));
    } else if (!value) {
      dispatch(actions.CARE_TYPE_READ({
        page: page,
        perPage: perPage
      }));
    }
  };


  return (
    <Card
      id={`${parent_id}-parent-card`}
      style={{ borderRadius: "12px" }}>
      <div
        id={`${parent_id}-parent-div`}
        style={{ width: "100%" }}>
        <Card
          id={`${parent_id}-parent-sub-Card`}
          style={{ borderRadius: "12px" }}>
          <div
            id={`${parent_id}-parent-sub-div`}
            style={{
              borderRadius: "6px",
            }}
          >
            <div
              id={`${parent_id}-header-title-div`}
              style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                id={`${parent_id}-header-title-sub-div`}
                style={{ marginLeft: "18px" }}>
                <p
                  id={`${parent_id}-header-title-p`}
                  style={{
                    fontFamily: "poppinsemibold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  {props.header}
                </p>
              </div>
              <div style={{ marginTop: "16px", display:'flex', marginRight:"20px" }}>
              <SearchWithFilter
                  id="symptomyandspeciality"
                  placeholder="Search code and Description!"
                  //  loading={data?.length > 0 ? loading : false}
                  onSearchChange={onSearchChange}
                  hideSearchBar={false}
                  hideFilter={true}
                  //  onFilterClicked={onFilterClicked}
                  //  isFilterApplied={props?.isFilterApplied}
                />
              </div>
            </div>

            {/* table */}
            <div
              id={`${parent_id}-CommonTable-div`}
              style={{ padding: 20, height: "71vh", overflow: 'auto' }}>
              {/* {
              loading ? 
                <div style={{display: 'flex', justifyContent: "center", alignItems: "center", minHeight: "66vh"}}>
                  <CircularProgress />
                </div>
              : */}
              <CommonTable
                parent_id={'CommonTable'}
                Header={["S.No", "Code", "Description", "Care Level Rank"]}
                dataList={listData}
                tableData={[
                  { type: ["INCRIMENT"], name: "" },
                  { type: ["TEXT"], name: "careType" },
                  { type: ["TEXT"], name: "description" },
                  { type: ["TEXT"], name: "carelevelRank" }
                ]}
                handlepageChange={(currentPage, PerPage) =>
                  handlepageChange(currentPage, PerPage)
                }
                TableCount={listData?.[0]?.total_count}
                incrementCount={page}
                showPagination={true}
                rowsPerPageOptions={[
                  { label: "10 Rows", value: 10 },
                  { label: "50 Rows", value: 50 },
                  { label: "100 Rows", value: 100 },
                ]}
              />
              {/* } */}
            </div>
          </div>
        </Card>
      </div>
    </Card>
  );
}

export default withAllContexts(CareTypeTable);