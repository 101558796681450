import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  Grid,
  TextField,
} from "@material-ui/core";
import { SelectBox } from "../../../../../../components/common/smartForm/component";
import DeleteIcon from "../../../../../../assets/icons8-trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primary_care_admin_binder";
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px 10px 25px #0000000A",
    padding: 20,
    borderRadius: 8,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  title: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
  },
  form: {},
  formtitle: {
    fontSize: "12px",
    paddingBottom: 9,
    Fontfamily: "pc_regular",
    color: "#6F6F6F",
  },
  formParent: {
    paddingRight: 10,
    paddingTop: 15,
  },
  textFieldAlign: {
    marginTop: "3px",
  },
  iconBorder: {
    border: "1px solid #e0e0e0",
    cursor: "pointer",
    borderRadius: "8px",
    marginLeft: "10px",
    padding: "0px 12px",
    color: "#0071f2",
    fontSize: "20px",
    marginTop: "45px",
    display: "flex",
    alignItems: "center",
  },
}));

function ConversionMap(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const drugMasterUOM = useSelector((state) => state.drugMasterUOMSlice);

  React.useEffect(() => {
    if (props.umoType && props.umoCode) {
      const getData = async () => {
        await dispatch(
          actions.TO_UDM_CODE_DROPDOWN({
            id: props.umoType.value,
            type: props.umoCode
          })
        );
      };
      getData();
    }
  }, [props.umoType, props.umoCode]);

  const { coversionData, parent_id } = props;

  return (
    <React.Fragment>
      <Paper
        id={`${parent_id}-parent-paper`}
        className={classes.root}>
        <Typography
          id={`${parent_id}-CONVERSION-typography`}
          className={classes.title} variant="h6">
          CONVERSION MAP
        </Typography>
        {coversionData.map((itm, i) => (
          <Grid
            id={`${parent_id}-${i}-UOM-code-grid`}
            container className={classes.form}>
            <Grid
              id={`${parent_id}-${i}-UOM-code-sub-grid`}
              md="2" lg="2" className={classes.formParent}>
              <Typography
                id={`${parent_id}-${i}-UOM-code-typography`}
                className={classes.formtitle} variant="body1">
                To UOM code 
                {/* <span style={{ color: "red" }}>*</span> */}
              </Typography>
              <SelectBox
                parent_id={"umoCode"}
                list={
                  drugMasterUOM?.drug_uom_code_master?.data
                    ? drugMasterUOM?.drug_uom_code_master?.data
                    : ""
                }
                placeholder={"Select"}
                value={itm?.umoCode}
                onchange={(e, value) =>
                  props.handleInputState("umoCode", value, i)
                }
              />
            </Grid>
            <Grid
              id={`${parent_id}-${i}-Factor-grid`}
              md="2" lg="2" className={classes.formParent}>
              <Typography
                id={`${parent_id}-${i}-Factor-label-typography`}
                className={classes.formtitle} variant="body1">
                Conversion Factor 
                {/* <span style={{ color: "red" }}>*</span> */}
              </Typography>
              <TextField
                id={`${parent_id}-${i}-Factor-textField`}
                value={itm?.coversionFactor}
                onChange={(e) =>
                  props.handleInputState("coversionFactor", e.target.value, i)
                }
                disabled={itm?.umoCode?.value === "" ? true : false}
                fullWidth
                type="text"
                variant="outlined"
                size="small"
                className={classes.textFieldAlign}
                type="number"
              />
            </Grid>
            {(coversionData.length === 1 && i === 0) ||
              coversionData.length - 1 === i ? (
              <div
                id={`${parent_id}-${i}-addConversion-div`}
                className={classes.iconBorder}
                onClick={(e) => props.addConversion(e)}
              >
                +
              </div>
            ) : null}
            {coversionData.length > 1 ? (
              <div
                id={`${parent_id}-${i}-deleteConversion-div`}
                className={classes.iconBorder}
                onClick={(e) => props.deleteConversion(e, i)}
              >
                <img
                  id={`${parent_id}-${i}-deleteicon-img`}
                  src={DeleteIcon} width="11px" />
              </div>
            ) : null}
          </Grid>
        ))}
      </Paper>
    </React.Fragment>
  );
}

export default ConversionMap;
