import React from "react";
import { OverlayCompt, CommonTable } from "../../../../../components";
import VitalApplicableForm from "./form";
import { withAllContexts } from "../../../../../HOCs";
import { connect } from "react-redux";
import { actions } from "primary_care_admin_binder";
//import { AlertProps } from "../../../../../utils";

class VitalsCriteria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editData: null,
      perPage : 10,
      page: 0
    };
    this.handleForm = this.handleForm.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  handlepageChange = async (currentPage, PerPage) => {
    this.setState({
      page: (currentPage - 1) * PerPage,
      perPage: PerPage,
    })
    await this.props.READ_VITAL_APPLICABLE({ page: (currentPage - 1) * PerPage, perPage: PerPage });

  };

  async componentDidMount() {
    this.props.currentTab("vitalsApplicable");
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Loading...",
    });
    //await this.props.DIAGNOSIS();
    await this.props.READ_VITAL_APPLICABLE({page: this.state.page, perPage: this.state.perPage});
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
  }
  handleForm = () => {
    let { closeFrom } = this.props;
    closeFrom();
    this.setState({
      editData: null,
    });
  };
  handleEdit = (e, data, index) => {
    let { closeFrom } = this.props;
    this.setState({
      editData: data,
    });
    closeFrom();
  };
  handleClose = async () => {
    let { closeFrom } = this.props;
    await this.props.READ_VITAL_APPLICABLE({
      page: this.state.page,
      perPage: this.state.perPage
    });
    closeFrom();
    this.setState({
      editData: null,
    });
  };

  //checkbox for status update
  handleCheckBox = async (e, data, index) => {
    ;
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: true,
      message: "Updating...",
    });
    await this.props.UPDATE_VITAL_APPLICABLE_STATUS({
      editId: data._id,
      status: e.target.checked,
    });
    await this.props.READ_VITAL_APPLICABLE({page: this.state.page, perPage: this.state.perPage});
    this.props.backDrop.setBackDrop({
      ...this.props.backDrop,
      open: false,
      message: "",
    });
     this.props.alert.setSnack("update");
  };

  render() {
    const { open, 
      //parent_id 
    } = this.props;
    const { editData } = this.state;
    return (
      <React.Fragment>
        <CommonTable
          parent_id={"vital_master_criteria"}
          handleEdit={this.handleEdit}
          handleCheckBox={this.handleCheckBox}
          Header={[
            "S.No",
            "Specialty Code",
            "Diagnosis Group",
            "Gender",
            "Age Range Group",
            "Age Category",
            "Status",
            "Action",
          ]}
          dataList={this.props.vitalsApplicableRead.data}
          tableData={[
            { type: ["INCRIMENT"], name: "" },
            { type: ["TEXT"], name: "specialCode" },
            { type: ["TEXT"], name: "diagnosisGroup" },
            { type: ["TEXT"], name: "gender" },
            { type: ["TEXT"], name: "ageRange" },
            { type: ["TEXT"], name: "ageCategory" },
            { type: ["CHECKBOX"], name: "status" },
            { type: ["EDIT"], name: "" },
          ]}
          handlepageChange={(currentPage, PerPage) =>
            this.handlepageChange(currentPage, PerPage)
          }
          TableCount={this.props.vitalsApplicableRead.data?.[0]?.total_count}
          incrementCount={this.state.page}
          showPagination={true}
          rowsPerPageOptions={[
            { label: "10 Rows", value: 10 },
            { label: "50 Rows", value: 50 },
            { label: "100 Rows", value: 100 },
          ]}
        />
        <OverlayCompt
          parent_id={"criteria"}
          open={open}
          children={
            <React.Fragment>
              {open && (
                <VitalApplicableForm
                  parent_id={"criteria"}
                  editData={editData}
                  closeForm={this.handleClose}
                  vitalState={this.state}
                />
              )}
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  vitalsApplicableRead: state.vitalsApplicableSlice.read_vital_applicable,
});

export default connect(
  mapStateToProps,
  actions
)(withAllContexts(VitalsCriteria));
