import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";

import EditIcon from "../../../assets/icons - Edit.svg";
import { TableSwitch } from "../../../components";

const SingleList = ({
	info,
	handleOpenForm,
	selected,
	handleClick,
	handleStatus,
	parent_id
}) => {
	return (
		<ListItem
			id={`${parent_id}-parent-ListItem`}
			role={undefined}
			dense
			button
			style={{
				background: selected ? "#E5F1FE" : "unset",
			}}
			onClick={handleClick}
		>

			<ListItemText
				id={`${parent_id}-parent-ListItemtext`}
				style={{
					color: selected ? "#0071F2" : "unset",
				}}
				primary={
					<div
						id={`${parent_id}-code-div`}
						style={{ textOverflow: "ellipsis", overflow: "hidden", width: "120px", whiteSpace: "nowrap" }}>
						<span
							id={`${parent_id}-geoLocationISDCode-span`}
							style={{ fontWeight: selected ? 700 : 500 }}>
							{info.code} - {info.name} {info.geoLocationISDCode ? "(" + info.geoLocationISDCode + ")" : ""}
						</span>
					</div>
				}
			/>
			<ListItemSecondaryAction
				id={`${parent_id}-IconButton-ListItemSecondaryaction`}
			>
				<IconButton
					id={`${parent_id}-EditIcon-iconbutton`}
					style={{ marginRight: "8px" }}
					width="12px"
					edge="end"
					onClick={() => handleOpenForm(info)}
				>
					<img
						id={`table-EditIcon-img`}
						src={EditIcon}
						alt="Edit Icon"
						width="12px"
					/>
				</IconButton>
				<TableSwitch
					id={`table-value-TableSwitch`}
					checked={info.status}
					onChange={handleStatus}
					name="checkbox"
				/>
			</ListItemSecondaryAction>
		</ListItem>
	);
};

export default SingleList;
