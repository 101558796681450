import React from "react";
import DrugMaster from "./drugMaster";
import { withHoverDrawer } from "../../HOCs";

class DrugMasterParent extends React.Component {
  render() {
    return <DrugMaster />;
  }
}

export default withHoverDrawer(DrugMasterParent);
