import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { Checkbox, FormControlLabel, FormGroup, Card, MenuItem, Select, TextField, FormControl } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { routes } from "../../router/routes";
import backarrow from "../../assets/icons8-expand-arrow.svg";
import RuleBuilder from "./ruleBuilder";
import SimplePopover from "./try";
import Scheduleperiodicon from "../../assets/Icon - SCHEDULE Period.svg";
import Appointmentperiodicon from "../../assets/Icon -Appointment Period.svg";
import AppointmenTDURATIONicon from "../../assets/Icon - APPOINTMENT DURATION.svg";
import people from "../../assets/icons8-people.svg";
// import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
// import Avatar from "@material-ui/core/Avatar";
import InnerCard from "./innercard";
import "../font.css";
import Dropdown from "./dropdown";
// import ResourceCriteria from "./resourceCriteria";
import expandarrow from "../../assets/icons8-expand-arrow.svg";
import { withHoverDrawer } from "../../HOCs";
// import { H1Props } from "qdm-component-library";

const useStyles = makeStyles((theme) => ({
  auto: {
    width: 350,
    "& > * + *": {
      //marginTop: theme.spacing(3),
    },
    "& .MuiChip-root": {
      height: "17px",
      paddingTop: "10px !important",
      paddingBottom: "10px !important",
      borderRadius: "5px",
    },
    "& .MuiChip-deleteIcon": {
      height: "15px",
    },
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
  },
  marginleft10: {
    marginLeft: "10px",
    fontSize: "12px",
    fontFamily: "poppin",
  },
  monthcheckbox: {
    "& .MuiTypography-body1": {
      fontSize: "12px !important",
      fontFamily: "poppin !important",
    },
  },
  boxsize: {
    "& .MuiOutlinedInput-inputMarginDense": {
      height: "10px !important",
      fontSize: "12px",
      fontFamily: "poppin",
    },
    "& .MuiOutlinedInput-root": {
      height: "40px !important",
    },
  },
  root: {
    "& .MuiOutlinedInput-inputMarginDense": {
      height: "10px !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px !important",
      height: "40px",
    },
  },
  formControl: {
    "&.MuiSelect-outlined": {
      "& .MuiSelect-outlined": {
        fontSize: "12px",
        fontFamily: "poppin",
      },
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

const top100Films = [{ title: "Consultation" }, { title: "Surgery" }];
function CreateTimeTable(props) {
  
  const history = useHistory();
  const classes = useStyles();
  const [mode, setmode] = React.useState("");
  const [mode1, setmode1] = React.useState("");
  const [availability, setavailability] = React.useState("");
  //const [availability1, setavailability1] = React.useState("");
  const [availability2, setavailability2] = React.useState("");

  const month = [
    { Mon: "Jan" },
    { Mon: "Feb" },
    { Mon: "Mar" },
    { Mon: "Apr" },
    { Mon: "May" },
    { Mon: "Jun" },
    { Mon: "Jul" },
    { Mon: "Aug" },
    { Mon: "Sep" },
    { Mon: "Oct" },
    { Mon: "Nov" },
    { Mon: "Dec" },
  ];
  const Week = [
    { week: "Week 1" },
    { week: "Week 2" },
    { week: "Week 3" },
    { week: "Week 4" },
    { week: "Week 5" },
  ];
  const Days = [
    { days: "Mon" },
    { days: "Tue" },
    { days: "Wed" },
    { days: "Thu" },
    { days: "Fri" },
    { days: "Sat" },
    { days: "Sun" },
  ];

  const handleChange = (event) => {
    setmode(event.target.value);
  };
  const handleChangeavailability = (event) => {
    setavailability(event.target.value);
  };

  //   <!---------checkbox------------->
  const [state, setState] = React.useState({
    // checkedA: true,
    // checkedB: true,
    // checkedF: true,
    // checkedG: true,
    checkedI: true,
  });

  const handleChangecheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  //   <!----------popper------------------------------>

  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const backfun = () => {
    history.push(routes.availabilitymanagement);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // <!--------------------Radio---------------------------->
  //const [value, setValue] = React.useState("female");
  // const handleChangeradio = (event) => {
  //   setValue(event.target.value);
  // };
  return (
    <div>
      <div style={{ backgroundColor: "#F6F6F6" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            <button
              onClick={backfun}
              style={{
                border: "none",
                backgroundColor: "#DCDCDC",
                borderRadius: "20px",
                padding: "5px",
                height: "25px",
                marginTop: "11px",
              }}
            >
              <img
                src={backarrow}
                alt="icon"
                style={{ transform: "rotate(90deg)" }}
              />
            </button>
            <p>Create New Time Table</p>
          </div>
          <div style={{ marginTop: "8px", marginRight: "30px" }}>
            <Dropdown />
          </div>
        </div>
        <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
      </div>
      <Card
        style={{
          padding: "24px",
          width: "95%",
          marginLeft: "20px",
          marginTop: "10px",
        }}
      >
        <div>
          <div className={classes.root} noValidate autoComplete="off">
            <div style={{ display: "flex" }}>
              <div style={{ width: "377px" }}>
                <label className={classes.grayfont}>Timetable Title</label>
                <br />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  inputProps={{
                    style: { fontSize: 12, fontFamily: "poppin" },
                  }}
                  size="small"
                  style={{ marginTop: "10px", width: "100%", height: "40px" }}
                  InputLabelProps={{
                    style: { fontSize: 12, marginTop: "4px" },
                  }}
                />
              </div>
              <div style={{ marginLeft: "20px", width: "786px" }}>
                <div className={classes.auto}>
                  <label className={classes.grayfont}>
                    Applicable App Types
                  </label>
                  <br />
                  <Autocomplete
                    style={{ width: "208%" }}
                    multiple
                    limitTags={2}
                    id="tags-outlined"
                    options={top100Films}
                    getOptionLabel={(option) => option.title}
                    // defaultValue={[top100Films[1]]}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        style={{ marginTop: "10px" }}
                        {...params}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ style: { fontSize: 12 } }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            {/* <!----------Resource Information-------------------------> */}
            <div style={{ marginTop: "30px" }}>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", width: "228px" }}>
                  <img
                    src={people}
                    alt="icon"
                    style={{ marginRight: "12px" }}
                  />
                  <p
                    style={{
                      width: "175px",
                      fontSize: "14px",
                      fontFamily: "poppinmedium",
                    }}
                  >
                    RESOURCE INFORMATION
                  </p>
                </div>
                <hr
                  style={{
                    height: "0px",
                    width: "86%",
                    marginTop: "23px",
                    borderTop: "2px dashed gray",
                  }}
                />
              </div>
              <div
                style={{ display: "flex", marginTop: "0px" }}
                className={classes.boxsize}
              >
                <div style={{ width: "377px", marginTop: "20px" }}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ width: "377px", margin: "0px" }}
                    size="small"
                    inputProps={{
                      style: { fontSize: 12, fontFamily: "poppin" },
                    }}
                  >
                    <label id="type" className={classes.grayfont}>
                      Resource Type
                    </label>
                    <Select
                      style={{ marginTop: "3px" }}
                      labelId="demo-simple-select-outlined-label"
                      id="type1"
                      value={mode}
                      onChange={handleChange}
                      inputProps={{
                        style: { fontSize: 12, fontFamily: "poppin" },
                      }}
                    >
                      <MenuItem
                        value={"Public"}
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        Practitioner 1
                      </MenuItem>
                      <MenuItem
                        value={"Local"}
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        Practitioner 2
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    marginLeft: "20px",
                    width: "377px",
                    marginTop: "20px",
                  }}
                >
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ width: "377px", margin: "0px" }}
                    size="small"
                  >
                    <label id="type" className={classes.grayfont}>
                      Resource Role
                    </label>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      style={{ marginTop: "3px" }}
                      id="type2"
                      value={availability}
                      onChange={handleChangeavailability}
                    >
                      <MenuItem
                        value={"1 Month"}
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        Consultant 1
                      </MenuItem>
                      <MenuItem
                        value={"2 Months"}
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        Consultant 2
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* ----------------Rule Builder-------------------------------------------- */}
              <div style={{ marginTop: "24px" }}>
                <Card style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                  <div>
                    <p style={{ fontFamily: "poppinmedium", fontSize: "14px" }}>
                      Resource Inclusion Criteria
                    </p>
                  </div>
                  <RuleBuilder />
                </Card>
              </div>
            </div>

            {/* <!---------------Scheduled Period----------------------------> */}

            <div style={{ marginTop: "24px" }}>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", width: "169px" }}>
                  <img
                    src={Scheduleperiodicon}
                    alt="icon"
                    style={{ marginRight: "12px" }}
                  />
                  <p
                    style={{
                      fontFamily: "poppinmedium",
                      fontSize: "14px",
                    }}
                  >
                    SCHEDULED PERIOD
                  </p>
                </div>
                <hr
                  style={{
                    height: "0px",
                    width: "86%",
                    marginTop: "23px",
                    borderTop: "2px dashed gray",
                  }}
                />
              </div>
              <div
                style={{ display: "flex", marginTop: "16px" }}
                className={classes.boxsize}
              >
                <div style={{ width: "260px" }}>
                  <label for="date" className={classes.grayfont}>
                    {" "}
                    Start Date{" "}
                  </label>
                  <TextField
                    id="date"
                    type="date"
                    variant="outlined"
                    style={{ width: "260px" }}
                    size="small"
                  />
                </div>

                <div style={{ width: "260px", marginLeft: "16px" }}>
                  <label for="date" className={classes.grayfont}>
                    {" "}
                    End Date{" "}
                  </label>
                  <TextField
                    id="enddate"
                    type="date"
                    variant="outlined"
                    style={{ width: "260px" }}
                    size="small"
                  />
                </div>
                <div style={{ width: "260px", marginLeft: "16px" }}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ margin: "0px" }}
                    size="small"
                    inputProps={{
                      style: { fontSize: 12, fontFamily: "poppin" },
                    }}
                  >
                    <label id="type" className={classes.grayfont}>
                      Schedule Generation Mode
                    </label>
                    <Select
                      style={{ marginTop: "3px", width: "260px" }}
                      labelId="demo-simple-select-outlined-label"
                      id="type3"
                      value={mode1}
                      onChange={handleChange}
                      inputProps={{
                        style: { fontSize: 12, fontFamily: "poppin" },
                      }}
                    >
                      <MenuItem
                        value=""
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        <em>None</em>
                      </MenuItem>
                      <MenuItem
                        value={"Public"}
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        Automatic
                      </MenuItem>
                      <MenuItem
                        value={"Local"}
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        Manual
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div style={{ marginLeft: "15px", width: "260px" }}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ width: "200px", margin: "0px" }}
                    size="small"
                  >
                    <label id="type" className={classes.grayfont}>
                      Schedule Availability Management
                    </label>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      style={{ marginTop: "3px", width: "260px" }}
                      id="type4"
                      value={availability2}
                      onChange={handleChangeavailability}
                    >
                      <MenuItem
                        value=""
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        <em>None</em>
                      </MenuItem>
                      <MenuItem
                        value={"1 Month"}
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        1 Month
                      </MenuItem>
                      <MenuItem
                        value={"2 Months"}
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        2 Months
                      </MenuItem>
                      <MenuItem
                        value={"3 Months"}
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        3 Months
                      </MenuItem>
                      <MenuItem
                        value={"4 Months"}
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        4 Months
                      </MenuItem>
                      <MenuItem
                        value={"5 Months"}
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        5 Months
                      </MenuItem>
                      <MenuItem
                        value={"6 Months"}
                        style={{ fontSize: "12px", fontFamily: "poppin" }}
                      >
                        6 Months
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            {/* <!-----------------------Appointment Period-----------------------------> */}

            <div style={{ marginTop: "30px" }}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "192px", display: "flex" }}>
                  <img
                    src={Appointmentperiodicon}
                    alt="icon"
                    style={{ marginRight: "12px" }}
                  />
                  <p>APPOINTMENT PERIOD</p>
                </div>
                <hr
                  style={{
                    height: "0px",
                    width: "86%",
                    marginTop: "23px",
                    borderTop: "2px dashed gray",
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#F7F7F7",
                  padding: "24px",
                  borderRadius: "10px",
                }}
              >
                <div
                  className={classes.month}
                  style={{ display: "flex", marginTop: "20px" }}
                >
                  <div style={{ display: "flex" }}>
                    <p className={classes.grayfont}>Month</p>
                    <div
                      style={{
                        height: "25px",
                        width: "3px",
                        backgroundColor: "#0C78F2",
                        marginLeft: "15px",
                        marginTop: "8px",
                      }}
                    ></div>
                  </div>

                  <div className={classes.monthcheckbox}>
                    <FormGroup row>
                      {month.map((val) => {
                        return (
                          <FormControlLabel
                            className={classes.marginleft10}
                            control={
                              <Checkbox
                                onChange={handleChangecheckbox}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="checkedI"
                                color="primary"
                              />
                            }
                            label={val.Mon}
                          />
                        );
                      })}
                    </FormGroup>
                  </div>
                </div>

                {/* <!------------------------Week---------------------------> */}

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ display: "flex" }}>
                    <p className={classes.grayfont}>Week</p>
                    <div
                      style={{
                        height: "25px",
                        width: "3px",
                        backgroundColor: "#F5900C",
                        marginLeft: "20px",
                        marginTop: "8px",
                      }}
                    ></div>
                  </div>
                  <div className={classes.monthcheckbox}>
                    <FormGroup row>
                      {Week.map((val) => {
                        return (
                          <FormControlLabel
                            className={classes.marginleft10}
                            control={
                              <Checkbox
                                onChange={handleChangecheckbox}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="checkedI"
                                color="primary"
                              />
                            }
                            label={val.week}
                          />
                        );
                      })}
                    </FormGroup>
                  </div>
                </div>

                {/* <!---------------------------Day--------------------> */}

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ display: "flex" }}>
                    <p className={classes.grayfont}>Day</p>
                    <div
                      style={{
                        height: "25px",
                        width: "3px",
                        backgroundColor: "#F5CE0C",
                        marginLeft: "30px",
                        marginTop: "8px",
                      }}
                    ></div>
                  </div>

                  <div className={classes.monthcheckbox}>
                    <FormGroup row>
                      {Days.map((val) => {
                        return (
                          <FormControlLabel
                            className={classes.marginleft10}
                            control={
                              <Checkbox
                                onChange={handleChangecheckbox}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="checkedI"
                                color="primary"
                              />
                            }
                            label={val.days}
                          />
                        );
                      })}
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card
        style={{
          padding: "24px",
          width: "95%",
          marginLeft: "20px",
          marginTop: "20px",
          borderRadius: "10px",
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "260px", display: "flex" }}>
            <img
              src={AppointmenTDURATIONicon}
              alt="icon"
              style={{ marginRight: "12px" }}
            />

            <p>APPOINTMENT DURATION</p>
          </div>

          <hr
            style={{
              height: "0px",
              width: "86%",
              marginTop: "23px",
              borderTop: "2px dashed gray",
            }}
          />

          <div style={{ marginTop: "8px" }}>
            <SimplePopover />
          </div>
          <div style={{ marginTop: "12px" }}>
            <img
              src={expandarrow}
              alt="icon"
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
        </div>
        <InnerCard />
      </Card>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          float: "right",
          marginRight: "40px",
          paddingBottom: "30px",
        }}
      >
        <button
          style={{
            backgroundColor: "#FFFFFF",
            border: "none",
            paddingTop: "5px",
            paddingBottom: "5px",
            borderRadius: "5px",
            fontFamily: "poppin",
            fontSize: "12px",
            width: "90px",
          }}
        >
          Cancel
        </button>
        <button
          style={{
            marginLeft: "10px",
            backgroundColor: "#0071F2",
            color: "white",
            border: "none",
            paddingTop: "5px",
            paddingBottom: "5px",
            borderRadius: "5px",
            fontFamily: "poppin",
            fontSize: "12px",
            width: "90px",
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}
export default withHoverDrawer(CreateTimeTable);
