import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";
// import FormGroup from "@material-ui/core/FormGroup";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import styles from "./styles";
import { Typography, Grid, Paper, IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  font: {
    fontSize: "14px",
    marginLeft: "10px",
  },
  grayfont: {
    fontSize: "12px",
    color: "gray",
    fontFamily: "poppin",
  },
  root: {
    "& .MuiOutlinedInput-root ": {
      borderRadius: "8px",
      height: "40px",
    },
  },
}));
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
    "& .MuiTypography-body1 ": {
      fontSize: "12px !mportant",
      fontFamily: "poppinsemibold",
      color: "gray",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const AntSwitch = withStyles((theme) => ({
  root: {
    width: "28px",
    height: "17px",
    padding: "2px",
    display: "flex",
    marginTop: "4px",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    marginTop: "1px",
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
export default function AppointmentDrawer(props) {
  const classes = styles();

  // const classes = useStyles();

  // const theme = useTheme();

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [state, setState] = React.useState({
    checkedG: true,
  });
  // const [open, setOpen] = React.useState(false);

  const handleChangebox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [ToggleOn, setToggleon] = React.useState();
  const handleToggle = (event) => {
    setToggleon(!ToggleOn);
  };
  return (
    // <List>
    //   <div className={classes.drawerHeader}></div>
    //   <div>
    //     <p className={classes.font}>Add New Holiday</p>
    //   </div>
    //   <hr style={{ borderTop: "1px solid lightgray", marginLeft: "5px" }} />
    //   <form
    //     className={classes.root}
    //     noValidate
    //     autoComplete="off"
    //     style={{ marginLeft: "20px" }}
    //   >
    //     <div style={{ marginTop: "20px" }}>
    //       <label for="reason" className={classes.grayfont}>
    //         TYPE CODE
    //       </label>
    //       <br />
    //       <TextField
    //         id="reason"
    //         variant="outlined"
    //         inputProps={{
    //           style: { fontSize: 12, fontFamily: "poppin" },
    //         }}
    //         style={{ width: "302px" }}
    //         size="small"
    //       />
    //     </div>
    //     <br />
    //     <div>
    //       <label for="reason" className={classes.grayfont}>
    //         TYPE DESCRIPTION
    //       </label>
    //       <br />
    //       <TextField
    //         id="reason"
    //         variant="outlined"
    //         inputProps={{
    //           style: { fontSize: 12, fontFamily: "poppin" },
    //         }}
    //         style={{ width: "302px" }}
    //         size="small"
    //       />
    //     </div>
    //     <br />
    //     <div
    //       style={{
    //         display: "flex",
    //         padding: "13px",
    //         paddingLeft: "0px",
    //       }}
    //     >
    //       <AntSwitch
    //         checked={state.checkedC}
    //         onChange={handleToggle}
    //         name="checkedC"
    //       />
    //       <div>
    //         {ToggleOn ? (
    //           <p
    //             style={{
    //               color: "#38C20A",
    //               marginTop: "3px",
    //               marginLeft: "8px",
    //             }}
    //           >
    //             Active
    //           </p>
    //         ) : (
    //           <p style={{ marginTop: "3px", marginLeft: "8px" }}>Inactive</p>
    //         )}
    //       </div>
    //     </div>
    //     <br />
    //     <Button
    //       variant="contained"
    //       style={{
    //         marginLeft: "70px",
    //         marginTop: "20px",
    //         backgroundColor: "#0071F2",
    //         color: "white",
    //         width: "154px",
    //         height: "40px",
    //       }}
    //     >
    //       Save
    //     </Button>
    //   </form>
    // </List>
    <div>
      <div className={classes.drawerHead}>
        <Typography className={classes.headerMain}>
          {props.editName ? "Edit Page" : "Add new page"}
        </Typography>
      </div>

      <Grid container direction="row">
        <Grid item xs={12}>
          <div className={classes.textTotalArea}>
            <Typography className={classes.drawerSubHead}>Type Code</Typography>
            <TextField
              label={"Page"}
              variant="outlined"
              fullWidth
              size="small"
              name="pageName"
              required
              inputProps={{ style: { fontFamily: "poppin", fontSize: "12px" } }}
              InputLabelProps={{
                style: { fontFamily: "poppin", fontSize: "12px" },
              }}
            />
            {/* <div style={{ paddingTop: "20px" }}>
              <Typography className={classes.drawerSubHead}>
                Holiday Type
              </Typography>
              <Select
                variant="outlined"
                labelId="demo-simple-select-outlined-label"
                id="type"
                value={age}
                style={{
                  fontfamily: "poppin",
                  fontSize: "12px",
                  width: "299px",
                  height: "36px",
                }}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"Public"}>Public</MenuItem>
                <MenuItem value={"Local"}>Local</MenuItem>
              </Select>
            </div> */}
            <div style={{ paddingTop: "20px" }}>
              <Typography className={classes.drawerSubHead}>
                Type Description
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                type="search"
                name="route"
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              padding: "13px",
              marginLeft: "30px",
              paddingLeft: "0px",
            }}
          >
            <AntSwitch
              checked={state.checkedC}
              onChange={handleToggle}
              name="checkedC"
            />
            <div>
              {ToggleOn ? (
                <p
                  style={{
                    color: "#38C20A",
                    marginTop: "3px",
                    marginLeft: "8px",
                  }}
                >
                  Active
                </p>
              ) : (
                <p style={{ marginTop: "3px", marginLeft: "8px" }}>Inactive</p>
              )}
            </div>
          </div>
          <div className={classes.btnPosition}>
            <Button contained className={classes.addDrawerBtn}>
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
