import React from "react";
import { Button, Grid } from "@material-ui/core";
import { CommonTabList } from "../../../../../../components";
import PerformOrderingDetails from "./performOrderingDetails";
import PerformService from "./performservice";
import PerformingDetails from "./performingDetails";
import { actions } from "primary_care_admin_binder";
import { connect } from "react-redux";
import { withAllContexts } from "../../../../../../HOCs";
import { valitationFunc } from "../../../../../../utils";
//import { AlertProps } from "../../../../../../utils"


let errorList = [
  "orderingFacility",
  "locationType",
  "ServiceApplicabiliy",
  "sourceLocation",
];
class PerformingLocationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderingFacility: {},
      performingData: [],
      sourceLocation: {},
      ServiceApplicabiliy: {},
      ruleSet : [],
      locationType: {},
      error: {}
    };
    this.handleFormState = this.handleFormState.bind(this);
  }

  componentDidMount() {
    const getdata = async () => {
      await this.props.SOURCELOCATIONTYPE();
      await this.props.ORDERINGFACILITY();
      await this.props.PERFORMINGDETAILS();
      // await this.props.SOURCELOCATION();
      await this.props.GET_ALL_MASTER();
    };
    getdata();
    const { editData } = this.props;
    console.log("editData", editData);
    const state = this.state;
    if (editData?.facility_name) {
      state["orderingFacility"] = {
        label: editData.ordering_org.name,
        value: editData.ordering_org._id,
      }
      state["sourceLocation"] = {
        label: editData.ordering_source_code.longdesc,
        value: editData.ordering_source_code._id
      }
      state["ruleSet"] = editData.order_criteria
      state["ServiceApplicabiliy"] = {
        label: editData.service_applicability.display,
        value: editData.service_applicability.display,
        _id: editData.service_applicability._id
      }
      state["locationType"] = {
        label: editData.ordering_source_type.display,
        value: editData.ordering_source_type._id,
      }
      state.performingData = editData.performing_details.map((val) => {
        return {
          performing_facility: {
            label: val.performing_org.name,
            value: val.performing_org._id
          },
          location_type: {
            label: val.performing_location_role_type.display,
            value: val.performing_location_role_type._id
          },
          performing_location: {
            label: val.performing_location_code.longdesc,
            value: val.performing_location_code._id
          }
        }
      })
    }
  }

  handleFormState = async (name, value) => {
    let errorObj = JSON.parse(JSON.stringify(this.state.error));
    errorObj[name] = false;
    if (name === "locationType" && value) {
      await this.props.SOURCELOCATION({ list: value.label });
    }
    this.setState({ [name]: value, error: errorObj });
  };

  saveForm = async () => {
    // let finalSaveData = this.state;
    // console.log("mysave", finalSaveData);
    let { error, errorState } = await valitationFunc(this.state, errorList);
    if (error) {
      this.props.alert.setSnack("mandatory");
      this.setState({
        ...this.state,
        error: errorState,
      });
      return;
    }
    await this.handleSave()
    let type = this.props.editData?.facility_name ? "update" : "success"
    this.props.alert.setSnack(type);
    await this.props.closeForm();
  };

  handleSave = async () => {

    let finalSaveData = this.state;
    const { editData } = this.props;
    finalSaveData.status = editData?.status === undefined ? true : editData?.status;
    await this.props.UPSERT_PERFORMINGLOCATION({
      list: finalSaveData,
      _key: editData?._key ?? ''
    })
  }

  render() {
    const { closeForm = () => null, parent_id } = this.props;
    console.log("state manage", this.state);
    console.log("locationtype", this.props?.performLocation?.source_location_type?.data);
    return (
      <React.Fragment>
        <CommonTabList
          parent_id={"performinglocation"}
          backbtn
          backFun={closeForm}
          title={this.props?.editData ? "Edit Performing Location" : "New Performing Location"}
          list={[]}
        />
        <Grid
          id={`${parent_id}-OrderingDetailsparent-div`}
          style={{ padding: "20px" }}>
          <PerformOrderingDetails
            parent_id={"PerformOrderingDetails"}
            facilityOption={this.props?.performLocation?.order_facility?.data}
            locationTypeOption={this.props?.performLocation?.source_location_type?.data}
            sourceLocationOption={this.props?.performLocation?.source_location?.data}
            handleFormState={this.handleFormState}
            {...this.state}
          />
        </Grid>

        <Grid
          id={`${parent_id}-PerformService-div`}
          style={{ padding: "0px 20px 20px 20px" }}>
          <PerformService
            parent_id={"PerformOrderingDetails"}
            serviceApplicable={this.props?.serviceApplicable?.service_applicables}
            handleFormState={this.handleFormState}
            {...this.state}
          />
        </Grid>

        <Grid
          id={`${parent_id}-PerformingDetails-grid`}
          style={{ padding: "0px 20px 20px 20px" }}>
          <PerformingDetails
            parent_id={"PerformingDetails"}
            handleFormState={this.handleFormState}
            {...this.state}
          />
        </Grid>

        <Grid
          id={`${parent_id}-Button-grid`}
          style={{ padding: "20px" }}>
          <Button
            id={`${parent_id}-Save-button`}
            onClick={() => this.saveForm()}
            style={{ float: "right" }}
            variant="contained"
            color="primary"
          >
            {this.props?.editData ? "Update" : "Save"}
          </Button>
          <Button
            id={`${parent_id}-Cancel-button`}
            onClick={() => closeForm()}
            style={{ float: "right", marginRight: "20px" }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  performLocation: state?.performLocationSlice,
  serviceApplicable: state?.alertRestictionsMasterSlice.get_all_masters?.data
});

export default connect(mapStateToProps, actions)(withAllContexts(PerformingLocationForm));
