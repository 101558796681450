import React from "react";
import { VerticalList, CommonTable, GenerateForm } from "../../components"

class PatientDemograpics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openForm: false
        }
        this.handleNewForm = this.handleNewForm.bind(this);
    }
    handleNewForm = () => {
        let { openForm } = this.state;
        this.setState({
            openForm: !openForm
        })
    }
    render() {
        const { parent_id } = this.props;
        return <React.Fragment>
            <VerticalList
                parent_id={'patient-VerticalList'}
                isLeftList={true}
                addFunc={this.handleNewForm}
                TabList={[
                    {
                        name: "Name Prefix", component: <CommonTable
                            style={{ height: "calc(100vh - 243px)" }}
                            parent_id={'patient-CommonTable'}
                            Header={["S.No", "Code", "Short Description", "Long Description", "Status", "Action"]}
                            dataList={[{ code: "MR", short: "Mr.", long: "Mister" },
                            { code: "MRS", short: "Mrs.", long: "Mrs" },
                            { code: "MISS", short: "Miss.", long: "Miss" },
                            { code: "DR", short: "Dr.", long: "Doctor" },
                            { code: "PAD", short: "Padmasri", long: "Padmasri" },]}
                            tableData={[
                                { type: ["INCRIMENT"], name: "" },
                                { type: ["TEXT"], name: "code" },
                                { type: ["TEXT"], name: "short" },
                                { type: ["TEXT"], name: "long" },
                                { type: ["CHECKBOX"], name: "" },
                                { type: ["EDIT"], name: "" },
                            ]}
                        />
                    },
                    // {
                    //     name: "Family Name", component: <CommonTable
                    //         Header={["S.No", "Code", "Short Description", "Long Description", "Status", "Action"]}
                    //         dataList={[{ code: "#123", short: "des", long: "description" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "short" },
                    //             { type: ["TEXT"], name: "long" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // },
                    {
                        name: "Name Suffix", component: <CommonTable
                            parent_id={'patient-CommonTable'}
                            Header={["S.No", "Code", "Short Description", "Long Description", "Status", "Action"]}
                            dataList={[{ code: "JR", short: "Jr.", long: "Junior" },
                            { code: "SR", short: "Sr.", long: "Senior" },
                            { code: "ONE", short: "I", long: "One" },
                            { code: "TWO", short: "II", long: "Two" },
                            { code: "THREE", short: "III", long: "Three" },]}
                            tableData={[
                                { type: ["INCRIMENT"], name: "" },
                                { type: ["TEXT"], name: "code" },
                                { type: ["TEXT"], name: "short" },
                                { type: ["TEXT"], name: "long" },
                                { type: ["CHECKBOX"], name: "" },
                                { type: ["EDIT"], name: "" },
                            ]}
                        />
                    },
                    {
                        name: "Patient Type", component: <CommonTable
                            parent_id={'patient-CommonTable'}
                            Header={["S.No", "Code", "Short Description", "Long Description", "Status", "Action"]}
                            dataList={[{ code: "VIP", short: "VIP", long: "VIP" },
                            { code: "VIP", short: "General", long: "General" },
                            { code: "STAFF", short: "Staff", long: "Staff" },
                            { code: "STREL", short: "Staff Relative", long: "Staff Relative" },]}
                            tableData={[
                                { type: ["INCRIMENT"], name: "" },
                                { type: ["TEXT"], name: "code" },
                                { type: ["TEXT"], name: "short" },
                                { type: ["TEXT"], name: "long" },
                                { type: ["CHECKBOX"], name: "" },
                                { type: ["EDIT"], name: "" },
                            ]}
                        />
                    },
                    // {
                    //     name: "Race", component: <CommonTable
                    //         Header={["S.No", "Level Code", "Level Name", "Parent Level Name", "Parent Level Code", "Status", "Action"]}
                    //         dataList={[{ code: "BNGCTY", levelName: "Bangalore", parentName: "District", parentCode: "BLR" },
                    //                    { code: "BNGCTA", levelName: "Hydrabad", parentName: "District", parentCode: "GEN" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "levelName" },
                    //             { type: ["TEXT"], name: "parentName" },
                    //             { type: ["TEXT"], name: "parentCode" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // },
                    // {
                    //     name: "Ethnicity", component: <CommonTable
                    //         Header={["S.No", "Level Code", "Level Name", "Parent Level Name", "Parent Level Code", "Status", "Action"]}
                    //         dataList={[{ code: "BNGCTY", levelName: "Bangalore", parentName: "District", parentCode: "BLR" },
                    //                    { code: "BNGCTA", levelName: "Hydrabad", parentName: "District", parentCode: "GEN" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "levelName" },
                    //             { type: ["TEXT"], name: "parentName" },
                    //             { type: ["TEXT"], name: "parentCode" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // },
                    // {
                    //     name: "Religion", component: <CommonTable
                    //         Header={["S.No", "Level Code", "Level Name", "Parent Level Name", "Parent Level Code", "Status", "Action"]}
                    //         dataList={[{ code: "BNGCTY", levelName: "Bangalore", parentName: "District", parentCode: "BLR" },
                    //                    { code: "BNGCTA", levelName: "Hydrabad", parentName: "District", parentCode: "GEN" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "levelName" },
                    //             { type: ["TEXT"], name: "parentName" },
                    //             { type: ["TEXT"], name: "parentCode" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // },
                    // {
                    //     name: "Marital Status", component: <CommonTable
                    //         Header={["S.No", "Level Code", "Level Name", "Parent Level Name", "Parent Level Code", "Status", "Action"]}
                    //         dataList={[{ code: "BNGCTY", levelName: "Bangalore", parentName: "District", parentCode: "BLR" },
                    //                    { code: "BNGCTA", levelName: "Hydrabad", parentName: "District", parentCode: "GEN" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "levelName" },
                    //             { type: ["TEXT"], name: "parentName" },
                    //             { type: ["TEXT"], name: "parentCode" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // },
                    // {
                    //     name: "Relationship", component: <CommonTable
                    //         Header={["S.No", "Level Code", "Level Name", "Parent Level Name", "Parent Level Code", "Status", "Action"]}
                    //         dataList={[{ code: "BNGCTY", levelName: "Bangalore", parentName: "District", parentCode: "BLR" },
                    //                    { code: "BNGCTA", levelName: "Hydrabad", parentName: "District", parentCode: "GEN" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "levelName" },
                    //             { type: ["TEXT"], name: "parentName" },
                    //             { type: ["TEXT"], name: "parentCode" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // },
                    // {
                    //     name: "Language", component: <CommonTable
                    //         Header={["S.No", "Level Code", "Level Name", "Parent Level Name", "Parent Level Code", "Status", "Action"]}
                    //         dataList={[{ code: "BNGCTY", levelName: "Bangalore", parentName: "District", parentCode: "BLR" },
                    //                    { code: "BNGCTA", levelName: "Hydrabad", parentName: "District", parentCode: "GEN" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "levelName" },
                    //             { type: ["TEXT"], name: "parentName" },
                    //             { type: ["TEXT"], name: "parentCode" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // },
                    // {
                    //     name: "Occupation", component: <CommonTable
                    //         Header={["S.No", "Level Code", "Level Name", "Parent Level Name", "Parent Level Code", "Status", "Action"]}
                    //         dataList={[{ code: "BNGCTY", levelName: "Bangalore", parentName: "District", parentCode: "BLR" },
                    //                    { code: "BNGCTA", levelName: "Hydrabad", parentName: "District", parentCode: "GEN" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "levelName" },
                    //             { type: ["TEXT"], name: "parentName" },
                    //             { type: ["TEXT"], name: "parentCode" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // },
                    // {
                    //     name: "Blood Group", component: <CommonTable
                    //         Header={["S.No", "Level Code", "Level Name", "Parent Level Name", "Parent Level Code", "Status", "Action"]}
                    //         dataList={[{ code: "BNGCTY", levelName: "Bangalore", parentName: "District", parentCode: "BLR" },
                    //                    { code: "BNGCTA", levelName: "Hydrabad", parentName: "District", parentCode: "GEN" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "levelName" },
                    //             { type: ["TEXT"], name: "parentName" },
                    //             { type: ["TEXT"], name: "parentCode" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // },
                    // {
                    //     name: "Alternate ID Types", component: <CommonTable
                    //         Header={["S.No", "Level Code", "Level Name", "Parent Level Name", "Parent Level Code", "Status", "Action"]}
                    //         dataList={[{ code: "BNGCTY", levelName: "Bangalore", parentName: "District", parentCode: "BLR" },
                    //                    { code: "BNGCTA", levelName: "Hydrabad", parentName: "District", parentCode: "GEN" }]}
                    //         tableData={[
                    //             { type: ["INCRIMENT"], name: "" },
                    //             { type: ["TEXT"], name: "code" },
                    //             { type: ["TEXT"], name: "levelName" },
                    //             { type: ["TEXT"], name: "parentName" },
                    //             { type: ["TEXT"], name: "parentCode" },
                    //             { type: ["CHECKBOX"], name: "" },
                    //             { type: ["EDIT"], name: "" },
                    //         ]}
                    //     />
                    // }
                ]}
            />
            <GenerateForm
                parent_id={'patient-GenerateForm'}
                // loading={saveloading}
                open={this.state.openForm}
                handlecancel={this.handleNewForm}
                header={'Add Form'}
                json={[{
                    componet_type: "text",
                    label: "Code",
                    state_name: "code",
                    value: this.state?.edit_data?.code ?? "",
                    required: true
                },
                {
                    componet_type: "text",
                    label: "Short Description",
                    state_name: "short_description",
                    value: this.state?.edit_data?.description ?? "",
                    required: true
                },
                {
                    componet_type: "text",
                    label: "Long Description",
                    state_name: "long_description",
                    value: this.state?.edit_data?.description ?? "",
                    required: true
                },
                {
                    componet_type: "switch",
                    label: "Status",
                    state_name: "status",
                    value: this.state?.edit_data?.status ?? false
                }
                ]}
            />
        </React.Fragment>
    }
}

export default PatientDemograpics