import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Paper, Typography, Button, IconButton } from "@material-ui/core";
// Components
import { InputBase, Autocomplete } from "../inputs";
// Icons
import { TrashIcon } from "../svg";
// Styles
import qdmstyles from "./styles.json";

function Aliasmappingcomp(props) {
  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {}, [state]);

  const {
    data,
    index,
    options,
    statevalue = [],
    stateName,
    onAddNewClick = () => {},
    onDeleteClick = () => {},
    setFun = () => {},
  } = props;

  return (
    <React.Fragment>
      <Grid
        key={"0"}
        id={"76GZv"}
        container={""}
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        item={true}
        lg={"12"}
        md={"12"}
        sm={"12"}
        xl={"12"}
        xs={"12"}
      >
        <Paper key={"0"} id={"fxDUL"} elevation={"{3}"} style={qdmstyles.rgfGG}>
          <Grid
            key={"0"}
            id={"OXKhr"}
            container={true}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            item={""}
          >
            {/* Header Starts */}
            <Grid
              key={"0"}
              id={"TgstR"}
              container={true}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              lg={"auto"}
              style={qdmstyles.xenT}
            >
              <Grid
                key={"0"}
                id={"TgstR"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                lg={"6"}
                md={"6"}
                sm={"6"}
                xl={"6"}
                xs={"6"}
              >
                <Typography
                  key={"0"}
                  id={"aliasmapping_Typography"}
                  align={"left"}
                  color={"initial"}
                  display={"initial"}
                  variant={"body1"}
                  children={"ALIAS MAPPING"}
                  aliasName={"aliasmappingTypography"}
                  style={qdmstyles.IeWX}
                />
              </Grid>
              <Grid
                key={"1"}
                id={"AliasmappingAddNewButton_button"}
                container={""}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item={true}
                aliasName={"AliasmappingAddNewButton"}
                lg={"6"}
                md={"6"}
                sm={"6"}
                xl={"6"}
                xs={"6"}
                style={qdmstyles.pQx}
              >
                <Button
                  key={"0"}
                  id={"alias_mapping_add_new_button"}
                  variant={"text"}
                  color={"primary"}
                  children={"+ Add New"}
                  aliasName={"aliasmappingaddnewbutton"}
                  style={qdmstyles.MRw}
                  onClick={() => onAddNewClick(stateName)}
                />
              </Grid>
            </Grid>
            {/* Header Ends */}

            {/* Body Starts */}
            <Grid
              key={"1"}
              id={"0PNTj"}
              container={true}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              item={true}
              wrap={"wrap"}
              spacing={""}
              lg={"12"}
              md={"12"}
              sm={"12"}
              xl={"12"}
              xs={"12"}
            >
              <Grid
                key={"0"}
                id={"7uXWP"}
                container={true}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"baseline"}
                lg={"12"}
                item={true}
                md={"12"}
                sm={"12"}
                xl={"12"}
                xs={"12"}
                spacing={1}
              >
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6} md={2}>
                    <Typography
                      key={"0"}
                      id={"sourceHeading_Typography"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Source"}
                      aliasName={"sourceHeading"}
                      style={qdmstyles.AQij}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography
                      key={"0"}
                      id={"codeHeading_Typography"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Code"}
                      aliasName={"codeHeading"}
                      style={qdmstyles.VKjZ}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography
                      key={"0"}
                      id={"Description_Typography"}
                      align={"inherit"}
                      color={"initial"}
                      display={"initial"}
                      variant={"body1"}
                      children={"Description"}
                      aliasName={"DescriptionTypography"}
                      style={qdmstyles.ZLyM}
                    />
                  </Grid>
                </Grid>

                {Array.isArray(statevalue) &&
                  statevalue?.map((item, index) => {
                    const rowState = statevalue?.[index];
                    return (
                      <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12} sm={6} md={2}>
                          <Autocomplete
                            id={"kXfMY"}
                            style={qdmstyles?.kXfMY}
                            getOptionLabel={(option) => option?.["label"]}
                            options={options?.source ?? []}
                            size={"small"}
                            placeholder=""
                            onLoad={() =>
                              setFun(
                                stateName,
                                "aliasType",
                                rowState?.aliasType,
                                index
                              )
                            }
                            value={rowState?.aliasType ?? null}
                            onChange={(event, newValue) =>
                              setFun(stateName, "aliasType", newValue, index)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <InputBase
                            key={"0"}
                            id={"code_autocomplete"}
                            label={""}
                            placeholder={""}
                            type={"text"}
                            variant={"outlined"}
                            size={"small"}
                            fullWidth={true}
                            aliasName={"codeAutocomplete"}
                            inputProps={{
                              maxlength: 15,
                            }}
                            value={rowState?.aliasCode}
                            onChange={(event) =>
                              setFun(
                                stateName,
                                "aliasCode",
                                event.target.value,
                                index
                              )
                            }
                          ></InputBase>
                        </Grid>
                        <Grid container item xs={12} sm={6} md={7} spacing={1}>
                          <Grid item xs>
                            <InputBase
                              key={"0"}
                              id={"description_textfield1"}
                              label={""}
                              placeholder={""}
                              type={"text"}
                              size={"small"}
                              variant={"outlined"}
                              fullWidth={true}
                              aliasName={"descriptiontextfield1"}
                              inputProps={{
                                maxlength: 30,
                              }}
                              value={rowState?.aliasName}
                              onChange={(event) =>
                                setFun(
                                  stateName,
                                  "aliasName",
                                  event.target.value,
                                  index
                                )
                              }
                            ></InputBase>
                          </Grid>
                          <Grid item>
                            <IconButton
                              onClick={() => onDeleteClick(stateName, index)}
                            >
                              <TrashIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}

export default withRouter(Aliasmappingcomp);
