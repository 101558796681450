import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { WebDataRocksTable } from '../../../components';
// import moment from 'moment';
import { utcTOLocal } from '../../../utils';

const useStyles = makeStyles(() => ({
    header: {
        fontSize: '16px',
        color: '#101010',
        fontFamily: 'pc_semibold',
        paddingBottom: '25px'
    },
}));

export const PendingListUi = (props) => {
    const { parent_id } = props;
    const classes = useStyles(props);

    const REPORT = {
        dataSource: {
            dataSourceType: 'json',
            data: props?.data
        },
        options: {
            grid: {
                type: 'flat',
                showTotals: "off",
                showGrandTotals: "off",
            },
        },
        slice: {
            columns: [
                { uniqueName: 'resource name' },
                { uniqueName: 'date' },
                { uniqueName: 'role' },
                { uniqueName: 'speciality' },
                { uniqueName: 'number Of Appointments Affected' },
                { uniqueName: 'action' },
            ]
        }
    }

    const customizeCellFunction = (cellBuilder, cellData) => {
        if (cellData?.type === "header" && cellData?.label === "action") {
            cellBuilder.text = "action"
        }
        else if (cellData?.measure?.uniqueName === "action" && cellData?.member?.caption) {
            cellBuilder.text = `<div 
                                style='color:#0071F2;display:flex;align-items:center;justify-content:center;cursor:pointer'
                                >
                                    <svg 
                                    style='width:15px;height:15px;fill:#0071F2;margin-right:7px'
                                    viewBox='0 0 24 24'
                                    aria-hidden='true'
                                    >
                                        <path d='M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z'>
                                        </path>
                                    </svg>
                                View
                            </div>`
        }
        else if (cellData?.measure?.uniqueName === "date" && cellData?.value) {
            cellBuilder.text = utcTOLocal(cellData?.value,'DD MMM,YYYY')
        }
    }

    const cellClick = (data, selected) => {
        if (selected?.measure?.uniqueName === "action" && selected?.columnIndex === 5) {
            props?.viewClick && props?.viewClick(data[selected["rowIndex"] - 1])
        }
    }

    return (
        <div
            id={`${parent_id}-parent-pendinglist-div`}
        >
            <Typography
                id={`${parent_id}-pendingReschedule-title-typography`}
                variant="body1" className={classes.header}>Pending Reschedule List</Typography>
            <div
                id={`${parent_id}-WebDataRocksTable-div`}
            >
                <WebDataRocksTable
                    parent_id={'pendinglist'}
                    report={props?.data?.length ? REPORT : []}
                    customizeCellFunction={customizeCellFunction}
                    cellClick={(data) => cellClick(props?.data, data)}
                />
            </div>
        </div>
    )
}