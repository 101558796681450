import React from "react";
import {
  Div,
  Paper,
  //Select,
  H6,
  Col,
  Row,
  //TextInput,
  Text,
  Button,
} from "qdm-component-library";
import {
  Dialog,
  DialogContent,
  Typography,
  Checkbox,
  // Tooltip,
  // makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  TextField,
} from "@material-ui/core";
import "./style.css";
import { withAllContexts } from "../../../HOCs";
import deleteicon from "../../../assets/icons8-trash.svg";
import { EditIcon } from "../../../components/svg/editIcon";
//import bussinessIcon from "../../../assets/icons8-database-administrator (1).svg";
import clock_ from "../../../assets/clock.svg";
import calender_ from "../../../assets/calendar-Blue.svg";
import { TimePicker } from "../../../components/common/timePicker/index";
import { DateTimePicker } from "../../../components/common/smartForm/component/datePicker";
import CustomInputDatePicker from "../../../components/common/datePicker/inputDatePicker";
import moment from "moment";
import { utcTOLocal } from "../../../utils";
class OverRide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    borderStyle1: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      width: "98px",
    },
    borderStyle2: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      width: "98px",
      marginLeft: "-65px",
    },
    labelStyle2: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
      marginLeft: "-65px",
    },
    borderStyleTime: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      width: "85%",
      padding: "0px",
    },
    entity: {
      margin: "0px",
      color: "#6F6F6F",
      paddingRight: "10px",
    },
    borderCover: {
      width: "160px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      border: "1px solid #E0E0E0",
      borderRadius: "8px",
    },
    content: {
      display: "flex",
      alignItems: "baseline",
      padding: "0 24px 20px",
    },
    label: {
      fontSize: "14px",
    },
    rodiolabel: {
      fontSize: "10px",
    },
    btnEdit: {
      backgroundColor: "#0071F2",
      color: "#fff",
      padding: "6px 16px",
      fontFamily: "poppin",
      fontSize: "12px",
      textTransform: "none",
      borderRadius: "10px",
      width: "100px",
    },
    btnEdit1: {
      backgroundColor: "#999999",
      color: "#fff",
      padding: "6px 16px",
      fontFamily: "poppin",
      fontSize: "12px",
      textTransform: "none",
      borderRadius: "10px",
      width: "100px",
    },
    cancelEdit: {
      backgroundColor: "#fff",
      marginRight: "15px",
      color: "#242424",
      padding: "6px 16px",
      fontFamily: "poppin",
      fontSize: "12px",
      textTransform: "none",
      borderRadius: "10px",
      width: "100px",
      border: "1px solid #F3F3F3",
    },
  };

  getDay_ = (value) => {
    ;
    //alert(value);
    let val = new Date(value).getDay();
    //alert(val);
    ;
    return val === 0
      ? "Sunday"
      : val === 1
        ? "Monday"
        : val === 2
          ? "Tuesday"
          : val === 3
            ? "Wednesday"
            : val === 4
              ? "Thursday"
              : val === 5
                ? "Friday"
                : val === 6
                  ? "Saturday"
                  : "";
  };

  render() {
    //let { communication } = this.props.patientData;
    const {
      // labelStyle,
      // label,
      // borderStyle,
      // outlinedButton,
      // containedButton,
      // borderStyleTime,
      // entity,
      // borderCover,
      content,
      btnEdit,
      btnEdit1,
      rodiolabel,
      cancelEdit,
    } = this.styles;
    const { parent_id } = this.props;
    return (
      <Div
        id={`${parent_id}-parent-div`}
        className="pi-root" style={{ margin: "20px 0px" }}>
        <Paper
          id={`${parent_id}-parent-paper`}
          className="pi-paper">
          <Div
            id={`${parent_id}-title-div`}
          >
            <H6
              id={`${parent_id}-title-h6`}
              className="pi-title">OVER RIDE</H6>

            <Text
              id={`${parent_id}-Add-Over-Ride-text`}
              inLineStyles={{
                color: "#0071F2",
                fontSize: 12,
                display: "flex",
                alignItems: "center",
              }}
              className="pi-addbtn"
            >
              <Text
                id={`${parent_id}-Add-Over-Ride-sub-text`}
                className="pi-addbtn"
                style={{ marginRight: 10 }}
                onClick={() => this.props.handleClose("add", "override")}
              >
                + Add Over Ride
              </Text>
            </Text>
          </Div>
          {this.props.overRideCard ? (
            <>
              {this.props.overRideCard.map((val, i) => {
                return (
                  <>
                    {val.type === "singleDate" ? (
                      <>
                        <Div
                          id={`${parent_id}-${i}-calender-div`}
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #e0e0e0",
                            padding: "18px 24px",
                            margin: "10px",
                          }}
                        >
                          <Div
                            id={`${parent_id}-${i}-calender-sub-div`}
                            style={{ display: "flex", alignItems: "end" }}>
                            <Div
                              id={`${parent_id}-${i}-calender-img-div`}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                id={`${parent_id}-${i}-calender-img`}
                                src={calender_}
                                width="18px"
                                style={{ margin: "0px 16px 0px 0px" }}
                                alt="pic"
                              />
                              <div
                                id={`${parent_id}-${i}-Date-div`}
                              >
                                <Typography
                                  id={`${parent_id}-${i}-Date-title-typography`}
                                  style={{ fontSize: "10px", color: "#6f6f6f" }}
                                >
                                  Date
                                </Typography>
                                <H6
                                  id={`${parent_id}-${i}-selectDate-title-h6`}
                                  className="pi-title">
                                  {utcTOLocal(val.selectDate, "DD MMM,YYYY")}
                                </H6>
                              </div>
                              <div
                                id={`${parent_id}-${i}-selectDate-styled-div`}
                                style={{
                                  border: "1px solid #eee",
                                  height: "50px",
                                  marginLeft: "100px",
                                  backgroundColor: "#E0E0E0",
                                }}
                              ></div>
                              <img
                                id={`${parent_id}-${i}-clock-img`}
                                src={clock_}
                                width="18px"
                                style={{ margin: "0px 0px 0px 16px" }}
                                alt="pic"
                              />
                            </Div>
                            <Grid
                              id={`${parent_id}-${i}-parent-grid`}
                              container direction="row">
                              <Grid
                                id={`${parent_id}-${i}-parent-sub-grid`}
                                item md={3} xs={6}>
                                <div
                                  id={`${parent_id}-${i}-parent-selectDate-div`}
                                  style={{
                                    display: "flex",
                                    marginLeft: "20px",
                                    marginBottom: "10px",
                                    borderRight: "1px solid #E0E0E0",
                                  }}
                                >
                                  <div
                                    id={`${parent_id}-${i}-selectDate-div`}
                                  >
                                    <Typography
                                      id={`${parent_id}-${i}-selectDate-typography`}
                                      style={{
                                        fontSize: "12px",
                                        color: "#6f6f6f",
                                      }}
                                    >
                                      {/* {"Monday"} */}
                                      {this.getDay_(val.selectDate)}
                                    </Typography>
                                    {val.timeSlots?.map((time_val) => (
                                      <H6
                                        id={`${parent_id}-${i}-selectDate-h6`}
                                        className="pi-title">
                                        {utcTOLocal(time_val.fromTime, "HH:mm")}
                                        -
                                        {utcTOLocal(time_val.toTime, "HH:mm")}
                                        ;
                                      </H6>
                                    ))}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                            <div
                              id={`${parent_id}-${i}-EditIcon-div`}
                              style={{ marginTop: "16px", cursor: "pointer" }}
                              onClick={(e) => this.props.handleEdit(val, e, i)}
                            >
                              <EditIcon
                                id={`${parent_id}-${i}-EditIcon`}
                                color="#0071f2" />
                            </div>
                          </Div>
                        </Div>
                      </>
                    ) : val.type === "dateRange" ? (
                      <>
                        <Div
                          id={`${parent_id}-${i}-Date-Range-div`}
                          style={{
                            borderRadius: "8px",
                            border: "1px solid #e0e0e0",
                            padding: "18px 24px",
                            margin: "10px",
                          }}
                        >
                          <Div
                            id={`${parent_id}-${i}-Date-Range-calender-div`}
                            style={{ display: "flex", alignItems: "end" }}>
                            <Div
                              id={`${parent_id}-${i}-Date-Range-calender-sub-div`}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                id={`${parent_id}-${i}-Date-Range-calender-img`}
                                src={calender_}
                                width="18px"
                                style={{ margin: "0px 16px 0px 0px" }}
                                alt="pic"
                              />
                              <div
                                id={`${parent_id}-${i}-Date-Range-typography-img`}
                                style={{
                                  marginRight: "100px",
                                }}
                              >
                                <Typography
                                  id={`${parent_id}-${i}-Date-Range-title-typography`}
                                  style={{ fontSize: "10px", color: "#6f6f6f" }}
                                >
                                  Date Range
                                </Typography>
                                <H6
                                  id={`${parent_id}-${i}-fromDate-title-H6`}
                                  className="pi-title">
                                  {utcTOLocal(val.fromDate, "DD MMM,YYYY")}
                                </H6>
                                <H6
                                  id={`${parent_id}-${i}-toDate-title-H6`}
                                  className="pi-title">
                                  {utcTOLocal(val.toDate, "DD MMM,YYYY")}
                                </H6>
                              </div>
                              <div
                                id={`${parent_id}-${i}-toDate-clock-div`}
                                style={{
                                  border: "1px solid #eee",
                                  height: "50px",
                                  //marginLeft: "100px",
                                  backgroundColor: "#E0E0E0",
                                }}
                              ></div>
                              <img
                                id={`${parent_id}-${i}-toDate-clock-img`}
                                src={clock_}
                                width="18px"
                                style={{ margin: "0px 0px 0px 16px" }}
                                alt="pic"
                              />
                            </Div>
                            <Grid
                              id={`${parent_id}-${i}-daySlots-grid`}
                              container direction="row">
                              {val.daySlots?.map((day_val, d_i) => (
                                <Grid
                                  id={`${parent_id}-${i}-daySlots-day-grid`}
                                  item md={3} xs={6}>
                                  <div
                                    id={`${parent_id}-${i}-daySlots-day-div`}
                                    style={{
                                      display: "flex",
                                      marginLeft: "20px",
                                      marginBottom: "10px",
                                      borderRight: "1px solid #E0E0E0",
                                    }}
                                  >
                                    <div
                                      id={`${parent_id}-${i}-daySlots-day-title-div`}
                                    >
                                      <Typography
                                        id={`${parent_id}-${i}-daySlots-day-title-typography`}
                                        style={{
                                          fontSize: "12px",
                                          color: "#6f6f6f",
                                        }}
                                      >
                                        {day_val.day}
                                      </Typography>
                                      {day_val.timeSlots?.map((time_val) => (
                                        <>
                                          {(time_val?.fromTime && time_val?.toTime) ?
                                            <H6
                                              id={`${parent_id}-${i}-daySlots-fromTime-toTime-h6`}
                                              className="pi-title">
                                              {time_val.fromTime ? utcTOLocal(time_val.fromTime, "HH:mm") : ""}
                                              -
                                              {time_val.toTime ? utcTOLocal(time_val.toTime, "HH:mm") : ""}
                                              ;
                                            </H6>
                                            :
                                            <H6
                                              id={`${parent_id}-${i}-daySlots-fromTime-toTime-h6_`}
                                              className="pi-title">-
                                            </H6>
                                          }
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                </Grid>
                              ))}
                            </Grid>
                            <div
                              id={`${parent_id}-${i}-daySlots-EditIcon-div`}
                              style={{
                                margin: "10px 15px",
                                cursor: "pointer",
                              }}
                              onClick={(e) => this.props.handleEdit(val, e, i)}
                            >
                              <EditIcon
                                id={`${parent_id}-${i}-daySlots-EditIcon`}
                                color="#0071f2" />
                            </div>
                          </Div>
                        </Div>
                      </>
                    ) : null}
                  </>
                );
              })}
            </>
          ) : null}
        </Paper>

        <Dialog
          id={`${parent_id}-openOverRide-dialog`}
          className="pi-root" open={this.props.openOverRide}>
          <Div
            id={`${parent_id}-title-div`}
            style={{ margin: "15px" }}>
            <H6 className="pi-title">OVER RIDE</H6>
          </Div>
          {/* DATE RANGE */}
          <div
            id={`${parent_id}-RadioGroup-div`}
            style={{ display: "flex", alignItems: "center" }}>
            {this.props.isEdit ? (
              <>
                <RadioGroup
                  id={`${parent_id}-RadioGroup`}
                  aria-label="position"
                  name="position"
                  value={this.props.overRideType}
                  onChange={(e) => this.props.handleRadioChange(e)}
                  row
                  style={{ paddingLeft: "15px" }}
                >
                  {this.props.editSingle && (
                    <FormControlLabel
                      id={`${parent_id}-editSingle-formcontrollabel`}
                      // style={{ color: "#6F6F6F" }}
                      value="singleDate"
                      control={
                        <Radio
                          id={`${parent_id}-editSingle-radio`}
                          size="small" style={{ color: "#0071F2" }} />
                      }
                      style={rodiolabel}
                      inLineStyles={{ fontSize: "5PX" }}
                      classes={{ label: rodiolabel }}
                      label="Single Date"
                    />
                  )}
                  {this.props.editDaterange && (
                    <FormControlLabel
                      id={`${parent_id}-editDaterange-formcontrollabel`}
                      style={{ color: "#6F6F6F" }}
                      value="dateRange"
                      control={
                        <Radio
                          id={`${parent_id}-editDaterange-radio`}
                          size="small" style={{ color: "#0071F2" }} />
                      }
                      label="Date Range"
                      classes={{ label: rodiolabel }}
                    />
                  )}
                </RadioGroup>
              </>
            ) : (
              <>
                <RadioGroup
                  id={`${parent_id}-overRideType-radiogroup`}
                  aria-label="position"
                  name="position"
                  value={this.props.overRideType}
                  onChange={(e) => this.props.handleRadioChange(e)}
                  row
                  style={{ paddingLeft: "15px" }}
                >
                  <FormControlLabel
                    id={`${parent_id}-overRideType-formcontrolabel`}
                    //style={{ color: "#6F6F6F" }}
                    value="singleDate"
                    control={
                      <Radio
                        id={`${parent_id}-overRideType-radio`}
                        size="small" style={{ color: "#0071F2" }} />
                    }
                    style={rodiolabel}
                    inLineStyles={{ fontSize: "5PX" }}
                    classes={{ label: rodiolabel }}
                    label="Single Date"
                  />
                  <FormControlLabel
                    id={`${parent_id}-dateRange-formcontrollabel`}
                    style={{ color: "#6F6F6F" }}
                    value="dateRange"
                    control={
                      <Radio
                        id={`${parent_id}-dateRange-radio`}
                        size="small" style={{ color: "#0071F2" }} />
                    }
                    label="Date Range"
                    classes={{ label: rodiolabel }}
                  />
                </RadioGroup>
              </>
            )}
          </div>
          <DialogContent>
            <>
              {this.props?.overRideType === "singleDate" ? (
                <>
                  {this.props.overRideInitial.map((item, i) => {
                    return (
                      <>
                        {item?.type === "singleDate" ? (
                          <Div
                            id={`${parent_id}-${i}-overRideInitial-div`}
                            style={content}>
                            <div
                              id={`${parent_id}-${i}-overRideInitial-sub-div`}
                            >
                              <div>
                                <Row
                                  id={`${parent_id}-${i}-overRideInitial-row`}
                                  className="pi-actions-root">
                                  <Col
                                    id={`${parent_id}-${i}-overRideInitial-col`}
                                    md={6}
                                    lg={6}
                                    sm={12}
                                    xs={12}
                                    inLineStyles={{ padding: 5 }}
                                  >
                                    <Typography
                                      id={`${parent_id}-${i}-overRideInitial-Select-Date-typography`}
                                      style={{
                                        fontSize: "12px",
                                        paddingBottom: "5px",
                                      }}
                                    >
                                      Select Date
                                    </Typography>

                                    {/* <TextField
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                      type="date"
                                      value={item.selectDate}
                                      inputProps={{
                                        style: {
                                          fontFamily: "poppin",
                                          fontSize: "12px",
                                        },
                                      }}
                                      onChange={(e) =>
                                        this.props.onDateChange(
                                          "selectDate",
                                          "add",
                                          e,
                                          i
                                        )
                                      }
                                      InputLabelProps={{
                                        style: {
                                          fontFamily: "poppin",
                                          fontSize: "12px",
                                        },
                                      }}
                                    /> */}
                                    {/* <DateTimePicker
                                      //format="DD-MM-YYYY"
                                      value={item.selectDate}
                                      onChange={(date) =>
                                        this.props.onDateChange(
                                          "selectDate",
                                          "add",
                                          date,
                                          i
                                        )
                                      }
                                    /> */}
                                    {/* <DateTimePicker
                                      id={`${parent_id}-${i}-overRideInitial-DateTimePicker`}
                                      required={true}
                                      //label={data.label}
                                      minDate={new Date()}
                                      dateFormat={"MM/dd/yyyy"}
                                      value={item.selectDate ?? null}
                                      onChange={(date) =>
                                        this.props.onDateChange(
                                          "selectDate",
                                          "add",
                                          date,
                                          i
                                        )
                                      }
                                    // error={
                                    //   state?.error?.[data?.state_name] ??
                                    //   false
                                    // }
                                    /> */}
                                    <CustomInputDatePicker
                                      // disabled={data?.disabled}
                                      placeholderText="dd / mm / yyyy"
                                      // minDate={
                                      //   state?.states?.[data?.minDateState] ??
                                      //   null
                                      // }
                                      selectedDate={
                                        item.selectDate
                                          ? new Date(item.selectDate)
                                          : null
                                      }
                                      handleChange={(value) =>
                                        this.props.onDateChange(
                                          "selectDate",
                                          "add",
                                          value,
                                          i
                                        )
                                      }
                                      inputField={
                                        <TextField
                                          id={`${parent_id}-${i}-overRideInitial-DateTimePicker`}
                                          //  label = {data.label}
                                          fullWidth
                                          variant="outlined"
                                          required={true}
                                          disabled={false}
                                          // type={data?.type ?? "text"}
                                          value={
                                            item.selectDate
                                              ? moment(
                                                new Date(item.selectDate)
                                              ).format("DD-MM-YYYY")
                                              : null
                                          }
                                          inputProps={{
                                            style: {
                                              padding: "10.5px 14px",
                                              fontFamily: "poppin",
                                              fontSize: "12px",
                                              // backgroundColor: data?.disabled
                                              //   ? "#E0E0E0"
                                              //   : "#fff",
                                            },
                                          }}
                                        />
                                      }
                                    />
                                  </Col>
                                </Row>
                                <Div>
                                  <H6
                                    id={`${parent_id}-${i}-overRideInitial-OPERATIONAL-TIMINGS-h6`}
                                    className="pi-title"
                                    style={{ margin: "15px 5px" }}
                                  >
                                    OPERATIONAL TIMINGS
                                  </H6>
                                </Div>
                                {item?.timeSlots?.map((smap, ci) => {
                                  return (
                                    <div
                                      id={`${parent_id}-${i}-overRideInitial-OPERATIONAL-TIMINGS-div`}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        //justifyContent: "flex-end",
                                        paddingBottom: "10px",
                                      }}
                                    >
                                      {/* <div style={borderCover}> */}
                                      {/* <TextInput
                                          labelStyle={labelStyle}
                                          style={borderStyleTime}
                                          size="small"
                                          placeholder={"08:00"}
                                          variant="none"
                                          value={smap.fromTime}
                                          onChange={(e) =>
                                            this.props.onTimeChange(
                                              "singleDate",
                                              "fromTime",
                                              e,
                                              i,
                                              ci
                                            )
                                          }
                                        />
                                        <h5 style={entity}> 24Hrs </h5> */}
                                      <TimePicker
                                        id={`${parent_id}-${i}-overRideInitial-timepicker`}
                                        value={smap.fromTime}
                                        onChange={(date) =>
                                          this.props.onTimeChange(
                                            "singleDate",
                                            "fromTime",
                                            date,
                                            i,
                                            ci
                                          )
                                        }
                                      />
                                      {/* </div> */}
                                      <div
                                        id={`${parent_id}-${i}-overRideInitial-div`}
                                        style={{
                                          border: "0.5px solid #E0E0E0",
                                          width: "8px",
                                          margin: "0 15px",
                                          background: "#E0E0E0",
                                        }}
                                      ></div>
                                      {/* <div style={borderCover}>
                                        <TextInput
                                          labelStyle={labelStyle}
                                          style={borderStyleTime}
                                          size="small"
                                          placeholder={"08:00"}
                                          variant="none"
                                          value={smap.toTime}
                                          onChange={(e) =>
                                            this.props.onTimeChange(
                                              "singleDate",
                                              "toTime",
                                              e,
                                              i,
                                              ci
                                            )
                                          }
                                        />
                                        <h5 style={entity}> 24Hrs </h5>
                                      </div> */}
                                      <TimePicker
                                        id={`${parent_id}-${i}-overRideInitial-timepicker`}
                                        value={smap.toTime}
                                        onChange={(date) =>
                                          this.props.onTimeChange(
                                            "singleDate",
                                            "toTime",
                                            date,
                                            i,
                                            ci
                                          )
                                        }
                                      />
                                      {/* {JSON.stringify(
                                          item?.timeSlots?.length - 1 + " " + ci
                                        )} */}
                                      {item?.timeSlots?.length === 0 ||
                                        item?.timeSlots?.length - 1 === ci ? (
                                        <div
                                          id={`${parent_id}-${i}-addfield-div`}
                                          style={{
                                            border: "1px solid #E0E0E0",
                                            color: "#0071F2",
                                            padding: "0 12px",
                                            fontSize: "20px",
                                            cursor: "pointer",
                                            borderRadius: "8px",
                                            marginLeft: "10px",
                                          }}
                                          onClick={(e) =>
                                            this.props.addfield(
                                              e,
                                              "singleDate",
                                              "day",
                                              i,
                                              "",
                                              ""
                                            )
                                          }
                                        >
                                          +
                                        </div>
                                      ) : (
                                        <div
                                          id={`${parent_id}-${i}-deletefield-div`}
                                          style={{
                                            border: "1px solid #E0E0E0",
                                            color: "#0071F2",
                                            padding: "0 12px",
                                            fontSize: "20px",
                                            cursor: "pointer",
                                            borderRadius: "8px",
                                            marginLeft: "10px",
                                          }}
                                          onClick={(e) =>
                                            this.props.deletefield(
                                              e,
                                              "singleDate",
                                              "day",
                                              i,
                                              ci
                                            )
                                          }
                                        >
                                          <img
                                            id={`${parent_id}-${i}-deleteicon-img`}
                                            src={deleteicon} alt="Icon" />
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Div>
                        ) : null}
                      </>
                    );
                  })}
                </>
              ) : this.props?.overRideType === "dateRange" ? (
                <>
                  {this.props.overRideInitial.map((item, di) => {
                    return (
                      <>
                        {item.type === "dateRange" ? (
                          <Div
                            id={`${parent_id}-${di}-parent-div`}
                            style={content}>
                            <div>
                              <div
                                id={`${parent_id}-${di}-parent-sub-div`}
                              >
                                <Row
                                  id={`${parent_id}-${di}-parent-row`}
                                  className="pi-actions-root">
                                  <Col
                                    id={`${parent_id}-${di}-parent-col`}
                                    md={4}
                                    lg={4}
                                    sm={12}
                                    xs={12}
                                    inLineStyles={{ padding: 5 }}
                                  >
                                    <Typography
                                      id={`${parent_id}-${di}-From-Date-typography`}
                                      style={{
                                        fontSize: "12px",
                                        paddingBottom: "5px",
                                      }}
                                    >
                                      From Date
                                    </Typography>
                                    {/* <TextField
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                      type="date"
                                      name="route"
                                      required
                                      inputProps={{
                                        style: {
                                          fontFamily: "poppin",
                                          fontSize: "12px",
                                        },
                                      }}
                                      onChange={(e) =>
                                        this.props.onDateChange(
                                          "fromDate",
                                          "add",
                                          e,
                                          di
                                        )
                                      }
                                      InputLabelProps={{
                                        style: {
                                          fontFamily: "poppin",
                                          fontSize: "12px",
                                        },
                                      }}
                                    /> */}
                                    {/* <DateTimePicker
                                      id={`${parent_id}-${di}-From-Date-datetimepicker`}
                                      format="DD-MM-YYYY"
                                      minDate={new Date()}
                                      value={item.fromDate}
                                      onChange={(date) =>
                                        this.props.onDateChange(
                                          "fromDate",
                                          "add",
                                          date,
                                          di
                                        )
                                      }
                                    /> */}
                                    <CustomInputDatePicker
                                      // disabled={data?.disabled}
                                      placeholderText="dd / mm / yyyy"
                                      // minDate={
                                      //   state?.states?.[data?.minDateState] ??
                                      //   null
                                      // }
                                      selectedDate={
                                        item.fromDate
                                          ? new Date(item.fromDate)
                                          : null
                                      }
                                      handleChange={(value) =>
                                        this.props.onDateChange(
                                          "fromDate",
                                          "add",
                                          value,
                                          di
                                        )
                                      }
                                      inputField={
                                        <TextField
                                          id={`${parent_id}-${di}-From-Date-datetimepicker`}
                                          //  label = {data.label}
                                          fullWidth
                                          variant="outlined"
                                          required={false}
                                          // disabled={data?.disabled}
                                          // type={data?.type ?? "text"}
                                          value={
                                            item.fromDate
                                              ? moment(
                                                new Date(item.fromDate)
                                              ).format("DD-MM-YYYY")
                                              : null
                                          }
                                          inputProps={{
                                            style: {
                                              padding: "10.5px 14px",
                                              fontFamily: "poppin",
                                              fontSize: "12px",
                                              // backgroundColor: data?.disabled
                                              //   ? "#E0E0E0"
                                              //   : "#fff",
                                            },
                                          }}
                                        />
                                      }
                                    />
                                  </Col>
                                  <Col
                                    id={`${parent_id}-${di}-To-Date-col`}
                                    md={4}
                                    lg={4}
                                    sm={12}
                                    xs={12}
                                    inLineStyles={{ padding: 5 }}
                                  >
                                    <Typography
                                      id={`${parent_id}-${di}-To-Date-typography`}
                                      style={{
                                        fontSize: "12px",
                                        paddingBottom: "5px",
                                      }}
                                    >
                                      To Date
                                    </Typography>
                                    {/* <TextField
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                      type="date"
                                      name="route"
                                      required
                                      inputProps={{
                                        style: {
                                          fontFamily: "poppin",
                                          fontSize: "12px",
                                        },
                                      }}
                                      onChange={(e) =>
                                        this.props.onDateChange(
                                          "toDate",
                                          "add",
                                          e,
                                          di
                                        )
                                      }
                                      InputLabelProps={{
                                        style: {
                                          fontFamily: "poppin",
                                          fontSize: "12px",
                                        },
                                      }}
                                    /> */}
                                    {/* <DateTimePicker
                                      id={`${parent_id}-${di}-To-Date-datetimepicker`}
                                      format="DD-MM-YYYY"
                                      minDate={item.fromDate}
                                      disabled={item.fromDate === "" ? true : false}
                                      value={item.toDate}
                                      onChange={(date) =>
                                        this.props.onDateChange(
                                          "toDate",
                                          "add",
                                          date,
                                          di
                                        )
                                      }
                                    /> */}
                                    <CustomInputDatePicker
                                      // disabled={data?.disabled}
                                      placeholderText="dd / mm / yyyy"
                                      minDate={item?.fromDate ?? null}
                                      selectedDate={
                                        item.toDate
                                          ? new Date(item.toDate)
                                          : null
                                      }
                                      handleChange={(value) =>
                                        this.props.onDateChange(
                                          "toDate",
                                          "add",
                                          value,
                                          di
                                        )
                                      }
                                      inputField={
                                        <TextField
                                          id={`${parent_id}-${di}-To-Date-datetimepicker`}
                                          //  label = {data.label}
                                          fullWidth
                                          variant="outlined"
                                          required={false}
                                          // disabled={data?.disabled}
                                          // type={data?.type ?? "text"}
                                          value={
                                            item.toDate
                                              ? moment(
                                                new Date(item.toDate)
                                              ).format("DD-MM-YYYY")
                                              : null
                                          }
                                          inputProps={{
                                            style: {
                                              padding: "10.5px 14px",
                                              fontFamily: "poppin",
                                              fontSize: "12px",
                                              borderRadius: 8,
                                              borderColor: "#e0e0e0",
                                              // backgroundColor: data?.disabled
                                              //   ? "#E0E0E0"
                                              //   : "#fff",
                                            },
                                          }}
                                        />
                                      }
                                    />
                                  </Col>
                                </Row>
                                <Div
                                  id={`${parent_id}-${di}-To-Date-OPERATIONAL-TIMINGS-div`}
                                >
                                  <H6
                                    id={`${parent_id}-${di}-To-Date-OPERATIONAL-TIMINGS-h6`}
                                    className="pi-title"
                                    style={{ margin: "15px 5px" }}
                                  >
                                    OPERATIONAL TIMINGS
                                  </H6>
                                </Div>
                                {item?.daySlots?.map((dmap, day_i) => {
                                  return (
                                    <Div
                                      id={`${parent_id}-${di}-dmap-div`}
                                      style={content}>
                                      <div
                                        id={`${parent_id}-${di}-dmap-Checkbox-div`}
                                        style={{ display: "flex" }}>
                                        <Checkbox
                                          id={`${parent_id}-${di}-dmap-Checkbox`}
                                          inputProps={{
                                            "aria-label": "primary checkbox",
                                          }}
                                          style={{
                                            padding: "0px 10px 0px 0px",
                                            color: "#0071F2",
                                          }}
                                          size="small"
                                          disabled={!this.props?.dateRangeArray.includes(dmap.day)}
                                          checked={dmap.checked}
                                          onChange={(e) => this.props.handleChangecheckbox(e, di, day_i)}
                                        />

                                        <H6
                                          id={`${parent_id}-${di}-dmap-day-Checkbox`}
                                          className="pi-title"
                                          style={{ width: "50px", margin: 0 }}
                                        >
                                          {dmap.day}
                                        </H6>
                                      </div>
                                      <div
                                        id={`${parent_id}-${di}-dmap-day-div`}
                                        style={{ flexGrow: 1 }}></div>
                                      <div>
                                        {dmap?.timeSlots?.map(
                                          (dtmap, time_i) => {
                                            return (
                                              <div
                                                id={`${parent_id}-${di}-dmap-fromTime-div`}
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  //justifyContent: "flex-end",
                                                  paddingBottom: "10px",
                                                }}
                                              >
                                                {/* <div style={borderCover}>
                                                  <TextInput
                                                    labelStyle={labelStyle}
                                                    style={borderStyleTime}
                                                    size="small"
                                                    placeholder={"08:00"}
                                                    variant="none"
                                                    value={dtmap.fromTime}
                                                    onChange={(e) =>
                                                      this.props.onTimeChange(
                                                        "dateRange",
                                                        "fromTime",
                                                        e,
                                                        di,
                                                        day_i,
                                                        time_i
                                                      )
                                                    }
                                                  />
                                                  <h5 style={entity}>24Hrs</h5>
                                                </div> */}
                                                <TimePicker
                                                  id={`${parent_id}-${di}-dmap-fromTime-timepicker`}
                                                  value={dtmap.fromTime}
                                                  onChange={(date) =>
                                                    this.props.onTimeChange(
                                                      "dateRange",
                                                      "fromTime",
                                                      date,
                                                      di,
                                                      day_i,
                                                      time_i
                                                    )
                                                  }
                                                  width={"80px"}
                                                  disabled={!this.props?.dateRangeArray.includes(dmap.day)}
                                                />
                                                <div
                                                  id={`${parent_id}-${di}-dmap-toTime-div`}
                                                  style={{
                                                    border:
                                                      "0.5px solid #E0E0E0",
                                                    width: "8px",
                                                    margin: "0 15px",
                                                    background: "#E0E0E0",
                                                  }}
                                                ></div>
                                                {/* <div style={borderCover}>
                                                  <TextInput
                                                    labelStyle={labelStyle}
                                                    style={borderStyleTime}
                                                    size="small"
                                                    placeholder={"08:00"}
                                                    variant="none"
                                                    value={dtmap.toTime}
                                                    onChange={(e) =>
                                                      this.props.onTimeChange(
                                                        "dateRange",
                                                        "toTime",
                                                        e,
                                                        di,
                                                        day_i,
                                                        time_i
                                                      )
                                                    }
                                                  />
                                                  <h5 style={entity}>24Hrs</h5>
                                                </div> */}
                                                <TimePicker
                                                  id={`${parent_id}-${di}-dmap-toTime-timepicker`}
                                                  value={dtmap.toTime}
                                                  onChange={(date) =>
                                                    this.props.onTimeChange(
                                                      "dateRange",
                                                      "toTime",
                                                      date,
                                                      di,
                                                      day_i,
                                                      time_i
                                                    )
                                                  }
                                                  width={"80px"}
                                                  disabled={!this.props?.dateRangeArray.includes(dmap.day)}
                                                />

                                                {dmap?.timeSlots?.length ===
                                                  0 ||
                                                  dmap?.timeSlots?.length - 1 ===
                                                  time_i ? (
                                                  <div
                                                    id={`${parent_id}-${di}-dmap-addfield-div`}
                                                    style={{
                                                      border:
                                                        "1px solid #E0E0E0",
                                                      color: "#0071F2",
                                                      padding: "0 12px",
                                                      fontSize: "20px",
                                                      cursor: "pointer",
                                                      borderRadius: "8px",
                                                      marginLeft: "10px",
                                                    }}
                                                    onClick={(e) =>
                                                      this.props.addfield(
                                                        e,
                                                        "dateRange",
                                                        dmap.day,
                                                        di,
                                                        day_i,
                                                        time_i
                                                      )
                                                    }
                                                  >
                                                    +
                                                  </div>
                                                ) : (
                                                  <div
                                                    id={`${parent_id}-${di}-dmap-deletefield-div`}
                                                    style={{
                                                      border:
                                                        "1px solid #E0E0E0",
                                                      color: "#0071F2",
                                                      padding: "0 12px",
                                                      fontSize: "20px",
                                                      cursor: "pointer",
                                                      borderRadius: "8px",
                                                      marginLeft: "10px",
                                                    }}
                                                    onClick={(e) =>
                                                      this.props.deletefield(
                                                        e,
                                                        "dateRange",
                                                        dmap.day,
                                                        di,
                                                        day_i,
                                                        time_i
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      id={`${parent_id}-${di}-dmap-deleteicon-img`}
                                                      src={deleteicon}
                                                      alt="Icon"
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </Div>
                                  );
                                })}
                              </div>
                            </div>
                          </Div>
                        ) : null}
                      </>
                    );
                  })}
                </>
              ) : null}
            </>
          </DialogContent>

          <div
            id={`${parent_id}-buttons-div`}
            style={{ margin: "20px 0", textAlign: "center" }}>
            {this.props.isEdit ? (
              <>
                <Button
                  id={`${parent_id}-Cancel-button`}
                  variant="contained"
                  style={cancelEdit}
                  onClick={() => this.props.handleClose("cancel", "override")}
                >
                  Cancel
                </Button>
                <Button
                  id={`${parent_id}-Update-button`}
                  variant="contained"
                  style={this.props.overridedisabled() ? btnEdit1 : btnEdit}
                  disabled={this.props.overridedisabled()}
                  onClick={() => this.props.handleClose("update", "override")}
                >
                  {"Update"}
                </Button>
              </>
            ) : (
              <>
                <Button
                  id={`${parent_id}-Cancel-button`}
                  variant="contained"
                  style={cancelEdit}
                  onClick={() => this.props.handleClose("cancel", "override")}
                >
                  Cancel
                </Button>
                <Button
                  id={`${parent_id}-Save-button`}
                  variant="contained"
                  //style={btnEdit}
                  style={this.props.overridedisabled() ? btnEdit1 : btnEdit}
                  disabled={this.props.overridedisabled()}
                  onClick={() => this.props.handleClose("saved", "override")}
                >
                  {"Save"}
                </Button>
              </>
            )}
          </div>
        </Dialog>
      </Div>
    );
  }
}

export default withAllContexts(OverRide);
