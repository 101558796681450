import React from "react";
import { withRouter } from "react-router-dom";
import { Grid, Box, Checkbox, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Styles
import qdmstyles from "./styles.json";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    "&.Mui-checked": {
      color: "#0071F2"
    },
  },
}));

function Drugotherattributes(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    permissionData: null,
  });

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  React.useEffect(() => {}, [state]);

  const {
    data,
    index,
    propsState,
    stateName,
    statevalue,
    setFun = () => {},
  } = props;

  return (
    <React.Fragment>
      <Grid
        key={"0"}
        id={"dmbxa"}
        container={true}
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        lg={"12"}
        wrap={"wrap"}
        alignContent={"flex-start"}
        style={qdmstyles.dmbxa}
      >
        <Box key={"0"} id={"a85gl"} component={"div"} style={qdmstyles.agl}>
          <Checkbox
            key={"0"}
            id={"B7nCd"}
            color={"primary"}
            size={"small"}
            className={classes.checkbox}
            checked={statevalue?.Additive}
            onChange={(event) =>
              setFun(stateName, "Additive", event.target.checked)
            }
            inputProps={{ "aria-label": "Use As Additive" }}
          />
          <Typography
            key={"1"}
            id={"BU52G"}
            align={"inherit"}
            color={"initial"}
            display={"initial"}
            variant={"body1"}
            children={"Use As Additive"}
            style={qdmstyles.BUG}
          />
        </Box>
        <Box key={"1"} id={"a85gl"} component={"div"} style={qdmstyles.ihUn}>
          <Checkbox
            key={"0"}
            id={"B7nCd"}
            color={"primary"}
            size={"small"}
            className={classes.checkbox}
            checked={statevalue?.ApplicableForCompounding}
            onChange={(event) =>
              setFun(
                stateName,
                "ApplicableForCompounding",
                event.target.checked
              )
            }
            inputProps={{ "aria-label": "Use For Compound Preparation" }}
          />
          <Typography
            key={"1"}
            id={"BU52G"}
            align={"inherit"}
            color={"initial"}
            display={"initial"}
            variant={"body1"}
            children={"Use For Compound Preparation"}
            style={qdmstyles.bUWkK}
          />
        </Box>
        <Box key={"2"} id={"a85gl"} component={"div"} style={qdmstyles.qUEoU}>
          <Checkbox
            key={"0"}
            id={"B7nCd"}
            color={"primary"}
            size={"small"}
            className={classes.checkbox}
            checked={statevalue?.DispenseviaprescriptionOnly}
            onChange={(event) =>
              setFun(
                stateName,
                "DispenseviaprescriptionOnly",
                event.target.checked
              )
            }
            inputProps={{ "aria-label": "Dispense Via Rx Only" }}
          />
          <Typography
            key={"1"}
            id={"BU52G"}
            align={"inherit"}
            color={"initial"}
            display={"initial"}
            variant={"body1"}
            children={"Dispense Via Rx Only"}
            style={qdmstyles.uTrv}
          />
        </Box>
        <Box key={"3"} id={"a85gl"} component={"div"} style={qdmstyles.qcBS}>
          <Checkbox
            key={"0"}
            id={"B7nCd"}
            color={"primary"}
            size={"small"}
            className={classes.checkbox}
            checked={statevalue?.CounsellingRequired}
            onChange={(event) =>
              setFun(stateName, "CounsellingRequired", event.target.checked)
            }
            inputProps={{ "aria-label": "Counselling Required" }}
          />
          <Typography
            key={"1"}
            id={"BU52G"}
            align={"inherit"}
            color={"initial"}
            display={"initial"}
            variant={"body1"}
            children={"Counselling Required"}
            style={qdmstyles.cRMr}
          />
        </Box>
        <Box key={"4"} id={"kjahdl"} component={"div"} style={qdmstyles.qcBS}>
          <Checkbox
            key={"0"}
            id={"clasdx"}
            color={"primary"}
            size={"small"}
            className={classes.checkbox}
            checked={statevalue?.returnApplicable}
            onChange={(event) =>
              setFun(stateName, "returnApplicable", event.target.checked)
            }
            inputProps={{ "aria-label": "Return Applicable" }}
          />
          <Typography
            key={"1"}
            id={"szBU52G"}
            align={"inherit"}
            color={"initial"}
            display={"initial"}
            variant={"body1"}
            children={"Return Applicable"}
            style={qdmstyles.cRMr}
          />
        </Box>
      </Grid>
    </React.Fragment>
  );
}

export default withRouter(Drugotherattributes);
