/* eslint-disable */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import ico from "../../../assets/icons8-flag-filled.svg";
import { Checkbox } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';


const data = {
  id: "root",
  name: "Apollow group of hospitals",
  checkedValue: "unchecked",
  children: [
    {
      id: "1",
      name: "Apollow hospitals - India",
      checkedValue: "unchecked"
    },
    {
      id: "2",
      name: "Apollow hospitals - China",
      checkedValue: "unchecked"
    },
    {
      id: "3",
      name: "Apollow hospitals - Canada",
      checkedValue: "unchecked"
    },
    {
      id: "4",
      name: "Apollow hospitals - USA",
      checkedValue: "unchecked"
    },
    {
      id: "5",
      name: "Apollow hospitals - Japan",
      checkedValue: "unchecked"
    },
    {
      id: "6",
      name: "Apollow hospitals - Brazil",
      checkedValue: "unchecked"
    },
    {
      id: "7",
      name: "Apollow hospitals - France",
      checkedValue: "unchecked"
    },
  ],
};

// const orgAccess = {
//   id: "root2",
//   name: "Apollow group of hospitals",
//   PermissionManagement: [
//     {
//       permissionName: "Apollow hospitals - India",
//       id: "1",
//     },
//     {
//       permissionName: "Apollow hospitals - China",
//       id: "2",
//     },
//     {
//       permissionName: "Apollow hospitals - USA",
//       id: "3",
//     },
//     {
//       permissionName: "Apollow hospitals - Japan",
//       id: "4",
//     },
//     {
//       permissionName: "Apollow hospitals - Brazil",
//       id: "5",
//     },
//     {
//       permissionName: "Apollow hospitals - France",
//       id: "6",
//     },
//     {
//       permissionName: "Apollow hospitals - Canada",
//       id: "7",
//     },
//   ],
// };
let PermissionManagement = [];

const orgAccess2 = {
  id: "root3",
  name: "Apollow group of hospitals",
  children: [
    {
      PermissionManagement: {
        permissionName: "Apollow hospitals - India",
        id: "1",
      },
    },
    {
      PermissionManagement: {
        permissionName: "Apollow hospitals - China",
        id: "2",
      },
    },
    {
      PermissionManagement: {
        permissionName: "Apollow hospitals - USA",
        id: "3",
      },
    },
  ],
};

const useStyles = makeStyles({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
  },
  fontEdits: {
    fontSize: "14px",
    fontFamily: "poppin",
    // padding: "10px 12px",
  },
  editLine: {
    position: "relative",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 14,
      left: -16,
      top: 18,
      borderBottom: "1px solid #B6B6B6",
    },
    "&:focus > $content": {
      backgroundColor: "E0EBF9",
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 15,
    borderLeft: "1px solid #B6B6B6",
  },
  selected: {
    backgroundColor: "#E0EBF9",
  },
});

export default function TreeViewComponent(props) {
  const classes = useStyles();
  const [orgCheck,setOrgCheck] = useState({
    orgData:props?.newState?.OrgAccess ?? []
  });
  // const [forCheck,setForCheck] = useState({
  //   trigger: true
  // })

  // console.log("orgCheck", orgCheck);
  // console.log("propsCheck", props.permissionState.change)
  useEffect(()=>{
    setOrgCheck(
      {
        orgData:props?.newState?.OrgAccess ?? []
      }
    );
  },[props?.newState?.OrgAccess])
  useEffect (()=>{
    let empty = [];
    if(props.permissionState.change === true){
      props.handleOrgCheck(empty)
    } 
  });

  const handleCheck2 = (e,item,i) =>{
    let {orgData} = orgCheck;
    
    if(orgData?.indexOf(item?.name) > -1){
        orgData.splice(orgData?.indexOf(item?.name),1) ?? false
    }else{
      orgData= orgData ? orgData : []
      orgData.push(item?.name)
    } 

    
    // }else{
      setOrgCheck({
        ...orgCheck,
        orgData 
      })
    // }
   
    let dataForOrg = orgCheck;
    props.handleOrgCheck(dataForOrg)
  }

  const renderTree = (item, i) => (
    <TreeItem
      key={item.id}
      nodeId={item.id}
      icon={<img src={ico} alt="dummy" />}
      label={
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox 
            checked ={
              orgCheck.orgData?.indexOf(
                item?.name
              ) > -1 
                ? true
                : false
            }
            onChange={(e)=> handleCheck2 (e,item,i)}
            style={{color: "#0071F2",}}
            size="small"
          />
          {/* } */}
          {/* ></FormControlLabel> */}
          {item.name}
        </div>
      }
      classes={{
        label: classes.fontEdits,
        root: classes.editLine,
        group: classes.group,
      }}
    >
      {Array.isArray(item.children)
        ? item.children.map((item, i) => renderTree(item, i))
        : null}
    </TreeItem>
  );

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={["root"]}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(data)}
    </TreeView>
  );
}
