import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  Checkbox,
} from "@material-ui/core";
import { withAllContexts } from "../../../HOCs";
import styles from "./styles";
import {
  addRole,
  upsertDocument,
  getUserId,
  updateRoleKeyClock,
  addRolesToKeyClock,
} from "../../../function/commonapi";
import Config from "../../../config";
import { v4 as uuidv4 } from "uuid";
function CreateNewRole(props) {
  const classes = styles();
  const [rolesData, setRolesData] = useState({
    roles: "",
    oldRoleName: "",
  });
  const [error, setError] = useState({
    role: false,
    msg: "Please enter the field",
  });
  const [loader, setLoader] = useState(false);

  const validation = () => {
    let isValidate = false;

    if (rolesData?.roles?.trim()?.length > 0) {
      error.role = false;
    } else {
      isValidate = true;
      error.role = true;
    }
    setError({
      ...error,
    });
    return isValidate;
  };

  useEffect(() => {
    if (props.editRoles) {
      setRolesData({
        ...rolesData,
        roles: props.editRoles,
        oldRoleName: props.editRoles,
        roleId: props.roleId,
      });
    }
    console.log("rolesData ;", rolesData);
  }, []);

  const handleRolesData = (event) => {
    setRolesData({ ...rolesData, [event.target.name]: event.target.value });
  };

  const submitEditRoles = async () => {
    const { drawer } = props;
    let check = validation();
    if (check) {
      return;
    }
    setLoader(true);
    let list = {
      code:[
        {
            "coding":[
                {
                    "code":rolesData.roles,
                    "display":rolesData.roles,
                }
            ]
        }
      ]
    };
    let tokenData = localStorage.getItem("payload");
    let parsedToken = JSON.parse(tokenData);

    let sendUserData = {
      entity: Config.practitionerRoleEntity,
      metadataId: Config.metadataid,
      isedit: true,
      id: props?.roleParams?.id,
      list,
      keyvalue: "id",
    };

    let addUserKeyClock = await updateRoleKeyClock(
      rolesData.roles,
      rolesData.oldRoleName
    );

    let addUserUpsert = await upsertDocument(sendUserData)
      .then((res) => {
        if (res?.data?.Code === 201) {
          props.getRoleData();
          drawer.setDrawer({
            ...drawer,
            open: false,
            component: <></>,
          });
          props.handleAlerts("Roles created successfully!", true);
        } else {
          props.handleAlerts("Something went wrong", false);
        }
      })
      .catch((error) => {
        props.handleAlerts("Something went wrong", false);
      });
  };

  const submitRoles = async () => {
    let submittedRoles = {
      roles: rolesData.roles,
    };

    const { drawer } = props;
    let check = validation();
    if (check) {
      return;
    }
    setLoader(true);
    // let list = {
    //   active:true,
    //   id:"",
    //   code:[
    //     {
    //         id:"",
    //         "Type":"PRACTROLE",
    //         "coding":[
    //             {
    //                 id:"",
    //                 "code":submittedRoles.roles,
    //                 "display":submittedRoles.roles,
    //                 "Type":"PRACTROLE"
    //             }
    //         ]
    //     }
    //   ]
    // };
    let startCode = '000';
    let codeGenerate = "PR"+`${startCode.substring((rolesData?.length+"").length,3)+ (rolesData?.length+"") }`
    let list = {
      "_id": "",
      "text": "",
      "coding": [
        {
          "_id": "",
          "system": "",
          "version": "",
          "code": codeGenerate,
          "display":submittedRoles.roles ,
          "userSelected": true,
          "id": 0,
          "Type": "PRACTROLE",
          "shortdesc": ""
        }
      ],
      "id": 0,
      "Type": "PRACTROLE",
      "status": true
    }
    let tokenData = localStorage.getItem("payload");
    let parsedToken = JSON.parse(tokenData);

    let sendRoleParams = {
      entity: Config.codabaleConceptMaster,
      metadataId: Config.metadataid,
      list,
    };

    let addUserKeyClock = await addRolesToKeyClock(
      parsedToken.access_token,
      submittedRoles.roles
    );

    let addUserUpsert = await upsertDocument(sendRoleParams)
      .then((res) => {
        if (res?.data?.Code === 201) {
          props.getRoleData();
          drawer.setDrawer({
            ...drawer,
            open: false,
            component: <></>,
          });
          props.handleAlerts("Roles created successfully!", true);
        } else {
          props.handleAlerts("Something went wrong", false);
        }
      })
      .catch((error) => {
        props.handleAlerts("Something went wrong", false);
      });
  };
  return (
    <div>
      <div className={classes.drawerHead}>
        <Typography className={classes.headerMain}>
          {props.editRoles ? "Edit Roles" : "Add New Role"}
        </Typography>
      </div>

      <Grid container direction="row">
        <Grid item xs={12}>
          <Paper>
            <div className={classes.textTotalArea}>
              <Typography className={classes.drawerSubHead}>
                Role Name
              </Typography>
              <TextField
                label={"Roles"}
                variant="outlined"
                fullWidth
                size="small"
                name="roles"
                onChange={handleRolesData}
                value={rolesData.roles}
                required
                inputProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                InputLabelProps={{
                  style: { fontFamily: "poppin", fontSize: "12px" },
                }}
                error={error?.role ? true : false}
                helperText={error?.role ? error.msg : ""}
              />
            </div>
            <div className={classes.btnPosition}>
            {loader ? (
              <Button
              contained
              className={classes.addDrawerBtn}
              disabled
            >
              Loading ...
            </Button>
            ) : (
              <Button
                contained
                className={classes.addDrawerBtn}
                onClick={props.editRoles ? submitEditRoles : submitRoles}
              >
                Save
              </Button>
            )}
              
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default withAllContexts(CreateNewRole);
