import React, { useEffect } from "react";
import {
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  makeStyles,
  Modal,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import * as ALLMCORE from "@material-ui/core";
import { withRouter } from "react-router-dom";
import copy from "fast-copy";
// Components
import { RichTextfield } from "..";
// Styles
import qdmstyles from "./styles.json";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalPaper: {
    width: 740,
    backgroundColor: theme.palette.background.paper,
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
    "& > .MuiTypography-root": {
      fontSize: 14,
      color: "rgba(0, 28, 60, 1)",
      fontWeight: 600,
      textTransform: "uppercase",
    },
    "& > .MuiIconButton-root": {
      background: "rgba(1, 32, 92, 0.1)",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.1rem",
    },
  },
  modalHeaderDivider: {
    background: "#DEE5EC",
  },
  modalBodyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: "20px 16px",
  },
  modalInputContainer: {
    display: "flex",
    flexDirection: "column",
    // gap: 8,
    "& > .MuiTypography-root": {
      color: "rgb(106, 120, 136)",
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0px",
      textAlign: "left",
      textTransform: "capitalize",
      margin: "0px",
    },
  },
  modalInput: {
    width: "345px",
  },
  modalActionContainer: {
    display: "flex",
    gap: 8,
    marginLeft: "auto",
  },
  modalSaveAndAdd: {
    color: "#0071F2",
    backgroundColor: "#F1F6FC",
    borderRadius: "8px",
  },
  modalAdd: {
    backgroundColor: "#01205C",
    borderRadius: "8px",
  },
  instructionValueContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
}));

const initialDirectionsState = {
  directiontag: "",
  directiontaglocal: "",
};

function Directioncomp({ statevalue, ...props }) {
  const [state, setState] = React.useState({
    permissionData: null,
    showAddNewInstructionModal: false,
    editIndex: "",

    ...copy(initialDirectionsState),

    // Local State
    directionDtls: statevalue,
  });

  useEffect(() => {
    if (state.directionDtls) {
      setState({
        ...state,
        directionDtls: statevalue,
      });
    }
  }, [statevalue]);

  const changeState = (key, value) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleModalOpen = (editIndex) => {
    setState({
      ...state,
      showAddNewInstructionModal: true,
      editIndex: null,
    });
    // changeState("showAddNewInstructionModal", true);
    // changeState("editIndex", editIndex?.toString());
  };
  const handleModalClose = () => {
    changeState("showAddNewInstructionModal", false);
  };

  const handleAddNewClick = () => {
    handleModalOpen("");
  };

  const saveNewInstruction = () => {};

  const resetDataState = () => {
    setState((prev) => ({
      ...prev,
      // ...initialDirectionsState,
      directiontag: "",
      directiontaglocal: "",
    }));
  };

  const handleSaveClick = (editIndex, addAnother) => {
    // // 1. Save
    // saveNewInstruction();

    // // 2. Reset Data
    // // resetDataState();

    // setState({ ...state, ...copy(initialDirectionsState) });

    // // 2 a. Reset edit index
    // // changeState("editIndex", null);

    // // 3. Close the modal if not add Another
    // if (!addAnother) {
    //   handleModalClose();
    // }

    const { directiontag, directiontaglocal } = state;

    if (editIndex) {
      // Take the previous instructions
      let newInstruction = [...state?.directionDtls];

      // Update the index element with new values
      newInstruction[editIndex] = {
        ...newInstruction[editIndex],
        directiontag,
        directiontaglocal,
      };

      // Update State
      setState((prev) => ({
        ...prev,
        directionDtls: newInstruction,
        directiontag: "",
        directiontaglocal: "",
        showAddNewInstructionModal: addAnother,
        editIndex: "",
      }));

      // Save value to parent
      props.setFun(newInstruction);
    } else {
      // Create New Object for add
      let newInstructionState = {
        ...initialDirectionsState,
        directiontag,
        directiontaglocal,
      };

      // Append to existing array
      let newInstruction = [...state?.directionDtls, newInstructionState];

      // Update State
      setState((prev) => ({
        ...prev,
        directionDtls: newInstruction,
        directiontag: "",
        directiontaglocal: "",
        showAddNewInstructionModal: addAnother,
      }));

      // Save value to parent
      props.setFun(newInstruction);
    }
  };

  const handleEditClick = (index) => {
    let newInstructions = [...state?.directionDtls];
    let newInstructionsState = newInstructions[index];

    setState((prev) => ({
      ...prev,
      directiontag: newInstructionsState?.directiontag,
      directiontaglocal: newInstructionsState?.directiontaglocal,

      // Open the modal
      showAddNewInstructionModal: true,

      // Set Edit Index
      editIndex: index?.toString(),
    }));

    // handleModalOpen(index);
  };

  //   const handleDeleteClick = (index) => {
  //     let newInstructions = [...state?.directionDtls];
  //     newInstructions.splice(index, 1);
  //     changeState("directionDtls", newInstructions);

  //     // Save value to parent
  //     props.setFun(newInstructions);
  //   };

  React.useEffect(() => {}, [state]);

  const classes = useStyles();
  const { data, index, options } = props;

  return (
    <React.Fragment>
      <Paper key={"0"} id={"DlhyF"} elevation={"{3}"} style={qdmstyles.hoZb}>
        {/* Header */}
        <Grid
          container={true}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          lg={"auto"}
          item={true}
          style={qdmstyles.qkQU}
        >
          {/* Instruction Head */}
          <Grid
            key={"0"}
            id={"TgstR"}
            container={""}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            item={true}
            lg={"6"}
            xs={"6"}
            xl={"6"}
            sm={"6"}
            md={"6"}
          >
            <Typography
              key={"0"}
              id={"instructions_header"}
              align={"left"}
              color={"initial"}
              display={"initial"}
              variant={"body1"}
              style={qdmstyles.MpX}
            >
              DIRECTION
            </Typography>
          </Grid>

          {/* Instruction Add new Button */}
          {state?.directionDtls?.length === 0 && (
            <Grid
              key={"1"}
              id={"TgstR"}
              container={""}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              item={true}
            >
              <Button
                key={"0"}
                variant={"text"}
                color={"primary"}
                children={"+ Add New"}
                style={qdmstyles.gPw}
                onClick={handleAddNewClick}
              />
            </Grid>
          )}
        </Grid>

        {/* Instruction Card Section Starts */}
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xl={12}
          xs={12}
          style={{ rowGap: 10 }}
        >
          {state?.directionDtls?.map((item, index) => {
            return (
              <Paper
                key={index}
                id={"1Cfv0"}
                elevation={0}
                style={qdmstyles.QFILH}
              >
                <Grid
                  key={"1"}
                  id={"3DIMg"}
                  container={true}
                  direction={"column"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  item={true}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                  style={qdmstyles.jiamz}
                >
                  <Grid
                    key={"0"}
                    id={"roJ0A"}
                    container={true}
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                  >
                    {/* Instruction Type Starts */}
                    <Grid
                      key={"0"}
                      id={"kZfj1"}
                      container={true}
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      item={true}
                    >
                      <Grid
                        key={"0"}
                        id={"kZfj1"}
                        // container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        item={true}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        style={qdmstyles.TMqMD}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            key={"0"}
                            id={"cTAwT"}
                            align={"inherit"}
                            color={"initial"}
                            display={"initial"}
                            variant={"body1"}
                            children={"Direction (English)"}
                            style={qdmstyles.Lqop}
                          />
                          <Typography
                            onClick={() => handleEditClick(index)}
                            style={{
                              color: "#0071F2",
                              fontSize: "14px",
                              fontWeight: 500,
                              cursor: "pointer",
                            }}
                          >
                            Edit
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        key={"1"}
                        id={"kZfj1"}
                        container={""}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        lg={"12"}
                        md={"12"}
                        sm={"12"}
                        xl={"12"}
                        xs={"12"}
                        item={true}
                        style={qdmstyles.srNfC}
                      >
                        <Divider
                          key={"0"}
                          id={"JxlSx"}
                          variant={"fullWidth"}
                          orientation={"horizontal"}
                          light={""}
                          flexItem={""}
                          absolute={""}
                        />
                      </Grid>
                    </Grid>
                    {/* Instruction Type Ends */}

                    {/* Instruction Value Starts */}
                    <Grid
                      key={"1"}
                      id={"roJ0A"}
                      container={true}
                      direction={"column"}
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      style={qdmstyles.YQQt}
                    >
                      <Typography
                        key={"0"}
                        id={"cTAwT"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        gutterBottom={""}
                        style={{ ...qdmstyles.TFbkV, width: "100%" }}
                      >
                        <div className={classes.instructionValueContainer}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.directiontag,
                            }}
                          />
                          <div style={{ alignSelf: "flex-end" }}>
                            {/* <Button
                                  key={"0"}
                                  id={"seemore"}
                                  color={"primary"}
                                  children={"See More"}
                                  style={{
                                    ...qdmstyles.gPw,
                                    lineHeight: "1",
                                    padding: "0px",
                                    textDecoration: "underline",
                                  }}
                                  disableRipple
                                  onClick={() => {}}
                                ></Button> */}
                          </div>
                        </div>
                      </Typography>
                    </Grid>
                    {/* Instruction Value Ends */}
                  </Grid>
                </Grid>

                <Grid
                  key={"2"}
                  id={"3DIMg"}
                  container={true}
                  direction={"column"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  item={true}
                  lg={"12"}
                  md={"12"}
                  sm={"12"}
                  xl={"12"}
                  xs={"12"}
                  style={qdmstyles.JVES}
                >
                  <Grid
                    key={"0"}
                    id={"kZfj1"}
                    container={true}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    item={true}
                    style={qdmstyles.XZHC}
                  >
                    <Grid
                      key={"0"}
                      id={"kZfj1"}
                      container={""}
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      lg={"5"}
                      md={"5"}
                      sm={"5"}
                      xl={"5"}
                      xs={"5"}
                    >
                      <Typography
                        key={"0"}
                        id={"cTAwT"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        children={"Direction(Local Language)"}
                        style={qdmstyles.pKjj}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    key={"1"}
                    id={"kZfj1"}
                    container={""}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                    item={true}
                    style={qdmstyles.tUPl}
                  >
                    <Divider
                      key={"0"}
                      id={"JxlSx"}
                      variant={"fullWidth"}
                      orientation={"horizontal"}
                      light={""}
                      flexItem={""}
                      absolute={""}
                    />
                  </Grid>
                  <Grid
                    key={"2"}
                    id={"roJ0A"}
                    container={true}
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    lg={"12"}
                    md={"12"}
                    sm={"12"}
                    xl={"12"}
                    xs={"12"}
                  >
                    <Grid
                      key={"0"}
                      id={"roJ0A"}
                      container={true}
                      direction={"column"}
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                      lg={"12"}
                      md={"12"}
                      sm={"12"}
                      xl={"12"}
                      xs={"12"}
                      style={qdmstyles.aHRsm}
                    >
                      <Typography
                        key={"0"}
                        id={"cTAwT"}
                        align={"inherit"}
                        color={"initial"}
                        display={"initial"}
                        variant={"body1"}
                        // children={"1. Fermentum Tristique"}
                        gutterBottom={""}
                        style={{ ...qdmstyles.JVGNw, width: "100%" }}
                      >
                        <div className={classes.instructionValueContainer}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.directiontaglocal,
                            }}
                          />
                          <div style={{ alignSelf: "flex-end" }}>
                            {/* <Button
                                  key={"0"}
                                  id={"seemore"}
                                  color={"primary"}
                                  children={"See More"}
                                  style={{
                                    ...qdmstyles.gPw,
                                    lineHeight: "1",
                                    padding: "0px",
                                    textDecoration: "underline",
                                  }}
                                  disableRipple
                                  onClick={() => {}}
                                ></Button> */}
                          </div>
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Grid>
        {/* Instruction Card Section Ends */}
      </Paper>

      {/* Modal Section Starts */}
      <Modal
        open={state.showAddNewInstructionModal}
        // className={classes.modal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={handleModalClose}
      >
        <Paper className={classes.modalPaper}>
          <div className={classes.modalHeader}>
            <Typography style={{fontFamily:"pc_semibold"}}>Add Direction</Typography>

            <ALLMCORE.IconButton
              size="small"
              onClick={() =>
                changeState(
                  "showAddNewInstructionModal",
                  !state.showAddNewInstructionModal
                )
              }
            >
              <CloseRoundedIcon htmlColor="rgba(1, 32, 92, 1)" />
            </ALLMCORE.IconButton>
          </div>

          <Divider className={classes.modalHeaderDivider} />

          <div className={classes.modalBodyContainer}>
            <div className={classes.modalInputContainer}>
              <Typography variant={"body1"} style={{fontFamily:"pc_regular"}}>Direction (English)</Typography>

              <RichTextfield
                withDirectionTags
                initialValue={state?.directiontag}
                onChange={(rawValue) => changeState("directiontag", rawValue)}
              />
            </div>
            <div className={classes.modalInputContainer}>
              <Typography variant={"body1"} style={{fontFamily:"pc_regular"}}>
                Direction Local Language
              </Typography>

              <RichTextfield
                withDirectionTags
                initialValue={state?.directiontaglocal}
                onChange={(rawValue) =>
                  changeState("directiontaglocal", rawValue)
                }
              />
            </div>

            <div className={classes.modalActionContainer}>
              <Button
                variant={"contained"}
                color={"primary"}
                // children={state?.editIndex ? "Update" : "Add"}
                style={qdmstyles.CPPs}
                size="small"
                // onClick={() => handleSaveClick(state?.editIndex, false)}
                className={classes.modalAdd}
                onClick={() => handleSaveClick(state?.editIndex, false)}
              >
                {state?.editIndex ? "Update" : "Add"}
              </Button>
            </div>
          </div>
        </Paper>
      </Modal>
      {/* Modal Section Ends */}
    </React.Fragment>
  );
}

export default withRouter(Directioncomp);
